import { Exercise } from "./exercise";

// Enum for step types to enforce valid values
export enum VitovaStepType {
    CARDIO = 'cardio',
    STRENGTH = 'strength',
    FLEXIBILITY = 'flexibility',
    CHOICE = 'choiceday',
    REST = 'rest',
}

export interface VitovaActivityStep {
    id: string;
    label: string;
    type: VitovaStepType;
    isTestEffort?:boolean;

    // Cardio-Specific Properties (Running, Cycling, Swimming)
    cardio?: {
        exercise?: Exercise,
        exerciseId?:string,
        duration?: number,
        distance?: number,
        heartRate?:number,
        power?:number,
        rpe?:number,
        pace?:number,
        zone?:number,
        intensityFactor?:string,
    };

    // Strength-Specific Properties (Exercises filtered by strength)
    strength?: {
        exercise?: Exercise,
        exerciseId?:string,
        sets?: number,
        reps?: number,
        repsInSeconds?:number;
        restDuration?:number;
        weight?: number;
        rpe?: number;
        percentOf10RepMax?:number,
    };

    // Flexibility-Specific Properties (Exercises filtered by flexibility)
    flexibility?: {
        exercise?: Exercise,
        exerciseId?:string,
        sets?: number,
        holdDuration?: number,
        restDuration?:number,
        notes?: string,
        rpe?: number,
    };

    choiceday?:{
        notes?:string,
        duration?:number,
        rpe?:number,
        exercise?:string,
    }

    // Description or Notes
    description?: string; 
}

// Enum for activity types to classify activities
export enum VitovaActivityType {
    STANDARD = 'standard',
    TEST = 'test',
}

export interface VitovaActivity {
    id: string; 
    title: string; 
    type: VitovaActivityType; 
    description?: string; 
    plannedDuration: string;
    plannedDistance?:string;
    plannedRPE:string,
    plannedPower?:string,
    plannedHeartRate?:string,
    plannedPace?:string,
    steps: Array<VitovaActivityStep>; 
    compulsory:boolean;

}

export interface VitovaDay{
    id:string,
    activities:Array<VitovaActivity>
}

export interface VitovaWeek{
    id:string,
    title:string,
    description:string,
    days:Array<VitovaDay>,
}

export interface VitovaPlan {
    _id:string,
    title: string,
    description:string,
    content: Array<VitovaWeek>,
}