import * as Api from "../network/api";
import { User } from '../models/user';
import '../styles/PlansPage.css';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faCheck, faCross, faFaceSadCry, faFaceTired, faPlus, faRemove } from "@fortawesome/free-solid-svg-icons";
import { act, useEffect, useState } from "react";
import { Plan } from "../models/plan";
import Spinner from "./Spinner";
import colorPalette from "../utils/colors";
import ChartComponent from "./ChartComponent";
import { VitovaPlan, VitovaStepType } from "../models/vitovaPlan";
import GeneralChartComponent from "./GeneralChartComponent";
import { getStressScore, getWeekStressScore } from "../utils/StressLoadFunctions";
import { DBCondition } from "../models/condition";
import { Exercise } from "../models/exercise";
import { getPlanToConditionCompatibility } from "../utils/GPFunctions";


interface VitovaPlansPageLoggedInProps {
    user: User,
    onLogoutSuccessful: () => void,
    onPlanUpdated: (user:User) => void
}

const VitovaPlansPageLoggedInView = ({user, onLogoutSuccessful, onPlanUpdated}:  VitovaPlansPageLoggedInProps) => {
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [plans, setPlans] = useState<Array<VitovaPlan>>([]);
    const [loading, setLoading] = useState(true)
    const [selectingPlan, setSelectingPlan] = useState<VitovaPlan | null>(null);
    const [selectedPlan, setSelectedPlan] = useState<VitovaPlan | null>(null);
    const [viewingPlan, setViewingPlan] = useState<VitovaPlan | null>(null);

    const [conditions, setConditions] = useState<Array<DBCondition> | undefined>(undefined);


    useEffect(() => {
        getVitovaPlans();
        getConditions();
    }, []);

    async function getConditions(){
        await Api.getConditions().then(res=>{
            setConditions(res);
        });
    }

    const getChartData = (plan:VitovaPlan) => {
        let sumTSSArray = [];
        for (const day of plan.content[0].days){
            let sumTSS = 0;
            for (const activity of day.activities){
                for (const step of activity.steps){
                    sumTSS += getStressScore(step, user);
                }
            }
            sumTSSArray.push(sumTSS)
        }
        return sumTSSArray;
    }

    async function getVitovaPlans(){
        const response = await Api.getVitovaPlans().then(res=>{
            setPlans(res);
            console.log(res)
            setLoading(false)
        });
    }

    async function handleSetClicked(plan:VitovaPlan) {
        setSelectingPlan(plan)
        try {
            // Clone the user object to avoid direct mutation
            const newUser = { ...user };
            
            newUser.currentVitovaPlan = plan;
            newUser.originalVitovaPlan = plan;
        
            // Update the user data via API
            await Api.updateUser(user._id, newUser);
            onPlanUpdated(newUser)
            console.log('new user')
            console.log(newUser)
                
        } catch (error) {
            console.error('Error updating user:', error);
            alert('Failed to save changes');
        }
        setSelectingPlan(null)
    }

    return (
        <div className="plans-page">
            {loading ? (
                <Spinner text="Loading Plans"/>
            ) : (
                viewingPlan ?
                (                
                    <div className='card-enlarged' style={{backgroundColor:'#ffffff66', height:'75vh', width:'100%', alignItems:'flex-start', flexDirection:'row',}}>
                        <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', backgroundColor:`${colorPalette.default}88`, width:'100%', height:'8vh', borderRadius:'0'}}>
                            <div style={{backgroundColor:'transparent',  display:'flex', alignItems:'center', width:'100%'}}>
                                <FontAwesomeIcon className="back-button" style={{width:'2em', height:'2em', marginLeft:'0.75em', padding:'5px'}} onClick={()=>setViewingPlan(null)} icon={faArrowLeft}></FontAwesomeIcon>
                            </div>
                        </div>

                        <div style={{display:'flex', width:'100%', height:'90%', flexDirection:'row', alignItems:'flex-start'}}>

                                <div style={{display:'flex', width:'100%', height:'100%', flex:1, backgroundColor:'#f1f1f1', flexDirection:'column'}}>
                                    <label style={{color:'#565656', marginTop: '10px', padding:'5px 20px', fontSize:'1.5em', fontWeight: 'bold', fontStyle:'italic', justifyContent:'center'}}>{viewingPlan.title.toUpperCase()}</label>
                                    <label style={{color:'#565656', fontSize:'1.125em', textAlign:'justify', padding:'0px 20px', maxLines:3}}>{`${viewingPlan.description.slice(0, 400)}...`}</label>

                                    <div style={{display: 'flex', marginTop:'1vh',margin:'10px 20px',alignItems: 'flex-start', flexDirection:'column', justifyContent:'flex-start'}}>
                                        <div style={{backgroundColor: `${colorPalette.steelBlue}dd`, padding: '6px 10px', width:'100%',borderRadius: '4px', marginTop: '5px', color: '#fff', fontWeight: 'bold'}}>
                                            {`LENGTH | ${viewingPlan.content.length} Weeks`}
                                        </div>
                                        
                                        <div style={{backgroundColor: `${colorPalette.steelBlue}dd`, padding: '6px 10px', maxHeight:'35vh', overflowY:'scroll',width:'100%',borderRadius: '4px', marginTop: '5px',color: '#fff', fontWeight: 'bold'}}>
                                            <label style={{fontSize:'1.25em'}}>CONDITIONS COMPLIANCE</label>
                                            {conditions?.map((condition:DBCondition)=>(
                                                <div style={{display:'flex', flex:1, flexDirection:'column', justifyContent:'center'}}>
                                                    <div style={{display:'flex', flex:1, flexDirection:'row', gap:'10px',  alignItems:'center', padding:'5px'}}>
                                                        <label>{`- ${condition.name.toUpperCase()}`}</label>
                                                        <FontAwesomeIcon color={getPlanToConditionCompatibility(viewingPlan, condition).errors.length === 0 ? colorPalette.rpeGreen : colorPalette.rpeRed} icon={getPlanToConditionCompatibility(viewingPlan, condition).errors.length === 0 ? faCheck : faRemove}></FontAwesomeIcon>
                                                    </div>
                                                    {getPlanToConditionCompatibility(viewingPlan, condition).errors.map((error, index)=>(
                                                        <label style={{fontWeight:'normal', color:colorPalette.softRed, marginLeft:'10px'}}>{error}</label>
                                                    ))}
                                                    {/* <div style={{display:'flex', flex:1, flexDirection:'row', gap:'10px',  alignItems:'center', padding:'5px'}}>
                                                    </div> */}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <button className="set-plan-button" 
                                                style={{
                                                    fontWeight: 'bold', 
                                                    color: '#fff', 
                                                    padding: '10px 15px', 
                                                    borderRadius: '5px', 
                                                    fontStyle:'italic',
                                                    border: 'none', 
                                                    cursor: 'pointer', 
                                                    width: 'auto',
                                                    transition: 'background-color 0.3s',
                                                    margin:'0px 20px',
                                                }}
                                                disabled={selectingPlan !== null}
                                                onClick={() => handleSetClicked(viewingPlan)}
                                        >
                                            {selectingPlan === viewingPlan ? `SETTING...` : `SET AS MY PLAN`}
                                        </button>
                                </div>
                                {/* <div style={{display:'flex', width:'100%', height:'100%', flex:2, flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
                                    <label style={{color:'#565656', marginTop: '10px', marginLeft:'1em', fontSize:'1.5em', fontWeight:'bold'}}>WEEK 1 TRAINING STRESS SCORE OVERVIEW</label>
                                    <GeneralChartComponent mh={80} mw={100} chartData={viewingPlan.content.map(w=>getWeekStressScore(w, user, false))} labelData={['0','1','2','3','4','5','6']} user={user}></GeneralChartComponent>
                                </div> */}
                            </div>
                    </div>
                )
                :
                (                
                    <div style={{display:'flex', justifyContent:'center', flexDirection:'column', alignItems:'center'}}>
                        <h2 className="title" style={{fontStyle:'italic', color:colorPalette.steelBlue, fontSize:'3em', fontWeight:'normal'}}><b>VITOVA</b> PLAN LIBRARY</h2>

                        <label style={{ fontSize: '1.5em', color:'#454545', marginBottom:'10px', fontStyle:'italic', fontWeight:'normal', textAlign:'center'}} className='main-title'>OUR COLLECTION OF PLANS <b>ADAPTABLE</b> TO YOUR CONDITIONS</label>
                        <div style={{ overflowY: 'auto', maxHeight: '65vh', padding:'10px 30px'}}>
                            <ul className="plan-container" style={{ listStyleType: 'none', padding: 0 }}>
                                {plans.map((plan, index) => (
                                    <li key={index} className="plan-item" style={{}}>
                                        <div className="plan-card" onClick={() => setViewingPlan(plan)} 
                                            style={{
                                                width: '100%', 
                                                position: 'relative', 
                                                backgroundColor: `white`, 
                                                padding: '15px', 
                                                borderRadius: '8px', 
                                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                                cursor: 'pointer',
                                                transition: 'transform 0.2s'
                                            }}
                                            // onMouseOver={(e) => e.currentTarget.style.transform = 'scale(1.02)'}
                                            // onMouseOut={(e) => e.currentTarget.style.transform = 'scale(1)'}
                                            >
 
                                            <h2 style={{marginTop: '10px', marginBottom: '5px', fontSize: '20px', color: '#454545', fontWeight: 'bold', fontStyle:'italic'}}>
                                                {plan.title.toUpperCase()}
                                            </h2>
                                            
                                            <p style={{color: '#898989', fontSize: '14px', marginBottom: '10px'}}>
                                                {plan.description.length > 60 ? `${plan.description.slice(0, 60)}...` : plan.description}
                                            </p>
                                            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                                <div style={{display: 'flex', alignItems: 'center'}}>
                                                    <div style={{backgroundColor: `${colorPalette.steelBlue}`, padding: '6px 10px', borderRadius: '4px', marginRight: '10px', color: '#fff', fontWeight: 'bold'}}>
                                                        {`${plan.content.length} Weeks`}
                                                    </div>
                                                    <div style={{backgroundColor: `${colorPalette.steelBlue}dd`, padding: '6px 10px', borderRadius: '4px', marginRight: '10px',color: '#fff', fontWeight: 'bold'}}>
                                                    {`${plan.content.length} Weeks`}
                                                    </div>
                                                    <div style={{backgroundColor: `${colorPalette.steelBlue}`, padding: '6px 10px', borderRadius: '4px', marginRight:'10px',color: '#fff', fontWeight: 'bold'}}>
                                                    {`${plan.content.length} Weeks`}
                                                    </div>
                                                    <div style={{backgroundColor: `${colorPalette.steelBlue}dd`, padding: '6px 10px', borderRadius: '4px', color: '#fff', fontWeight: 'bold'}}>
                                                    {`${plan.content.length} Weeks`}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>

                                ))}
                                {/* {user.fullname === 'Nicholas Latham' && 
                                    <li className="plan-item" >
                                        <div className='plan-card' style={{ width: '100%', position: 'relative', backgroundColor: `${colorPalette.steelBlue}77`, padding: '10px' }}>
                                            <input type="file" style={{ marginBottom: '15px' }} onChange={e => handleFileOpen(e)} />
                                        </div>
                                        <button className="set-plan-button" onClick={handlePlanUpload}>Upload Plan</button>
                                    </li>
                                } */}

                            </ul>
                        </div>
                    </div>
                )
                


            )}
        </div>
    );
};

export default VitovaPlansPageLoggedInView;