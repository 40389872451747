import * as Api from "../network/api";
import { CheckboxDataObject, CheckedCondition, CheckedMedication, ChecklistDataObject, DataObject, Effort, Goal, InboxMessage, Review, StravaActivity, Test, User, UserTest, VitovaReview } from '../models/user';
import '../styles/DashboardPage.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {fa1, fa2, fa3, faAdjust, faArrowLeft,faArrowRight,faArrowsTurnRight,faBan,faBanSmoking,faBarsProgress,faBed,faBeer,faBeerMugEmpty,faBicycle,faBorderNone,faBowlFood,faBullseye,faC,faCalendar, faCalendarCheck, faCalendarDay, faCalendarDays, faCalendarWeek, faCaretSquareRight, faChartLine, faCheck, faChevronLeft, faChevronRight, faCircle, faClipboard, faClock, faCross, faDotCircle, faDownload, faDumbbell, faEllipsis, faExclamationCircle, faExpandArrowsAlt, faFlag, faGripLinesVertical, faHeart, faHeartbeat, faHeartPulse, faHiking, faHouse, faInbox, faList, faListCheck, faLongArrowAltRight, faM, faMinus, faNeuter, faNewspaper, faNotdef, faPerson, faPersonRunning, faPlay, faPlus, faRemove, faRightToBracket, faRobot, faRuler, faRunning, faSign, faSmile, faSmoking, faSmokingBan, faSpinner, faSwimmer, faTired, faUserDoctor, faVideo, faW, faWalking, faWeight, faYen, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import React, { act, useEffect, useState } from "react";
import { secondsToTimeString, timeStringToSeconds } from "../utility";
import Slider from '@mui/material/Slider';
import '../styles/SessionReview.css';
import { SliderProps } from '@mui/material/Slider'; // Ensure SliderProps is imported if necessary
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid'; // Import uuid if not already imported
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import '../styles/CustomGrid.css'; // Make sure to include the custom CSS file
import { DayLong, Document, HeightRule, Packer, Paragraph, TextRun } from "docx";
import { saveAs } from "file-saver";
import colorPalette from '../utils/colors';
import { Table } from "react-bootstrap";
import ChartComponent from "./ChartComponent";
import { getTotalActivityLength } from "../utils/ActivityStats";
import Spinner from "./Spinner";
import { Box, Button, Checkbox, Container, Dialog, DialogActions, DialogContent, DialogTitle,FormControl, FormControlLabel, Grid, Hidden, IconButton, Input, InputLabel, List, ListItem, ListItemText, MenuItem, Paper, Select, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import AnimatedBarChart from "./AnimatedBarChart";
import { Bar, Line } from "react-chartjs-2";
import DeleteIcon from '@mui/icons-material/Delete';
import { LoadingIndicator } from "react-select/dist/declarations/src/components/indicators";
import DatabasePage from "../pages/DatabasePage";
import DatabaseComponentView from "./DatabaseComponentView";
import { Exercise } from "../models/exercise";
import youtubeWorkouts from '../localdata/youtubeWorkouts.json'
import generateOutcomeLetter, { getNumberOfStrengthSessions } from "../utils/OutcomeLetterGenerator";
import { getAlcoholScoreColor, getAverageAlcoholScoreColor, getAverageNutritionColor, getAverageSleepQualityColor, getAverageSmokingScoreColor, getDateAfterPlan, getNutritionDataColor, getPlanToConditionCompatibility, getPlanToMedicationCompatibility, getSleepQualityColor, getSmokingScoreColor, getUserToPlanCompliance } from "../utils/GPFunctions";
import { VitovaActivity, VitovaActivityStep, VitovaActivityType, VitovaDay, VitovaPlan, VitovaStepType, VitovaWeek } from "../models/vitovaPlan";
import Popup from "./Popup";
import { minutesToTimeString } from "../utils/FormattingFunctions";
import { ObjectId } from 'bson'; // Import ObjectId
import GeneralChartComponent from "./GeneralChartComponent";
import { getActivityStressScore, getDayStressScore, getStressScore, getWeekStressScore } from "../utils/StressLoadFunctions";
import WorkoutStepsDiagram from "./WorkoutStepsDiagram";
import { buildStringFromStep } from "../utils/StringBuilder";
import { getRpeColourFromStep } from "../utils/TSS";
import YouTubeWorkoutsTable from "./YoutubeWorkoutsTable";
import EndOfPlanReport from "./EndOfPlanReport";
import ComparisonChartComponent from "./ComparisonChartComponent";
import DisclaimerPopup from "./DisclaimerPopup";
import ExercisePopup from "./ExercisePopup";
import { color, motion } from "framer-motion";

import { loadChoiceDayFromTemplate } from "../localdata/templateactivities";
import SpinnerIcon from "./SpinnerIcon";
import { Label } from "@mui/icons-material";
import { phq9 } from "../localdata/tests";
import RadarGraph from "./RadarGraph";
import { DBCondition } from "../models/condition";
import { DBMedication } from "../models/medication";
import CustomActivityReviewDialog from "./CustomActivityReviewDialog";


interface VitovaDashboardPageLoggedInProps {
    user: User,
    onLogoutSuccessful: () => void,
}

const activityIcons: Record<VitovaStepType, IconDefinition> = {
    'cardio':faRunning,
    'strength': faDumbbell,
    'flexibility': faDumbbell,
    'rest': faBed,
    'choiceday': faHouse
};

const exerciseIcons: Record<string, IconDefinition> = {
    'Running':faRunning,
    'Walking': faWalking,
    'Cycling': faBicycle,
    'Swimming': faSwimmer,
    'Trail Running':faRunning
};

const drinksData = [
    { name: 'Beer (Pint, 4%)', units: 2.0 },
    { name: 'Beer (Bottle, 330ml, 5%)', units: 1.7 },
    { name: 'Wine (Glass, 175ml, 12%)', units: 2.1 },
    { name: 'Wine (Bottle, 750ml, 12%)', units: 9.0 },
    { name: 'Spirit (Single, 25ml, 40%)', units: 1.0 },
    { name: 'Cocktail (Average)', units: 1.5 }
  ];

const VitovaDashboardPageLoggedInView = ({ user, onLogoutSuccessful }: VitovaDashboardPageLoggedInProps) => {
    const [currentUser, setCurrentUser] = useState<User>(user);
    const [selectedDay, setSelectedDay] = useState<VitovaDay |null>(null);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isYoutubeWorkoutPopupOpen, setIsYoutubeWorkoutPopupOpen] = useState(false);

    const handleDayClick = (day:VitovaDay) => {
            getActivityReview(day.activities[selectedActivityIndex]).then(()=>{
            setSelectedDay(day);
            setIsPopupOpen(true);
        })
    };

    const [isModalOpen, setIsModalOpen] = useState(false);

    const [reviewType, setReviewType] = useState('');
    const [userPlan, setUserPlan] = useState<VitovaPlan>(user.currentVitovaPlan);
    const [reviewingActivity, setReviewingActivity] = useState<VitovaActivity | null>(null);
    const [selectedReview, setSelectedReview] = useState<VitovaReview | undefined>(undefined)

    const [reviewStage, setReviewStage] = useState(0);
    const [duration, setDuration] = useState(-1);
    const [distance, setDistance] = useState(-1);

    const [medications, setMedications] = useState<DBMedication[]>([]);
    const [conditions, setConditions] = useState<DBCondition[]>([]);

    const [pace, setPace] = useState(-1);
    const [power, setPower] = useState(-1);
    const [weight, setWeight] = useState(
        currentUser?.weightData?.length > 0 
            ? currentUser.weightData.at(-1)?.value?.toString() ?? ''
            : ''
    );
    
    const [waist, setWaistCircumference] = useState(
        currentUser?.waistData?.length > 0 
            ? currentUser.waistData.at(-1)?.value?.toString() ?? ''
            : ''
    );
    
    const [bp, setBp] = useState(
        currentUser?.bpData?.length > 0 
            ? currentUser.bpData.at(-1)?.value?.toString() ?? ''
            : ''
    );
    
    const [hba1c, setHba1c] = useState(
        currentUser?.hba1cData?.length > 0 
            ? currentUser.hba1cData.at(-1)?.value?.toString() ?? ''
            : ''
    );
    
    const [hdl, setHdl] = useState(
        currentUser?.hdlData?.length > 0 
            ? currentUser.hdlData.at(-1)?.value?.toString() ?? ''
            : ''
    );
    
    const [ldl, setLdl] = useState(
        currentUser?.ldlData?.length > 0 
            ? currentUser.ldlData.at(-1)?.value?.toString() ?? ''
            : ''
    );
    
    const [cholesterol, setCholesterol] = useState(
        currentUser?.cholesterolData?.length > 0 
            ? currentUser.cholesterolData.at(-1)?.value?.toString() ?? ''
            : ''
    );
    
    

    const [heartRate, setHeartRate] = useState(100);
    const [effort, setEffort] = useState(5);
    const [sleepQuality, setSleepQuality] = useState(5);
    const [isExercisePopupOpen, setIsExercisePopupOpen] = useState(false);
    const [popupExercise, setPopupExercise] = useState<Exercise | undefined>(undefined);
    const [popupStep, setPopupStep] = useState<VitovaActivityStep | undefined>(undefined);

    const [selectedActivityIndex, setSelectedActivityIndex] = useState(0);
    const [loading, setLoading] = useState(true);

    const [next7Days, setNext7Days] = useState<VitovaDay[]>([]);

    const [openTestDialog,setOpenTestDialog] = useState<Test | undefined>(undefined)

    const [showCalendar, setShowCalendar] = useState(false);
    const [showRightPanel, setShowRightPanel] = useState(false);
    const [selectedTab, setSelectedTab] = useState('');

    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [reviewLoading, setReviewLoading] = useState(false);
    const [openTabReviewPopup, setOpenTabReviewPopup] = useState(false)

    const [cigaretteInput, setCigaretteInput] = useState(0);
    const [loadingSmokerChange, setLoadingSmokerChange] = useState(false)

    const [alcoholInput, setAlcoholInput] = useState(0);
    const [loadingDrinkerChange, setLoadingDrinkerChange] = useState(false)

    const [selectedDrink, setSelectedDrink] = useState(drinksData[0]);
    const [quantity, setQuantity] = useState(1);
    const [drinksList, setDrinksList] = useState<{ name: string; units: number; quantity: number }[]>([]);
  
    const totalUnits = drinksList.reduce((sum, drink) => sum + drink.units * drink.quantity, 0);

    const [sleepingInput, setSleepingInput] = useState(0);

    const [stravaActivities, setStravaActivities] = useState<Array<any>>([])

    const [openTestResult, setOpenTestResult] = useState(Array(30).fill(0));

    const handlePHQ9SliderChange = (index: number, value: number) => {
        const updatedResults = [...openTestResult];
        updatedResults[index] = value;
        setOpenTestResult(updatedResults);
    };

    const [nutritionChecklistQuestions, setNutritionChecklistQuestions] = useState<Array<CheckboxDataObject>>([
        {
            label: "Eaten breakfast",
            checked: false,
        },
        {
            label: "5 portions of fruit / veg per day.",
            checked: false,
        },
        {
            label: currentUser.info.weight  ? `Around ${Math.round(currentUser.info.weight * 1.2)}g (1.2 x bodyweight) of protein eaten.` : `Around 1.2g x bodyweight (kg) of protein eaten.`,
            checked: false,
        },
        {
            label: "Wholegrain bread or brown rice and pasta used over white.",
            checked: false,
        },
        {
            label: "No more than 2 juice drinks, soft drinks, or energy drinks.",
            checked: false,
        },
    ]);

    const [nutritionInput, setNutritionInput] = useState<Array<CheckboxDataObject>>(nutritionChecklistQuestions)

    const [editingExercise, setEditingExercise] = useState<Exercise>();

    const [showFinalReport, setShowFinalReport] = useState(false);

    const [sendingMessage, setSendingMessage] = useState(false);


    const [isCustomActivityReviewDialogOpen, setIsCustomActivityReviewDialogOpen] = useState(false);
    const [exercises, setExercises] = useState<Exercise[]>([]);

    const [viewportSize, setViewportSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    const [selectedWorkoutPart, setSelectedWorkoutPart] = useState(0)

    const [loadingAddGoal, setLoadingAddGoal] = useState(false);
    const [loadingRemoveGoal, setLoadingRemoveGoal] = useState<Goal | undefined>(undefined);

    // Calculate today's index within the current week
    const startDate = new Date(currentUser.userPlanStartDate);
    const currentDate = new Date();
    const diffInMilliseconds = currentDate.getTime() - startDate.getTime();
    const millisecondsPerDay = 24 * 60 * 60 * 1000;
    const diffInDays = Math.floor(diffInMilliseconds / millisecondsPerDay);
    // alert(diffInDays)
    const todayIndex = diffInDays % 7;

    // Ensure todayIndex is within bounds
    const todaysIndex = todayIndex < 0 ? 0 : todayIndex;

    const [adjustedTodayIndex, setAdjustedTodayIndex] = useState(todaysIndex);

      const addDrink = () => {
        const newDrink = { ...selectedDrink, quantity };
        setDrinksList([...drinksList, newDrink]);
      };
    
      const removeDrink = (index:number) => {
        setDrinksList(drinksList.filter((_, i) => i !== index));
      };

    const [newGoal, setNewGoal] = useState({
        specific: '',
        measurable: '',
        achievable: '',
        relevant: '',
        timeBound: '',
      });

      const handleSubmitModalValue = async () => {
        const newWeightValue = parseFloat(weight);
        const newWaistValue = parseFloat(waist);
    
        if (!isNaN(newWeightValue) && !isNaN(newWaistValue)) {
            try {
                await submitPhysicalMeasurementValues();
    
                // Close the modal and reset the input fields
                setIsModalOpen(false);
            } catch (error) {
                console.error('Error submitting values:', error);
                alert('Failed to save changes');
            }
        } else {
            alert('Please enter a valid number');
        }
    };
    

    const toggleCalendar = () => {
        getNext7Days();
        setReviewingActivity(null);
        setSelectedDay(null);
        setReviewStage(0);
        setReviewType('manual');
        setSelectedActivityIndex(0);

        setShowCalendar(!showCalendar);
    };

    const toggleRightPanel = (tab?:string) => {
        if (tab){
            setSelectedTab(tab)
        }
        setShowRightPanel(!showRightPanel);
    };
    
    const handleInputChange = (field:string, value:string) => {
    setNewGoal({ ...newGoal, [field]: value });
    };

    const handleSubmit = async () => {
        setLoadingAddGoal(true);
        // Assuming there’s a function to handle adding goals
        if (
            newGoal.specific &&
            newGoal.measurable &&
            newGoal.achievable &&
            newGoal.relevant &&
            newGoal.timeBound
        ) {
            const newUser = { ...currentUser };
            const today = new Date();
            newUser.goals.push({
                date: today,
                content: { ...newGoal },
            })

            try {
                const response = await Api.updateUser(currentUser._id, newUser);
                setCurrentUser(response); // Update currentUser only after response is received
                setNewGoal({ specific: '', measurable: '', achievable: '', relevant: '', timeBound: '' });
                } catch (error) {
                console.error('Error updating user:', error);
                alert('An error occurred while updating your goals.');
                }
                

            // alert('Goal submitted for review!');
        } else {
            alert('Please fill out all fields.');
        }
        setLoadingAddGoal(false);
    };
    const handleRemoveGoal = async (goalToRemove: Goal) => {
    setLoadingRemoveGoal(goalToRemove);
    try {
        // Create a copy of the current user to avoid directly mutating state
        const updatedUser = { ...currentUser };
    
        // Filter out the goal to be removed
        updatedUser.goals = updatedUser.goals.filter(
        (goal) => goal !== goalToRemove
        );
    
        // Update the user's goals on the server
        const response = await Api.updateUser(currentUser._id, updatedUser);
    
        // Update the state with the response from the server
        setCurrentUser(response);
    
    } catch (error) {
        console.error('Error removing goal:', error);
        alert('An error occurred while removing the goal. Please try again.');
    }
    setLoadingRemoveGoal(undefined);
    };
      
    const handleSend = async () => {
        setSendingMessage(true);
        // Check if both subject and message are provided
        if (!subject || !message) {
            alert("Both subject and message are required.");
            setSendingMessage(false)
            return;
        }

        const now = new Date();
    
        const messageToSend: InboxMessage = {
            _id:uuidv4(),
            subject: subject,
            content: message,
            senderId: currentUser._id,
            dateSent: now,
            acknowledged: false
        };
    
        try {
            // Handle sending the message (e.g., API call)
            const response = await Api.sendMessage(currentUser.gpReferralCode, messageToSend);

            let newUser = {...currentUser}

            newUser.inbox = response;

            setCurrentUser(newUser);
    
            // Clear the form fields after sending the message
            setSubject('');
            setMessage('');
        } catch (error) {
            // Handle API call failure (e.g., network issue, server down)
            console.error("Error sending message:", error);
            alert("There was an issue sending the message. Please try again later.");
        }
        setSendingMessage(false);
    };

    useEffect(() => {
        const handleResize = () => {
            setViewportSize({
                width: window.innerWidth,
                height: window.innerHeight
            });
        };
        // Add the resize event listener
        window.addEventListener('resize', handleResize);

        adaptPlanLength()

        if (currentUser.currentVitovaPlan){
            getNext7Days();
        }

        getExercises();
        getMedications();
        getConditions();

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    async function submitSmokingValue(passedUser?:User) {
        if (isNaN(Number(cigaretteInput))) {
            alert('Input must be a number');
        } else {
            try {
                let newUser = passedUser ? passedUser : { ...currentUser };
    
                // Get the user's smokingData and create a copy
                let newSmokingData = [...newUser.smokingData];
                const now = new Date();
    
                // Create new data entry for the current day
                const newValue: DataObject = {
                    value: Number(cigaretteInput),
                    date: now
                };
    
                // Helper to check if a date exists in the data
                const isDatePresent = (data: DataObject[], date: Date): boolean => {
                    return data.some(entry => {
                        const entryDate = new Date(entry.date);
                        return (
                            entryDate.getFullYear() === date.getFullYear() &&
                            entryDate.getMonth() === date.getMonth() &&
                            entryDate.getDate() === date.getDate()
                        );
                    });
                };
    
                // Add today's data first
                if (!isDatePresent(newSmokingData, now)) {
                    newSmokingData.push(newValue);
                }
    
                // Fill missing days if any
                const fillMissingDays = (data: DataObject[]): DataObject[] => {
                    const filledData = [...data];
                    const currentDate = new Date();
    
                    // Sort smokingData by date (ascending)
                    filledData.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
    
                    // Find the earliest recorded date or default to today
                    const startDate = filledData.length
                        ? new Date(filledData[0].date)
                        : currentDate;
    
                    // Loop through all dates from startDate to today
                    const allDates = [];
                    for (
                        let d = new Date(startDate);
                        d <= currentDate;
                        d.setDate(d.getDate() + 1)
                    ) {
                        allDates.push(new Date(d));
                    }
    
                    // Add missing dates with default values (e.g., null)
                    allDates.forEach(date => {
                        if (!isDatePresent(filledData, date)) {
                            filledData.push({
                                value: -1, // Default value for missing days
                                date: new Date(date)
                            });
                        }
                    });
    
                    // Sort again by date after adding missing days
                    return filledData.sort(
                        (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
                    );
                };
    
                // Ensure all days are accounted for
                newSmokingData = fillMissingDays(newSmokingData);
    
                // Update user information
                newUser.smokingData = newSmokingData;
    
                const response = await Api.updateUser(newUser._id, newUser);
    
                // Update the current user in the application state
                setCurrentUser(response);
                return response;
            } catch (error) {
                console.error('Error updating user:', error);
                alert('Failed to save changes');
            }
        }
    }

    async function submitAlcoholValue(passedUser?:User) {
        if (isNaN(Number(totalUnits))) {
            alert('Input must be a number');
        } else {
            try {
                let newUser = passedUser ? passedUser : { ...currentUser };
    
                // Get the user's drinkingData and create a copy
                let newDrinkingData = [...newUser.drinkingData];
                const now = new Date();
    
                // Create new data entry for the current day
                const newValue: DataObject = {
                    value: Number(totalUnits),
                    date: now
                };
    
                // Helper to check if a date exists in the data
                const isDatePresent = (data: DataObject[], date: Date): boolean => {
                    return data.some(entry => {
                        const entryDate = new Date(entry.date);
                        return (
                            entryDate.getFullYear() === date.getFullYear() &&
                            entryDate.getMonth() === date.getMonth() &&
                            entryDate.getDate() === date.getDate()
                        );
                    });
                };
    
                // Add today's data first
                if (!isDatePresent(newDrinkingData, now)) {
                    newDrinkingData.push(newValue);
                }
    
                // Fill missing days if any
                const fillMissingDays = (data: DataObject[]): DataObject[] => {
                    const filledData = [...data];
                    const currentDate = new Date();
    
                    // Sort drinkingData by date (ascending)
                    filledData.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
    
                    // Find the earliest recorded date or default to today
                    const startDate = filledData.length
                        ? new Date(filledData[0].date)
                        : currentDate;
    
                    // Loop through all dates from startDate to today
                    const allDates = [];
                    for (
                        let d = new Date(startDate);
                        d <= currentDate;
                        d.setDate(d.getDate() + 1)
                    ) {
                        allDates.push(new Date(d));
                    }
    
                    // Add missing dates with default values (e.g., null)
                    allDates.forEach(date => {
                        if (!isDatePresent(filledData, date)) {
                            filledData.push({
                                value: -1, // Default value for missing days
                                date: new Date(date)
                            });
                        }
                    });
    
                    // Sort again by date after adding missing days
                    return filledData.sort(
                        (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
                    );
                };
    
                // Ensure all days are accounted for
                newDrinkingData = fillMissingDays(newDrinkingData);
    
                // Update user information
                newUser.drinkingData = newDrinkingData;
    
                const response = await Api.updateUser(newUser._id, newUser);
    
                // Update the current user in the application state
                setCurrentUser(response);
                return response;
            } catch (error) {
                console.error('Error updating user:', error);
                alert('Failed to save changes');
            }
        }
    }

    async function submitNutritionData(passedUser?:User) {
        try {
            let newUser = passedUser ? passedUser : { ...currentUser };
    
            // Get the user's nutritionData and create a copy
            let newNutritionData = [...newUser.nutritionData];
            const now = new Date();
    
            // Create new data entry for the current day
            const newValue: ChecklistDataObject = {
                value: nutritionInput,
                date: now
            };
    
            // Helper to check if a date exists in the data
            const isDatePresent = (data: ChecklistDataObject[], date: Date): boolean => {
                return data.some(entry => {
                    const entryDate = new Date(entry.date);
                    return (
                        entryDate.getFullYear() === date.getFullYear() &&
                        entryDate.getMonth() === date.getMonth() &&
                        entryDate.getDate() === date.getDate()
                    );
                });
            };
    
            // Add today's data first
            if (!isDatePresent(newNutritionData, now)) {
                newNutritionData.push(newValue);
            }
    
            // Fill missing days if any
            const fillMissingDays = (data: ChecklistDataObject[]): ChecklistDataObject[] => {
                const filledData = [...data];
                const currentDate = new Date();
    
                // Sort nutritionData by date (ascending)
                filledData.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
    
                // Find the earliest recorded date or default to today
                const startDate = filledData.length
                    ? new Date(filledData[0].date)
                    : currentDate;
    
                // Loop through all dates from startDate to today
                const allDates = [];
                for (
                    let d = new Date(startDate);
                    d <= currentDate;
                    d.setDate(d.getDate() + 1)
                ) {
                    allDates.push(new Date(d));
                }
    
                // Add missing dates with default values (e.g., empty string or null)
                allDates.forEach(date => {
                    if (!isDatePresent(filledData, date)) {
                        filledData.push({
                            value: [], // Default value for missing days (empty string)
                            date: new Date(date)
                        });
                    }
                });
    
                // Sort again by date after adding missing days
                return filledData.sort(
                    (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
                );
            };
    
            // Ensure all days are accounted for
            newNutritionData = fillMissingDays(newNutritionData);
    
            // Update user information
            newUser.nutritionData = newNutritionData;
    
            const response = await Api.updateUser(newUser._id, newUser);
    
            // Update the current user in the application state
            setCurrentUser(response);
            return response
        } catch (error) {
            console.error('Error updating user:', error);
            alert('Failed to save changes');
        }
    }
    async function submitSleepingValue(passedUser?:User) {
        if (isNaN(Number(sleepingInput))) {
            alert('Input must be a number');
        } else {
            try {
                let newUser = passedUser ? passedUser : { ...currentUser };
    
                // Get the user's sleepingData and create a copy
                let newSleepingData = [...newUser.sleepingData];
                const now = new Date();
    
                // Create new data entry for the current day
                const newValue: DataObject = {
                    value: Number(sleepingInput),
                    date: now
                };
    
                // Helper to check if a date exists in the data
                const isDatePresent = (data: DataObject[], date: Date): boolean => {
                    return data.some(entry => {
                        const entryDate = new Date(entry.date);
                        return (
                            entryDate.getFullYear() === date.getFullYear() &&
                            entryDate.getMonth() === date.getMonth() &&
                            entryDate.getDate() === date.getDate()
                        );
                    });
                };
    
                // Add today's data first
                if (!isDatePresent(newSleepingData, now)) {
                    newSleepingData.push(newValue);
                }
    
                // Fill missing days if any
                const fillMissingDays = (data: DataObject[]): DataObject[] => {
                    const filledData = [...data];
                    const currentDate = new Date();
    
                    // Sort sleepingData by date (ascending)
                    filledData.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
    
                    // Find the earliest recorded date or default to today
                    const startDate = filledData.length
                        ? new Date(filledData[0].date)
                        : currentDate;
    
                    // Loop through all dates from startDate to today
                    const allDates = [];
                    for (
                        let d = new Date(startDate);
                        d <= currentDate;
                        d.setDate(d.getDate() + 1)
                    ) {
                        allDates.push(new Date(d));
                    }
    
                    // Add missing dates with default values (e.g., null)
                    allDates.forEach(date => {
                        if (!isDatePresent(filledData, date)) {
                            filledData.push({
                                value: -1, // Or any default value you'd like for missing days
                                date: new Date(date)
                            });
                        }
                    });
    
                    // Sort again by date after adding missing days
                    return filledData.sort(
                        (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
                    );
                };
    
                // Ensure all days are accounted for
                newSleepingData = fillMissingDays(newSleepingData);
    
                // Update user information
                newUser.sleepingData = newSleepingData;
    
                const response = await Api.updateUser(newUser._id, newUser);
    
                // Update the current user in the application state
                setCurrentUser(response);
                return response;
            } catch (error) {
                console.error('Error updating user:', error);
                alert('Failed to save changes');
            }
        }
    }
    async function submitWeightValue(value: number) {
        try {
            let newUser = { ...currentUser };
    
            // Get the user's weightData and create a copy
            let newWeightData = [...currentUser.weightData];
            const now = new Date();
    
            // Create new data entry for the current day
            const newValue: DataObject = {
                value: Number(value),
                date: now
            };
    
            // Helper to check if a date exists in the data
            const isDatePresent = (data: DataObject[], date: Date): boolean => {
                return data.some(entry => {
                    const entryDate = new Date(entry.date);
                    return (
                        entryDate.getFullYear() === date.getFullYear() &&
                        entryDate.getMonth() === date.getMonth() &&
                        entryDate.getDate() === date.getDate()
                    );
                });
            };
    
            // Helper to update weight if the date exists or add new entry
            const updateWeightIfDateExists = (data: DataObject[], date: Date, newWeight: number): void => {
                const index = data.findIndex(entry => {
                    const entryDate = new Date(entry.date);
                    return (
                        entryDate.getFullYear() === date.getFullYear() &&
                        entryDate.getMonth() === date.getMonth() &&
                        entryDate.getDate() === date.getDate()
                    );
                });
    
                if (index !== -1) {
                    // Update the weight if the date exists
                    data[index].value = newWeight;
                } else {
                    // Otherwise, add the new entry
                    data.push({ value: newWeight, date: date });
                }
            };
    
            // Update the weight for today's date
            updateWeightIfDateExists(newWeightData, now, Number(newValue.value));
    
            // Fill missing days if any
            const fillMissingDays = (data: DataObject[]): DataObject[] => {
                const filledData = [...data];
                const currentDate = new Date();
    
                // Sort data by date (ascending)
                filledData.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
    
                // Find the earliest recorded date or default to today
                const startDate = filledData.length ? new Date(filledData[0].date) : currentDate;
    
                // Loop through all dates from startDate to today
                const allDates = [];
                for (let d = new Date(startDate); d <= currentDate; d.setDate(d.getDate() + 1)) {
                    allDates.push(new Date(d));
                }
    
                // Add missing dates with default values (e.g., -1)
                allDates.forEach(date => {
                    if (!isDatePresent(filledData, date)) {
                        filledData.push({
                            value: -1, // Default value for missing days
                            date: new Date(date)
                        });
                    }
                });
    
                // Sort again by date after adding missing days
                return filledData.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
            };
    
            // Ensure all days are accounted for
            newWeightData = fillMissingDays(newWeightData);
    
            // Update user information
            newUser.weightData = newWeightData;
    
            const response = await Api.updateUser(newUser._id, newUser);
            setCurrentUser(response);
    
        } catch (error) {
            console.error('Error updating user:', error);
            alert('Failed to save changes');
        }
    }

    async function submitPhysicalMeasurementValues() {
        try {
            let newUser = { ...currentUser };
    
            // Get the user's measurement data and create copies
            let newWeightData = [...currentUser.weightData];
            let newWaistData = [...currentUser.waistData];
            let newBpData = [...currentUser.bpData];
            let newHba1cData = [...currentUser.hba1cData];
            let newHdlData = [...currentUser.hdlData];
            let newLdlData = [...currentUser.ldlData];
            let newCholesterolData = [...currentUser.cholesterolData];
            const now = new Date();
    
            // Helper to check if a date exists in the data
            const isDatePresent = (data: DataObject[], date: Date): boolean => {
                return data.some(entry => {
                    const entryDate = new Date(entry.date);
                    return (
                        entryDate.getFullYear() === date.getFullYear() &&
                        entryDate.getMonth() === date.getMonth() &&
                        entryDate.getDate() === date.getDate()
                    );
                });
            };
    
            // Helper to update a value if the date exists or add new entry
            const updateValueIfDateExists = (data: DataObject[], date: Date, newValue: number|string): void => {
                const index = data.findIndex(entry => {
                    const entryDate = new Date(entry.date);
                    return (
                        entryDate.getFullYear() === date.getFullYear() &&
                        entryDate.getMonth() === date.getMonth() &&
                        entryDate.getDate() === date.getDate()
                    );
                });
    
                if (index !== -1) {
                    // Update the value if the date exists
                    data[index].value = newValue;
                } else {
                    // Otherwise, add the new entry
                    data.push({ value: newValue, date: date });
                }
            };
    
            // Helper to fill missing days with default values
            const fillMissingDays = (data: DataObject[]): DataObject[] => {
                const filledData = [...data];
                const currentDate = new Date();
    
                filledData.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
                const startDate = filledData.length ? new Date(filledData[0].date) : currentDate;
    
                const allDates = [];
                for (let d = new Date(startDate); d <= currentDate; d.setDate(d.getDate() + 1)) {
                    allDates.push(new Date(d));
                }
    
                allDates.forEach(date => {
                    if (!isDatePresent(filledData, date)) {
                        filledData.push({
                            value: -1, // Default value for missing days
                            date: new Date(date)
                        });
                    }
                });
    
                return filledData.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
            };
    
            // Update each physical measurement
            updateValueIfDateExists(newWeightData, now, Number(weight));
            updateValueIfDateExists(newWaistData, now, Number(waist));
            updateValueIfDateExists(newBpData, now, bp);
            updateValueIfDateExists(newHba1cData, now, Number(hba1c));
            updateValueIfDateExists(newHdlData, now, Number(hdl));
            updateValueIfDateExists(newLdlData, now, Number(ldl));
            updateValueIfDateExists(newCholesterolData, now, Number(cholesterol));
    
            // Fill missing days for each measurement
            newWeightData = fillMissingDays(newWeightData);
            newWaistData = fillMissingDays(newWaistData);
            newBpData = fillMissingDays(newBpData);
            newHba1cData = fillMissingDays(newHba1cData);
            newHdlData = fillMissingDays(newHdlData);
            newLdlData = fillMissingDays(newLdlData);
            newCholesterolData = fillMissingDays(newCholesterolData);
    
            // Update user information
            newUser.weightData = newWeightData;
            newUser.waistData = newWaistData;
            newUser.bpData = newBpData;
            newUser.hba1cData = newHba1cData;
            newUser.hdlData = newHdlData;
            newUser.ldlData = newLdlData;
            newUser.cholesterolData = newCholesterolData;
    
            const response = await Api.updateUser(newUser._id, newUser);
            setCurrentUser(response);
    
        } catch (error) {
            console.error('Error updating user:', error);
            alert('Failed to save changes');
        }
    }

    async function hanldeTestResultSubmit() {
        if (openTestDialog){
            try {
                let newUser = { ...currentUser };
                let newTestData = [...(currentUser?.tests || [])];
                
                const now = new Date();
                const newTestResult:UserTest = {
                    test:openTestDialog,
                    dateTaken:now,
                    result:openTestResult.slice(0,openTestDialog?.questions.length)
                }
                newTestData.push(newTestResult)
        
                // Update user information
                newUser.tests = newTestData;
        
                const response = await Api.updateUser(newUser._id, newUser);
                setCurrentUser(response);
                setOpenTestDialog(undefined);
                setOpenTestResult(Array(30).fill(0));
        
            } catch (error) {
                console.error('Error updating user:', error);
                alert('Failed to save changes');
            }
        }else{
            alert('No Test Found')
        }

    }

    
    
    
    async function submitWaistCircumferenceValue(value: number) {
        try {
            let newUser = { ...currentUser };
    
            // Get the user's waistData and create a copy
            let newWaistData = [...currentUser.waistData];
            const now = new Date();
    
            // Create new data entry for the current day
            const newValue: DataObject = {
                value: Number(value),
                date: now
            };
    
            // Helper to check if a date exists in the data
            const isDatePresent = (data: DataObject[], date: Date): boolean => {
                return data.some(entry => {
                    const entryDate = new Date(entry.date);
                    return (
                        entryDate.getFullYear() === date.getFullYear() &&
                        entryDate.getMonth() === date.getMonth() &&
                        entryDate.getDate() === date.getDate()
                    );
                });
            };
    
            // Helper to update waist circumference if the date exists or add new entry
            const updateWaistCircumferenceIfDateExists = (data: DataObject[], date: Date, newValue: number): void => {
                const index = data.findIndex(entry => {
                    const entryDate = new Date(entry.date);
                    return (
                        entryDate.getFullYear() === date.getFullYear() &&
                        entryDate.getMonth() === date.getMonth() &&
                        entryDate.getDate() === date.getDate()
                    );
                });
    
                if (index !== -1) {
                    // Update the waist circumference if the date exists
                    data[index].value = newValue;
                } else {
                    // Otherwise, add the new entry
                    data.push({ value: newValue, date: date });
                }
            };
    
            // Update the waist circumference for today's date
            updateWaistCircumferenceIfDateExists(newWaistData, now, Number(newValue.value));
    
            // Fill missing days if any
            const fillMissingDays = (data: DataObject[]): DataObject[] => {
                const filledData = [...data];
                const currentDate = new Date();
    
                // Sort data by date (ascending)
                filledData.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
    
                // Find the earliest recorded date or default to today
                const startDate = filledData.length ? new Date(filledData[0].date) : currentDate;
    
                // Loop through all dates from startDate to today
                const allDates = [];
                for (let d = new Date(startDate); d <= currentDate; d.setDate(d.getDate() + 1)) {
                    allDates.push(new Date(d));
                }
    
                // Add missing dates with default values (e.g., -1)
                allDates.forEach(date => {
                    if (!isDatePresent(filledData, date)) {
                        filledData.push({
                            value: -1, // Default value for missing days
                            date: new Date(date)
                        });
                    }
                });
    
                // Sort again by date after adding missing days
                return filledData.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
            };
    
            // Ensure all days are accounted for
            newWaistData = fillMissingDays(newWaistData);
    
            // Update user information
            newUser.waistData = newWaistData;
    
            const response = await Api.updateUser(newUser._id, newUser);
    
            // Update the current user in the application state
            setCurrentUser(response);
    
        } catch (error) {
            console.error('Error updating user:', error);
            alert('Failed to save changes');
        }
    }

    async function submitRetrospectiveNutritionValue(date: Date | string, questionIndex:number): Promise<void> {
        try {
            // Create a copy of the current user
            let updatedUser = { ...currentUser };

            // Ensure drinkingData has a proper type
            let updatedNutritionData: ChecklistDataObject[] = [...updatedUser.nutritionData];

            // Helper to find the entry by date
            const findEntryByDate = (data: ChecklistDataObject[], targetDate: Date | string): ChecklistDataObject | undefined => {
                return data.find(entry => {
                    const entryDate = new Date(entry.date);
                    const target = new Date(targetDate);
                    return (
                        entryDate.getFullYear() === target.getFullYear() &&
                        entryDate.getMonth() === target.getMonth() &&
                        entryDate.getDate() === target.getDate()
                    );
                });
            };

            // Find the entry matching the provided date
            const entryToUpdate = findEntryByDate(updatedNutritionData, date);
            console.log(entryToUpdate)

            if (entryToUpdate && entryToUpdate.value.length > 0) {
                // Update the value for the matching entry
                entryToUpdate.value[questionIndex].checked = !entryToUpdate.value[questionIndex].checked;
            } 
            else if (entryToUpdate){
                entryToUpdate.value = nutritionChecklistQuestions.map((i) => ({ label: i.label, checked: false }));
                entryToUpdate.value[questionIndex].checked = true;
            }
            else {
                // If no matching entry exists, add a new one
                updatedNutritionData.push({
                    value: nutritionChecklistQuestions.map((i) => ({ label: i.label, checked: false })),
                    date: new Date(date) // Ensure date is stored in correct format
                });
            }

            // Sort drinkingData by date (ascending)
            updatedNutritionData.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

            // Update the user's drinkingData
            updatedUser.nutritionData = updatedNutritionData;
            // setCurrentUser(updatedUser)
            // Send the updated user data to the API
            const response = await Api.updateUser(updatedUser._id, updatedUser);

            // Update the application state with the updated user
            setCurrentUser(response);
        } catch (error) {
            console.error('Error updating user:', error);
            alert('Failed to save retrospective value');
        }
    }
    
    async function submitRetrospectiveSmokingValue(newValue: string | number, date: Date | string): Promise<void> {
        if (isNaN(Number(newValue))) {
            alert('Input must be a number');
        } else {
            try {
                // Create a copy of the current user
                let updatedUser = { ...currentUser };
    
                // Ensure drinkingData has a proper type
                let updatedSmokingData: DataObject[] = [...updatedUser.smokingData];
    
                // Helper to find the entry by date
                const findEntryByDate = (data: DataObject[], targetDate: Date | string): DataObject | undefined => {
                    return data.find(entry => {
                        const entryDate = new Date(entry.date);
                        const target = new Date(targetDate);
                        return (
                            entryDate.getFullYear() === target.getFullYear() &&
                            entryDate.getMonth() === target.getMonth() &&
                            entryDate.getDate() === target.getDate()
                        );
                    });
                };
    
                // Find the entry matching the provided date
                const entryToUpdate = findEntryByDate(updatedSmokingData, date);
    
                if (entryToUpdate) {
                    // Update the value for the matching entry
                    entryToUpdate.value = Number(newValue);
                } else {
                    // If no matching entry exists, add a new one
                    updatedSmokingData.push({
                        value: Number(newValue),
                        date: new Date(date) // Ensure date is stored in correct format
                    });
                }
    
                // Sort drinkingData by date (ascending)
                updatedSmokingData.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
    
                // Update the user's drinkingData
                updatedUser.smokingData = updatedSmokingData;
    
                // Send the updated user data to the API
                const response = await Api.updateUser(updatedUser._id, updatedUser);
    
                // Update the application state with the updated user
                setCurrentUser(response);
            } catch (error) {
                console.error('Error updating user:', error);
                alert('Failed to save retrospective value');
            }
        }
    }

    async function submitRetrospectiveAlcoholValue(newValue: string | number, date: Date | string): Promise<void> {
        if (isNaN(Number(newValue))) {
            alert('Input must be a number');
        } else {
            try {
                // Create a copy of the current user
                let updatedUser = { ...currentUser };
    
                // Ensure drinkingData has a proper type
                let updatedDrinkingData: DataObject[] = [...updatedUser.drinkingData];
    
                // Helper to find the entry by date
                const findEntryByDate = (data: DataObject[], targetDate: Date | string): DataObject | undefined => {
                    return data.find(entry => {
                        const entryDate = new Date(entry.date);
                        const target = new Date(targetDate);
                        return (
                            entryDate.getFullYear() === target.getFullYear() &&
                            entryDate.getMonth() === target.getMonth() &&
                            entryDate.getDate() === target.getDate()
                        );
                    });
                };
    
                // Find the entry matching the provided date
                const entryToUpdate = findEntryByDate(updatedDrinkingData, date);
    
                if (entryToUpdate) {
                    // Update the value for the matching entry
                    entryToUpdate.value = Number(newValue);
                } else {
                    // If no matching entry exists, add a new one
                    updatedDrinkingData.push({
                        value: Number(newValue),
                        date: new Date(date) // Ensure date is stored in correct format
                    });
                }
    
                // Sort drinkingData by date (ascending)
                updatedDrinkingData.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
    
                // Update the user's drinkingData
                updatedUser.drinkingData = updatedDrinkingData;
    
                // Send the updated user data to the API
                const response = await Api.updateUser(updatedUser._id, updatedUser);
    
                // Update the application state with the updated user
                setCurrentUser(response);
            } catch (error) {
                console.error('Error updating user:', error);
                alert('Failed to save retrospective value');
            }
        }
    }

    async function submitRetrospectiveSleepingValue(newValue: string | number, date: Date | string): Promise<void> {
        if (isNaN(Number(newValue))) {
            alert('Input must be a number');
        } else {
            try {
                // Create a copy of the current user
                let updatedUser = { ...currentUser };
    
                // Ensure drinkingData has a proper type
                let updatedSleepingData: DataObject[] = [...updatedUser.sleepingData];
    
                // Helper to find the entry by date
                const findEntryByDate = (data: DataObject[], targetDate: Date | string): DataObject | undefined => {
                    return data.find(entry => {
                        const entryDate = new Date(entry.date);
                        const target = new Date(targetDate);
                        return (
                            entryDate.getFullYear() === target.getFullYear() &&
                            entryDate.getMonth() === target.getMonth() &&
                            entryDate.getDate() === target.getDate()
                        );
                    });
                };
    
                // Find the entry matching the provided date
                const entryToUpdate = findEntryByDate(updatedSleepingData, date);
    
                if (entryToUpdate) {
                    // Update the value for the matching entry
                    entryToUpdate.value = Number(newValue);
                } else {
                    // If no matching entry exists, add a new one
                    updatedSleepingData.push({
                        value: Number(newValue),
                        date: new Date(date) // Ensure date is stored in correct format
                    });
                }
    
                // Sort drinkingData by date (ascending)
                updatedSleepingData.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
    
                // Update the user's drinkingData
                updatedUser.sleepingData = updatedSleepingData;
    
                // Send the updated user data to the API
                const response = await Api.updateUser(updatedUser._id, updatedUser);
    
                // Update the application state with the updated user
                setCurrentUser(response);
            } catch (error) {
                console.error('Error updating user:', error);
                alert('Failed to save retrospective value');
            }
        }
    }
    
    async function handleSmokerChange() {  
        setLoadingSmokerChange(true)
        try {
            let newUser = { ...currentUser };

            newUser.smoker = !newUser.smoker

            const response = await Api.updateUser(newUser._id, newUser);

            setCurrentUser(response)
        } catch (error) {
            console.error('Error updating user:', error);
            alert('Failed to save changes');
        }
        setLoadingSmokerChange(false)
    }

    async function handleDrinkerChange() {  
        setLoadingDrinkerChange(true)
        try {
            let newUser = { ...currentUser };

            newUser.drinker = !newUser.drinker

            const response = await Api.updateUser(newUser._id, newUser);

            setCurrentUser(response)
        } catch (error) {
            console.error('Error updating user:', error);
            alert('Failed to save changes');
        }
        setLoadingDrinkerChange(false)
    }

    async function getExercises(){
        await Api.getExercises().then(res=>{
            setExercises(res);
        });
    }

    async function getMedications(){
        await Api.getMedications().then(res=>{
            setMedications(res);
        });
    }

    async function getConditions(){
        await Api.getConditions().then(res=>{
            setConditions(res);
        });
    }

    async function adaptPlanLength() {
        console.log("Function called");
        await Api.adaptPlanLength(currentUser._id, {
            surgeryDate: currentUser.surgeryDate,
            currentVitovaPlan: currentUser.currentVitovaPlan
        }).then(res => {
            setCurrentUser(res);

            getNext7Days();
            console.log("Response received:", res);
        }).catch(error => {
            console.error("Error in API call:", error);
        });
    }
    

    const handlePaceChange: SliderProps['onChange'] = (event:any, value:any) => {
        setPace(value as number);
    };

    const handlePowerChange: SliderProps['onChange'] = (event:any, value:any) => {
        setPower(value as number);
    };

    const handleDistanceChange: SliderProps['onChange'] = (event:any, value:any) => {
        setDistance(value as number);
    };

    const getRandom11DigitNumber = () => Math.floor(10000000000 + Math.random() * 90000000000);


    const nextStage = () => setReviewStage(prevStage => prevStage + 1);
    const prevStage = () => setReviewStage(prevStage => Math.max(prevStage - 1, 0));

    function getNext7Days():VitovaDay[] {
        if (showFinalReport){
            setNext7Days(currentUser.currentVitovaPlan.content[currentUser.currentVitovaPlan.content.length-1].days);
            return currentUser.currentVitovaPlan.content[currentUser.currentVitovaPlan.content.length-1].days; // Return default days for the first week
        }

        // Get the current date (reset time to midnight to avoid time-related issues)
        const startDate = new Date(currentUser.userPlanStartDate);
        const currentDate = new Date();
        
        // Reset time to midnight to avoid time-related inconsistencies
        currentDate.setHours(0, 0, 0, 0);
        startDate.setHours(0, 0, 0, 0);
        
        // Convert dates to timestamps (number of milliseconds since epoch)
        const timeDifference = startDate.getTime() - currentDate.getTime();
        const numberOfDaysUntilPlanStart = Math.ceil(timeDifference / (1000 * 60 * 60 * 24)) < 7 ? Math.ceil(timeDifference / (1000 * 60 * 60 * 24)) : 7;
                
        if (numberOfDaysUntilPlanStart > 0) {
            let prePlanDays: VitovaDay[] = [];
        
            for (let i = 0; i < numberOfDaysUntilPlanStart; i++) {
                prePlanDays.push({
                    id: `prePlanDay_${getRandom11DigitNumber()}`,
                    activities: loadChoiceDayFromTemplate(20),
                });
            }
        
            setNext7Days(prePlanDays);
            setLoading(false);
            return prePlanDays;
        }
        
        // Ensure both dates are valid Date objects
        if (isNaN(startDate.getTime()) || isNaN(currentDate.getTime())) {
            throw new Error("Invalid Date");
        }
    
        // Calculate the difference in milliseconds
        const diffInMilliseconds = currentDate.getTime() - startDate.getTime();
        
        // Convert milliseconds to days
        const millisecondsPerDay = 24 * 60 * 60 * 1000;
        const diffInDays = Math.floor(diffInMilliseconds / millisecondsPerDay);

        // const diffInDays = 2
        // Calculate the current week and day within that week
        let weekIndex = Math.floor((diffInDays + 1) / 7);

        // Adjust for index bounds
        if (weekIndex < 0) {
            weekIndex = 0;
        }
        setLoading(false)
        
        if (weekIndex >= currentUser.currentVitovaPlan.content.length) {
            setNext7Days(currentUser.currentVitovaPlan.content[currentUser.currentVitovaPlan.content.length-1].days)
            setShowFinalReport(true);
            return currentUser.currentVitovaPlan.content[currentUser.currentVitovaPlan.content.length-1].days; // Return default days for the last week
        } else {
            setNext7Days(currentUser.currentVitovaPlan.content[weekIndex].days)
            return currentUser.currentVitovaPlan.content[weekIndex].days;
        }
    }

    const onDragEnd = async (result: DropResult) => {
        if (!result.destination) return;
    
        // Step 1: Reorder next7Days (we'll swap the items directly)
        const reorderedItems = Array.from(next7Days);
        const [removed] = reorderedItems.splice(result.source.index, 1);
        reorderedItems.splice(result.destination.index, 0, removed);
        
        // Step 2: Swap the `day.day` properties between the two swapped items
        const swappedItems = [...reorderedItems];
        const sourceItem = reorderedItems[result.destination.index];
        const destinationItem = reorderedItems[result.source.index];

        if (isDayInThePast(destinationItem) || isDayReviewed(destinationItem)){
            alert('Reviewed and past days cannot be moved')
            return
        }
        
        // Swap the `day.day` of the source and destination items
        const tempDay = sourceItem.id;
        sourceItem.id = destinationItem.id;
        destinationItem.id = tempDay;
        
        // Step 3: Update next7Days state
        setNext7Days(swappedItems);
        
        // Step 4: Update currentUser.currentVitovaPlan.content
        const weekIndex = getWeekNumber(reorderedItems[0]); // Assuming all items belong to the same week
        
        // We now correctly swap the `day.day` while updating the days in the content
        const updatedContent = currentUser.currentVitovaPlan.content.map((week: VitovaWeek, index: number) => {
            if (index === weekIndex) {
                return {
                    ...week,
                    days: swappedItems,  // Use the swapped items with their new `day.day`
                };
            }
            return week; // No change for other weeks
        });
    
        // Step 5: Set the updated currentUser.currentVitovaPlan.content
        const updatedUser = {
            ...currentUser,
            currentVitovaPlan: {
                ...currentUser.currentVitovaPlan,
                content: updatedContent,
            },
        };
    
        setCurrentUser(updatedUser);
    
        // Step 6: Call the API with the updated user
        try {
            await Api.updateUser(currentUser._id, updatedUser);
            console.log("User updated successfully!");
        } catch (error) {
            console.error("Error updating user:", error);
        }
    };
    
    function getDateFromDay(start: Date, week: number, day: number): Date {
        // Clone the start date to avoid mutating the original date
        const resultDate = new Date(start);

        // Calculate the total number of days to add
        const totalDaysToAdd = week * 7 + day;
        
        // Add the total days to the new date
        resultDate.setDate(resultDate.getDate() + totalDaysToAdd);
        
        // Return the new date
        return resultDate;
    }
    
    const generateMarks = () => {
        const marks = [];
        for (let seconds = 60; seconds <= 540; seconds += 5) {
            marks.push({ value: seconds });
        }
        return marks;
    };

    const marks = generateMarks();

    const sliderStyling = {
        color: 'gray', // or use a hex code like '#1976d2'
        height: 48, // height of the slider track
        width: '60em',
        borderRadius:'1px',
        margin:'5px 20px',
        '& .MuiSlider-thumb': {
            height: 48, // size of the thumb
            width: 6,
            backgroundColor: '#fff',
            borderRadius:'0px',
            // border: '2px solid currentColor',
            '&:hover': {
                boxShadow: 0,
                backgroundColor:colorPalette.steelBlue
            },
            '&.Mui-active': {
                boxShadow: 0,
                backgroundColor:colorPalette.steelBlue
            },
        },
        '& .MuiSlider-rail': {
            height: 48,
        },
        '& .MuiSlider-track': {
            height: 48,
        },
    }

    function secondsToPaceString(totalSeconds:number) {
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;
    
        const padWithZero = (num:number) => num.toString().padStart(2, '0');
    
        return `${padWithZero(hours)}:${padWithZero(minutes)}:${padWithZero(seconds)}`;
    }

    function getWeekNumber(day:VitovaDay){
        for (var i =0;i<userPlan.content.length;i++){
            if (userPlan.content[i].days.find((d:VitovaDay)=>d.id === day.id)){
                return i;
            }
        }

        return 0
    }

    function getDayNumber(day:VitovaDay){
        const findIndex = next7Days.findIndex(d=>d.id === day.id);
        if (findIndex && findIndex > 0){
            return findIndex

        }
        return 0
    }
    function getCalendarDay(dateString: string, week: number, day: number) {
        // Convert initial date string to a Date object
        let startDate = new Date(dateString);
        // Get the current date (reset time to midnight to avoid time-related issues)
        const currentDate = new Date();
        // Reset time to midnight to avoid time-related inconsistencies
        currentDate.setHours(0, 0, 0, 0);
        startDate.setHours(0, 0, 0, 0);

        if (startDate > currentDate){
            startDate = currentDate
        }

        // Number of days to add (corrected by removing +1)
        const daysToAdd = week * 7 + day;
    
        // Clone the initial date to avoid mutating it directly
        const newDate = new Date(startDate);
    
        // Add the correct number of days
        newDate.setDate(newDate.getDate() + daysToAdd);
    
        // Get the day of the month from the new date
        const dayOfMonth = newDate.getDate();
        
        // Format the day to include "th", "st", "nd", "rd" suffixes
        function getDayWithSuffix(day: number) {
            if (day >= 11 && day <= 13) {
                return `${day}th`;
            }
            switch (day % 10) {
                case 1:
                    return `${day}st`;
                case 2:
                    return `${day}nd`;
                case 3:
                    return `${day}rd`;
                default:
                    return `${day}th`;
            }
        }
        
        const formattedDay = getDayWithSuffix(dayOfMonth);
        return formattedDay;
    }

    function getDayOfWeek(dateString: string, week: number, day: number) {
        // Convert initial date string to a Date object
        let startDate = new Date(dateString);
        // Get the current date (reset time to midnight to avoid time-related issues)
        const currentDate = new Date();
        // Reset time to midnight to avoid time-related inconsistencies
        currentDate.setHours(0, 0, 0, 0);
        startDate.setHours(0, 0, 0, 0);

        if (startDate > currentDate){
            startDate = currentDate
        }
        
        // Number of days to add (corrected by removing +1)
        const daysToAdd = week * 7 + day;
        
        // Clone the initial date to avoid mutating it directly
        const newDate = new Date(startDate);
        
        // Add the correct number of days
        newDate.setDate(newDate.getDate() + daysToAdd);
        
        // Get the day of the week from the new date (0 = Sunday, 1 = Monday, etc.)
        const dayOfWeek = newDate.getDay();
        
        // Array for day names
        const dayNames = [
            'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'
        ];
    
        // Return the day name
        return dayNames[dayOfWeek];
    }
    
    function isDayComplete(day:VitovaDay){
        const compulsoryActivities = day.activities.filter(a=>a.compulsory).length;
        let completedActivities = 0;
        for (const activity of day.activities){
            const findReview = currentUser.vitovaReviews.find(review=> review.activityReviewData.id === activity.id && review.saved);

            if (findReview){
                completedActivities += 1;
            }
        }
        return completedActivities >= compulsoryActivities;
    }

    function isDayReviewed(day: VitovaDay) {
        // Calculate the day of the week index from the day string
        const dayIndex = next7Days.findIndex(d=>d.id===day.id);
        
        // Calculate the date of the day based on the startDate and getWeekNumber(day)
        const dayDate = new Date(currentUser.userPlanStartDate.toString());
        dayDate.setDate(dayDate.getDate() + getWeekNumber(day) * 7 + dayIndex);
    
        // Get today's date without time for accurate comparison
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        const compulsoryActivities = day.activities.filter(a=>a.compulsory).length;
        let completedActivities = 0;

        // Check if all activities are reviewed
        for (const activity of day.activities) {
            const findReview = currentUser.vitovaReviews.find(review => review.activityReviewData.id === activity.id && review.saved === true);
            
            if (!findReview) {
                // If the day has passed and an activity is not reviewed, return false
                if (today > dayDate) {
                    return false;
                }
            }else{
                completedActivities += 1;
            }
        }

        if (completedActivities >= compulsoryActivities){
            return true;
        }
    
        return false;
    }

    function milliSecondsToDate(value: number): string {
        const date = new Date(value);
        const day = String(date.getDate()).padStart(2, '0'); // Get the day and pad with leading zero if needed
        const monthNames = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];
        const month = monthNames[date.getMonth()]; // Get the month name
        const year = date.getFullYear(); // Get the year
    
        return `${day} ${month} ${year}`;
    }

    function formatMinutes(value:number){
        if (value >= 0) {
            // Calculate hours and remaining minutes
            let hours = Math.floor(value / 60);
            let minutes = Math.floor(value % 60);
            let seconds = Math.round((value % 1) * 60);
    
            // Format hours, minutes, and seconds
            let hoursStr = hours > 0 ? hours + " hour" + (hours !== 1 ? "s" : "") + " " : "";
            let minutesStr = minutes > 0 ? minutes + " minute" + (minutes !== 1 ? "s" : "") + " " : "";
            let secondsStr = seconds > 0 ? seconds + " second" + (seconds !== 1 ? "s" : "") : "";
    
            // Combine the formatted string
            return (hoursStr + minutesStr + secondsStr).trim();
        } else {
            return "Invalid value";
        }
    }

    async function getActivityReview(activity: VitovaActivity) {
        setReviewLoading(true);

        const findReview = currentUser.vitovaReviews.find(r => r.activityReviewData.id === activity.id);
    
        if (findReview) {
            // Return Found Review
            setSelectedReview(findReview);
        } else {
            if (selectedDay){
                // Update user by adding a new review
                const today = new Date();
                const newReview: VitovaReview = {
                    id: new ObjectId().toHexString(),
                    activityReviewData: activity,
                    dateOfActivity:getDateFromDay(currentUser.userPlanStartDate, getWeekNumber(selectedDay), getDayNumber(selectedDay)),
                    dateReviewed: today,
                    saved:false,
                    notes: ''
                };
                
                // Ensure currentUser.vitovaReviews is being updated correctly
                const updatedUser = { 
                    ...currentUser, 
                    vitovaReviews: [...currentUser.vitovaReviews, newReview] 
                };
        
                try {
                    const res = await Api.updateUser(updatedUser._id, updatedUser);
                    setCurrentUser(res);
                    setSelectedReview(newReview);
                } catch (error) {
                    console.error("Error updating user: ", error);
                    throw error;
                }
            }else{
                console.log("Couldn't retrieve the day")
            }
        }
        setReviewLoading(false)
    }

    async function updateReviewField(activity: VitovaActivity, fieldPath: string, value: any) {
        const findReview = currentUser.vitovaReviews.find(r => r.activityReviewData.id === activity.id);
        
        if (!findReview) {
            console.error("Review not found for the given activity.");
            return;
        }
    
        // Helper function to update a nested field based on the provided fieldPath
        const updateNestedField = (obj: any, path: string, value: any): void => {
            const keys = path.split('.'); // Split path into an array of keys
            let currentObj = obj;
    
            // Traverse the object to the last key
            keys.slice(0, -1).forEach((key: string) => {
                if (!currentObj[key]) {
                    currentObj[key] = {}; // If the key doesn't exist, create an empty object
                }
                currentObj = currentObj[key];
            });
    
            // Set the value at the final key
            currentObj[keys[keys.length - 1]] = value;
        };
    
        // Update the specific field using the fieldPath
        const updatedReview = { 
            ...findReview,
        };
    
        updateNestedField(updatedReview, fieldPath, value);
    
        // Ensure currentUser.vitovaReviews is being updated correctly
        const updatedUser = {
            ...currentUser,
            vitovaReviews: currentUser.vitovaReviews.map(review => 
                review.id === updatedReview.id ? updatedReview : review
            )
        };
    

        setCurrentUser(updatedUser);
        setSelectedReview(updatedReview);
    }
    

    function getDayIndex() {
        const currentDay = new Date().getDay(); // Sunday is 0, Monday is 1, ..., Saturday is 6
        return (currentDay === 0) ? 6 : currentDay - 1; // Adjust to make Monday 0, Tuesday 1, ..., Sunday 6
    }    

    async function onSaveCustomReviews(submit:boolean, reviews:VitovaReview[]) {
        try {
            const userReviews = [...reviews]; // Clone to avoid mutating state directly

            // Update user without adding best efforts
            const updatedUser = {
                ...currentUser,
                vitovaReviews: userReviews,
            };
        
            await Api.updateUser(currentUser._id, updatedUser);
            setIsCustomActivityReviewDialogOpen(false);
            
        } catch (error) {
            console.error("Failed to save review changes:", error);
            alert("Failed to save review changes");
        }
    }

    async function onSaveReview(submit:boolean) {
        if (!selectedDay){
            return;
        }
        try {
            const userReviews = [...currentUser.vitovaReviews]; // Clone to avoid mutating state directly
    
            const findReview = userReviews.find(
                (r) => r.activityReviewData.id === selectedDay.activities[selectedActivityIndex].id
            );
    
            if (findReview) {
                if (submit){
                    findReview.saved = true; // Ensure the review is saved
                }
            } else {
                console.warn("No matching review found");
                return; // Exit if no review found
            }

            // CHECK DATA IS THERE

            const testSteps = findReview.activityReviewData.steps.filter(s=>s.isTestEffort);
            for (const step of testSteps){
                if (step.type === VitovaStepType.CARDIO){
                    if (!step.cardio?.duration || !step.cardio.distance){
                        alert('Please enter duration and distance on cardio tests')
                        return;
                    }
                    else{
                        if (step.cardio.duration <= 0 || step.cardio.distance <= 0){
                            alert('Please enter duration and distance on cardio tests')
                            return;
                        }
                    }
                }
                if (step.type === VitovaStepType.STRENGTH){
                    if (!step.strength?.reps){
                        alert('Please enter reps on strength tests')
                        return;
                    }
                    else{
                        if (step.strength.reps <= 0){
                            alert('Please enter at least 1 rep strength tests')
                            return;
                        }
                    }
                }
            }
            const today = new Date();
            if (testSteps.length > 0) {
                // Collect all best efforts before updating
                const newBestEfforts = testSteps.map((bestEffortStep) => ({
                    id: new ObjectId().toHexString(),
                    date: today,
                    activity: findReview.activityReviewData,
                    step: bestEffortStep,
                }));
            
                const updatedBestEfforts = [...currentUser.bestEfforts, ...newBestEfforts];
            
                // Update the user object with modified reviews and best efforts ONCE
                const updatedUser = {
                    ...currentUser,
                    vitovaReviews: userReviews,
                    bestEfforts: updatedBestEfforts,
                };
            
                await Api.updateUser(currentUser._id, updatedUser);  // Single API call
            
                setIsPopupOpen(false); // Close popup on success
                setReviewingActivity(null);
            } else {
                // Update user without adding best efforts
                const updatedUser = {
                    ...currentUser,
                    vitovaReviews: userReviews,
                };
            
                await Api.updateUser(currentUser._id, updatedUser);
                setIsPopupOpen(false);
                setReviewingActivity(null);
            }
            setReviewStage(0);
            setReviewingActivity(null);
            setReviewType('manual');
            
        } catch (error) {
            console.error("Failed to save review changes:", error);
            alert("Failed to save review changes");
        }
    }
    

    function getActivityColor(activityType:string){
        switch (activityType){
            case 'Run':
                return colorPalette.steelBlue;  // Soft Red
            case 'Strength':
                return colorPalette.steelBlue;  // Light Pink
            case 'Swim':
                return colorPalette.steelBlue;  // Dark Turquoise
            case 'Bike':
                return colorPalette.steelBlue;  // Medium Purple
            case 'Other':
                return colorPalette.steelBlue;  // Gold
            default:
                return colorPalette.steelBlue;  // Steel Blue
        }
    }

    function hasSubmittedSmokingDataToday (){
        const today = new Date();
        const isValueForToday = currentUser.smokingData.some(data => {
        const dataDate = new Date(data.date);
        return (
            dataDate.getFullYear() === today.getFullYear() &&
            dataDate.getMonth() === today.getMonth() &&
            dataDate.getDate() === today.getDate()
        );
        });

        if (!isValueForToday) {
            return false;
        } else {
            return true;
        }

    }

    function hasSubmittedDrinkingDataToday (){
        const today = new Date();
        const isValueForToday = currentUser.drinkingData.some(data => {
        const dataDate = new Date(data.date);
        return (
            dataDate.getFullYear() === today.getFullYear() &&
            dataDate.getMonth() === today.getMonth() &&
            dataDate.getDate() === today.getDate()
        );
        });

        if (!isValueForToday) {
            return false;
        } else {
            return true;
        }

    }

    function hasSubmittedSleepingDataToday (){
        const today = new Date();
        const isValueForToday = currentUser.sleepingData.some(data => {
        const dataDate = new Date(data.date);
        return (
            dataDate.getFullYear() === today.getFullYear() &&
            dataDate.getMonth() === today.getMonth() &&
            dataDate.getDate() === today.getDate()
        );
        });

        if (!isValueForToday) {
            return false;
        } else {
            return true;
        }

    }

    const addActivity = async () => {
        if (!selectedDay) {
            console.error('Missing required state: selectedDay');
            return;
        }
    
        // Define the activity to be added
        const activity: VitovaActivity = {
            id: `extra_activity_${Date.now()}`,
            type: VitovaActivityType.STANDARD,
            title: `Patient Added Activity`,
            description: "",
            plannedDuration: '60',
            plannedRPE: '5',
            steps: [],
            compulsory: false,
        };
    
        try {
            const currentPlan = currentUser.currentVitovaPlan;
            const weekIndex = getWeekNumber(selectedDay);
            const dayIndex = getDayNumber(selectedDay);
            const currentWeek = currentPlan.content[weekIndex];
            const currentDay = currentWeek.days[dayIndex];
    
            // Clone the current week and days to ensure immutability
            const updatedDays = [...currentWeek.days];
            const updatedDay = {
                ...currentDay,
                activities: [...(currentDay.activities || []), activity],
            };
            updatedDays[dayIndex] = updatedDay;
    
            const updatedWeek = {
                ...currentWeek,
                days: updatedDays,
            };
    
            // Update the overall plan content
            const updatedContent = [...currentPlan.content];
            updatedContent[weekIndex] = updatedWeek;
    
            const updatedPlan = {
                ...currentPlan,
                content: updatedContent,
            };
    
            // Update user object
            const updatedUser = {
                ...currentUser,
                currentVitovaPlan: updatedPlan,
            };
    
            // Save updated user data
            const user = await Api.updateUser(currentUser._id, updatedUser);
            setCurrentUser(user);
            setNext7Days(updatedWeek.days);
            setSelectedDay(updatedDay);
        } catch (error) {
            console.error('Error updating activity:', error);
        }
    };
    

    const deleteActivity = async (activityId: string) => {
        if (!selectedDay) {
            console.error('Missing required state: selectedDay');
            return;
        }
    
        try {
            const currentPlan = currentUser.currentVitovaPlan;
            const weekIndex = getWeekNumber(selectedDay);
            const dayIndex = getDayNumber(selectedDay);
            const currentWeek = currentPlan.content[weekIndex];
            const currentDay = currentWeek.days[dayIndex];
    
            // Filter out the activity to delete
            const updatedActivities = currentDay.activities.filter(activity => activity.id !== activityId);
    
            // Clone and update the day
            const updatedDays = [...currentWeek.days];
            const updatedDay = {
                ...currentDay,
                activities: updatedActivities,
            };
            updatedDays[dayIndex] = updatedDay;
    
            const updatedWeek = {
                ...currentWeek,
                days: updatedDays,
            };
    
            // Update the overall plan content
            const updatedContent = [...currentPlan.content];
            updatedContent[weekIndex] = updatedWeek;
    
            const updatedPlan = {
                ...currentPlan,
                content: updatedContent,
            };
    
            // Update user object
            const updatedUser = {
                ...currentUser,
                currentVitovaPlan: updatedPlan,
            };
    
            // Save updated user data
            const user = await Api.updateUser(currentUser._id, updatedUser);
            setCurrentUser(user);
            setNext7Days(updatedWeek.days);
            setSelectedDay(updatedDay);
        } catch (error) {
            console.error('Error deleting activity:', error);
        }
    };
    

    function hasSubmittedNutritionDataToday (){
        const today = new Date();
        const isValueForToday = currentUser.nutritionData.some(data => {
        const dataDate = new Date(data.date);
        return (
            dataDate.getFullYear() === today.getFullYear() &&
            dataDate.getMonth() === today.getMonth() &&
            dataDate.getDate() === today.getDate()
        );
        });

        if (!isValueForToday) {
            return false;
        } else {
            return true;
        }

    }
    function isActivityReviewed (activity:VitovaActivity){
        const userReviews = currentUser.vitovaReviews;

        const findReview = userReviews.filter(r=>r.activityReviewData.id === activity.id && r.saved);

        if (findReview.length>0){
            return true;
        }else{
            return false;
        }
    }

    function isDayInThePast(day: VitovaDay) {
        // Assuming currentUser and next7Days are accessible in this scope
        const userPlanStartDate = new Date(currentUser.userPlanStartDate); // Convert start date to Date object
        const today = new Date(); // Get today's date
    
        // Get the number of weeks and convert it to days (1 week = 7 days)
        const weekNumber = getWeekNumber(day);
        const daysFromWeeks = (weekNumber * 7) + 1;
    
        // Find the index of the day in next7Days
        const dayIndex = next7Days.indexOf(day);
        
        if (dayIndex === -1) {
            throw new Error("The specified day is not in the next7Days array.");
        }
    
        // Calculate the final date by adding the days from weeks and dayIndex to the userPlanStartDate
        const finalDate = new Date(userPlanStartDate);
        finalDate.setDate(finalDate.getDate() + daysFromWeeks + dayIndex);
    
        // Check if the final date is before today
        return finalDate < today;
    }
    
    function isDayInTheFuture(day: VitovaDay) {
        const userPlanStartDate = new Date(currentUser.userPlanStartDate);
        const todaysDate = new Date();
    
        const weekNumber = getWeekNumber(day);
        if (isNaN(weekNumber)) {
            throw new Error("Invalid week number.");
        }

        const daysFromWeeks = weekNumber * 7;
    
        const dayIndex = next7Days.findIndex(d => d.id === day.id);
        if (dayIndex === -1) {
            throw new Error("The specified day is not in the next7Days array.");
        }
    
        if ((userPlanStartDate > todaysDate) && (dayIndex === 0)){
            return false;
        }

        // Calculate activity date
        const activityDate = new Date(userPlanStartDate);
        activityDate.setDate(activityDate.getDate() + daysFromWeeks + dayIndex);
        
        // Normalize activityDate to midnight
        activityDate.setHours(0, 0, 0, 0);
    
        // Debugging logs
        console.log("activityDate:", activityDate, "today:", todaysDate);
        console.log("activityDate timestamp:", activityDate.getTime(), "today timestamp:", todaysDate.getTime());
    
        return activityDate.getTime() >= todaysDate.getTime();
    }
    
    // async function submitAllTabs() {
    //     try {
    //         let updatedUser = await submitAlcoholValue();
    //         if (updatedUser) {
    //             const results = await Promise.all([
    //                 submitSleepingValue(updatedUser),
    //                 submitNutritionData(updatedUser),
    //                 submitSmokingValue(updatedUser)
    //             ]);
                
    //             // If any of the promises return an updated user, pick the last resolved value
    //             updatedUser = results.find(res => res) || updatedUser;
    //         }
    //     } catch (error) {
    //         alert('Something Went Wrong');
    //     } finally {
    //         setOpenTabReviewPopup(false);
    //     }
    // }

    async function submitAllTabs() {
        try {
            // Create a deep copy of currentUser to modify it safely
            let updatedUser = { ...currentUser };
    
            // Update Alcohol Data
            if (!isNaN(Number(alcoholInput))) {
                const now = new Date();
                let newDrinkingData = [...updatedUser.drinkingData];
    
                const newValue = { value: Number(alcoholInput), date: now };
    
                if (!newDrinkingData.some(entry => isSameDate(entry.date, now))) {
                    newDrinkingData.push(newValue);
                }
                updatedUser.drinkingData = fillMissingDays(newDrinkingData);
            }
    
            // Update Sleeping Data
            if (!isNaN(Number(sleepingInput))) {
                const now = new Date();
                let newSleepingData = [...updatedUser.sleepingData];
    
                const newValue = { value: Number(sleepingInput), date: now };
    
                if (!newSleepingData.some(entry => isSameDate(entry.date, now))) {
                    newSleepingData.push(newValue);
                }
                updatedUser.sleepingData = fillMissingDays(newSleepingData);
            }
    
            // Update Nutrition Data
            const now = new Date();
            let newNutritionData = [...updatedUser.nutritionData];
    
            const newNutritionValue = { value: nutritionInput, date: now };
    
            if (!newNutritionData.some(entry => isSameDate(entry.date, now))) {
                newNutritionData.push(newNutritionValue);
            }
                
            // Fill missing days if any
            const fillNutritionMissingDays = (data: ChecklistDataObject[]): ChecklistDataObject[] => {
                const filledData = [...data];
                const currentDate = new Date();
            
                // Sort data by date (ascending)
                filledData.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
            
                const startDate = filledData.length ? new Date(filledData[0].date) : currentDate;
                
                const allDates = [];
                for (let d = new Date(startDate); d <= currentDate; d.setDate(d.getDate() + 1)) {
                    allDates.push(new Date(d));
                }
            
                allDates.forEach(date => {
                    if (!filledData.some(entry => isSameDate(entry.date, date))) {
                        filledData.push({ value: [], date: new Date(date) });
                    }
                });
            
                return filledData.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
            };
    
            // Ensure all days are accounted for
            newNutritionData = fillNutritionMissingDays(newNutritionData);
    
            // Update user information
            updatedUser.nutritionData = newNutritionData;
    
            // Update Smoking Data
            if (!isNaN(Number(cigaretteInput))) {
                const now = new Date();
                let newSmokingData = [...updatedUser.smokingData];
    
                const newValue = { value: Number(cigaretteInput), date: now };
    
                if (!newSmokingData.some(entry => isSameDate(entry.date, now))) {
                    newSmokingData.push(newValue);
                }
                updatedUser.smokingData = fillMissingDays(newSmokingData);
            }
    
            // Submit all updates once
            const response = await Api.updateUser(updatedUser._id, updatedUser);
    
            // Update application state
            setCurrentUser(response);
    
        } catch (error) {
            console.error('Error updating user:', error);
            alert('Something Went Wrong');
        } finally {
            setOpenTabReviewPopup(false);
        }
    }
    
    // Helper function to check if two dates are the same (ignoring time)
    function isSameDate(date1:string|Date, date2:string|Date) {
        const d1 = new Date(date1);
        const d2 = new Date(date2);
        return d1.getFullYear() === d2.getFullYear() &&
               d1.getMonth() === d2.getMonth() &&
               d1.getDate() === d2.getDate();
    }
    
    // Function to fill missing days in the dataset
    function fillMissingDays(data:DataObject[]) {
        const filledData = [...data];
        const currentDate = new Date();
    
        // Sort data by date (ascending)
        filledData.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
    
        const startDate = filledData.length ? new Date(filledData[0].date) : currentDate;
        
        const allDates = [];
        for (let d = new Date(startDate); d <= currentDate; d.setDate(d.getDate() + 1)) {
            allDates.push(new Date(d));
        }
    
        allDates.forEach(date => {
            if (!filledData.some(entry => isSameDate(entry.date, date))) {
                filledData.push({ value: -1, date: new Date(date) });
            }
        });
    
        return filledData.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
    }
    
    
    
    const formatAsShortDayName = (date:Date) => {
        const dayLabel = new Intl.DateTimeFormat("en-GB", {
            weekday: "short"
        }).format(new Date(date))
        return dayLabel
    }
    function formatDateWithOrdinal(input: Date | string): string {
        let date: Date;
    
        // Ensure the input is a valid Date object
        if (input instanceof Date) {
            date = input;
        } else {
            date = new Date(input); // Attempt to convert string to Date
            if (isNaN(date.getTime())) {
                throw new TypeError("Invalid date input");
            }
        }
    
        const day = date.getDate();
    
        // Determine the appropriate ordinal suffix
        const getOrdinalSuffix = (n: number): string => {
            if (n % 10 === 1 && n % 100 !== 11) return "st";
            if (n % 10 === 2 && n % 100 !== 12) return "nd";
            if (n % 10 === 3 && n % 100 !== 13) return "rd";
            return "th";
        };
    
        const ordinalSuffix = getOrdinalSuffix(day);
        return `${day}${ordinalSuffix}`;
    }

    function getUserCustomReviewsGroupedIntoWeeks() {
        const reviews = currentUser.vitovaReviews;
        const planStartDate = new Date(currentUser.userPlanStartDate).getTime(); // Convert to timestamp
        let weeks: number[] = currentUser.currentVitovaPlan.content.map((w)=>0);
        
        for (const review of reviews) {
            const reviewDate = new Date(review.dateOfActivity).getTime(); // Convert to timestamp
            const weekIndex = Math.floor((reviewDate - planStartDate) / (7 * 24 * 60 * 60 * 1000)); // Calculate week index
            if (weekIndex>= 0 && weekIndex < weeks.length){
                weeks[weekIndex] += (getActivityStressScore(review.activityReviewData, currentUser));

            }
        }
    
        return weeks;

    }


    function getUserReviewsGroupedIntoWeeks() {
        if (currentUser.currentVitovaPlan){
            const reviews = currentUser.vitovaReviews;
            const planStartDate = new Date(currentUser.userPlanStartDate).getTime(); // Convert to timestamp
            let weeks: VitovaDay[] = currentUser.currentVitovaPlan.content.map((w): VitovaDay => {
                return {
                    id: '',
                    activities: []
                };
            });
            
            for (const review of reviews.filter(r=>r.saved)) {
                const reviewDate = new Date(review.dateOfActivity).getTime(); // Convert to timestamp
                const weekIndex = Math.floor((reviewDate - planStartDate) / (7 * 24 * 60 * 60 * 1000)) + 1; // Calculate week index
                if (weekIndex>= 0 && weekIndex < weeks.length){
                    weeks[weekIndex].activities.push(review.activityReviewData);
                }
            }
        
            return weeks;
        }else{
            return []
        }
    }

    function getExercise(exercise: Exercise | undefined): Exercise | undefined {
        if (exercise === undefined){
            return undefined
        }
        const findExercise = exercises.find(e => e.id === exercise.id);
        return findExercise || undefined;
      }

    function getUserReviewsGroupedIntoDays(): VitovaDay[] {
        if (!currentUser.currentVitovaPlan) {
            return [];
        }
    
        const reviews = currentUser.vitovaReviews || [];
        const planStartDate = new Date(currentUser.userPlanStartDate);
        planStartDate.setHours(0, 0, 0, 0); // Normalize to midnight
    
        // Ensure week array has proper types
        let week: Array<{ id: string; activities: VitovaActivity[] }> = next7Days.map((d) => ({
            id: d.id, // Preserve ID from next7Days
            activities: [] as VitovaActivity[],
        }));
    
        reviews
            .filter((r) => r.saved)
            .forEach((review) => {
                const reviewDate = new Date(review.dateOfActivity);
                reviewDate.setHours(0, 0, 0, 0); // Normalize to midnight
                // Convert both dates to timestamps (milliseconds)
                const dayIndex = (Math.floor((reviewDate.getTime() - planStartDate.getTime()) / (24 * 60 * 60 * 1000)) + 1) - (7 * getWeekNumber(next7Days[0]));
                // alert(dayIndex)
    
                if (dayIndex >= 0 && dayIndex < week.length) {
                    week[dayIndex].activities.push(review.activityReviewData);
                }
            });
    
        return week;
    }

    const handleStateVariableInputChange = (setter: React.Dispatch<React.SetStateAction<number>>) => 
        (e: React.ChangeEvent<HTMLInputElement>) => {
          const value = e.target.value;
          if (value === "" || /^\d+$/.test(value)) {
            setter(value === "" ? 0 : Number(value)); // Convert empty input to 0
          } else {
            alert("Please enter a valid number");
          }
      };

    const handleCheckboxChange = (index: number) => {
        // Create a copy of the current state
        const updatedInput = [...nutritionInput];
        // Toggle the checked state for the selected checkbox
        updatedInput[index].checked = !updatedInput[index].checked;
        // Update the state
        setNutritionInput(updatedInput);
    };
    function getAverageSessionDuration(days:VitovaDay[]){
        let sum = 0;
        let numberOfActivities = 0;
        for (const day of days){
            for (const activity of day.activities){
                sum += Number(activity.plannedDuration)
                numberOfActivities += 1;
            }
        }
        return Math.round(sum/numberOfActivities);
    }
    function getTotalSessionDuration(days:VitovaDay[]){
        let sum = 0;
        for (const day of days){
            for (const activity of day.activities){
                if (activity.compulsory){
                    sum += Number(activity.plannedDuration)

                }
            }
        }
        return Math.round(sum);
    }
    const formatDate = (dateString: string | Date): string => {
        if (!dateString) return "";
        
        const date = new Date(dateString);
        if (isNaN(date.getTime())) return "Invalid Date";
    
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
        const year = date.getFullYear();
    
        return `${day}/${month}/${year}`;
    };
    function getTotalSessionISS(days:VitovaDay[]){
        let sum = 0;
        for (const day of days){
            for (const activity of day.activities){
                if (activity.compulsory){
                    for (const step of activity.steps){
                        sum += getStressScore(step, currentUser)
    
                    }
                }

            }
        }
        return Math.round(sum);
    }

    function getCompletedSessionDuration(days:VitovaDay[]){
        let sum = 0;
        const reviews = currentUser.vitovaReviews;
        for (const day of next7Days){
            for (const activity of day.activities){
                const findActivity = reviews.find(r=>r.activityReviewData.id===activity.id && r.saved);

                if (findActivity){
                    sum += Number(findActivity.activityReviewData.plannedDuration);
                }
            }

        }
        return Math.round(sum);
    }
    function getCompletedSessionISS(days:VitovaDay[]){
        let sum = 0;
        const reviews = currentUser.vitovaReviews;
        for (const day of next7Days){
            for (const activity of day.activities){
                const findActivity = reviews.find(r=>r.activityReviewData.id===activity.id && r.saved);

                if (findActivity){
                    sum += getActivityStressScore(activity, currentUser)
                }
            }

        }
        // const reviews = currentUser.vitovaReviews;

        // for (const day of days){
        //     for (const activity of day.activities){
        //         const findActivity = reviews.find(r=>r.activityReviewData.id===activity.id && r.saved)

        //         if (findActivity){
        //             for (const step of findActivity.activityReviewData.steps){
        //                 sum += getStressScore(step, currentUser)
    
        //             }
        //         }
        //     }
        // }
        return Math.round(sum);
    }
    function getAverageSessionRPE(days:VitovaDay[]){
        let sum = 0;
        let numberOfActivities = 0;
        for (const day of days){
            for (const activity of day.activities){
                sum += Number(activity.plannedRPE)
                numberOfActivities += 1;
            }
        }
        return Math.round(sum/numberOfActivities);
    }
    const parseDate = (dateString: string) => {
        const [day, month, year] = dateString.split('/');  // Split by '/'
        // Reconstruct the date in mm/dd/yyyy format
        return new Date(`${month}/${day}/${year}`);
      };
    function getUserTimeline(user:User){
        if (user.accountType==='haw'){
            let timeline = [
                { date: user.accountCreatedDate ? new Date(user.accountCreatedDate) : new Date(user.weightData[0].date), title: "Joined VITOVA!", description: <>You join <b>VITOVA</b> and embark on your fitness journey</> },
                { date: new Date(user.userPlanStartDate), title: "Plan Starts", 
                    description:     
                    <>
                    You start your <b>{currentUser.currentVitovaPlan.title.toUpperCase()}</b> {currentUser.currentVitovaPlan.title.toLowerCase().includes('plan') ? '':'plan'} and begin your journey!
                    </> 
                },
                // { date: parseDate(getDateAfterPlan(user.userPlanStartDate.toString(), user.currentVitovaPlan.content.length)), title: "Plan Finishes", description: "You reach the end of your plan and prepare for your surgery!" },
                { date: new Date(user.surgeryDate), title: "Plan Finishes", description: "You reach your target date, having been through an incredible health transformation" },
            ]
            return timeline;
        }else{
            let timeline = [
                { date: user.accountCreatedDate ? new Date(user.accountCreatedDate) : new Date(user.weightData[0].date), title: "Joined VITOVA!", description: <>You join <b>VITOVA</b> and embark on your fitness journey</> },
                { date: new Date(user.userPlanStartDate), title: "Plan Starts", 
                    description:     
                    <>
                    You start your <b>{currentUser.currentVitovaPlan.title.toUpperCase()}</b> {currentUser.currentVitovaPlan.title.toLowerCase().includes('plan') ? '':'plan'} and begin your journey!
                    </> 
                },
                { date: parseDate(getDateAfterPlan(user.userPlanStartDate.toString(), user.currentVitovaPlan.content.length)), title: "Plan Finishes", description: "You reach the end of your plan and prepare for your surgery!" },
                { date: new Date(user.surgeryDate), title: "Surgery Day", description: "You reach your surgery date, having been through an incredible health transformation" },
            ]
            return timeline;
        }

    }
    function getNumberOfSessions(type:VitovaStepType, days:VitovaDay[]){
        switch (type){
            case VitovaStepType.CARDIO:
                let cardioSum = 0;
                for (const day of days){
                    for (const activity of day.activities.filter(a=>a.compulsory)){
                        if (activity.steps.length > 0 && activity.steps[0].type === VitovaStepType.CARDIO){
                            cardioSum += 1
                        }
                    }
                }
                return cardioSum;
            case VitovaStepType.STRENGTH:
                let strengthSum = 0;
                for (const day of days){
                    for (const activity of day.activities.filter(a=>a.compulsory)){
                        if (activity.steps.length > 0 && (activity.steps[0].type === VitovaStepType.STRENGTH || activity.steps[0].type === VitovaStepType.FLEXIBILITY)){
                            strengthSum += 1
                        }
                    }
                }
                return strengthSum;
        }
    }
    if (currentUser.currentVitovaPlan){
        if (!loading){
            return (
                
                <div className="dashboard-page" style={{backgroundColor:'#f1f1f1'}}>
                    <DisclaimerPopup/>
                    <div className="dashboard-view-container" style={{backgroundColor:'#f1f1f1'}}>
                        {showCalendar && 
                            <div className="side-panel-container"> 
                                <div className="expand-arrow-container" onClick={toggleCalendar}>
                                    <FontAwesomeIcon className="expand-arrow" icon={showCalendar ? faChevronLeft : faGripLinesVertical}></FontAwesomeIcon>
                                </div>
                            </div>
                        }
                        {showRightPanel && 
                            <div className="side-panel-container"> 
                                <div className="expand-arrow-container" onClick={()=>toggleRightPanel()}>
                                    <FontAwesomeIcon className="expand-arrow" icon={showRightPanel ? faChevronLeft : faGripLinesVertical}></FontAwesomeIcon>
                                </div>
                            </div>
                        }

                        {showCalendar ? 
                            (
                                <div className={`main-panel-container`} style={{backgroundColor:'#f1f1f1'}}>

                                </div>
                            )
                        :
                        showRightPanel ? 
                            (
                                <div className={`main-panel-container`} style={{backgroundColor:'#f1f1f1'}}>

                                </div>
                            )
                            :
                            (
                                <div className={`main-panel-container`} style={{flexDirection:'row',backgroundColor:'#f1f1f1',display:'flex', justifyContent:'flex-start', alignItems:'flex-start', overflow:'hidden'}}>
                                    <div style={{display:'flex',flex:14, flexDirection:'column', backgroundColor:'#f1f1f1', alignItems:'center'}}>
                                        <div className="top-panel" style={{gap:'10px', height:'18vh',maxHeight:'18vh', display:'flex'}}>
                                            {currentUser.accountType === 'haw' &&
                                                <div onClick={()=>toggleRightPanel('myprogress')}  className="top-panel-streak" style={{flex:1,backgroundColor:colorPalette.steelBlue, height:'100%',boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",}} >
                                                    <FontAwesomeIcon className="streak-icon" icon={faAdjust}></FontAwesomeIcon>
                                                    <label className="streak-label" style={{fontWeight:'bold', fontStyle:'italic', fontSize:'20px'}}>
                                                    {`MY PROGRESS`}
                                                    </label>
                                                </div>
                                            }
                                            {currentUser.accountType === 'ffs' &&
                                                <div onClick={()=>toggleRightPanel('myplan')}  className="top-panel-streak" style={{flex:1,backgroundColor:colorPalette.steelBlue, height:'100%',boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",}} >
                                                {/* <FontAwesomeIcon className="streak-icon" icon={faClock}></FontAwesomeIcon>
                                                <label className="streak-label" style={{fontWeight:'bold', fontStyle:'italic', fontSize:'20px'}}>
                                                {(() => {
                                                    const surgeryDate = new Date(currentUser.surgeryDate);
                                                    const now = new Date();
                                                    const diffInMs = surgeryDate.getTime() - now.getTime(); // Use getTime() to get numeric timestamps
                                                    const diffInDays = Math.ceil(diffInMs / (1000 * 60 * 60 * 24)); // Convert to days
                                                    return `${diffInDays} DAYS TO GO`;
                                                })()}
                                                </label> */}
                                                <FontAwesomeIcon className="streak-icon" icon={faAdjust}></FontAwesomeIcon>
                                                <label className="streak-label" style={{fontWeight:'bold', fontStyle:'italic', fontSize:'20px'}}>
                                                {`MY PLAN`}
                                                </label>
                                            </div>
                                            }
                                            {currentUser.accountType === 'ffs' &&
                                            <div className="top-panel-streak" style={{scale:'1', flex:1,boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",}} onClick={()=>showFinalReport ? {} : toggleCalendar()}>
                                                <FontAwesomeIcon className="streak-icon" style={{color:`${colorPalette.steelBlue}dd`}} icon={showFinalReport ? faCalendarCheck : faCalendarDays}></FontAwesomeIcon>

                                                <label className="streak-label" style={{fontWeight:'bold', fontStyle:'italic', fontSize:'20px', color:`${colorPalette.steelBlue}dd`}}>{showFinalReport ? `ALL DONE` : `THIS WEEK`}</label>

                                            </div>
                                            }

                                            <div style={{display:'flex', flex:5, justifyContent:'flex-start', alignItems:'flex-start', flexDirection:'column', width:'100%', height:'100%'}}>
                                                <div style={{display:'flex', justifyContent:'center', alignItems:'center',width:'100%', backgroundColor:'white', marginBottom:'8px',borderRadius:'5px', boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",}}>
                                                    {/* <FontAwesomeIcon className="calendar-icon" style={{marginTop:'20px'}} icon={faCalendarDay}></FontAwesomeIcon> */}
                                                    <label className="streak-label" style={{fontWeight:'bold', fontStyle:'italic',fontSize:'1.25em', color:'#2f3b4a', marginTop:'0', padding:'6px'}}>{showFinalReport ? `NO FURTHER ACTIVITIES - PLAN COMPLETE!` : currentUser.accountType === 'haw' ? `HEALTH AND WELLBEING SUPPORT`:`OUR PLAN FOR YOU TODAY`}</label>
                                                </div>
                                                <div style={{display:'flex', flexDirection:'row', width:'100%', height:'100%',}}>
                                                <div style={{display:'flex', flex:6,flexDirection:'row',justifyContent:'center', alignItems:'center',  width:'100%'}}>
                                                    {next7Days[adjustedTodayIndex].activities.filter(a=>a.compulsory).length === 0 && 
                                                    <div className="activity-button" style={{backgroundColor:'fafafa', borderRadius:'5px', width:'94%', flexDirection:'column', display:'flex'}}>
                                                            <label style={{color:colorPalette.steelBlue, fontStyle:'italic', fontWeight:'bold', pointerEvents:'none'}}>NO ACTIVITIES PLANNED FOR TODAY!</label>    
                                                        </div>
                                                    }
                                                    {showFinalReport ?
                                                        <div style={{display:'flex', flexDirection:'column',justifyContent:'flex-start', alignItems:'center', width:'100%'}} onClick={()=>toggleRightPanel('endreport')}>
                                                            <div className="activity-button" style={{backgroundColor:'#fafafa', padding:'30px', marginTop:'5px', borderRadius:'5px', width:'94%', flexDirection:'column', display:'flex'}}>
                                                                <label style={{color:colorPalette.steelBlue, fontStyle:'italic', fontWeight:'bold', pointerEvents:'none'}}>{`VIEW YOUR END OF PLAN REPORT`}</label>    
                                                                <label style={{color:colorPalette.steelBlue, fontSize:'15px', pointerEvents:'none'}}>{`Well Done ${currentUser.fullname}! We hope you've enjoyed your time using our platform!`}</label>    
                                                            </div>
                                                        </div>
                                                    :
                                                    currentUser.accountType === 'haw' ? (
                                                        <div className="activity-button" style={{backgroundColor:'#fafafa', height:'100%', borderRadius:'5px', width:'100%', flexDirection:'row', display:'flex'}} 
                                                        >
                                                            <div style={{flex:4, display:'flex', justifyContent:'center', alignItems:'center',padding:'20px'}}
                                                                onClick={()=>{}}
>
                                                            <label style={{color:colorPalette.steelBlue, fontStyle:'italic', fontWeight:'bold', pointerEvents:'none'}}>LET US KNOW HOW TODAY'S ACTIVITY WENT</label>    

                                                            </div>
                                                            <div style={{flex:1, display:'flex', justifyContent:'center', alignItems:'center',borderTopRightRadius:'5px',borderBottomRightRadius:'5px',
                                                            backgroundColor:
                                                            currentUser.vitovaReviews
                                                                    .filter(r => {
                                                                    const reviewDate = new Date(r.dateOfActivity); // Ensure r.date is a Date object
                                                                    const today = new Date();
                                                                    
                                                                    return (
                                                                        reviewDate.getFullYear() === today.getFullYear() &&
                                                                        reviewDate.getMonth() === today.getMonth() &&
                                                                        reviewDate.getDate() === today.getDate()
                                                                    );
                                                                }).length > 0 ? colorPalette.rpeGreen:colorPalette.softRed,
                                                            padding:'20px'}}
                                                                    onClick={()=>{setIsCustomActivityReviewDialogOpen(true)}}
                                                                        >
                                                            <FontAwesomeIcon className="streak-icon" style={{color:`white`, width:'40px', height:'40px'}} icon={faRobot}></FontAwesomeIcon>

                                                            </div>
                                                            <CustomActivityReviewDialog 
                                                                conditions={conditions}
                                                                onSave={(reviews) => {onSaveCustomReviews(false, reviews)}} 
                                                                onSaveAndSubmit={(reviews) => {onSaveCustomReviews(true, reviews)}} 
                                                                reviews={currentUser.vitovaReviews
                                                                    .filter(r => {
                                                                    const reviewDate = new Date(r.dateOfActivity); // Ensure r.date is a Date object
                                                                    const today = new Date();
                                                                    
                                                                    return (
                                                                        reviewDate.getFullYear() === today.getFullYear() &&
                                                                        reviewDate.getMonth() === today.getMonth() &&
                                                                        reviewDate.getDate() === today.getDate()
                                                                    );
                                                                })
                                                            } 
                                                                open={isCustomActivityReviewDialogOpen} 
                                                                onDialogClose={() => setIsCustomActivityReviewDialogOpen(false)} 
                                                                user={currentUser} 
                                                                exercises={exercises} 
                                                            />
                                                        </div>
                                                    )
                                                    :
                                                    currentUser.accountType === 'ffs' ? (
                                                    next7Days[adjustedTodayIndex].activities.filter(a=>a.compulsory).map((activity, index)=>(
                                                        <div className="activity-button" style={{backgroundColor:index%2===0?'#fafafa':'#f6f6f6', marginLeft:index > 0 ? '5px' : 0, height:'100%', borderRadius:'5px', width:'100%', flexDirection:'row', display:'flex'}} 
                                                        >
                                                            <div style={{flex:4, display:'flex', justifyContent:'center', alignItems:'center',padding:'20px'}}
                                                                onClick={()=>{setShowCalendar(true);setSelectedDay(next7Days[adjustedTodayIndex]); setSelectedActivityIndex(index)}}
>
                                                            <label style={{color:colorPalette.steelBlue, fontStyle:'italic', fontWeight:'bold', pointerEvents:'none'}}>{activity.title.length > 30 ? `${activity.title.toUpperCase().slice(0, 30)}...` : activity.title.toUpperCase()}</label>    

                                                            </div>
                                                            <div style={{flex:1, display:'flex', justifyContent:'center', alignItems:'center',borderTopRightRadius:'5px',borderBottomRightRadius:'5px',
                                                            backgroundColor:isActivityReviewed(activity) ? colorPalette.rpeGreen : colorPalette.softRed,
                                                            padding:'20px'}}
                                                                    onClick={()=>{
                                                                        setShowCalendar(true); 
                                                                        setSelectedDay(next7Days[adjustedTodayIndex]); 
                                                                        setSelectedActivityIndex(index);
                                                                        getActivityReview(next7Days[adjustedTodayIndex].activities[index])
                                                                        setReviewingActivity(next7Days[adjustedTodayIndex].activities[index])}}
                                                                        >
                                                            <FontAwesomeIcon className="streak-icon" style={{color:`white`, width:'40px', height:'40px'}} icon={faRobot}></FontAwesomeIcon>

                                                            </div>
                                                        </div>
                                                    )))
                                                    :
                                                    (<></>)
                                                    }
                                                    
                                                </div>
                                                <div className="tabreviewbutton" style={{flex:1, display:'flex',  marginLeft:'8px',height:'100%',
                                                            backgroundColor:hasSubmittedDrinkingDataToday() && hasSubmittedNutritionDataToday() && hasSubmittedSleepingDataToday() && hasSubmittedSmokingDataToday() ? colorPalette.rpeGreen : colorPalette.softRed,
                                                            justifyContent:'center', alignItems:'center', borderRadius:'5px'}}
                                                                    onClick={()=>setOpenTabReviewPopup(true)}>
                                                                    <FontAwesomeIcon className="streak-icon" style={{color:`white`,width:'40px', height:'40px'}} icon={faHeartPulse}></FontAwesomeIcon>
                                                </div>
                                                </div>

                                            </div>
                                        </div>
                                        <Dialog sx={{p:3}} open={openTabReviewPopup} onClose={() => setOpenTabReviewPopup(false)} maxWidth="md" fullWidth>
                                                                            <DialogTitle sx={{ fontStyle: 'italic', fontWeight: 'bold', padding: '16px 24px', textAlign: 'center' }}>
                                                                                {`LET'S SEE HOW YOU GOT ON TODAY!`}
                                                                            </DialogTitle>
                                                                            <DialogContent sx={{ padding: '16px 24px', overflowY: 'auto' }}>
                                                                                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "1rem" }}>
                                                                                    {currentUser.smoker && (
                                                                                        <Box display="flex" flexDirection="column" alignItems="flex-start" gap={2} width="100%">
                                                                                            <Typography variant="h6" fontWeight="bold" fontStyle="italic" mt={1} textAlign="left">
                                                                                                {hasSubmittedSmokingDataToday() ? "SMOKING DATA SUBMITTED FOR TODAY" : "SMOKING CHECK-IN"}
                                                                                            </Typography>
                                                                                            <TextField
                                                                                                label="Smoking (cigarettes smoked today)"
                                                                                                type="text"
                                                                                                fullWidth
                                                                                                disabled={hasSubmittedSmokingDataToday()}
                                                                                                variant="outlined"
                                                                                                value={hasSubmittedSmokingDataToday() ? 'Already Submitted' : cigaretteInput}
                                                                                                onChange={handleStateVariableInputChange(setCigaretteInput)}
                                                                                                InputLabelProps={{ shrink: true }}
                                                                                                style={{ fontSize: "1.2em", backgroundColor: "transparent" }}
                                                                                            />
                                                                                        </Box>
                                                                                    )}
                                                                                    {currentUser.drinker && (
                                                                                        <Box display="flex" flexDirection="column" alignItems="flex-start" gap={2} width="100%">
                                                                                            <Typography variant="h6" fontWeight="bold" fontStyle="italic" mt={1} textAlign="left">
                                                                                                {hasSubmittedDrinkingDataToday() ? "ALCOHOL DATA SUBMITTED FOR TODAY" : "ALCOHOL CHECK-IN"}
                                                                                            </Typography>
                                                                                            <TextField
                                                                                                label="Alcohol (units today)"
                                                                                                type="text"
                                                                                                fullWidth
                                                                                                disabled={hasSubmittedDrinkingDataToday()}
                                                                                                variant="outlined"
                                                                                                value={hasSubmittedDrinkingDataToday() ? 'Already Submitted' : alcoholInput}
                                                                                                onChange={handleStateVariableInputChange(setAlcoholInput)}
                                                                                                InputLabelProps={{ shrink: true }}
                                                                                                style={{ fontSize: "1.2em", backgroundColor: "transparent" }}
                                                                                            />
                                                                                        </Box>
                                                                                    )}
                                                                                    <Box display="flex" flexDirection="column" alignItems="flex-start" gap={2} width="100%">
                                                                                        <Typography variant="h6" fontWeight="bold" fontStyle="italic" mt={1} textAlign="left">
                                                                                            {hasSubmittedSleepingDataToday() ? "SLEEPING DATA SUBMITTED FOR TODAY" : "SLEEPING CHECK-IN"}
                                                                                        </Typography>
                                                                                        <TextField
                                                                                            label="Sleep (hours slept last night)"
                                                                                            type="text"
                                                                                            fullWidth
                                                                                            disabled={hasSubmittedSleepingDataToday()}
                                                                                            variant="outlined"
                                                                                            value={hasSubmittedSleepingDataToday() ? 'Already Submitted' : sleepingInput}
                                                                                            onChange={handleStateVariableInputChange(setSleepingInput)}
                                                                                            InputLabelProps={{ shrink: true }}
                                                                                            style={{ fontSize: "1.2em", backgroundColor: "transparent" }}
                                                                                        />
                                                                                    </Box>
                                                                                    <Box display="flex" flexDirection="column" alignItems="flex-start" gap={2} width="100%">
                                                                                        <Typography variant="h6" fontWeight="bold" fontStyle="italic" mt={1} textAlign="left">
                                                                                            {hasSubmittedNutritionDataToday() ? "NUTRITION DATA SUBMITTED FOR TODAY" : "DAILY NUTRITION CHECKLIST"}
                                                                                        </Typography>
                                                                                        {!hasSubmittedNutritionDataToday() && (
                                                                                            <Box display="flex" flexDirection="column" gap={0} alignItems="flex-start">
                                                                                                {nutritionInput.map((q, index) => (
                                                                                                    <FormControlLabel
                                                                                                        key={index}
                                                                                                        control={
                                                                                                            <Checkbox
                                                                                                                checked={q.checked}
                                                                                                                disabled={hasSubmittedNutritionDataToday()}
                                                                                                                onChange={() => handleCheckboxChange(index)}
                                                                                                                sx={{ color: "#454545", p: 0, m: 0 }}
                                                                                                            />
                                                                                                        }
                                                                                                        label={
                                                                                                            <Typography sx={{ color: "#4682B4", ml: 1 }}>
                                                                                                                {q.label}
                                                                                                            </Typography>
                                                                                                        }
                                                                                                        sx={{ alignItems: "center", ml: 2 }}
                                                                                                    />
                                                                                                ))}
                                                                                            </Box>
                                                                                        )}
                                                                                    </Box>
                                                                                </div>
                                                                            </DialogContent>
                                                                            <DialogActions sx={{ padding: '8px 24px' }}>
                                                                            <Button
                                                                                    onClick={() => setOpenTabReviewPopup(false)}
                                                                                    color="primary"
                                                                                    sx={{ width: '100%' }}
                                                                                >
                                                                                    Close Without Saving
                                                                                </Button>
                                                                                <Button
                                                                                    onClick={() => submitAllTabs()}
                                                                                    variant="contained"
                                                                                    color="primary"
                                                                                    sx={{ width: '100%' }}
                                                                                >
                                                                                    Save & Close
                                                                                </Button>

                                                                            </DialogActions>
                                        </Dialog>

                                        <div style={{display:'flex',flex:3, width:'100%',height:'100%',flexDirection:'row', backgroundColor:'#f1f1f1',justifyContent:'flex-start', alignItems:'flex-start'}}>
                                       
                                        <div style={{display:'flex',flex:2,width:'100%',paddingLeft:'1em', paddingRight:'1em',height:'100%',flexDirection:'column',justifyContent:'center', alignItems:'center'}}>
                                            {(()=>{
                                            const complianceScore = getUserToPlanCompliance(currentUser.currentVitovaPlan, currentUser).score;
                                            const complianceColor = complianceScore >= 80 ? colorPalette.rpeGreen : complianceScore >= 50 ? colorPalette.rpeYellow : colorPalette.softRed;
                                            return(
                                            <div style={{display:'flex', borderRadius:'5px',padding:'1em', height:'80vh', overflowY:'scroll',maxHeight:'60vh',width:'100%',backgroundColor:`${complianceColor}22`, flexDirection:'column', justifyContent:'flex-start', alignItems:'center'}}>

                                            <div
                                                style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                backgroundColor: `${complianceColor}66`,
                                                padding: '2em',
                                                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                                                borderRadius: '8px',
                                                height:'100%',
                                                justifyContent:'center',
                                                width: '100%',
                                                overflowX: 'scroll',
                                                }}
                                            >
                                                <div
                                                style={{
                                                    width: `150px`,
                                                    height: `150px`,
                                                    borderRadius: '50%',
                                                    backgroundColor: `${complianceColor}99`,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    position: 'relative',
                                                }}
                                                >
                                                <div
                                                    style={{
                                                    width: `${complianceScore}%`,
                                                    height: `${complianceScore}%`,
                                                    borderRadius: '50%',
                                                    backgroundColor: complianceColor,
                                                    position: 'absolute',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    }}
                                                >
                                                    <label
                                                    style={{
                                                        fontSize: '1.5vw',
                                                        fontStyle: 'italic',
                                                        fontWeight: 'bold',
                                                        color: 'white',
                                                    }}
                                                    >
                                                    {complianceScore}%
                                                    </label>
                                                </div>
                                                </div>
                                                <label
                                                style={{
                                                    fontSize: '1.2vw',
                                                    fontWeight: 'bold',
                                                    fontStyle: 'italic',
                                                    color: 'white',
                                                    textAlign: 'center',
                                                    marginTop: '1em',
                                                }}
                                                >
                                                {`COMPLIANT WITH YOUR PLAN`}
                                                </label>
                                            </div>
                                            </div>
                                            )
                                            })()}
                                        </div>
                                        
                                        <div style={{display:'flex',flex:5, width:'100%',height:'auto',flexDirection:'column', backgroundColor:'#f1f1f1',justifyContent:'flex-start', alignItems:'flex-start'}}>
                                            <label style={{color:colorPalette.steelBlue, marginBottom: '5vh', marginLeft:'5px',fontSize:'1.5em', fontWeight:'bold', fontStyle:'italic'}}>INFORMED STRESS SCORE (ISS) OVERVIEW</label>
                                            {currentUser.accountType === 'haw' &&
                                            <GeneralChartComponent mh={80} mw={100} 
                                            chartData={getUserCustomReviewsGroupedIntoWeeks()} 
                                            labelData={currentUser.currentVitovaPlan.content.map((week:VitovaWeek, index:number)=>`WEEK ${index+1}`)}
                                            user={currentUser}></GeneralChartComponent>
                                            }
                                            {currentUser.accountType === 'ffs' &&
                                            <ComparisonChartComponent mh={80} mw={100} 
                                            plannedData={currentUser.currentVitovaPlan.content.map((week:VitovaWeek)=>getWeekStressScore(week, currentUser, false))} 
                                            realData={getUserReviewsGroupedIntoWeeks().map((week:VitovaDay)=>getDayStressScore(week, currentUser, true))} 
                                            labelData={currentUser.currentVitovaPlan.content.map((week:VitovaWeek, index:number)=>`WEEK ${index+1}`)}
                                            user={currentUser}></ComparisonChartComponent>
                                            }
                                            {/* <label style={{color:colorPalette.steelBlue, marginBottom: '10px', marginLeft:'5px',fontSize:'1.5em', fontWeight:'bold', fontStyle:'italic'}}>ACTIVITY HISTORY</label>
                                            {currentUser.reviews.length <= 0 && (
                                                <div className="history-item" style={{marginBottom:'2vh', height:'10vh', width:'100%', justifyContent:'center', alignItems:'center', display:'flex'}}>
                                                <label style={{color:'#565656', margin: '10px 10px', fontSize:'1.25em', fontWeight:'normal'}}>No Activity History Yet</label>
                                            </div>
                                            )} */}
                                            <div style={{maxHeight:'70vh', overflowY:'scroll', paddingBottom:'10vh', width:'100%'}}>
                                            {currentUser.reviews.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()).map((historyItem: Review, index: number) => (
                                                historyItem.results.effort >= 0 ?
                                                <div className="history-item" style={{marginBottom:'2vh', backgroundColor:index % 2 === 0 ? '#ffffff':'#fafafa'}}>
                                                    <div style={{display:'flex', width:'100%', height:'auto',flex:1}}>
                                                        <div style={{display:'flex', flex:1}}>
                                                            <label style={{color:'#454545', margin: '10px 15px', fontSize:'1.5em', fontWeight:'bold'}}>{historyItem.activity.type}</label>
                                                        </div>
                                                        <div style={{display:'flex', justifyContent:'flex-end', flex:1}}>
                                                            <label style={{color:'#565656', margin: '10px 15px', fontSize:'1em', fontWeight:'bold'}}>{milliSecondsToDate(historyItem.date)}</label>
                                                        </div>
                                                    </div>
                                                    <div style={{display:'flex', width:'100%', height:'auto',flex:1, marginLeft:'1.5em', marginRight:'1.5em',alignItems:'center'}}>
                                                        <div style={{display:'flex', flexDirection:'column'}}>
                                                            <label style={{color:'#787878', fontSize:'0.75em', fontWeight:'bold', textAlign:'center'}}>Duration</label>   
                                                            <label style={{color:'#454545', margin: '10px 15px', fontSize:'1.5em', fontWeight:'bold', textAlign:'center'}}>{historyItem.results.duration}</label> 
                                                        </div>
                                                        <div style={{height:'4em', width:'2px', backgroundColor:'#aaaaaa', marginLeft:'1em', marginRight:'1em'}}></div>
                                                        <div style={{display:'flex', flexDirection:'column'}}>
                                                            <label style={{color:'#787878', fontSize:'0.75em', fontWeight:'bold', textAlign:'center'}}>Pace</label>   
                                                            <label style={{color:'#454545', margin: '10px 15px', fontSize:'1.5em', fontWeight:'bold', textAlign:'center'}}>{secondsToPaceString(historyItem.results.pace)}</label> 
                                                        </div>
                                                        <div style={{height:'4em', width:'2px', backgroundColor:'#aaaaaa', marginLeft:'1em', marginRight:'1em'}}></div>
                                                        <div style={{display:'flex', flexDirection:'column'}}>
                                                            <label style={{color:'#787878', fontSize:'0.75em', fontWeight:'bold', textAlign:'center'}}>HR</label>   
                                                            <label style={{color:'#454545', margin: '10px 15px', fontSize:'1.5em', fontWeight:'bold', textAlign:'center'}}>{historyItem.results.hr}</label> 
                                                        </div>
                                                        <div style={{height:'4em', width:'2px', backgroundColor:'#aaaaaa', marginLeft:'1em', marginRight:'1em'}}></div>
                                                        <div style={{display:'flex', flexDirection:'column'}}>
                                                            <label style={{color:'#787878', fontSize:'0.75em', fontWeight:'bold', textAlign:'center'}}>Effort</label>   
                                                            <label style={{color:'#454545', margin: '10px 15px', fontSize:'1.5em', fontWeight:'bold', textAlign:'center'}}>{historyItem.results.effort} / 10</label> 
                                                        </div>

                                                    </div>
                                                </div>
                                                :                                                
                                                <div className="history-item" style={{marginBottom:'2vh', backgroundColor:colorPalette.softRed}}>
                                                <div style={{display:'flex', width:'100%', height:'auto',flex:1}}>
                                                    <div style={{display:'flex', flex:1}}>
                                                        <label style={{color:'#f1f1f1', margin: '10px 15px', fontSize:'1.5em', fontWeight:'bold'}}>{historyItem.activity.type} (Missed)</label>
                                                    </div>
                                                    <div style={{display:'flex', justifyContent:'flex-end', flex:1}}>
                                                        <label style={{color:'#f3f3f3', margin: '10px 15px', fontSize:'1em', fontWeight:'bold'}}>{milliSecondsToDate(historyItem.date)}</label>
                                                    </div>
                                                </div>
                                                <div style={{display:'flex', width:'100%', height:'auto',flex:1, marginLeft:'1.5em', marginRight:'1.5em',alignItems:'center'}}>
                                                    <div style={{display:'flex', flexDirection:'column'}}>
                                                        <label style={{color:'#f1f1f1', fontSize:'0.75em', fontWeight:'bold', textAlign:'center'}}>Duration</label>   
                                                        <label style={{color:'white', margin: '10px 15px', fontSize:'1.5em', fontWeight:'bold', textAlign:'center'}}>n/a</label> 
                                                    </div>
                                                    <div style={{height:'4em', width:'2px', backgroundColor:'#aaaaaa', marginLeft:'1em', marginRight:'1em'}}></div>
                                                    <div style={{display:'flex', flexDirection:'column'}}>
                                                        <label style={{color:'#f1f1f1', fontSize:'0.75em', fontWeight:'bold', textAlign:'center'}}>Pace</label>   
                                                        <label style={{color:'white', margin: '10px 15px', fontSize:'1.5em', fontWeight:'bold', textAlign:'center'}}>n/a</label> 
                                                    </div>
                                                    <div style={{height:'4em', width:'2px', backgroundColor:'#aaaaaa', marginLeft:'1em', marginRight:'1em'}}></div>
                                                    <div style={{display:'flex', flexDirection:'column'}}>
                                                        <label style={{color:'#f1f1f1', fontSize:'0.75em', fontWeight:'bold', textAlign:'center'}}>HR</label>   
                                                        <label style={{color:'white', margin: '10px 15px', fontSize:'1.5em', fontWeight:'bold', textAlign:'center'}}>n/a</label> 
                                                    </div>
                                                    <div style={{height:'4em', width:'2px', backgroundColor:'#aaaaaa', marginLeft:'1em', marginRight:'1em'}}></div>
                                                    <div style={{display:'flex', flexDirection:'column'}}>
                                                        <label style={{color:'#f1f1f1', fontSize:'0.75em', fontWeight:'bold', textAlign:'center'}}>Effort</label>   
                                                        <label style={{color:'white', margin: '10px 15px', fontSize:'1.5em', fontWeight:'bold', textAlign:'center'}}>n/a</label> 
                                                    </div>
                                                </div>
                                            </div>
                                            ))}
                                            </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div style={{
                                        display: 'flex', 
                                        flex: 1, 
                                        flexDirection: 'column', 
                                        height: '90vh', 
                                        borderTopLeftRadius: '8px', 
                                        borderBottomLeftRadius:'8px',
                                        paddingBottom:'10px',
                                        paddingLeft:'10px',
                                        paddingRight:'10px',
                                        marginTop: '9vh',
                                        backgroundColor: 'white',
                                        justifyContent: 'flex-start', 
                                        overflowY:'scroll',
                                        overflowX:'hidden',
                                        alignItems: 'center',
                                    }}>
                                        <div className="icon-button" style={{borderTopRightRadius:0, borderTopLeftRadius:'5px', borderBottomRightRadius:0, borderBottomLeftRadius:0}}>
                                        <FontAwesomeIcon onClick={()=>toggleRightPanel('checkin')} className="icon-button-icon" icon={faInbox}/>
                                            <label style={{fontSize:'10px', fontWeight:'bold', fontStyle:'italic', marginTop:'10px'}}>CHECK IN</label>
                                        </div>
                                        <div onClick={()=>toggleRightPanel('alcohol')}  className="icon-button" style={{borderRadius:0}}>
                                            <FontAwesomeIcon className="icon-button-icon" style={{color:currentUser.drinker ? (hasSubmittedDrinkingDataToday() ? (getAverageAlcoholScoreColor(currentUser)) : '#34343466'):'#34343422'}} icon={faBeer}/>
                                            <label style={{
                                                fontSize:'10px'
                                                ,fontWeight:'bold'
                                                ,fontStyle:'italic'
                                                ,marginTop:'10px',
                                                color:currentUser.drinker ? (hasSubmittedDrinkingDataToday() ? (getAverageAlcoholScoreColor(currentUser)) : '#34343466'):'#34343422'                                                
                                        }}>ALCOHOL</label>                                        
                                        </div>

                                        <div onClick={()=>toggleRightPanel('smoking')}  className="icon-button" style={{borderRadius:0}}>
                                        <FontAwesomeIcon className="icon-button-icon" 
                                            style={{
                                                color:currentUser.smoker ? (hasSubmittedSmokingDataToday() ? (getAverageSmokingScoreColor(currentUser)) : '#34343466'):'#34343422'  
                                            }} 
                                            icon={faSmoking}
                                            />
                                            <label style={{
                                                fontSize:'10px'
                                                ,fontWeight:'bold'
                                                ,fontStyle:'italic'
                                                ,marginTop:'10px',
                                                color:currentUser.smoker ? (hasSubmittedSmokingDataToday() ? (getAverageSmokingScoreColor(currentUser)) : '#34343466'):'#34343422'  
                                                }}>SMOKING</label>
                                        </div>
                                        <div onClick={()=>toggleRightPanel('sleep')}  className="icon-button" style={{borderTopRightRadius:0, borderTopLeftRadius:0, borderBottomRightRadius:0, borderBottomLeftRadius:0}}>
                                            <FontAwesomeIcon className="icon-button-icon"
                                            style={{
                                                color:hasSubmittedSleepingDataToday() ? (getAverageSleepQualityColor(currentUser)) : '#34343466'                                                 
                                            }}
                                             icon={faBed}/>
                                            <label style={{
                                                fontSize:'10px', 
                                                fontWeight:'bold', 
                                                fontStyle:'italic', 
                                                marginTop:'10px',
                                                color:hasSubmittedSleepingDataToday() ? (getAverageSleepQualityColor(currentUser)) : '#34343466'                                               
                                            }}>SLEEP</label>
                                        </div>
                                        <div onClick={()=>toggleRightPanel('nutrition')}  className="icon-button" style={{borderRadius:0}}>
                                            <FontAwesomeIcon className="icon-button-icon" 
                                            style={{color:hasSubmittedNutritionDataToday() ? getAverageNutritionColor(currentUser) : '#34343466' }}
                                            icon={faBowlFood}/>
                                            <label style={{
                                                fontSize:'10px', 
                                                fontWeight:'bold', 
                                                fontStyle:'italic', 
                                                marginTop:'10px',
                                                color:hasSubmittedNutritionDataToday() ? getAverageNutritionColor(currentUser) : '#34343466'
                                            }}>NUTRITION</label>
                                        </div>
                                        <div onClick={()=>toggleRightPanel('tests')}  className="icon-button" style={{borderTopRightRadius:0, borderTopLeftRadius:0, borderBottomRightRadius:0, borderBottomLeftRadius:'5px'}}>
                                            <FontAwesomeIcon className="icon-button-icon"
                                            style={{
                                                color:colorPalette.steelBlue                                               
                                            }}
                                             icon={faList}/>
                                            <label style={{
                                                fontSize:'10px', 
                                                fontWeight:'bold', 
                                                fontStyle:'italic', 
                                                marginTop:'10px',
                                                color:colorPalette.steelBlue                                              
                                            }}>TESTS</label>
                                        </div>
                                        <div onClick={()=>toggleRightPanel('goals')}  className="icon-button" style={{borderTopRightRadius:0, borderTopLeftRadius:0, borderBottomRightRadius:0, borderBottomLeftRadius:0}}>
                                            <FontAwesomeIcon className="icon-button-icon"
                                            style={{
                                                color:colorPalette.steelBlue                                          
                                            }}
                                             icon={faBullseye}/>
                                            <label style={{
                                                fontSize:'10px', 
                                                fontWeight:'bold', 
                                                fontStyle:'italic', 
                                                marginTop:'10px',
                                                color:colorPalette.steelBlue                                               
                                            }}>GOALS</label>
                                        </div>
                                        <div onClick={()=>toggleRightPanel('community')}  className="icon-button" style={{borderTopRightRadius:0, borderTopLeftRadius:0, borderBottomRightRadius:0, borderBottomLeftRadius:0}}>
                                            <FontAwesomeIcon className="icon-button-icon"
                                            style={{
                                                color:colorPalette.steelBlue                                          
                                            }}
                                             icon={faNewspaper}/>
                                            <label style={{
                                                fontSize:'10px', 
                                                fontWeight:'bold', 
                                                fontStyle:'italic', 
                                                marginTop:'10px',
                                                color:colorPalette.steelBlue                                               
                                            }}>COMMUNITY</label>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                    {/* CALENDAR */}
                    <div className={`calendar-container ${showCalendar ? 'show' : ''}`}>
                        {/* CALENDAR VIEW */}
                        {selectedDay ? (
                            !reviewingActivity ?
                                (
                                <div className='card-enlarged' style={{overflowY:'scroll',backgroundColor:'#ffffff66', minHeight:'75vh', width:'100%', display: 'flex', flexDirection: 'column', justifyContent:'flex-start', alignItems:'flex-start'}}>
                                    <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', backgroundColor:`${colorPalette.default}88`, width:'100%', height:'8vh', borderRadius:'0'}}>
                                        <div style={{backgroundColor:'transparent',  display:'flex', alignItems:'center', width:'100%'}}>
                                            <FontAwesomeIcon className="back-button" style={{width:'2em', height:'2em', marginLeft:'0.75em', padding:'5px'}} onClick={()=>{setSelectedDay(null)}} icon={faArrowLeft}></FontAwesomeIcon>
                                        </div>

                                        <div style={{backgroundColor:'transparent', display:'flex', justifyContent:'flex-end', alignItems:'center', width:'100%',}}>
                                            {/* <label style={{fontSize:'1em', marginTop:'5px', fontWeight:'bold', marginRight:'5px'}}>Review</label> */}
                                            {/* {reviewLoading ? 
                                            <div style={{display:'flex', width:'20px', height:'20px'}}>
                                                <Spinner text=""/>
                                            </div>
                                            : */}
                                            {reviewLoading?
                                            <SpinnerIcon/>
                                            :
                                            <FontAwesomeIcon className="download-button" onClick={()=> 
                                                isActivityReviewed(selectedDay.activities[selectedActivityIndex]) ? alert('Already Reviewed') : isDayInTheFuture(selectedDay) ? alert(`Please wait until the ${getCalendarDay(
                                                        currentUser.userPlanStartDate.toString(),
                                                        getWeekNumber(selectedDay),
                                                        getDayNumber(selectedDay)
                                                    )} to review this activity`) :
                                                        // Open Review                                                
                                                        getActivityReview(selectedDay.activities[selectedActivityIndex]).then(()=>{
                                                            setReviewingActivity(selectedDay.activities[selectedActivityIndex]);
                                                            setReviewLoading(false);
                                                        })}
                                                    style={{width:'2.5em', height:'2.5em', marginRight:'1em', padding:'5px', color:isActivityReviewed(selectedDay.activities[selectedActivityIndex]) ? colorPalette.mediumSeaGreen : 'white'}}  icon={faRobot}></FontAwesomeIcon>
                                            }
                                        {/* } */}

                                        </div>
                                    </div>

                                    <div style={{display:'flex', justifyContent:'flex-start',justifyItems:'flex-start',flexDirection:'column', backgroundColor:'transparent', width:'100%', height:'auto'}}>
                                        <div style={{display:'flex',flex:1,backgroundColor:'transparent', justifyContent:'center', alignItems:'center'}}>
                                            <div style={{width:'100%', height:'80%', backgroundColor:`${colorPalette.default}aa`, display:'flex', justifyContent:'center', alignItems:"center"}}>
                                                <div style={{display:'flex',flex:4,backgroundColor:'transparent', justifyContent:'center', alignItems:'center', height:'100%'}}>
                                                    <label style={{fontSize:'1em', fontWeight:'bold',padding:'2em',maxLines:1, overflowY:'hidden', fontStyle:'italic'}}>{
                                                    ((currentUser.currentVitovaPlan.content.length === getWeekNumber(selectedDay)+1) && getDayNumber(selectedDay) === 6) ? `${selectedDay.activities[selectedActivityIndex].title.toUpperCase()} - PRE-SURGERY GENTLE WALK IS OKAY` : selectedDay.activities[selectedActivityIndex].title.toUpperCase()
                                                    }</label>
                                                </div>
                                                <div style={{display:'flex',flex:2,backgroundColor:'transparent', justifyContent:'center', alignItems:'center', height:'100%'}}>
                                                <div style={{width:'100%', height:'100%', backgroundColor:selectedDay.activities[selectedActivityIndex].compulsory ? `${colorPalette.mediumSeaGreen}99`:`${colorPalette.darkOrange}99`, display:'flex', justifyContent:'center', alignItems:"center"}}>
                                                        <label style={{fontSize:'1.25em', fontWeight:'bold',width:'100%', maxLines:1,overflowY:'hidden', fontStyle:'italic'}}>{selectedDay.activities[selectedActivityIndex].compulsory ? `COMPULSORY ${selectedDay.activities[selectedActivityIndex].type === VitovaActivityType.TEST ?'TEST':'ACTIVITY'}`:`OPTIONAL ${selectedDay.activities[selectedActivityIndex].type === VitovaActivityType.TEST ?'TEST':'ACTIVITY'}`}</label>
                                                    </div>
                                                </div>
                                                <div style={{display:'flex',flex:1,backgroundColor:'transparent', justifyContent:'center', alignItems:'center', height:'100%'}}>
                                                    <div style={{width:'100%', height:'100%', backgroundColor:`${colorPalette.rpeYellow}aa`, display:'flex', justifyContent:'center', alignItems:"center"}}>
                                                        <label style={{fontSize:'1.25em', fontWeight:'bold', maxLines:1, overflowY:'hidden', fontStyle:'italic'}}>
                                                        {Math.round(getActivityStressScore(selectedDay.activities[selectedActivityIndex], currentUser))} ISS
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {selectedDay.activities[selectedActivityIndex].description &&
                                        <div style={{display:'flex',flex:8,backgroundColor:'transparent', justifyContent:'center', alignItems:'center'}}>
                                            <div style={{width:'100%', height:'100%', backgroundColor:`${colorPalette.default}dd`, display:'flex', textAlign:'start', justifyContent:'center', alignItems:'center'}}>
                                                <label style={{padding:'15px 10px', fontSize:'0.9em', textAlign:'justify', overflowY:'scroll', lineHeight:'20px'}}>
                                                    {
                                                    ((currentUser.currentVitovaPlan.content.length === getWeekNumber(selectedDay)+1) && getDayNumber(selectedDay) === 6) ? `${selectedDay.activities[selectedActivityIndex].description} Wishing all the best from VITOVA, be happy knowing you've made a difference to your health and wellbeing!` :selectedDay.activities[selectedActivityIndex].description
                                                    }</label>
                                            </div>
                                        </div>
                                        }
                                    </div>
                                    <Dialog open={isYoutubeWorkoutPopupOpen} onClose={()=>setIsYoutubeWorkoutPopupOpen(false)} maxWidth="md" fullWidth>
                                            <DialogTitle style={{ fontStyle: "italic", fontWeight: "bold" }}>
                                                FOLLOW ONE OF OUR YOUTUBE WORKOUTS
                                            </DialogTitle>
                                            <DialogContent>
                                            <YouTubeWorkoutsTable workouts={youtubeWorkouts}/>

                                            </DialogContent>
                                            <DialogActions>
                                                
                                                <Button onClick={()=>setIsYoutubeWorkoutPopupOpen(false)} variant="contained" color="primary">
                                                Back
                                                </Button>
                                            </DialogActions>
                                    </Dialog>
                                    <div style={{display:'flex', justifyContent:'flex-start',justifyItems:'flex-start',flexDirection:'row', backgroundColor:'#f1f1f1', width:'100%', height:'100%', overflow:'hidden'}}>                                        
                                        {/* SIDE SELECTOR */}
                                        {viewportSize.width/viewportSize.height > 1.25 && 
                                            <div style={{display:'flex',flex:1,backgroundColor:'#f1f1f1', justifyContent:'center', alignItems:'center'}}>
                                                <div style={{width:'8em', height:'100%', overflowX:'hidden',overflowY:'scroll',backgroundColor:`#f1f1f1`,  flexDirection:'column', display:"flex", justifyContent:'center', alignItems:'center'}}>
                                                    {selectedDay?.activities?.length > 0 && (selectedDay.activities ?? []).map((activity: VitovaActivity | undefined, index: number) => {
                                                        if (!activity) return null; // Ensure activity is defined before rendering
                                                        
                                                        return (
                                                            <div
                                                                key={activity.id} // Always add a unique key when mapping elements
                                                                className="section-buttons"
                                                                onClick={() => setSelectedActivityIndex(index)}
                                                                style={{
                                                                    minWidth:'100%',
                                                                    backgroundColor: selectedActivityIndex === index ? getActivityColor(activity.type) : `${colorPalette.default}88`,
                                                                    opacity: index % 2 === 0 ? 1 : 0.9,
                                                                    borderStyle: 'solid',
                                                                    display: 'flex',
                                                                    flexDirection: 'column',
                                                                    borderWidth: '0px',
                                                                    borderRightWidth: selectedActivityIndex === index ? '5px' : '3px',
                                                                    borderColor: selectedActivityIndex === index ? '#ffffff' : '#ffffff77',
                                                                }}
                                                            >
                                                                <FontAwesomeIcon
                                                                    className="button-icon"
                                                                    icon={
                                                                        activity.steps.length > 0
                                                                            ? activity.steps[0].type === VitovaStepType.CARDIO && activity.steps[0].cardio?.exercise
                                                                                ? exerciseIcons[activity.steps[0].cardio.exercise.name]
                                                                                : activityIcons[activity.steps[0].type]
                                                                            : faEllipsis
                                                                    }
                                                                />
                                                            </div>
                                                        );
                                                    })}
                                                        <div
                                                                className="section-buttons"
                                                                onClick={() => setIsYoutubeWorkoutPopupOpen(true)}
                                                                style={{
                                                                    minWidth:'100%',
                                                                    backgroundColor: `${colorPalette.softRed}`,
                                                                    opacity: selectedDay.activities.length % 2 === 0 ? 1 : 0.9,
                                                                    borderStyle: 'solid',
                                                                    display: 'flex',
                                                                    flexDirection: 'column',
                                                                    borderWidth: '0px',
                                                                    borderRightWidth: selectedActivityIndex === selectedDay.activities.length ? '5px' : '3px',
                                                                    borderColor: selectedActivityIndex === selectedDay.activities.length ? '#ffffff' : '#ffffff77',
                                                                }}
                                                            >
                                                                <FontAwesomeIcon
                                                                    className="button-icon"
                                                                    icon={faPlay}
                                                                />
                                                                <label style={{fontSize:'0.75em', fontWeight:'bold', fontStyle:'italic', color:'white', paddingLeft:'10px', paddingRight:'10px'}}>YOUTUBE VIDEOS</label>
                                                            </div>
                                                                    
                                                    {/* <div className="section-buttons" 
                                                        onClick={()=>addActivity()} 
                                                        style={{
                                                            backgroundColor:`${colorPalette.default}aa`,
                                                            borderStyle:'solid',
                                                            borderWidth:'0px',
                                                            borderRightWidth:'3px',
                                                            borderColor:'#ffffff77'
                                                            }}>
                                                        <FontAwesomeIcon className="button-icon" 
                                                            icon={faPlus}
                                                        ></FontAwesomeIcon>

                                                    </div> */}
                                                </div>
                                            </div>
                                        }
                                        {/* CARDIO BREAKDOWN */}
                                        {viewportSize.width/viewportSize.height > 1.25 && selectedDay.activities[selectedActivityIndex].steps.length > 0 && selectedDay.activities[selectedActivityIndex].steps[0].type === VitovaStepType.CARDIO &&
                                            <div style={{display:'flex',flex:8,backgroundColor:`#f1f1f1`, justifyContent:'center', alignItems:'center', flexDirection:'column'}}>
                                            {selectedDay.activities[selectedActivityIndex].steps.length > 0 ?
                                            <div style={{width:'100%', height:'100%', maxWidth:'100%',backgroundColor:`${colorPalette.default}33`, display:'flex', paddingLeft:'1em',paddingBottom:'1em',justifyContent:'flex-start', alignItems:'flex-end'}}>
                                                <div style={{ flexDirection: 'row', display:'flex', flex:3,justifyContent:'center', alignContent:'center', justifyItems:'center', alignItems:'flex-end', backgroundColor:'transparent', borderWidth: '3px',
                                                borderColor: '#ffffff',
                                                borderStyle: 'solid',
                                                borderTopWidth:'0',
                                                overflowX:'hidden',
                                                maxWidth:'80em',
                                                // maxHeight:'45vh',
                                                minHeight:'45vh',
                                                marginTop:'10vh',
                                                borderRightWidth:'0',
                                                width:'95%',
                                                // maxWidth:'50em',
                                                padding:'5px 5px 0px 0px'}}>
                                            <div style={{display:'flex', width:'100%', height:'100%',flexDirection:'row', alignItems:'flex-end', justifyContent:'center'}}>
                                                <WorkoutStepsDiagram exercises={exercises} bg={'transparent'} floor={true} user={currentUser} steps={selectedDay.activities[selectedActivityIndex].steps} key={selectedDay.activities[selectedActivityIndex].id}/>
                                            </div>  
                                        </div>
                                            </div>
                                            :
                                            <div style={{width:'100%', height:'100%', backgroundColor:`#f1f1f1`, display:'flex',justifyContent:'center', alignItems:'center'}}>

                                            <div style={{ flexDirection: 'row', display:'flex',justifyContent:'center', alignContent:'center', justifyItems:'center', alignItems:'flex-end', backgroundColor:'transparent'}}>
                                                <label style={{fontSize:'1.5em', fontWeight:'bold', color:'#454545'}}>No Workout Available For This Activity</label>
                                            </div>
                                            </div>
                                            }
                                            <div style={{display:"flex", width:'100%', backgroundColor:`${colorPalette.default}33`, justifyContent:'flex-end'}}>
                                                <></>
                                            </div>

                                            </div>
                                        }
                                        {/* CHOICEDAY  */}
                                        {viewportSize.width/viewportSize.height > 1.25 && selectedDay.activities[selectedActivityIndex].steps.length > 0 && selectedDay.activities[selectedActivityIndex].steps[0].type === VitovaStepType.CHOICE &&
                                            <div style={{display:'flex',flex:8,backgroundColor:`#f1f1f1`, justifyContent:'center', alignItems:'center', flexDirection:'column'}}>
                                            {selectedDay.activities[selectedActivityIndex].steps.length > 0 ?
                                            <div style={{width:'100%', height:'100%', maxWidth:'100%',backgroundColor:`${colorPalette.default}33`, display:'flex', paddingLeft:'1em',paddingBottom:'1em',justifyContent:'flex-start', alignItems:'flex-end'}}>
                                                <div style={{ flexDirection: 'row', display:'flex', flex:3,justifyContent:'center', alignContent:'center', justifyItems:'center', alignItems:'flex-end', backgroundColor:'transparent', borderWidth: '3px',
                                                borderColor: '#ffffff',
                                                borderStyle: 'solid',
                                                borderTopWidth:'0',
                                                overflowX:'hidden',
                                                maxWidth:'80em',
                                                // maxHeight:'45vh',
                                                minHeight:'45vh',
                                                marginTop:'10vh',
                                                borderRightWidth:'0',
                                                width:'95%',
                                                // maxWidth:'50em',
                                                padding:'5px 5px 0px 0px'}}>
                                            <div style={{display:'flex', flex:2,width:'100%', height:'100%',flexDirection:'row', alignItems:'flex-end', justifyContent:'center'}}>
                                                <WorkoutStepsDiagram bg={'transparent'} exercises={exercises} floor={true} user={currentUser} steps={selectedDay.activities[1].steps} key={selectedDay.activities[1].id}/>
                                            </div>  
                                            {/* <div style={{
                                                    display: 'flex',
                                                    width: '100%',
                                                    height:'100%',
                                                    flex:1,
                                                    flexDirection:'column',
                                                    backgroundColor: 'transparent',
                                                    alignItems:'center',
                                                    justifyContent: 'center',
                                                }}>
                                                    <label style={{color:'steelblue', padding:0, margin:0}}>Need Inspiration?</label>
                                                    <button style={{marginRight:'5px', marginTop:'0', alignSelf:'flex-start'}} className="review-button" onClick={() => setIsYoutubeWorkoutPopupOpen(true)}>
                                                        <span style={{ color: 'steelblue', fontStyle: 'italic', fontSize:'1em', marginTop:0, paddingTop:0}}>
                                                            NEED INSPIRATION?
                                                        </span>
                                                    </button>
                                                </div> */}
                                            </div> 
                                            </div>
                                            :
                                            <div style={{width:'100%', height:'100%', backgroundColor:`#f1f1f1`, display:'flex',justifyContent:'center', alignItems:'center'}}>

                                            <div style={{ flexDirection: 'row', display:'flex',justifyContent:'center', alignContent:'center', justifyItems:'center', alignItems:'flex-end', backgroundColor:'transparent'}}>
                                                <label style={{fontSize:'1.5em', fontWeight:'bold', color:'#454545'}}>No Workout Available For This Activity</label>
                                            </div>
                                            </div>
                                            }
                                            <div style={{display:"flex", width:'100%', backgroundColor:`${colorPalette.default}33`, justifyContent:'flex-end'}}>
                                                <></>
                                            </div>

                                            </div>
                                        }
                                        {/* CUSTOM ACTIVITY */}
                                        {viewportSize.width/viewportSize.height > 1.25 && selectedDay.activities[selectedActivityIndex].id.includes('extra_activity') &&
                                            <div style={{display:'flex',flex:8,backgroundColor:`#f1f1f1`, justifyContent:'center', alignItems:'center', flexDirection:'column'}}>
                                                <div

                                                style={{ display: 'flex', width: '100%', height:'100%',justifyContent: 'flex-start', alignItems: 'flex-start' }}
                                            >
                                                    <button style={{marginRight:'5px', marginTop:'5px', alignSelf:'flex-start'}} className="review-button" 
                                                        onClick={(e) => {
                                                            e.stopPropagation(); // Prevent triggering parent onClick
                                                            setSelectedActivityIndex(selectedActivityIndex-1);
                                                            deleteActivity(selectedDay.activities[selectedActivityIndex].id);
                                                        }}
                                                    >
                                                        <span style={{ color: 'steelblue', fontStyle: 'italic', fontSize:'1em', marginTop:0, paddingTop:0}}>
                                                            REMOVE ACTIVITY
                                                        </span>
                                                    </button>
  
                                            </div>
                                            <div style={{display:'flex', padding:'15px', gap:'10px'}}>
                                                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                                            <TextField
                                                                label="Duration (minutes)"
                                                                type="text"
                                                                fullWidth
                                                                variant="outlined"
                                                                // value={step.choiceday?.duration || ''}
                                                                // onChange={(e) => updateStepField(step.id, 'choiceday.duration', Number(e.target.value))} 
                                                                InputLabelProps={{ shrink: true }}
                                                                style={{ fontSize: "1.2em", backgroundColor: "transparent" }}
                                                                />
                                                        </div>
                                                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                                            <TextField
                                                                label="RPE (/10)"
                                                                type="text"
                                                                fullWidth
                                                                variant="outlined"
                                                                // value={step.choiceday?.rpe || ''}
                                                                // onChange={(e) => updateStepField(step.id, 'choiceday.rpe', Number(e.target.value))}
                                                                InputLabelProps={{ shrink: true }}
                                                                style={{ fontSize: "1.2em", backgroundColor: "transparent" }}
                                                                />
                                                        </div>
                                                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                                            <TextField
                                                                label="Notes"
                                                                multiline
                                                                type="text"
                                                            
                                                                fullWidth
                                                                variant="outlined"
                                                                // value={step.choiceday?.notes || ''}
                                                                // onChange={(e) => updateStepField(step.id, 'choiceday.notes', e.target.value)} 
                                                                InputLabelProps={{ shrink: true }}
                                                                style={{ fontSize: "1.2em", backgroundColor: "transparent", width:'30em'}}
                                                                />
                                                        </div>
                                                    </div>             

                                            </div>
                                        }

                                        <div style={{display:'flex',flex:(selectedDay.activities[selectedActivityIndex].steps.length > 0 && (selectedDay.activities[selectedActivityIndex].steps[0].type === VitovaStepType.CARDIO || selectedDay.activities[selectedActivityIndex].steps[0].type === VitovaStepType.CHOICE)) || selectedDay.activities[selectedActivityIndex].id.includes('extra_activity') ? 3:20,backgroundColor:`${colorPalette.default}44`, flexDirection:'column',justifyContent:'flex-start', alignItems:'center'}}>
                                            <div style={{width:'100%', height:'auto', backgroundColor:`${colorPalette.default}33`, flexDirection:'row', display:"flex", justifyContent:'flex-start', alignItems:'flex-start'}}>
                                            <div style={{width:'100%', height:'auto', backgroundColor:'transparent', flexDirection:'row', display:"flex", justifyContent:'flex-start', alignItems:'flex-start'}}>
                                                    <div className="section-buttons" onClick={()=>setSelectedWorkoutPart(0)} 
                                                    style={{
                                                        backgroundColor:selectedWorkoutPart===0?`${colorPalette.steelBlue}ff`:`${colorPalette.steelBlue}aa`,
                                                        borderStyle:'solid',
                                                        borderWidth:'0px',
                                                        borderBottomWidth:selectedWorkoutPart===0?'2px':'0px',
                                                        borderColor:'white',
                                                        width:'auto'
                                                        }}>
                                                        <label style={{
                                                            padding:'5px 20px', 
                                                            fontSize:'1em', 
                                                            pointerEvents:'none',
                                                            fontWeight:'bold',
                                                            fontStyle:'italic'
                                                            }}>{`WORKOUT (${Math.round(getActivityStressScore(selectedDay.activities[selectedActivityIndex], currentUser))} ISS)`}</label>
                                                    </div>
                                            </div>


                                            </div>
                                            {selectedWorkoutPart === 0 &&
                                            (
                                                <div style={{height:'100%', overflowY:'scroll',width:'100%', display:'flex',justifyContent:'flex-start', alignItems:'center', flexDirection:'column'}}>
                                                        {selectedDay.activities[selectedActivityIndex].steps.length > 0 && selectedDay.activities[selectedActivityIndex].steps[0].type === VitovaStepType.CHOICE
                                                        ?
                                                        selectedDay.activities[selectedActivityIndex+1].steps.map((step:VitovaActivityStep, index:number)=>(
                                                            <div style={{display:'flex', width:'100%',padding:'5px',flexDirection:'column',opacity:index%2 === 0 ? 0.9 : 0.75,
                                                                backgroundColor: colorPalette.steelBlue,
                                                                justifyContent:'center', alignItems:'flex-start'}}>
                                                                    <label style={{padding:'3px 6px',fontSize:'0.85em', textAlign:'left', overflowY:'scroll',fontStyle:'italic', fontWeight:'bold'}}>{`${index+1} | ${(buildStringFromStep(currentUser, exercises,step)).toUpperCase()}`}</label>
                                                            </div>
                                                        ))
                                                        :
                                                        selectedDay.activities[selectedActivityIndex].steps.length > 0 && selectedDay.activities[selectedActivityIndex].steps[0].type === VitovaStepType.CARDIO
                                                        ?
                                                        selectedDay.activities[selectedActivityIndex].steps.map((step:VitovaActivityStep, index:number)=>(
                                                            <div style={{display:'flex', width:'100%',padding:'5px',flexDirection:'column',opacity:index%2 === 0 ? 0.9 : 0.75,
                                                                backgroundColor: colorPalette.steelBlue,
                                                                justifyContent:'center', alignItems:'flex-start'}}>
                                                                    <label style={{padding:'3px 6px',fontSize:'0.85em', textAlign:'left', overflowY:'scroll',fontStyle:'italic', fontWeight:'bold'}}>{`${index+1} | ${(buildStringFromStep(currentUser, exercises,step)).toUpperCase()}`}</label>
                                                            </div>
                                                        ))
                                                            :
                                                            <div style={{ width: '100%' }}>
                                                                
                                                                {selectedDay.activities[selectedActivityIndex].steps.length > 0 && selectedDay.activities[selectedActivityIndex].steps[0].type !== VitovaStepType.REST &&
                                                                <div style={{ display: 'flex', width: '100%', padding: '5px', backgroundColor: colorPalette.steelBlue, fontWeight: 'bold', borderBottom: '2px solid #f1f1f1' }}>
                                                                    <span style={{ flex: 1, padding: '5px', fontSize: '1em', textAlign: 'center' }}>EXERCISE</span>
                                                                    <span style={{ flex: 1, padding: '5px', fontSize: '1em', textAlign: 'center' }}>SETS</span>
                                                                    <span style={{ flex: 1, padding: '5px', fontSize: '1em', textAlign: 'center' }}>REPS / DURATION</span>
                                                                    <span style={{ flex: 1, padding: '5px', fontSize: '1em', textAlign: 'center' }}>EFFORT</span>
                                                                </div>
                                                                }
                                                                {/* Data Rows */}
                                                                {selectedDay.activities[selectedActivityIndex].steps.map((step: VitovaActivityStep, index: number) => (
                                                                    step.type === VitovaStepType.STRENGTH || step.type === VitovaStepType.FLEXIBILITY ? (
                                                                        <div key={index} style={{ 
                                                                            display: 'flex', width: '100%', padding: '5px', 
                                                                            flexDirection: 'row', opacity: index % 2 === 0 ? 0.9 : 0.75, 
                                                                            backgroundColor: colorPalette.steelBlue, 
                                                                            justifyContent: 'space-between', alignItems: 'center', 
                                                                            borderBottom: '1px solid #f1f1f1' 
                                                                        }}>

                                                                            {/* Exercise Name */}
                                                                            <span 
                                                                                style={{textDecoration:'underline',flex: 1, padding: '5px', fontSize: '1.25em', textAlign: 'center', fontWeight:'bold',fontStyle:'italic',cursor: 'pointer' }}
                                                                                onClick={() => { 
                                                                                    setPopupExercise(getExercise(
                                                                                        step.type === VitovaStepType.STRENGTH && step.strength?.exercise 
                                                                                        ? step.strength?.exercise 
                                                                                        : step.type === VitovaStepType.FLEXIBILITY && step.flexibility?.exercise 
                                                                                        ? step.flexibility?.exercise 
                                                                                        : undefined
                                                                                    )); 
                                                                                    setIsExercisePopupOpen(true);
                                                                                }}
                                                                            >
                                                                                {step.type === VitovaStepType.STRENGTH && step.strength?.exercise 
                                                                                    ? `${step.strength?.exercise.name.toUpperCase() } ${step.isTestEffort ? '(TEST EFFORT)':''}`
                                                                                    : step.type === VitovaStepType.FLEXIBILITY && step.flexibility?.exercise 
                                                                                    ? `${step.flexibility?.exercise.name.toUpperCase()} ${step.isTestEffort ? '(TEST EFFORT)':''}`
                                                                                    : "N/A"}
                                                                            </span>

                                                                            {/* Sets */}
                                                                            <span style={{ flex: 1, padding: '5px', fontSize: '1em', textAlign: 'center' }}>
                                                                                {step.type === VitovaStepType.STRENGTH && step.strength?.sets 
                                                                                    ? step.strength.sets 
                                                                                    : step.type === VitovaStepType.FLEXIBILITY && step.flexibility?.sets 
                                                                                    ? step.flexibility.sets 
                                                                                    : "-"}
                                                                            </span>

                                                                            {/* Reps / Hold Duration */}
                                                                            <span style={{ flex: 1, padding: '5px', fontSize: '1em', textAlign: 'center' }}>
                                                                                {step.type === VitovaStepType.STRENGTH && step.strength?.reps 
                                                                                    ? step.strength.reps 
                                                                                    : step.type === VitovaStepType.STRENGTH && step.strength?.repsInSeconds ? step.strength.repsInSeconds >= 60 ? `${ step.strength.repsInSeconds/60}mins` : `${step.strength.repsInSeconds}s`
                                                                                    : step.type === VitovaStepType.FLEXIBILITY && step.flexibility?.holdDuration 
                                                                                    ? `${step.flexibility.holdDuration}s` 
                                                                                    : "-"}
                                                                            </span>

                                                                            {/* RPE */}
                                                                            <span style={{ flex: 1, padding: '5px', fontSize: '1em', textAlign: 'center' }}>
                                                                                {step.type === VitovaStepType.STRENGTH && step.strength?.rpe 
                                                                                    ? `${step.strength.rpe}/10` 
                                                                                    : step.type === VitovaStepType.FLEXIBILITY && step.flexibility?.rpe 
                                                                                    ? `${step.flexibility.rpe}/10` 
                                                                                    : "-"}
                                                                            </span>
                                                                        </div>
                                                                    ) : (
                                                                        <div key={index} style={{ 
                                                                            display: 'flex', width: '100%', padding: '5px', 
                                                                            flexDirection: 'column', opacity: index % 2 === 0 ? 0.9 : 0.75, 
                                                                            backgroundColor: colorPalette.steelBlue, 
                                                                            justifyContent: 'center', alignItems: 'flex-start' 
                                                                        }}>
                                                                            <label style={{ padding: '3px 6px', fontSize: '1.25em', textAlign: 'left', overflowY: 'scroll', fontStyle: 'italic', fontWeight: 'bold' }}>
                                                                                {`${index + 1} | ${(buildStringFromStep(currentUser, exercises,step)).toUpperCase()}`}
                                                                            </label>
                                                                        </div>
                                                                    )
                                                                ))}

                                                            </div>
                                                            
                                                            }
                                                        <ExercisePopup setExercise={setPopupExercise} exercises={exercises} index={0} isOpen={isExercisePopupOpen} handleClose={()=>setIsExercisePopupOpen(false)} exercise={popupExercise} step={popupStep}/>

                                                </div>
                                            )
                                            }
                                            {selectedWorkoutPart === 1 &&
                                            (
                                                <></>
                                            )
                                            }
                                        </div>
                                    </div>
                                </div>
                                )
                                :
                                (
                                <div className='card-enlarged'
                                    style={{
                                        backgroundColor: '#ffffff55', 
                                        height: '75vh', 
                                        width: '100%', 
                                        display: 'flex', 
                                        flexDirection: 'column', 
                                        justifyContent: 'center', 
                                        alignItems: 'center'
                                    }}>
                                        {(reviewStage === 0 || reviewStage===1) && 
                                        <FontAwesomeIcon className="back-button" style={{position:'absolute', top:'20px', left:'20px', color:'#454545'}}  onClick={()=>{setReviewingActivity(null); prevStage();}} icon={faArrowLeft}></FontAwesomeIcon>
                                        }
                                        {/* <h2 style={{fontSize:'40px'}} className="card-title" >Session Review</h2> */}
                                        <div className="session-review-form" >
                                            {reviewStage === 0 && (
                                                <div className="form-stage">
                                                    <h2 style={{ fontSize: '2.5em', fontWeight: 'bold', fontStyle:'italic',color:colorPalette.steelBlue}}>WELL DONE!</h2>
                                                    <h1 style={{color:'#454545', fontSize:'1.5em'}} className="single-line-text">Let's review your session and find out how it went!</h1>
            
                                                    <div style={{width:'100%', flexDirection:'column',display:'flex', justifyContent:'center', alignItems:'center', borderRadius:'5px',}}>
                                                        <button className="review-button" style={{fontSize:'1.5em'}} onClick={()=>{
                                                            if (!selectedDay.activities[selectedActivityIndex].steps.some(s => s.isTestEffort)) {
                                                                setReviewType('quick'); 
                                                                getActivityReview(selectedDay.activities[selectedActivityIndex])
                                                                nextStage(); 
                                                            }else{
                                                                alert('Please use in-depth review for tests')
                                                            }
                                                        }
                                                            }>{reviewLoading ? `LOADING REVIEW`: `QUICK REVIEW`} WITH <span style={{ color: 'steelblue', fontStyle: 'italic', marginLeft: '5px'}}>VITOVA</span>
                                                            </button>
                                                        <button className="review-button"  style={{marginTop:'5px', fontSize:'1.5em'}} onClick={()=>{
                                                                setReviewType('manual'); 
                                                                getActivityReview(selectedDay.activities[selectedActivityIndex])
                                                                nextStage(); 

                                                        }
                                                            }>IN-DEPTH REVIEW WITH <span style={{ color: 'steelblue', fontStyle: 'italic', marginLeft: '5px'}}>VITOVA</span></button>
                                                        <button
                                                        style={{fontSize:'1.5em'}}
                                                        onClick={()=>{
                                                            // setReviewType('strava');nextStage();
                                                            alert('This Feature Is Still In Development')
                                                        }}
                                                        className="strava-button"
                                                        >
                                                            AUTO REVIEW WITH <span style={{ color: '#fc4c02', fontStyle: 'italic', marginLeft: '5px' }}>STRAVA</span>
                                                        </button> 
                                                        {/* <Button className="review-button" onClick={()=>{}}>I MISSED THIS ACTIVITY</Button> */}

                                                        </div>
                                                </div>
            
                                            )}
                                            {reviewStage === 1 && reviewType === 'strava' && (
                                                <div className="form-stage">
                                                    <h2>Select The Activities You Completed Today</h2>
                                                    <div className="strava-activities-grid">
                                                        {currentUser.stravaActivities
                                                            .filter((sa: StravaActivity) => {
                                                                const activityDate = new Date(sa.start_date);
                                                                const today = new Date();
                                                                const sevenDaysAgo = new Date();
                                                                sevenDaysAgo.setDate(today.getDate() - 14);
                                                                return activityDate >= sevenDaysAgo && activityDate <= today;
                                                            })
                                                            .map((sa: StravaActivity) => (
                                                                <div key={sa.id} className="strava-activity-card">
                                                                    <label style={{marginTop:'10px'}} className="activity-name">{sa.name}</label>
                                                                    <label className="activity-detail">Date: {sa.start_date}</label>
                                                                    <label className="activity-detail">Type: {sa.sport_type}</label>

                                                                    <label className="activity-detail">Distance: {(sa.distance / 1000).toFixed(2)} km</label>
                                                                    <label style={{marginBottom:'10px'}} className="activity-detail">Duration: {Math.floor(sa.moving_time / 60)} min</label>
                                                                </div>

                                                            ))}
                                                    </div>

                                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', borderRadius: '5px', position: 'absolute', bottom: 0 }}>
                                                        <Button className="form-back-button" onClick={prevStage}>Previous</Button>
                                                        <Button className="form-next-button" onClick={nextStage}>Next Step</Button>
                                                    </div>
                                                </div>

                                            )}

                                            {reviewStage === 1 && reviewType === 'manual' && (
                                                <div style={{ display: 'flex', justifyContent: 'center', width: '100em'}}>
                                                <Container
                                                    style={{
                                                        color:"black",
                                                        textAlign:'left',
                                                        backgroundColor:'transparent',
                                                        // maxWidth: '80em',  // Ensures it doesn't exceed 80% of viewport width
                                                        width: '100%',  // Allows flexibility without overflowing
                                                        maxHeight: '70vh',  // Limits height to prevent excessive stretching
                                                        overflowY: 'auto',  // Enables scrolling if needed
                                                        overflowX: 'hidden',  // Prevents horizontal overflow
                                                        alignItems:'center',
                                                        borderRadius: '10px',
                                                        padding: '1rem',
                                                    }}
                                                >
                                                    {/* Popup */}
                                                        {/* <Container maxWidth="md" fullWidth> */}
                                                            {/* Centered DialogTitle with white background */}
                                                            <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                                                                <Paper
                                                                    sx={{
                                                                        backgroundColor: 'white', 
                                                                        padding: '0.75rem 1rem', 
                                                                        borderRadius: '5px',
                                                                        width:'96%',
                                                                        boxShadow:0,
                                                                        border:'1px solid lightgray',
                                                                        // maxWidth: '100%',  // Adjust width as needed (e.g., 50% of parent)
                                                                        textAlign: 'center', 
                                                                    }}
                                                                >
                                                                    <DialogTitle
                                                                        sx={{
                                                                            fontStyle: 'italic',
                                                                            fontWeight: 'bold',
                                                                            margin: 0,  // Remove extra margin
                                                                            padding: 0, // Adjust to fit within Paper
                                                                        }}
                                                                    >
                                                                        {selectedDay
                                                                            ? `LET'S SEE HOW YOU GOT ON WITH ${getDayOfWeek(
                                                                                currentUser.userPlanStartDate.toString(),
                                                                                getWeekNumber(selectedDay),
                                                                                next7Days.indexOf(selectedDay)
                                                                            ).toUpperCase()}'S ACTIVITIES!`
                                                                            : 'DAY DETAILS'}
                                                                    </DialogTitle>
                                                                </Paper>
                                                            </Box>
                                                            <DialogContent>
                                                                {/* Activity Selector */}
                                                                <FormControl style={{backgroundColor:'white', borderRadius:'5px'}} fullWidth sx={{ mb: 2, mt:2 }}>
                                                                    <InputLabel>Select Activity</InputLabel>
                                                                    <Select
                                                                        label={'Select Activity'}
                                                                        value={selectedActivityIndex}
                                                                        onChange={(e) => {setSelectedActivityIndex(Number(e.target.value)); getActivityReview(selectedDay.activities[Number(e.target.value)])}}
                                                                    >
                                                                        {selectedDay?.activities.map((activity, index) => (
                                                                            <MenuItem key={index} value={index}>
                                                                                {activity.title}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </FormControl>
                                                                {reviewLoading ?
                                                                <Spinner text="Loading Activity Review"/>
                                                                    :
                                                                <>
                                                                {/* Fetch the selected activity dynamically */}
                                                                {selectedDay?.activities.length > 0 && (
                                                                    
                                                                    <Paper variant="outlined" sx={{ p: 2, mt: 0 }}>
                                                                        <WorkoutStepsDiagram user={currentUser} steps={selectedDay.activities[selectedActivityIndex].steps} exercises={exercises} key={selectedDay.activities[selectedActivityIndex].id}/>
                                                                        <Typography sx={{mt:2}} style={{ fontStyle: 'italic', color: colorPalette.steelBlue }} variant="h6">
                                                                            {selectedDay.activities[selectedActivityIndex].title.toUpperCase()}
                                                                        </Typography>
                                                                        <Grid container justifyContent="space-between" sx={{ mt: 1 }}>
                                                                            <Typography variant="body2">
                                                                                {minutesToTimeString(selectedDay.activities[selectedActivityIndex].plannedDuration)}
                                                                            </Typography>
                                                                            <Typography variant="body2">
                                                                                {selectedDay.activities[selectedActivityIndex].plannedDistance
                                                                                    ? `${selectedDay.activities[selectedActivityIndex].plannedDistance} KM`
                                                                                    : '-- KM'}
                                                                            </Typography>
                                                                            <Typography variant="body2">
                                                                                {selectedDay.activities[selectedActivityIndex].plannedRPE
                                                                                    ? selectedDay.activities[selectedActivityIndex].plannedRPE
                                                                                    : '-- '}{' '}
                                                                                / 10 RPE
                                                                            </Typography>
                                                                            <Typography variant="body2">-- TSS</Typography>
                                                                        </Grid>
                                                                    </Paper>
                                                                )}

                                                                {selectedDay.activities[selectedActivityIndex].steps.map((step:VitovaActivityStep)=>(
                                                                    step.type === VitovaStepType.STRENGTH ?
                                                                    (
                                                                        <TableContainer style={{backgroundColor:step.isTestEffort?`${colorPalette.softRed}33`:'white'}} component={Paper} sx={{ mt: 3 }}>
                                                                        <DialogTitle style={{ fontStyle: 'italic', fontSize:'1.25em'}}>
                                                                            {step.strength?.exercise && step.strength?.exercise.name.toUpperCase()}
                                                                        </DialogTitle>
                                                                        <Table>
                                                                            <TableHead>
                                                                                <TableRow>
                                                                                    <TableCell></TableCell>
                                                                                    <TableCell align="center">
                                                                                        <Typography style={{ fontWeight: 'bold', fontStyle: 'italic' }} variant="subtitle1">
                                                                                            Planned
                                                                                        </Typography>
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                        <Typography style={{ fontWeight: 'bold', fontStyle: 'italic' }} variant="subtitle1">
                                                                                            Completed
                                                                                        </Typography>
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            </TableHead>
                                                                            <TableBody>
                                                                                <TableRow>
                                                                                    <TableCell style={{ fontWeight: 'bold', fontStyle: 'italic' }} align="center">
                                                                                        <Typography>Sets</Typography>
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                        {step.strength?.sets
                                                                                            ? step.strength.sets
                                                                                            : '--'}{' '}
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                    <TextField
                                                                                        value={
                                                                                            selectedReview?.activityReviewData.steps.find(s => s.id === step.id)?.strength?.sets || ''
                                                                                        }
                                                                                        onChange={(e) => {
                                                                                            const updatedSteps = selectedReview?.activityReviewData.steps.map((s) =>
                                                                                                s.id === step.id ? { ...s, strength: { ...s.strength, sets: e.target.value } } : s
                                                                                            );

                                                                                            updateReviewField(
                                                                                                selectedDay.activities[selectedActivityIndex],
                                                                                                'activityReviewData.steps',
                                                                                                updatedSteps
                                                                                            );
                                                                                        }}
                                                                                        variant="outlined"
                                                                                        size="small"
                                                                                    />
                                                                                </TableCell>
                                                                                </TableRow>
                                                                                <TableRow>
                                                                                    <TableCell style={{ fontWeight: 'bold', fontStyle: 'italic' }} align="center">
                                                                                        <Typography>Reps</Typography>
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                        {step.strength?.reps
                                                                                            ? step.strength.reps
                                                                                            : '--'}{' '}
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                        <TextField
                                                                                        value={
                                                                                            selectedReview?.activityReviewData.steps.find(s => s.id === step.id)?.strength?.reps || ''
                                                                                        }
                                                                                        onChange={(e) => {
                                                                                            const updatedSteps = selectedReview?.activityReviewData.steps.map((s) =>
                                                                                                s.id === step.id ? { ...s, strength: { ...s.strength, reps: e.target.value } } : s
                                                                                            );

                                                                                            updateReviewField(
                                                                                                selectedDay.activities[selectedActivityIndex],
                                                                                                'activityReviewData.steps',
                                                                                                updatedSteps
                                                                                            );
                                                                                        }}
                                                                                            variant="outlined"
                                                                                            size="small"
                                                                                        />
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                                <TableRow>
                                                                                    <TableCell style={{ fontWeight: 'bold', fontStyle: 'italic' }} align="center">
                                                                                        <Typography>RPE (/10)</Typography>
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                        {step.strength?.rpe
                                                                                            ? step.strength.rpe
                                                                                            : '--'}{' '}
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                        <TextField
                                                                                        value={
                                                                                            selectedReview?.activityReviewData.steps.find(s => s.id === step.id)?.strength?.rpe || ''
                                                                                        }
                                                                                        onChange={(e) => {
                                                                                            const updatedSteps = selectedReview?.activityReviewData.steps.map((s) =>
                                                                                                s.id === step.id ? { ...s, strength: { ...s.strength, rpe: e.target.value } } : s
                                                                                            );

                                                                                            updateReviewField(
                                                                                                selectedDay.activities[selectedActivityIndex],
                                                                                                'activityReviewData.steps',
                                                                                                updatedSteps
                                                                                            );
                                                                                        }}
                                                                                            variant="outlined"
                                                                                            size="small"
                                                                                        />
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            </TableBody>
                                                                        </Table>
                                                                        </TableContainer>
                                                                    )
                                                                    :
                                                                    step.type === VitovaStepType.CARDIO ?
                                                                    (
                                                                        <TableContainer style={{backgroundColor:step.isTestEffort?`${colorPalette.softRed}33`:'white'}} component={Paper} sx={{ mt: 3 }}>
                                                                        <DialogTitle style={{ fontStyle: 'italic', fontSize:'1.25em'}}>
                                                                            {step.cardio?.exercise && step.cardio?.exercise.name.toUpperCase()}
                                                                        </DialogTitle>
                                                                        <Table>
                                                                            <TableHead>
                                                                                <TableRow>
                                                                                    <TableCell></TableCell>
                                                                                    <TableCell align="center">
                                                                                        <Typography style={{ fontWeight: 'bold', fontStyle: 'italic' }} variant="subtitle1">
                                                                                            Planned
                                                                                        </Typography>
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                        <Typography style={{ fontWeight: 'bold', fontStyle: 'italic' }} variant="subtitle1">
                                                                                            Completed
                                                                                        </Typography>
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            </TableHead>
                                                                            <TableBody>
                                                                                <TableRow>
                                                                                    <TableCell style={{ fontWeight: 'bold', fontStyle: 'italic' }} align="center">
                                                                                        <Typography>Duration</Typography>
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                    {step.cardio?.duration
                                                                                            ? minutesToTimeString(step.cardio.duration)
                                                                                            : '--'}{' '}
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                        <TextField
                                                                                            value={
                                                                                                selectedReview?.activityReviewData.steps.find(s => s.id === step.id)?.cardio?.duration || ''
                                                                                            }
                                                                                            onChange={(e) => {
                                                                                                const updatedSteps = selectedReview?.activityReviewData.steps.map((s) =>
                                                                                                    s.id === step.id ? { ...s, cardio: { ...s.cardio, duration: e.target.value } } : s
                                                                                                );

                                                                                                updateReviewField(
                                                                                                    selectedDay.activities[selectedActivityIndex],
                                                                                                    'activityReviewData.steps',
                                                                                                    updatedSteps
                                                                                                );
                                                                                            }}
                                                                                            variant="outlined"
                                                                                            size="small"
                                                                                        />
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                                <TableRow>
                                                                                    <TableCell style={{ fontWeight: 'bold', fontStyle: 'italic' }} align="center">
                                                                                        <Typography>Distance</Typography>
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                        {step.cardio?.distance
                                                                                            ? step.cardio.distance
                                                                                            : '--'}{' '}
                                                                                        KM
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                        <TextField
                                                                                        value={
                                                                                            selectedReview?.activityReviewData.steps.find(s => s.id === step.id)?.cardio?.distance || ''
                                                                                        }
                                                                                        onChange={(e) => {
                                                                                            const updatedSteps = selectedReview?.activityReviewData.steps.map((s) =>
                                                                                                s.id === step.id ? { ...s, cardio: { ...s.cardio, distance: e.target.value } } : s
                                                                                            );

                                                                                            updateReviewField(
                                                                                                selectedDay.activities[selectedActivityIndex],
                                                                                                'activityReviewData.steps',
                                                                                                updatedSteps
                                                                                            );
                                                                                        }}
                                                                                            variant="outlined"
                                                                                            size="small"
                                                                                        />
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                                <TableRow>
                                                                                    <TableCell style={{ fontWeight: 'bold', fontStyle: 'italic' }} align="center">
                                                                                        <Typography>RPE (/10)</Typography>
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                        {step.cardio?.rpe
                                                                                            ? step.cardio.rpe
                                                                                            : '--'}{' '}
                                                                                        / 10
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                        <TextField
                                                                                        value={
                                                                                            selectedReview?.activityReviewData.steps.find(s => s.id === step.id)?.cardio?.rpe || ''
                                                                                        }
                                                                                        onChange={(e) => {
                                                                                            const updatedSteps = selectedReview?.activityReviewData.steps.map((s) =>
                                                                                                s.id === step.id ? { ...s, cardio: { ...s.cardio, rpe: e.target.value } } : s
                                                                                            );

                                                                                            updateReviewField(
                                                                                                selectedDay.activities[selectedActivityIndex],
                                                                                                'activityReviewData.steps',
                                                                                                updatedSteps
                                                                                            );
                                                                                        }}
                                                                                            variant="outlined"
                                                                                            size="small"
                                                                                        />
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                                <TableRow>
                                                                                    <TableCell style={{ fontWeight: 'bold', fontStyle: 'italic' }} align="center">
                                                                                        <Typography>Heart Rate</Typography>
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                    {step.cardio?.heartRate
                                                                                            ? step.cardio.heartRate
                                                                                            : '--'}{' '}
                                                                                        BPM
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                        <TextField
                                                                                        value={
                                                                                            selectedReview?.activityReviewData.steps.find(s => s.id === step.id)?.cardio?.heartRate || ''
                                                                                        }
                                                                                        onChange={(e) => {
                                                                                            const updatedSteps = selectedReview?.activityReviewData.steps.map((s) =>
                                                                                                s.id === step.id ? { ...s, cardio: { ...s.cardio, heartRate: e.target.value } } : s
                                                                                            );

                                                                                            updateReviewField(
                                                                                                selectedDay.activities[selectedActivityIndex],
                                                                                                'activityReviewData.steps',
                                                                                                updatedSteps
                                                                                            );
                                                                                        }}
                                                                                            variant="outlined"
                                                                                            size="small"
                                                                                        />
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                                <TableRow>
                                                                                    <TableCell style={{ fontWeight: 'bold', fontStyle: 'italic' }} align="center">
                                                                                        <Typography>Pace (m/s)</Typography>
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                        {step.cardio?.pace
                                                                                            ? step.cardio.pace
                                                                                            : '--'}{' '}
                                                                                        M/S
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                        <TextField
                                                                                        value={
                                                                                            selectedReview?.activityReviewData.steps.find(s => s.id === step.id)?.cardio?.pace || ''
                                                                                        }
                                                                                        onChange={(e) => {
                                                                                            const updatedSteps = selectedReview?.activityReviewData.steps.map((s) =>
                                                                                                s.id === step.id ? { ...s, cardio: { ...s.cardio, pace: e.target.value } } : s
                                                                                            );

                                                                                            updateReviewField(
                                                                                                selectedDay.activities[selectedActivityIndex],
                                                                                                'activityReviewData.steps',
                                                                                                updatedSteps
                                                                                            );
                                                                                        }}
                                                                                            variant="outlined"
                                                                                            size="small"
                                                                                        />
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                                <TableRow>
                                                                                    <TableCell style={{ fontWeight: 'bold', fontStyle: 'italic' }} align="center">
                                                                                        <Typography>Power (watts)</Typography>
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                    {step.cardio?.power
                                                                                            ? step.cardio.power
                                                                                            : '--'}{' '}
                                                                                        W
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                        <TextField
                                                                                        value={
                                                                                            selectedReview?.activityReviewData.steps.find(s => s.id === step.id)?.cardio?.power || ''
                                                                                        }
                                                                                        onChange={(e) => {
                                                                                            const updatedSteps = selectedReview?.activityReviewData.steps.map((s) =>
                                                                                                s.id === step.id ? { ...s, cardio: { ...s.cardio, power: e.target.value } } : s
                                                                                            );

                                                                                            updateReviewField(
                                                                                                selectedDay.activities[selectedActivityIndex],
                                                                                                'activityReviewData.steps',
                                                                                                updatedSteps
                                                                                            );
                                                                                        }}
                                                                                            variant="outlined"
                                                                                            size="small"
                                                                                        />
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            </TableBody>
                                                                        </Table>
                                                                        </TableContainer>
                                                                    )
                                                                    :
                                                                    step.type === VitovaStepType.FLEXIBILITY ?
                                                                    (
                                                                        <TableContainer style={{backgroundColor:step.isTestEffort?`${colorPalette.softRed}33`:'white'}} component={Paper} sx={{ mt: 3 }}>
                                                                        <DialogTitle style={{ fontStyle: 'italic', fontSize:'1.25em'}}>
                                                                            {step.flexibility?.exercise && step.flexibility?.exercise.name.toUpperCase()}
                                                                        </DialogTitle>
                                                                        <Table>
                                                                            <TableHead>
                                                                                <TableRow>
                                                                                    <TableCell></TableCell>
                                                                                    <TableCell align="center">
                                                                                        <Typography style={{ fontWeight: 'bold', fontStyle: 'italic' }} variant="subtitle1">
                                                                                            Planned
                                                                                        </Typography>
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                        <Typography style={{ fontWeight: 'bold', fontStyle: 'italic' }} variant="subtitle1">
                                                                                            Completed
                                                                                        </Typography>
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            </TableHead>
                                                                            <TableBody>
                                                                                <TableRow>
                                                                                    <TableCell style={{ fontWeight: 'bold', fontStyle: 'italic' }} align="center">
                                                                                        <Typography>Sets</Typography>
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                        {step.flexibility?.sets
                                                                                            ? step.flexibility.sets
                                                                                            : '--'}{' '}
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                        <TextField
                                                                                            value={
                                                                                                selectedReview?.activityReviewData.steps.find(s => s.id === step.id)?.flexibility?.sets || ''
                                                                                            }
                                                                                            onChange={(e) => {
                                                                                                const updatedSteps = selectedReview?.activityReviewData.steps.map((s) =>
                                                                                                    s.id === step.id ? { ...s, flexibility: { ...s.flexibility, sets: e.target.value } } : s
                                                                                                );

                                                                                                updateReviewField(
                                                                                                    selectedDay.activities[selectedActivityIndex],
                                                                                                    'activityReviewData.steps',
                                                                                                    updatedSteps
                                                                                                );
                                                                                            }}
                                                                                            variant="outlined"
                                                                                            size="small"
                                                                                        />
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                                <TableRow>
                                                                                    <TableCell style={{ fontWeight: 'bold', fontStyle: 'italic' }} align="center">
                                                                                        <Typography>Hold Duration</Typography>
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                        {step.flexibility?.holdDuration
                                                                                            ? step.flexibility.holdDuration
                                                                                            : '--'}{' '}
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                        <TextField
                                                                                            value={
                                                                                                selectedReview?.activityReviewData.steps.find(s => s.id === step.id)?.flexibility?.holdDuration || ''
                                                                                            }
                                                                                            onChange={(e) => {
                                                                                                const updatedSteps = selectedReview?.activityReviewData.steps.map((s) =>
                                                                                                    s.id === step.id ? { ...s, flexibility: { ...s.flexibility, holdDuration: e.target.value } } : s
                                                                                                );

                                                                                                updateReviewField(
                                                                                                    selectedDay.activities[selectedActivityIndex],
                                                                                                    'activityReviewData.steps',
                                                                                                    updatedSteps
                                                                                                );
                                                                                            }}
                                                                                            variant="outlined"
                                                                                            size="small"
                                                                                        />
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                                <TableRow>
                                                                                    <TableCell style={{ fontWeight: 'bold', fontStyle: 'italic' }} align="center">
                                                                                        <Typography>RPE (/10)</Typography>
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                        {step.flexibility?.rpe
                                                                                            ? step.flexibility.rpe
                                                                                            : '--'}{' '}
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                        <TextField
                                                                                            value={
                                                                                                selectedReview?.activityReviewData.steps.find(s => s.id === step.id)?.flexibility?.rpe || ''
                                                                                            }
                                                                                            onChange={(e) => {
                                                                                                const updatedSteps = selectedReview?.activityReviewData.steps.map((s) =>
                                                                                                    s.id === step.id ? { ...s, flexibility: { ...s.flexibility, rpe: e.target.value } } : s
                                                                                                );

                                                                                                updateReviewField(
                                                                                                    selectedDay.activities[selectedActivityIndex],
                                                                                                    'activityReviewData.steps',
                                                                                                    updatedSteps
                                                                                                );
                                                                                            }}
                                                                                            variant="outlined"
                                                                                            size="small"
                                                                                        />
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            </TableBody>
                                                                        </Table>
                                                                        </TableContainer>
                                                                    )
                                                                    :
                                                                    (
                                                                        <TableContainer component={Paper} sx={{ mt: 3 }}>
                                                                        <DialogTitle style={{ fontStyle: 'italic', fontSize:'1.25em'}}>
                                                                            {'Daily Activity'}
                                                                        </DialogTitle>
                                                                        <Table>
                                                                            <TableHead>
                                                                                <TableRow>
                                                                                    <TableCell></TableCell>
                                                                                    <TableCell align="center">
                                                                                        <Typography style={{ fontWeight: 'bold', fontStyle: 'italic' }} variant="subtitle1">
                                                                                            Planned
                                                                                        </Typography>
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                        <Typography style={{ fontWeight: 'bold', fontStyle: 'italic' }} variant="subtitle1">
                                                                                            Completed
                                                                                        </Typography>
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            </TableHead>
                                                                            <TableBody>
                                                                                <TableRow>
                                                                                    <TableCell style={{ fontWeight: 'bold', fontStyle: 'italic' }} align="center">
                                                                                        <Typography>Exercise</Typography>
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                        {step.choiceday?.exercise
                                                                                            ? step.choiceday.exercise
                                                                                            : '--'}{' '}
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                        <TextField
                                                                                            value={
                                                                                                selectedReview?.activityReviewData.steps.find(s => s.id === step.id)?.choiceday?.exercise || ''
                                                                                            }
                                                                                            onChange={(e) => {
                                                                                                const updatedSteps = selectedReview?.activityReviewData.steps.map((s) =>
                                                                                                    s.id === step.id ? { ...s, choiceday: { ...s.choiceday, exercise: e.target.value } } : s
                                                                                                );

                                                                                                updateReviewField(
                                                                                                    selectedDay.activities[selectedActivityIndex],
                                                                                                    'activityReviewData.steps',
                                                                                                    updatedSteps
                                                                                                );
                                                                                            }}
                                                                                            variant="outlined"
                                                                                            size="small"
                                                                                        />
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                                <TableRow>
                                                                                    <TableCell style={{ fontWeight: 'bold', fontStyle: 'italic' }} align="center">
                                                                                        <Typography>Duration</Typography>
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                        {step.choiceday?.duration
                                                                                            ? step.choiceday.duration
                                                                                            : '--'}{' '}
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                        <TextField
                                                                                            value={
                                                                                                selectedReview?.activityReviewData.steps.find(s => s.id === step.id)?.choiceday?.duration || ''
                                                                                            }
                                                                                            onChange={(e) => {
                                                                                                const updatedSteps = selectedReview?.activityReviewData.steps.map((s) =>
                                                                                                    s.id === step.id ? { ...s, choiceday: { ...s.choiceday, duration: e.target.value } } : s
                                                                                                );

                                                                                                updateReviewField(
                                                                                                    selectedDay.activities[selectedActivityIndex],
                                                                                                    'activityReviewData.steps',
                                                                                                    updatedSteps
                                                                                                );
                                                                                            }}
                                                                                            variant="outlined"
                                                                                            size="small"
                                                                                        />
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                                <TableRow>
                                                                                    <TableCell style={{ fontWeight: 'bold', fontStyle: 'italic' }} align="center">
                                                                                        <Typography>RPE (/10)</Typography>
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                        {step.choiceday?.rpe
                                                                                            ? step.choiceday.rpe
                                                                                            : '--'}{' '}
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                        <TextField
                                                                                            value={
                                                                                                selectedReview?.activityReviewData.steps.find(s => s.id === step.id)?.choiceday?.rpe || ''
                                                                                            }
                                                                                            onChange={(e) => {
                                                                                                const updatedSteps = selectedReview?.activityReviewData.steps.map((s) =>
                                                                                                    s.id === step.id ? { ...s, choiceday: { ...s.choiceday, rpe: e.target.value } } : s
                                                                                                );

                                                                                                updateReviewField(
                                                                                                    selectedDay.activities[selectedActivityIndex],
                                                                                                    'activityReviewData.steps',
                                                                                                    updatedSteps
                                                                                                );
                                                                                            }}
                                                                                            variant="outlined"
                                                                                            size="small"
                                                                                        />
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            </TableBody>
                                                                        </Table>
                                                                        </TableContainer>
                                                                    )
                                                                ))}

                                                                <Typography variant="subtitle1" sx={{ mt: 3 }}>
                                                                    Description
                                                                </Typography>

                                                                <Typography variant="body2" color="textSecondary">{selectedDay.activities[selectedActivityIndex].description}
                                                                </Typography>

                                                                <Typography variant="subtitle1" sx={{ mt: 3 }}>
                                                                    Post-activity comments
                                                                </Typography>
                                                                <TextField
                                                                    value={selectedReview?.notes}
                                                                    onChange={(e) => updateReviewField(selectedDay.activities[selectedActivityIndex], 'notes', e.target.value)}
                                                                    fullWidth
                                                                    multiline
                                                                    rows={3}
                                                                    placeholder="Enter a new comment"
                                                                    variant="outlined"
                                                                />
                                                                </>
                                                                }

                                                            </DialogContent>
                                                            <DialogActions>
                                                                <Button onClick={() => onSaveReview(false)} variant="contained" color="primary">Save For Later</Button>
                                                                <Button onClick={() => onSaveReview(true)} color="primary">Save & Submit</Button>
                                                            </DialogActions>
                                                        {/* </Container> */}
                                                </Container>
                                                </div>
                                            )}

                                            {reviewStage === 1 && reviewType === 'quick' && (
                                                <div style={{ display: 'flex', justifyContent: 'center', width: '100em'}}>
                                                <Container
                                                    style={{
                                                        color:"black",
                                                        textAlign:'left',
                                                        backgroundColor:'transparent',
                                                        width: '100%',  // Allows flexibility without overflowing
                                                        maxHeight: '70vh',  // Limits height to prevent excessive stretching
                                                        overflowY: 'auto',  // Enables scrolling if needed
                                                        overflowX: 'hidden',  // Prevents horizontal overflow
                                                        alignItems:'center',
                                                        borderRadius: '10px',
                                                        padding: '1rem',
                                                    }}
                                                >                                                    
                                                    {/* ACTVIITES */}
                                                            <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                                                                <Paper
                                                                    sx={{
                                                                        backgroundColor: 'white', 
                                                                        padding: '0.75rem 1rem', 
                                                                        borderRadius: '5px',
                                                                        width:'96%',
                                                                        boxShadow:0,
                                                                        border:'1px solid lightgray',
                                                                        // maxWidth: '100%',  // Adjust width as needed (e.g., 50% of parent)
                                                                        textAlign: 'center', 
                                                                    }}
                                                                >
                                                                    <DialogTitle
                                                                        sx={{
                                                                            fontStyle: 'italic',
                                                                            fontWeight: 'bold',
                                                                            margin: 0,  // Remove extra margin
                                                                            padding: 0, // Adjust to fit within Paper
                                                                        }}
                                                                    >
                                                                        {selectedDay
                                                                            ? `LET'S SEE HOW YOU GOT ON WITH ${getDayOfWeek(
                                                                                currentUser.userPlanStartDate.toString(),
                                                                                getWeekNumber(selectedDay),
                                                                                next7Days.indexOf(selectedDay)
                                                                            ).toUpperCase()}'S ACTIVITIES!`
                                                                            : 'DAY DETAILS'}
                                                                    </DialogTitle>
                                                                </Paper>
                                                            </Box>
                                                            <DialogContent>
                                                                {/* Activity Selector */}
                                                                <FormControl style={{backgroundColor:'white', borderRadius:'5px'}} fullWidth sx={{ mb: 2, mt:2 }}>
                                                                    <InputLabel>Select Activity</InputLabel>
                                                                    <Select
                                                                        label={'Select Activity'}
                                                                        value={selectedActivityIndex}
                                                                        onChange={(e) => {setSelectedActivityIndex(Number(e.target.value)); getActivityReview(selectedDay.activities[Number(e.target.value)])}}
                                                                    >
                                                                        {selectedDay?.activities.map((activity, index) => (
                                                                            <MenuItem key={index} value={index}>
                                                                                {activity.title}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </FormControl>

                                                                {reviewLoading ?
                                                                <Spinner text="Loading Activity Review"/>
                                                                    :
                                                                <>
                                                                {/* Fetch the selected activity dynamically */}
                                                                {selectedDay?.activities.length > 0 && (
                                                                    <Paper variant="outlined" sx={{ p: 2, mt: 0 }}>
                                                                        <WorkoutStepsDiagram user={currentUser} steps={selectedDay.activities[selectedActivityIndex].steps} exercises={exercises} key={selectedDay.activities[selectedActivityIndex].id}/>
                                                                        <Typography sx={{mt:2}} style={{ fontStyle: 'italic', color: colorPalette.steelBlue }} variant="h6">
                                                                            {selectedDay.activities[selectedActivityIndex].title.toUpperCase()}
                                                                        </Typography>
                                                                        <Grid container justifyContent="space-between" sx={{ mt: 1 }}>
                                                                            <Typography variant="body2">
                                                                                {minutesToTimeString(selectedDay.activities[selectedActivityIndex].plannedDuration)}
                                                                            </Typography>
                                                                            <Typography variant="body2">
                                                                                {selectedDay.activities[selectedActivityIndex].plannedDistance
                                                                                    ? `${selectedDay.activities[selectedActivityIndex].plannedDistance} KM`
                                                                                    : '-- KM'}
                                                                            </Typography>
                                                                            <Typography variant="body2">
                                                                                {selectedDay.activities[selectedActivityIndex].plannedRPE
                                                                                    ? selectedDay.activities[selectedActivityIndex].plannedRPE
                                                                                    : '-- '}{' '}
                                                                                / 10 RPE
                                                                            </Typography>
                                                                            <Typography variant="body2">-- TSS</Typography>
                                                                        </Grid>
                                                                    </Paper>
                                                                )}

                                                                <TableContainer style={{backgroundColor:'white'}} component={Paper} sx={{ mt: 3 }}>
                                                                        <DialogTitle style={{ fontStyle: 'italic', fontSize:'1.25em'}}>
                                                                        {`${reviewingActivity.title.toUpperCase()} COMPLETION FORM`}
                                                                        </DialogTitle>
                                                                        <Table>
                                                                            <TableHead>
                                                                                <TableRow>
                                                                                    <TableCell></TableCell>
                                                                                    <TableCell align="center">
                                                                                        <Typography style={{ fontWeight: 'bold', fontStyle: 'italic' }} variant="subtitle1">
                                                                                            Planned
                                                                                        </Typography>
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                        <Typography style={{ fontWeight: 'bold', fontStyle: 'italic' }} variant="subtitle1">
                                                                                            Completed
                                                                                        </Typography>
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            </TableHead>
                                                                            <TableBody>
                                                                            <TableRow>
                                                                                    <TableCell style={{ fontWeight: 'bold', fontStyle: 'italic' }} align="center">
                                                                                        <Typography>Duration (minutes)</Typography>
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                        {selectedDay.activities[selectedActivityIndex].plannedDuration}
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                        <TextField
                                                                                            value={selectedReview?.activityReviewData.plannedDuration || ''}
                                                                                            onChange={(e) => {
                                                                                                const updatedValue = e.target.value;

                                                                                                updateReviewField(
                                                                                                    selectedDay.activities[selectedActivityIndex],
                                                                                                    'activityReviewData.plannedDuration',
                                                                                                    updatedValue
                                                                                                );
                                                                                            }}
                                                                                            variant="outlined"
                                                                                            size="small"
                                                                                        />
                                                                                    </TableCell>

                                                                                </TableRow>
                                                                                <TableRow>
                                                                                    <TableCell style={{ fontWeight: 'bold', fontStyle: 'italic' }} align="center">
                                                                                        <Typography>RPE (/10)</Typography>
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                        {selectedDay.activities[selectedActivityIndex].plannedRPE}
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                        <TextField
                                                                                            value={selectedReview?.activityReviewData.plannedRPE || ''}
                                                                                            onChange={(e) => {
                                                                                                const updatedValue = e.target.value;

                                                                                                updateReviewField(
                                                                                                    selectedDay.activities[selectedActivityIndex],
                                                                                                    'activityReviewData.plannedRPE',
                                                                                                    updatedValue
                                                                                                );
                                                                                            }}
                                                                                            variant="outlined"
                                                                                            size="small"
                                                                                        />
                                                                                    </TableCell>

                                                                                </TableRow>
                                                                            </TableBody>
                                                                        </Table>
                                                                </TableContainer>

                                                                <Typography variant="subtitle1" sx={{ mt: 3 }}>
                                                                    Description
                                                                </Typography>

                                                                <Typography variant="body2" color="textSecondary">{selectedDay.activities[selectedActivityIndex].description}
                                                                </Typography>

                                                                <Typography variant="subtitle1" sx={{ mt: 3 }}>
                                                                    Post-activity comments
                                                                </Typography>
                                                                <TextField
                                                                    value={selectedReview?.notes}
                                                                    onChange={(e) => updateReviewField(selectedDay.activities[selectedActivityIndex], 'notes', e.target.value)}
                                                                    fullWidth
                                                                    multiline
                                                                    rows={3}
                                                                    placeholder="Enter a new comment"
                                                                    variant="outlined"
                                                                />
                                                                </>
                                                                }
                                                            </DialogContent>
                                                            <DialogActions>
                                                                <Button onClick={() => onSaveReview(false)} variant="contained" color="primary">Save For Later</Button>
                                                                <Button onClick={() => onSaveReview(true)} color="primary">Save & Submit</Button>
                                                            </DialogActions>
                                                        {/* </Container> */}
                                                </Container>
                                                </div>
                                            )}
                                        </div>
                                </div>
                                )
                        ) : (
                            <div style={{display:'flex', flexDirection:'column', width:'100%', height:'100%', gap:'2vh', marginTop:0}}>

                            <div style={{ flex: 1, minWidth: '100%', maxWidth:'100em',height: '90%' }}>
                            <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="droppable-grid" direction="horizontal">
                                {(provided) => (
                                <ul 
                                    className="custom-grid-container"
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                >
                                    {next7Days
                                    .map((day: VitovaDay, index: number) => ({ day, id: index.toString() }))
                                    .map((dayItem, index) => (
                                        <Draggable
                                        isDragDisabled={isDayInThePast(dayItem.day) || isDayComplete(dayItem.day)}
                                        key={dayItem.id}
                                        draggableId={dayItem.id}
                                        index={index}
                                        >
                                        {(provided, snapshot) => (
                                            <li
                                            onClick={() => {setSelectedActivityIndex(0);setSelectedDay(dayItem.day)}}
                                            className={`custom-grid-item ${snapshot.isDragging ? 'dragging' : ''} ${
                                                isDayInThePast(dayItem.day) || isDayComplete(dayItem.day)
                                                ? 'disabled'
                                                : ''
                                            }`}
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={{
                                                ...provided.draggableProps.style,
                                                opacity: snapshot.isDragging ? 0.5 : 1,
                                                backgroundColor:
                                                snapshot.isDragging && !isDayInThePast(dayItem.day) && !isDayComplete(dayItem.day)
                                                    ? 'lightgrey'
                                                    : '',
                                            }}
                                            >
                                            <div className="custom-card">
                                                {/* Icon Section */}
                                                <div
                                                className="custom-card-icon-section"
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    justifyContent: 'flex-end',
                                                    alignItems: 'flex-end',
                                                    height:'100%'
                                                }}
                                                >
                                                <div
                                                    style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    width: '100%',
                                                    gap: '10px',
                                                    }}
                                                >
                                                    {dayItem.day.activities.filter(a=>a.compulsory).map((activity:VitovaActivity, idx:number) => (
                                                    <React.Fragment key={idx}>
                                                        {/* Icon */}
                                                        <div
                                                        style={{
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                        }}
                                                        >
                                                        <FontAwesomeIcon
                                                            style={{
                                                            color: isDayInTheFuture(dayItem.day)
                                                                ? '#2f3b4a' :
                                                                isDayComplete(dayItem.day)? 
                                                                colorPalette.mediumSeaGreen
                                                                : colorPalette.softRed,
                                                            fontSize: dayItem.day.activities.filter(a=>a.compulsory).length <= 1 ? '3em' : '2em',
                                                            marginLeft:
                                                                dayItem.day.activities.filter(a=>a.compulsory).length > 0 && idx === 0 ? '5px' : '0px',
                                                            marginRight: idx === 1 ? '0px' : '5px',
                                                            }}
                                                            icon={activity.steps.length > 0 ? 
                                                                (
                                                                activity.steps[0].type === VitovaStepType.CARDIO && 
                                                                activity.steps[0].cardio?.exercise ?
                                                                    exerciseIcons[activity.steps[0].cardio.exercise.name]
                                                                : 
                                                                    activityIcons[activity.steps[0].type]
                                                                ) : faEllipsis}
                                                        />
                                                        </div>

                                                        {/* Separator */}
                                                        {idx < dayItem.day.activities.filter(a=>a.compulsory).length - 1 && (
                                                        <div
                                                            style={{
                                                            width: '2px',
                                                            height: '4em',
                                                            backgroundColor: '#ccc',
                                                            }}
                                                        />
                                                        )}
                                                    </React.Fragment>
                                                    ))}
                                                </div>
                                                </div>

                                                {/* Content Section */}
                                                <div className="custom-card-content" style={{justifyContent:'flex-end', display:'flex', height:'100%'}}>
                                                <div style={{ color: '#2f3b4a' }}>
                                                    <div
                                                    style={{
                                                        fontSize: '1.2em',
                                                        fontWeight: 'bold',
                                                    }}
                                                    >
                                                    {getDayOfWeek(
                                                        currentUser.userPlanStartDate.toString(),
                                                        getWeekNumber(dayItem.day),
                                                        next7Days.indexOf(dayItem.day)
                                                    )}
                                                    </div>
                                                    <div
                                                    style={{
                                                        fontSize: '1em',
                                                    }}
                                                    >
                                                    {getCalendarDay(
                                                        currentUser.userPlanStartDate.toString(),
                                                        getWeekNumber(dayItem.day),
                                                        next7Days.indexOf(dayItem.day)
                                                    )}
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                            </li>
                                        )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </ul>
                                )}
                            </Droppable>
                            </DragDropContext>
                            </div>
                            <div style={{flex:3,width:'100%'}}>

                            <div style={{display:'flex', width:'100%', height:'100%', flexDirection:'row', alignItems:'flex-start'}}>
                                        <div style={{display:'flex', width:'100%', height:'100%', flex:2, flexDirection:'column', alignItems:'flex-start', justifyContent:'center'}}>
                                            <label style={{color:colorPalette.steelBlue, marginTop: '0px', marginLeft:'1em', fontSize:'1.5em', fontWeight:'bold', fontStyle:'italic'}}>{`TRAINING LOAD FOR WEEK ${getWeekNumber(next7Days[0])+1}`}</label>
                                            {/* <GeneralChartComponent mh={80} mw={100} 
                                            chartData={next7Days.map(day=>getDayStressScore(day, currentUser))} 
                                            labelData={next7Days.map((d:VitovaDay)=>getCalendarDay(currentUser.userPlanStartDate.toString(), getWeekNumber(d), next7Days.indexOf(d)).toString())} 
                                            user={currentUser}></GeneralChartComponent> */}
                                            <ComparisonChartComponent mh={80} mw={100} 
                                            plannedData={next7Days.map(day=>getDayStressScore(day, currentUser, false))} 
                                            realData={getUserReviewsGroupedIntoDays().map(day=>getDayStressScore(day, currentUser, true))}
                                            labelData={next7Days.map((d:VitovaDay)=>getCalendarDay(currentUser.userPlanStartDate.toString(), getWeekNumber(d), next7Days.indexOf(d)).toString())} 
                                            user={currentUser}></ComparisonChartComponent>
                                        </div>
                                        <div style={{display:'flex', width:'100%', height:'100%', flex:1, backgroundColor:'#e7e7e7', flexDirection:'column', alignItems:'center'}}>
                                            <div style={{display:'flex', width:'100%'}}>
                                            <label style={{color:colorPalette.steelBlue, marginTop: '10px', marginLeft:'1em', fontSize:'1.5em', fontWeight:'bold', fontStyle:'italic'}}>{`WEEK ${getWeekNumber(next7Days[0])+1} BREAKDOWN`}</label>
                                            </div>
                                            {/* <div style={{backgroundColor:`white`, width:'95%',margin:'8px 0px', borderRadius:'5px', flexDirection:'row', display:'flex'}}>
                                                <div style={{display:'flex',  width:'100%', flex:2}}>
                                                    <label style={{fontSize:'1em', color:`#454545`, backgroundColor:'#d1d1d1',textAlign:'left', padding:'5px 15px', fontWeight:'bold'}}>{`Average Session Duration`}</label>
                                                </div>
                                                <div style={{display:'flex',  width:'100%', flex:1, justifyContent:'flex-end'}}>
                                                    <label
                                                        style={{fontSize:'1em', color:`#454545`, textAlign:'right', marginRight:'6px', padding:'5px 5px', border:'none', outline:'none', marginTop:0}}
                                                        className="card-title">{`${getAverageSessionDuration(next7Days)} Minutes`}</label>
                                                </div>
                                            </div>
                                            <div style={{backgroundColor:`white`, width:'95%',margin:'8px 0px', borderRadius:'5px', flexDirection:'row', display:'flex'}}>
                                                <div style={{display:'flex',  width:'100%', flex:2}}>
                                                    <label style={{fontSize:'1em', color:`#454545`, backgroundColor:'#d1d1d1',textAlign:'left', padding:'5px 15px', fontWeight:'bold'}}>{`Average Session Effort`}</label>
                                                </div>
                                                <div style={{display:'flex',  width:'100%', flex:1, justifyContent:'flex-end'}}>
                                                    <label
                                                        style={{fontSize:'1em', color:`#454545`, textAlign:'right', marginRight:'6px', padding:'5px 5px', border:'none', outline:'none', marginTop:0}}
                                                        className="card-title">{`${getAverageSessionRPE(next7Days)}/10`}</label>
                                                </div>
                                            </div> */}
                                            <div style={{backgroundColor:`white`, width:'95%',margin:'8px 0px', borderRadius:'5px', flexDirection:'row', display:'flex'}}>
                                                <div style={{display:'flex',  width:'100%', flex:2}}>
                                                    <label style={{fontSize:'1em', color:`#454545`, backgroundColor:'#d1d1d1',textAlign:'left', padding:'5px 15px', fontWeight:'bold'}}>{`Cardio Sessions`}</label>
                                                </div>
                                                <div style={{display:'flex',  width:'100%', flex:1, justifyContent:'flex-end'}}>
                                                    <label
                                                        style={{fontSize:'1em', color:`#454545`, textAlign:'right', marginRight:'6px', padding:'5px 5px', border:'none', outline:'none', marginTop:0}}
                                                        className="card-title">{getNumberOfSessions(VitovaStepType.CARDIO, next7Days)}</label>
                                                </div>
                                            </div>
                                            <div style={{backgroundColor:`white`, width:'95%',margin:'8px 0px', borderRadius:'5px', flexDirection:'row', display:'flex'}}>
                                                <div style={{display:'flex',  width:'100%', flex:2}}>
                                                    <label style={{fontSize:'1em', color:`#454545`, backgroundColor:'#d1d1d1',textAlign:'left', padding:'5px 15px', fontWeight:'bold'}}>{`Strength/Conditioning Sessions`}</label>
                                                </div>
                                                <div style={{display:'flex',  width:'100%', flex:1, justifyContent:'flex-end'}}>
                                                    <label
                                                        style={{fontSize:'1em', color:`#454545`, textAlign:'right', marginRight:'6px', padding:'5px 5px', border:'none', outline:'none', marginTop:0}}
                                                        className="card-title">{getNumberOfSessions(VitovaStepType.STRENGTH, next7Days)}</label>
                                                </div>
                                            </div>
                                            <div style={{backgroundColor:`white`, width:'95%',margin:'8px 0px', borderRadius:'5px', flexDirection:'row', display:'flex'}}>
                                                <div style={{display:'flex',  width:'100%', flex:2}}>
                                                    <label style={{fontSize:'1em', color:`#454545`, backgroundColor:'#d1d1d1',textAlign:'left', padding:'5px 15px', fontWeight:'bold'}}>{`Completed Duration`}</label>
                                                </div>
                                                <div style={{display:'flex',  width:'100%', flex:1, justifyContent:'flex-end'}}>
                                                    <label
                                                        style={{fontSize:'1em', color:`#454545`, textAlign:'right', marginRight:'6px', padding:'5px 5px', border:'none', outline:'none', marginTop:0}}
                                                        className="card-title">{`${getCompletedSessionDuration(next7Days)} Minutes`}</label>
                                                </div>
                                            </div>
                                            <div style={{backgroundColor:`white`, width:'95%',margin:'8px 0px', borderRadius:'5px', flexDirection:'row', display:'flex'}}>
                                                <div style={{display:'flex',  width:'100%', flex:2}}>
                                                    <label style={{fontSize:'1em', color:`#454545`, backgroundColor:'#d1d1d1',textAlign:'left', padding:'5px 15px', fontWeight:'bold'}}>{`Total Duration`}</label>
                                                </div>
                                                <div style={{display:'flex',  width:'100%', flex:1, justifyContent:'flex-end'}}>
                                                    <label
                                                        style={{fontSize:'1em', color:`#454545`, textAlign:'right', marginRight:'6px', padding:'5px 5px', border:'none', outline:'none', marginTop:0}}
                                                        className="card-title">{`${getTotalSessionDuration(next7Days)} Minutes`}</label>
                                                </div>
                                            </div>
                                            <div style={{backgroundColor:`white`, width:'95%',margin:'8px 0px', borderRadius:'5px', flexDirection:'row', display:'flex'}}>
                                                <div style={{display:'flex',  width:'100%', flex:2}}>
                                                    <label style={{fontSize:'1em', color:`#454545`, backgroundColor:'#d1d1d1',textAlign:'left', padding:'5px 15px', fontWeight:'bold'}}>{`Completed ISS`}</label>
                                                </div>
                                                <div style={{display:'flex',  width:'100%', flex:1, justifyContent:'flex-end'}}>
                                                    <label
                                                        style={{fontSize:'1em', color:`#454545`, textAlign:'right', marginRight:'6px', padding:'5px 5px', border:'none', outline:'none', marginTop:0}}
                                                        className="card-title">{`${getCompletedSessionISS(next7Days)} ISS`}</label>
                                                </div>
                                            </div>
                                            <div style={{backgroundColor:`white`, width:'95%',margin:'8px 0px', borderRadius:'5px', flexDirection:'row', display:'flex'}}>
                                                <div style={{display:'flex',  width:'100%', flex:2}}>
                                                    <label style={{fontSize:'1em', color:`#454545`, backgroundColor:'#d1d1d1',textAlign:'left', padding:'5px 15px', fontWeight:'bold'}}>{`Total ISS`}</label>
                                                </div>
                                                <div style={{display:'flex',  width:'100%', flex:1, justifyContent:'flex-end'}}>
                                                    <label
                                                        style={{fontSize:'1em', color:`#454545`, textAlign:'right', marginRight:'6px', padding:'5px 5px', border:'none', outline:'none', marginTop:0}}
                                                        className="card-title">{`${getTotalSessionISS(next7Days)} ISS`}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                            </div>

                        )}
                    </div>
                    {/* RIGHT PANEL */}
                    <div style={{overflowY:'scroll', overflowX:'hidden'}} className={`calendar-container ${showRightPanel ? 'show' : ''}`}>
                        {selectedTab === 'endreport' &&
                            // <div style={{width:'100%', maxWidth: '100%', fontFamily: 'Arial, sans-serif'}}>
                            //     <h2 style={{ textAlign: 'center', marginBottom: '2vh', fontWeight:'bold', fontStyle:'italic'}}>END OF PLAN REPORT</h2>
                            //     <button style={{backgroundColor:colorPalette.steelBlue}} onClick={() => generateOutcomeLetter(currentUser)}>
                            //         Download Document
                            //     </button>
                            // </div>
                            <EndOfPlanReport currentUser={currentUser}/>
                        }
                        {selectedTab === 'tests' &&
                            <div style={{width:'100%', maxWidth: '100%', fontFamily: 'Arial, sans-serif'}}>
                                {/* <h2 style={{ textAlign: 'center', marginBottom: '2vh', fontWeight:'bold', fontStyle:'italic'}}>SMOKING</h2> */}
                                <div  style={{display:'flex', flexDirection:'row'}}>
                                    <div style={{marginBottom: '10px', display:'flex',flex:3,alignItems:'flex-start', justifyContent:'flex-start', flexDirection:'column'}}>
                                        <label style={{ textAlign: 'center', fontWeight:'bold', fontStyle:'italic'}}>{'TEST CENTRE'}</label>
                                        <label style={{ textAlign: 'center', marginBottom: '5px', fontStyle:'italic', fontSize:'1em'}}>{"It's best to work with your fitness professional in the planning stage to look at which tests are most important for you to track. But if you want to fill in more then more information is positive."}</label>
                                        <table
                                            style={{
                                                width: '100%',
                                                borderCollapse: 'collapse',
                                                textAlign: 'center',
                                                marginTop:'2vh'
                                            }}
                                            >
                                            <thead style={{marginTop:'2vh'}}>
                                                <tr style={{ borderBottom: '2px solid #ccc' }}>
                                                <th>Test</th>
                                                <th>Type</th>
                                                <th>Date</th>
                                                <th>Latest</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr style={{ borderBottom: '1px solid #ccc' }}>
                                                    <td>Weight</td>
                                                    <td>Physical</td>
                                                    <td>{currentUser.weightData.length > 0 ? `${formatDate(currentUser.weightData[currentUser.weightData.length-1].date)}` : `No Date`}</td>
                                                    <td>{currentUser.weightData.length > 0 ? `${currentUser.weightData[currentUser.weightData.length-1].value.toString()}KG` :'Not Submitted'}</td>
                                                </tr>
                                                <tr style={{ borderBottom: '1px solid #ccc' }}>
                                                    <td>Waist Circumference</td>
                                                    <td>Physical</td>
                                                    <td>{currentUser.waistData.length > 0 ? `${formatDate(currentUser.waistData[currentUser.waistData.length-1].date)}` : `No Date`}</td>
                                                    <td>{currentUser.waistData.length > 0 ? `${currentUser.waistData[currentUser.waistData.length-1].value.toString()}CM` :'Not Submitted'}</td>
                                                    <td>
                                                    </td>
                                                </tr>
                                                <tr style={{ borderBottom: '1px solid #ccc' }}>
                                                    <td>Blood Pressure</td>
                                                    <td>Physical</td>
                                                    <td>
                                                        {currentUser?.bpData?.length > 0 
                                                            ? formatDate(currentUser.bpData.at(-1)?.date ?? '') 
                                                            : 'No Date'}
                                                    </td>
                                                    <td>
                                                        {currentUser?.bpData?.length > 0 
                                                            ? currentUser.bpData.at(-1)?.value?.toString() 
                                                            : 'Not Submitted'}
                                                    </td>
                                                </tr>


                                                {currentUser.hba1cData &&
                                                    <tr style={{ borderBottom: '1px solid #ccc' }}>
                                                        <td>Hba1c Level</td>
                                                        <td>Physical</td>
                                                        <td>{currentUser.hba1cData.length > 0 ? `${formatDate(currentUser.hba1cData[currentUser.hba1cData.length-1].date)}` : `No Date`}</td>

                                                        <td>{currentUser.hba1cData.length > 0 ? `${currentUser.hba1cData[currentUser.hba1cData.length-1].value.toString()}` :'Not Submitted'}</td>
                                                    </tr>
                                                }
                                                {currentUser.hdlData &&
                                                <tr style={{ borderBottom: '1px solid #ccc' }}>
                                                    <td>HDL Level</td>
                                                    <td>Physical</td>
                                                    <td>{currentUser.hdlData.length > 0 ? `${formatDate(currentUser.hdlData[currentUser.hdlData.length-1].date)}` : `No Date`}</td>

                                                    <td>{currentUser.hdlData.length > 0 ? `${currentUser.hdlData[currentUser.hdlData.length-1].value.toString()}` :'Not Submitted'}</td>
                                                </tr>
                                                }
                                                {currentUser.ldlData &&
                                                <tr style={{ borderBottom: '1px solid #ccc' }}>
                                                    <td>LDL Level</td>
                                                    <td>Physical</td>
                                                    <td>{currentUser.ldlData.length > 0 ? `${formatDate(currentUser.ldlData[currentUser.ldlData.length-1].date)}` : `No Date`}</td>

                                                    <td>{currentUser.ldlData.length > 0 ? `${currentUser.ldlData[currentUser.ldlData.length-1].value.toString()}` :'Not Submitted'}</td>
                                                </tr>
                                                }
                                                {currentUser.cholesterolData &&
                                                <tr style={{ borderBottom: '1px solid #ccc' }}>
                                                    <td>Cholesterol</td>
                                                    <td>Physical</td>
                                                    <td>{currentUser.cholesterolData.length > 0 ? `${formatDate(currentUser.cholesterolData[currentUser.cholesterolData.length-1].date)}` : `No Date`}</td>

                                                    <td>{currentUser.cholesterolData.length > 0 ? `${currentUser.cholesterolData[currentUser.cholesterolData.length-1].value.toString()}` :'Not Submitted'}</td>
                                                </tr>
                                                }
                                                {Object.values(
                                                currentUser.tests.reduce((acc: Record<string, UserTest>, test) => {
                                                    // If the testName group doesn't exist or the current test has a more recent dateTaken
                                                    if (!acc[test.test.testName] || new Date(test.dateTaken) > new Date(acc[test.test.testName].dateTaken)) {
                                                    acc[test.test.testName] = test;
                                                    }
                                                    return acc;
                                                }, {})
                                                ).map((t, index) => (
                                                t.result && (
                                                    <tr key={index} style={{ borderBottom: '1px solid #ccc' }}>
                                                    <td className="labelLink" onClick={() => setOpenTestDialog(t.test)}>
                                                        {t.test.testName}
                                                    </td>
                                                    <td>Mental Health</td>
                                                    <td>{formatDate(t.dateTaken)}</td>
                                                    <td>{`${t.result.reduce((partialSum, a) => partialSum + a, 0)} / ${t.result.length * 3}`}</td>
                                                    <td></td>
                                                    </tr>
                                                )
                                                ))}
                                                <Dialog 
                                                open={openTestDialog!== undefined} 
                                                onClose={()=>setOpenTestDialog(undefined)} 
                                                maxWidth="lg" 
                                                fullWidth
                                                PaperProps={{
                                                    style: { 
                                                        borderRadius: '12px',
                                                        maxHeight: '80vh'
                                                    }
                                                }}
                                            >
                                                <DialogTitle 
                                                    style={{ 
                                                        fontSize: '2em', 
                                                        textAlign: 'center', 
                                                        color: '#454545', 
                                                        marginTop: '10px' 
                                                    }}
                                                >
                                                    {openTestDialog?.testName}
                                                </DialogTitle>

                                                <DialogContent dividers style={{ overflowY: 'auto', maxHeight: '60vh' }}>
                                                    <p style={{ textAlign: 'center', color: '#454545', marginBottom: '20px' }}>
                                                        {openTestDialog?.description}
                                                    </p>

                                                    <div style={{ paddingLeft: '30px', paddingRight: '30px', display: 'flex', flexDirection: 'column' }}>
                                                        {openTestDialog?.questions.map((question: any, index: number) => (
                                                            <div key={index} style={{ marginBottom: '40px', width: '100%' }}>
                                                                <label className="question-label" style={{ color: '#454545', marginBottom: '10px' }}>
                                                                    {`Q${index + 1}. ${question.question}`}
                                                                </label>
                                                                <Slider
                                                                    defaultValue={0}
                                                                    value={openTestResult[index]}
                                                                    onChange={(e, value) => handlePHQ9SliderChange(index, value as number)}
                                                                    step={1}
                                                                    min={0}
                                                                    max={3}
                                                                    marks={[
                                                                        { value: 0, label: "Not at all" },
                                                                        { value: 1, label: "Several days" },
                                                                        { value: 2, label: "More than half the days" },
                                                                        { value: 3, label: "Nearly every day" }
                                                                    ]}
                                                                    valueLabelDisplay="off"
                                                                    sx={{
                                                                        '& .MuiSlider-markLabel': {
                                                                            marginTop: '20px',
                                                                            fontSize: '0.85em',
                                                                            color: '#454545'
                                                                        },
                                                                    }}
                                                                />
                                                            </div>
                                                        ))}
                                                    </div>
                                                </DialogContent>

                                                <DialogActions style={{ justifyContent: 'space-between', padding: '16px' }}>
                                                    <Button onClick={()=>setOpenTestDialog(undefined)} color="primary" variant="outlined">
                                                        Close Without Saving
                                                    </Button>
                                                    <Button onClick={()=>hanldeTestResultSubmit()} color="primary" variant="contained">
                                                        Finish And Submit
                                                    </Button>
                                                </DialogActions>
                                            </Dialog>
                                            </tbody>
                                            </table>
                                            <div style={{width:'100%', display:'flex', justifyContent:'flex-end', alignItems:'flex-end', marginTop:'15px'}}>
                                                    <button
                                                        style={{
                                                            alignSelf:'flex-end',
                                                            padding: '5px 10px',
                                                            cursor: 'pointer',
                                                            backgroundColor: colorPalette.steelBlue,
                                                            color: '#fff',
                                                            // width: '100%',
                                                            border: 'none',
                                                            borderRadius: '5px',
                                                            transition: 'background-color 0.3s ease',
                                                            fontWeight: 'bold',
                                                        }}
                                                        onMouseOver={(e) => (e.currentTarget.style.backgroundColor = 'steelblue')}
                                                        onMouseOut={(e) => (e.currentTarget.style.backgroundColor = colorPalette.steelBlue)}
                                                        onClick={() => setIsModalOpen(true)}
                                                    >
                                                        UPDATE PHYSICAL
                                                    </button>

                                                        {isModalOpen && (
                                                            <Dialog open={isModalOpen} fullWidth maxWidth={'md'} onClose={() => setIsModalOpen(false)}>
                                                            <DialogTitle style={{fontStyle:'italic', fontWeight:'bold', color:colorPalette.steelBlue}}>UPDATE YOUR PHYSCIAL MEASUREMENTS BELOW</DialogTitle>
                                                            <DialogContent>
                                                                <InputLabel style={{marginLeft:'5px', fontSize:'0.8em', marginTop:'1em'}}>WEIGHT (KG)</InputLabel>
                                                                <TextField 
                                                                    type="number" 
                                                                    value={weight} 
                                                                    onChange={(e) => setWeight(e.currentTarget.value)} 
                                                                    fullWidth 
                                                                    placeholder="Enter your latest weight..."
                                                                    margin="dense"
                                                                />
                                                                <InputLabel style={{marginLeft:'5px', fontSize:'0.8em', marginTop:'1em'}}>WAIST (CM)</InputLabel>
                                                                <TextField 
                                                                    type="number" 
                                                                    value={waist} 
                                                                    onChange={(e) => setWaistCircumference(e.currentTarget.value)} 
                                                                    fullWidth 
                                                                    placeholder="Enter your latest waist circumference..."
                                                                    margin="dense"
                                                                />
                                                                <InputLabel style={{marginLeft:'5px', fontSize:'0.8em', marginTop:'1em'}}>BLOOD PRESSURE (MM/HG)</InputLabel>
                                                                <TextField 
                                                                    type="text" 
                                                                    value={bp}
                                                                    onChange={(e) => setBp(e.currentTarget.value)} 
                                                                    fullWidth 
                                                                    placeholder="Enter your latest blood pressure level..."
                                                                    margin="dense"
                                                                />
                                                                <InputLabel style={{marginLeft:'5px', fontSize:'0.8em', marginTop:'1em'}}>HBA1C LEVEL (MMOLS/MOL)</InputLabel>
                                                                <TextField 
                                                                    type="number" 
                                                                    value={hba1c} 
                                                                    onChange={(e) => setHba1c(e.currentTarget.value)} 
                                                                    fullWidth 
                                                                    placeholder="Enter your latest hba1c level..."
                                                                    margin="dense"
                                                                />
                                                                <InputLabel style={{marginLeft:'5px', fontSize:'0.8em', marginTop:'1em'}}>HDL LEVEL (MMOL/L)</InputLabel>
                                                                <TextField 
                                                                    type="number" 
                                                                    value={hdl} 
                                                                    onChange={(e) => setHdl(e.currentTarget.value)} 
                                                                    fullWidth 
                                                                    placeholder="Enter your latest hdl level..."
                                                                    margin="dense"
                                                                />
                                                                <InputLabel style={{marginLeft:'5px', fontSize:'0.8em', marginTop:'1em'}}>LDL LEVEL (MMOL/L)</InputLabel>
                                                                <TextField 
                                                                    type="number" 
                                                                    value={ldl} 
                                                                    onChange={(e) => setLdl(e.currentTarget.value)} 
                                                                    fullWidth 
                                                                    placeholder="Enter your latest ldl level..."
                                                                    margin="dense"
                                                                />
                                                                <InputLabel style={{marginLeft:'5px', fontSize:'0.8em', marginTop:'1em'}}>CHOLESTEROL (MMOL/L)</InputLabel>
                                                                <TextField 
                                                                    type="number" 
                                                                    value={cholesterol} 
                                                                    onChange={(e) => setCholesterol(e.currentTarget.value)} 
                                                                    fullWidth 
                                                                    placeholder="Enter your latest cholesterol level..."
                                                                    margin="dense"
                                                                />

                                                            </DialogContent>
                                                            <DialogActions>
                                                                <Button onClick={handleSubmitModalValue} variant="contained" color="primary">Submit</Button>
                                                                <Button onClick={() => setIsModalOpen(false)} variant="outlined">Cancel</Button>
                                                            </DialogActions>
                                                        </Dialog>
                                                        )}
                                                    </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {selectedTab === 'checkin' &&
                            <Box sx={{ width: '100%', maxWidth: '100%', fontFamily: 'Arial, sans-serif' }}>
                                <Typography variant="h4" align="center" sx={{ fontWeight: 'bold', fontStyle: 'italic', marginBottom: '2vh' }}>
                                    SEND A CHECK-IN MESSAGE
                                </Typography>
                                <Box sx={{ marginBottom: '10px' }}>
                                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold', fontStyle: 'italic', marginBottom: '5px' }}>SUBJECT</Typography>
                                    <TextField 
                                        fullWidth
                                        id="subject"
                                        placeholder="Enter subject here..."
                                        value={subject}
                                        onChange={(e) => setSubject(e.target.value)}
                                        variant="outlined"
                                    />
                                </Box>
                                <Box sx={{ marginBottom: '10px', marginTop: '20px' }}>
                                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold', fontStyle: 'italic', marginBottom: '5px' }}>MESSAGE</Typography>
                                    <TextField 
                                        fullWidth
                                        id="message"
                                        placeholder="Write your message here..."
                                        value={message}
                                        onChange={(e) => setMessage(e.target.value)}
                                        variant="outlined"
                                        multiline
                                        minRows={4}
                                    />
                                </Box>
                                <Button 
                                    fullWidth
                                    variant="contained"
                                    sx={{ backgroundColor: colorPalette.steelBlue, color: '#fff' }}
                                    onClick={handleSend}
                                    disabled={sendingMessage}
                                >
                                    {sendingMessage ? `Sending...` : `Send Message`}
                                </Button>
                                <Typography variant="subtitle2" sx={{ fontWeight: 'bold', fontStyle: 'italic', marginTop: '20px' }}>
                                    {currentUser.inbox.length > 0 ? `SENT MESSAGES` : `SENT MESSAGES WILL APPEAR HERE`}
                                </Typography>
                                {currentUser.inbox
                                    .sort((m, n) => new Date(n.dateSent).getTime() - new Date(m.dateSent).getTime())
                                    .filter(m => m.senderId === currentUser._id)
                                    .map((message, index) => (
                                        <Paper key={index} sx={{ padding: '10px', marginTop: '10px', position: 'relative', border: '1px solid gray', justifyContent:'flex-start', display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
                                            <Typography variant="caption">{message.dateSent.toString()}</Typography>
                                            <Typography variant="caption" sx={{ position: 'absolute', right: '5px' }}>
                                                {message.acknowledged ? 'Acknowledged By Your GP' : 'Waiting For GP Acknowledgement'}
                                            </Typography>
                                            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>{message.subject}</Typography>
                                            <Typography variant="body2">{message.content}</Typography>
                                        </Paper>
                                    ))}
                            </Box>
                        }                  
                        {selectedTab === 'smoking' &&
                            <div style={{width:'100%',maxWidth: '100%', fontFamily: 'Arial, sans-serif'}}>
                                {/* <h2 style={{ textAlign: 'center', marginBottom: '2vh', fontWeight:'bold', fontStyle:'italic'}}>SMOKING</h2> */}
                                <div  style={{display:'flex', flexDirection:'row'}}>
                                <div style={{marginBottom: '10px', display:'flex',flex:3,alignItems:'flex-start', justifyContent:'flex-start', flexDirection:'column'}}>                                    
                                    <label style={{ textAlign: 'center', fontWeight:'bold', fontStyle:'italic'}}>{currentUser.smoker ? 'SMOKING TRACKER' : 'GREAT WORK'}</label>
                                    <label style={{ textAlign: 'center', marginBottom: '5px', fontStyle:'italic', fontSize:'1em'}}>{currentUser.smoker ? 'Track Your Smoking Habits Through Our Check-In System' : 'Nothing To See Here, Continue To Avoid Smoking'}</label>
                                    {currentUser.smoker &&
                                        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>      
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: '10px',}}>
                                            {!hasSubmittedSmokingDataToday() &&
                                                <label 
                                                    style={{ 
                                                    fontStyle: 'italic', 
                                                    fontWeight: 'bold', 
                                                    color: colorPalette.steelBlue, 
                                                    fontSize: '1em' 
                                                    }}
                                                >
                                                    HOW MANY CIGARETTES DID YOU SMOKE TODAY?
                                                </label>
                                            }
                                            {!hasSubmittedSmokingDataToday() &&
                                            <input 
                                                type="number" 
                                                disabled={hasSubmittedSmokingDataToday()}
                                                min={0}
                                                style={{ 
                                                fontStyle: 'italic', 
                                                fontWeight: 'bold', 
                                                color: colorPalette.steelBlue, 
                                                fontSize: '1em', 
                                                width: '5em', 
                                                borderRadius: '5px', 
                                                border: 'none',
                                                textAlign:'center',
                                                outline:'none', 
                                                height: '40px', // Consistent height
                                                }}
                                                value={cigaretteInput}
                                                onChange={(e) => setCigaretteInput(Number(e.currentTarget.value))}
                                            />
                                            }
                                            <button 
                                                // type="submit" 
                                                style={{ 
                                                borderRadius: '5px', 
                                                height: '40px', // Same height as input
                                                padding: '0 10px', // Adjust padding for consistent size
                                                fontSize: '1em',
                                                margin:0,
                                                width:'auto',
                                                fontStyle:'italic',
                                                fontWeight:'bold',
                                                backgroundColor:colorPalette.steelBlue
                                                }} 
                                                className="submit-button"
                                                onClick={() => submitSmokingValue()}
                                                disabled={hasSubmittedSmokingDataToday()}
                                                title={
                                                    hasSubmittedSmokingDataToday()
                                                    ? "You have already submitted smoking data for today."
                                                    : ""
                                                }
                                            >
                                                {hasSubmittedSmokingDataToday() ? 'SUBMITTED FOR TODAY' : 'SUBMIT FOR TODAY'}
                                            </button>
                                            </div>
                                            <div style={{height:'1px', backgroundColor:'gray',opacity:'0.4', width:'97%', marginTop:'10px', marginBottom:'10px'}}></div>

                                            <div style={{ height: '100%', display: 'flex', marginTop: '1vh' }}>
                                                {currentUser.smokingData && 
                                            <div style={{ width: '97%', height: '50vh', backgroundColor: 'transparent', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent:'flex-start'}}>
                                                {/* Header with days of the week */}
                                                <div style={{ display: 'flex', justifyContent: 'center', gap: '20px', marginBottom: '10px' }}>
                                                {currentUser.smokingData.slice(-7)
                                                    .sort((a, b) => {
                                                        const dateA = new Date(a.date);
                                                        const dateB = new Date(b.date);

                                                        // Ensure both dates are valid
                                                        if (isNaN(dateA.getTime()) || isNaN(dateB.getTime())) {
                                                        return 0; // In case the date is invalid, you can adjust how you handle the sorting
                                                        }

                                                        return dateA.getTime() - dateB.getTime();  // Use getTime() to compare timestamps
                                                    })
                                                    .map((o: DataObject, index) => {
                                                        return (
                                                            <span key={index} style={{ fontSize: '16px', fontWeight: 'bold', textAlign: 'center', minWidth: '40px', maxWidth:'40px',marginLeft:index===0?'165px':'0'}}>
                                                                {`${formatAsShortDayName(o.date)} ${formatDateWithOrdinal(o.date)}`}
                                                            </span>
                                                        );
                                                    })}
                                                </div>

                                                {/* Row for title and circles */}
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    {/* Title for units drunk */}
                                                    <span style={{ fontSize: '16px', fontWeight: 'bold', marginRight: '20px' }}>Cigarettes Smoked</span>

                                                    {/* Circles representing number of drinks */}
                                                    <div style={{ display: 'flex', gap: '20px' }}>
                                                    {currentUser.smokingData.slice(-7)
                                                        .sort((a, b) => {
                                                            const dateA = new Date(a.date);
                                                            const dateB = new Date(b.date);

                                                            // Ensure both dates are valid
                                                            if (isNaN(dateA.getTime()) || isNaN(dateB.getTime())) {
                                                            return 0; // In case the date is invalid, you can adjust how you handle the sorting
                                                            }

                                                            return dateA.getTime() - dateB.getTime();  // Use getTime() to compare timestamps
                                                        })
                                                        .map((o: DataObject, index) => {
                                                        let backgroundColor = getSmokingScoreColor(currentUser, Number(o.value)) // Default value

                                                        return (
                                                            <div
                                                            key={index}
                                                            onClick={() => {
                                                                if (Number(o.value) < 0) {
                                                                const value = prompt('Enter your retrospective value:');
                                                                if (value !== null) {
                                                                    submitRetrospectiveSmokingValue(value, o.date);
                                                                }
                                                                }
                                                            }}
                                                            style={{
                                                                width: '40px',
                                                                height: '40px',
                                                                borderRadius: '50%',
                                                                backgroundColor: backgroundColor,
                                                                display: 'flex',
                                                                opacity: '0.7',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                cursor: Number(o.value) < 0 ? 'pointer' : 'default',
                                                            }}
                                                            >
                                                            </div>
                                                        );
                                                    })}

                                                    </div>

                                                </div>
                                                <Line 
                                                    data={{
                                                        labels: currentUser.smokingData.map((o: DataObject) =>
                                                            new Intl.DateTimeFormat("en-GB", {
                                                                day: "2-digit",
                                                                month: "2-digit",
                                                                year: "2-digit",
                                                            }).format(new Date(o.date))
                                                        ),
                                                        datasets: [
                                                            {
                                                                // label: "Your Data",
                                                                data: currentUser.smokingData.map((o: DataObject) => 
                                                                    o.value >= 0 ? o.value : null // Plot only values >= 0
                                                                ),
                                                                borderColor: "steelblue", // Blue line for user's data
                                                                borderWidth: 2,
                                                                fill: false,
                                                                borderDash: [], // Makes the line dashed (5px dash and 5px gap)
                                                                spanGaps: true, // Skip gaps in the user's data
                                                            },

                                                        ],
                                                    }}
                                                    options={{
                                                        animation: {
                                                            duration: 200,
                                                        },
                                                        scales: {
                                                            y: {
                                                                type: 'linear',
                                                                beginAtZero: true,
                                                                ticks: {
                                                                    display: true,
                                                                },
                                                                grid: {
                                                                    display: false,
                                                                },
                                                            },
                                                            x: {
                                                                ticks: {
                                                                    display: true,
                                                                },
                                                                grid: {
                                                                    display: false,
                                                                },
                                                            },
                                                        },
                                                        maintainAspectRatio: false,
                                                        responsive: true,
                                                        plugins: {
                                                            legend: {
                                                                display: false, // Display legend to differentiate the two lines
                                                            },
                                                        },
                                                        layout: {
                                                            padding: {
                                                                top: 10,
                                                                bottom: 10,
                                                                left: 10,
                                                                right: 10,
                                                            },
                                                        },
                                                    }}
                                                    style={{ marginTop: '10px' }}
                                                />
                                                </div>

                                                }
                                            </div>

                                        </div>
                                    }

                                </div>
                                <div style={{display:'flex', flex:1, borderLeftWidth:'1px',borderLeft:'1px solid #a3a3a388', paddingLeft:'15px',height:'70vh', flexDirection:'column',alignItems:'flex-start', justifyContent:'flex-start'}}>
                                <label style={{ textAlign: 'left',  fontWeight:'bold', fontStyle:'italic', marginTop:'10px'}}>PREFERENCES</label>
                                    <div style={{alignItems: 'center'}}>
                                        <label>I Don't Smoke</label>
                                        <Checkbox
                                        disabled={loadingSmokerChange}
                                        checked={!currentUser.smoker}
                                        onChange={()=>handleSmokerChange()}
                                        style={{color:'#454545', padding:0, marginLeft:'5px'}}/>

                                    </div>
                                    <label style={{ textAlign: 'left', fontWeight:'bold', fontStyle:'italic', marginTop:'10px'}}>NHS RESOURCE LINKS</label>
                                    <a target='_blank' rel="noreferrer" style={{textDecoration:'underline', textAlign:'left',color:'steelblue',fontStyle:'italic'}} href="https://www.nhs.uk/better-health/quit-smoking/find-your-local-stop-smoking-service/">Find Your Local Smoking Service</a>
                                    <a target='_blank' rel="noreferrer" style={{textDecoration:'underline', textAlign:'left', color:'steelblue',fontStyle:'italic'}} href="https://www.nhs.uk/better-health/quit-smoking/">Quit Smoking</a>
                                    <a target='_blank' rel="noreferrer" style={{textDecoration:'underline', textAlign:'left', color:'steelblue',fontStyle:'italic'}} href="https://www.england.nhs.uk/2024/11/nhs-rolls-out-stop-smoking-pill-to-help-tens-of-thousands-quit/">Stop Smoking Pill</a>
                                    <a target='_blank' rel="noreferrer" style={{textDecoration:'underline', textAlign:'left', color:'steelblue',fontStyle:'italic'}} href="https://111.wales.nhs.uk/lifestylewellbeing/smoking/">Wellbeing Impact</a>
                                    <a target='_blank' rel="noreferrer" style={{textDecoration:'underline', textAlign:'left', color:'steelblue',fontStyle:'italic'}} href="https://www.nhsinform.scot/healthy-living/stopping-smoking/reasons-to-stop/benefits-of-stopping-smoking/">Reasons To Stop</a>
                                    <label style={{ textAlign: 'left',  fontWeight:'bold', fontStyle:'italic', marginTop:'10px'}}>NHS INFORMATION LINKS</label>
                                    <a target='_blank' rel="noreferrer" style={{textDecoration:'underline', textAlign:'left', color:'steelblue',fontStyle:'italic'}} href="https://www.livelifebetterderbyshire.org.uk/latest-news/quitting-vs-cutting-down.aspx ">Quitting VS Cutting</a>
                                    <a target='_blank' rel="noreferrer" style={{textDecoration:'underline', textAlign:'left', color:'steelblue',fontStyle:'italic'}} href="https://www.hdft.nhs.uk/wp-content/uploads/2022/06/47189-NHS-My-Quitting-2pp-A5_v2.pdf">What Happens When You Stop Smoking?</a>
                                    <a target='_blank' rel="noreferrer" style={{textDecoration:'underline', textAlign:'left', color:'steelblue',fontStyle:'italic'}} href="https://www.nhs.uk/better-health/quit-smoking/ready-to-quit-smoking/find-the-best-stop-smoking-products-for-you/">Stop Smoking Products</a>
                                    <a target='_blank' rel="noreferrer" style={{textDecoration:'underline', textAlign:'left', color:'steelblue',fontStyle:'italic'}} href="https://www.nhsinform.scot/healthy-living/stopping-smoking/reasons-to-stop/benefits-of-stopping-smoking/">Benefits Of <b>Stopping</b> Smoking</a>
                                    <a target='_blank' rel="noreferrer" style={{textDecoration:'underline', textAlign:'left', color:'steelblue',fontStyle:'italic'}} href="https://www.nhs.uk/better-health/quit-smoking/why-quit-smoking/benefits-of-quitting-smoking/">Benefits Of <b>Quitting</b> Smoking</a>   
                                </div>
                                </div>
                            </div>
                        }
                        {selectedTab === 'alcohol' &&
                            <div style={{width:'100%', maxWidth: '100%', fontFamily: 'Arial, sans-serif'}}>
                            {/* <h2 style={{ textAlign: 'center', marginBottom: '2vh', fontWeight:'bold', fontStyle:'italic'}}>SMOKING</h2> */}
                            <div  style={{display:'flex', flexDirection:'row'}}>
                            <div style={{marginBottom: '10px', display:'flex',flex:3,alignItems:'flex-start', justifyContent:'flex-start', flexDirection:'column'}}>                                
                                <label style={{ textAlign: 'center', fontWeight:'bold', fontStyle:'italic'}}>{currentUser.drinker ? 'ALCOHOL TRACKER' : 'GREAT WORK'}</label>
                                <label style={{ textAlign: 'center', marginBottom: '5px', fontStyle:'italic', fontSize:'1em'}}>{currentUser.drinker ? 'Track Your Alcohol Consumption Habits Through Our Check-In System' : 'Nothing To See Here, Continue To Limit Your Alcohol Consumption'}</label>
                                {currentUser.drinker &&
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>      
                                        <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', gap: '10px',flexDirection:'column'}}>
                                        {!hasSubmittedDrinkingDataToday() &&
                                            <label 
                                                style={{ 
                                                fontStyle: 'italic', 
                                                fontWeight: 'bold', 
                                                color: colorPalette.steelBlue, 
                                                fontSize: '1em' 
                                                }}
                                            >
                                                HOW MANY UNITS DID YOU DRINK TODAY?
                                            </label>
                                        }
                                        {!hasSubmittedDrinkingDataToday() &&
                                            <div style={{ display: 'flex', flexDirection: 'column', alignItems:'flex-start',gap: '10px', width: '97%',}}>
                                            <div style={{display:'flex', flexDirection:'row'}}>
                                            <Select 
                                            value={selectedDrink.name} 
                                            onChange={(e) => {
                                                const drink = drinksData.find(d => d.name === e.target.value) || drinksData[0];
                                                setSelectedDrink(drink);
                                            }}
                                            >
                                            {drinksData.map((drink, index) => (
                                                <MenuItem key={index} value={drink.name}>{drink.name}</MenuItem>
                                            ))}
                                            </Select>

                                            {/* <label style={{ fontWeight: 'bold', color: 'steelblue' }}>Quantity:</label> */}
                                            <TextField
                                            style={{marginLeft:'10px'}}
                                            type='number'
                                            inputProps={{ min: 1 }}
                                            value={quantity}
                                            onChange={(e) => setQuantity(Number(e.target.value))}
                                            />

                                            <button className="review-button" onClick={addDrink} style={{marginLeft:'10px', marginTop:0, marginBottom:0, fontSize:'1em'}}>
                                            ADD DRINK
                                            </button>
                                            </div>
                                            <List>
                                            {drinksList.map((drink, index) => (
                                                <ListItem style={{border:'1px solid steelblue', backgroundColor:'white', width:'40em',marginBottom:'5px'}} key={index} secondaryAction={
                                                <IconButton edge="end" onClick={() => removeDrink(index)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                                }>
                                                <ListItemText primary={`${drink.quantity} × ${drink.name} (${(drink.units * drink.quantity).toFixed(1)} units)`} />
                                                </ListItem>
                                            ))}
                                            </List>

                                            <div style={{ fontWeight: 'bold', fontStyle:'italic'}}>
                                            TOTAL UNITS: {totalUnits.toFixed(1)}
                                            </div>

                                            {/* <Button 
                                            onClick={() => submitAlcoholValue()}
                                            variant="contained" 
                                            color="success"
                                            disabled={totalUnits === 0}
                                            >
                                            Submit
                                            </Button> */}
                                        </div>
                                        }
                                        <button 
                                            // type="submit" 
                                            style={{ 
                                            borderRadius: '5px', 
                                            height: '40px', // Same height as input
                                            padding: '0 10px', // Adjust padding for consistent size
                                            fontSize: '1em',
                                            margin:0,
                                            width:'auto',
                                            fontStyle:'italic',
                                            fontWeight:'bold',
                                            backgroundColor:colorPalette.steelBlue
                                            }} 
                                            className="submit-button"
                                            onClick={() => submitAlcoholValue()}
                                            disabled={hasSubmittedDrinkingDataToday()}
                                            title={
                                                hasSubmittedDrinkingDataToday()
                                                ? "You have already submitted alcohol data for today."
                                                : ""
                                            }
                                        >
                                            {hasSubmittedDrinkingDataToday() ? 'SUBMITTED FOR TODAY' : 'SUBMIT FOR TODAY'}
                                        </button>
                                        </div>
                                        <div style={{height:'1px', backgroundColor:'gray',opacity:'0.4', width:'97%', marginTop:'10px', marginBottom:'10px'}}></div>

                                        <div style={{ height: '100%', display: 'flex', marginTop: '2vh' }}>
                                            {currentUser.drinkingData &&                                             
                                            <div style={{ width: '97%', height: '50vh', backgroundColor: 'transparent', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent:'flex-start'}}>
                                                {/* PICTOGRAM */}
                                                <div style={{ display: 'flex', justifyContent: 'center', gap: '20px', marginBottom: '10px' }}>
                                                    {currentUser.drinkingData.slice(-7).map((o: DataObject, index) => {
                                                        return (
                                                            <span key={index} style={{ fontSize: '16px', fontWeight: 'bold', textAlign: 'center', minWidth: '40px', maxWidth:'40px',marginLeft:index===0?'110px':'0'}}>
                                                                {`${formatAsShortDayName(o.date)} ${formatDateWithOrdinal(o.date)}`}
                                                            </span>
                                                        );
                                                    })}
                                                </div>
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    {/* Title for units drunk */}
                                                    <span style={{ fontSize: '16px', fontWeight: 'bold', marginRight: '20px' }}>Units Drunk</span>

                                                    {/* Circles representing number of drinks */}
                                                    <div style={{ display: 'flex', gap: '20px' }}>
                                                    {currentUser.drinkingData.slice(-7).map((o: DataObject, index) => {
                                                        // Assuming the original value is 5 and the value should decrease by 10% each day
                                                        const originalValue = 5; // Example starting value
                                                        const expectedValue = originalValue * (1 - 0.1 * index); // 10% reduction per day

                                                        let backgroundColor = ''; // Default value

                                                        // Determine the background color based on the value comparison
                                                        if (Number(o.value) < 0){
                                                            backgroundColor = '#34343466'
                                                        } else if (Number(o.value) < expectedValue) {
                                                            // Green: Value is lower than expected
                                                            backgroundColor = colorPalette.seaGreen;
                                                        } else if (Number(o.value) <= expectedValue * 1.05) {
                                                            // Orange: Value is between expected and 1.05 times the expected
                                                            backgroundColor = colorPalette.orange;
                                                        } else {
                                                            // Red: Value is more than 1.05 times the expected
                                                            backgroundColor = colorPalette.softRed;
                                                        }

                                                        return (
                                                            <div
                                                            key={index}
                                                            onClick={() => {
                                                                if (Number(o.value) < 0) {
                                                                const value = prompt('Enter your retrospective value:');
                                                                if (value !== null) {
                                                                    submitRetrospectiveAlcoholValue(value, o.date);
                                                                }
                                                                }
                                                            }}
                                                            style={{
                                                                width: '40px',
                                                                height: '40px',
                                                                borderRadius: '50%',
                                                                backgroundColor: backgroundColor,
                                                                display: 'flex',
                                                                opacity: '0.7',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                cursor: Number(o.value) < 0 ? 'pointer' : 'default',
                                                            }}
                                                            >
                                                            </div>
                                                        );
                                                    })}

                                                    </div>
                                                </div>

                                                <Line 
                                                    data={{
                                                        labels: currentUser.drinkingData.map((o: DataObject) =>
                                                            new Intl.DateTimeFormat("en-GB", {
                                                                day: "2-digit",
                                                                month: "2-digit",
                                                                year: "2-digit",
                                                            }).format(new Date(o.date))
                                                        ),
                                                        datasets: [
                                                            {
                                                                label: "Your Data",
                                                                data: currentUser.drinkingData.map((o: DataObject) => 
                                                                    o.value >= 0 ? o.value : null // Plot only values >= 0
                                                                ),
                                                                borderColor: "steelblue", // Blue line for user's data
                                                                borderWidth: 2,
                                                                fill: false,
                                                                borderDash: [], // Solid line
                                                                spanGaps: true, // Skip gaps in the user's data
                                                            },
                                                            {
                                                                label: "Recommendeded Decrease (10% Every 4 Days)",
                                                                data: currentUser.drinkingData.map((o: DataObject, index: number) => {
                                                                    // Starting value, e.g., 100 (you can adjust this as needed)
                                                                    let initialValue = Number(currentUser.info.averageWeeklyUnits)/7 | 0;
                                                                    let decreasedValue = initialValue * Math.pow((1-0.026), index);
                                                                    return decreasedValue > 2 ? decreasedValue : 2;
                                                                }),
                                                                borderColor: `${colorPalette.seaGreen}`,
                                                                borderWidth: 2,
                                                                pointRadius: 0,
                                                                borderDash: [5, 5], // Dashed line
                                                                fill: false,
                                                                spanGaps: true,
                                                            },
                                                        ],
                                                    }}

                                                    options={{
                                                        animation: {
                                                            duration: 200,
                                                        },
                                                        scales: {
                                                            y: {
                                                                type: 'linear',
                                                                beginAtZero: true,
                                                                ticks: {
                                                                    display: true,
                                                                },
                                                                grid: {
                                                                    display: false,
                                                                },
                                                            },
                                                            x: {
                                                                ticks: {
                                                                    display: true,
                                                                },
                                                                grid: {
                                                                    display: false,
                                                                },
                                                            },
                                                        },
                                                        maintainAspectRatio: false,
                                                        responsive: true,
                                                        plugins: {
                                                            legend: {
                                                                display: true, // Display legend to differentiate the two lines
                                                            },
                                                        },
                                                        layout: {
                                                            padding: {
                                                                top: 10,
                                                                bottom: 10,
                                                                left: 10,
                                                                right: 10,
                                                            },
                                                        },
                                                    }}
                                                    style={{ marginTop: '30px' }}
                                                />




                                            </div>

                                            }
                                        </div>

                                    </div>
                                }

                            </div>
                            
                            <div style={{display:'flex', flex:1, borderLeftWidth:'1px',borderLeft:'1px solid #a3a3a388', paddingLeft:'15px',height:'70vh', flexDirection:'column',alignItems:'flex-start', justifyContent:'flex-start'}}>
                            <label style={{ textAlign: 'left', fontWeight:'bold', fontStyle:'italic', marginTop:'10px'}}>PREFERENCES</label>

                            <div style={{alignItems: 'center'}}>
                                    <label>I Don't Drink</label>
                                    <Checkbox
                                    disabled={loadingDrinkerChange}
                                    checked={!currentUser.drinker}
                                    onChange={()=>handleDrinkerChange()}
                                    style={{color:'#454545', padding:0, marginLeft:'5px'}}/>

                                </div>
                                <label style={{ textAlign: 'left', fontWeight: 'bold', fontStyle: 'italic', marginTop: '10px' }}>
                                    RECOMMENDED DECREASE
                                </label>
                                <p style={{ textAlign: 'justify', fontSize: '0.95em' }}>
                                    {`It's important when cutting down your alcohol intake to do so in a slow, specific manner to minimise the risk of alcohol withdrawal. The risks of alcohol withdrawal can be severe, leading to shakes, seizures and even death in severe circumstances.`}
                                </p>
                                <p style={{ textAlign: 'justify', fontSize: '0.95em' }}>
                                    {`Our graph here shows you the recommended reduction in alcohol intake (a 10% reduction every 4 days). Getting you down to the maximum recommended alcohol intake of 14 units per week, or 2 units per day.`}
                                </p>

                                {/* Recommended Decrease Table */}
                                <div style={{  textAlign: 'center' }}>
                                    <table style={{ margin: '0 auto', borderCollapse: 'collapse' }}>
                                        <thead>
                                            <tr>
                                                <th style={{ padding: '10px', border: '1px solid #ccc' }}>Date</th>
                                                {/* Calculate next 5 days starting from today */}
                                                {Array.from({ length: 5 }).map((_, index) => {
                                                    const startDate = new Date(); // First date in data
                                                    startDate.setDate(startDate.getDate() + index); // Add index days to the start date
                                                    return (
                                                        <th key={index} style={{ padding: '10px', border: '1px solid #ccc' }}>
                                                            {formatDateWithOrdinal(startDate)}
                                                        </th>
                                                    );
                                                })}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td style={{ padding: '10px', border: '1px solid #ccc' }}>Units</td>
                                                {/* Calculate the units for each of the next 5 days */}
                                                {Array.from({ length: 5 }).map((_, index) => {
                                                    const startDate = new Date(currentUser.drinkingData.length > 0 ? currentUser.drinkingData[0].date : 0); // First date in data
                                                    const initialValue = Number(currentUser.info.averageWeeklyUnits ? currentUser.info.averageWeeklyUnits/7: 0);
                                                    
                                                    // Calculate the number of days since the start date
                                                    const currentDate = new Date();
                                                    const diffInTime = currentDate.getTime() - startDate.getTime();
                                                    const daysElapsed = Math.floor(diffInTime / (1000 * 3600 * 24));

                                                    // Calculate the decreased value based on 2.6% per day
                                                    const decreasedValue = initialValue * Math.pow(1 - 0.026, daysElapsed + index) < 2 ? 2 : initialValue * Math.pow(1 - 0.026, daysElapsed + index);

                                                    return (
                                                        <td key={index} style={{ padding: '10px', border: '1px solid #ccc' }}>
                                                            {Math.max(decreasedValue, 2).toFixed(2)} {/* Ensure value does not go below 2 */}
                                                        </td>
                                                    );
                                                })}
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>


                               <label style={{ textAlign: 'left', fontWeight:'bold', fontStyle:'italic', marginTop:'10px'}}>NHS RESOURCE LINKS</label>
                                <a target='_blank' rel="noreferrer" style={{textDecoration:'underline', textAlign:'left', color:'steelblue',fontStyle:'italic'}} 
                                    href="https://www.nhs.uk/live-well/alcohol-advice/alcohol-support/">Alcohol Advice</a>
                                <a target='_blank' rel="noreferrer" style={{textDecoration:'underline', textAlign:'left', color:'steelblue',fontStyle:'italic'}} 
                                    href="https://www.england.nhs.uk/ourwork/prevention/alcohol-dependency-programme/">Alcohol Dependency Programme</a>
                                <a target='_blank' rel="noreferrer" style={{textDecoration:'underline', textAlign:'left', color:'steelblue',fontStyle:'italic'}} 
                                    href="https://www.gmmh.nhs.uk/advice-for-people-who-use-alcohol/ ">Advice For People Who Consume Alcohol</a>
                                <label style={{ textAlign: 'left',  fontWeight:'bold', fontStyle:'italic', marginTop:'10px'}}>OTHER INFORMATION AND AA LINKS</label>
                                <a target='_blank' rel="noreferrer" style={{textDecoration:'underline', textAlign:'left', color:'steelblue',fontStyle:'italic'}} 
                                    href="http://www.alcoholics-anonymous.org.uk/">Alcoholics Anonymous</a>
                                <a target='_blank' rel="noreferrer" style={{textDecoration:'underline', textAlign:'left', color:'steelblue',fontStyle:'italic'}} 
                                    href="http://www.al-anonuk.org.uk/">Another Alcoholics Anonymous</a>
                                <a target='_blank' rel="noreferrer" style={{textDecoration:'underline', textAlign:'left', color:'steelblue',fontStyle:'italic'}} 
                                    href="https://www.smartrecovery.org.uk/">Smart Recovery</a>
                                <a target='_blank' rel="noreferrer" style={{textDecoration:'underline', textAlign:'left', color:'steelblue',fontStyle:'italic'}} 
                                    href="https://www.wearewithyou.org.uk/">We Are With You</a>
                                <a target='_blank' rel="noreferrer" style={{textDecoration:'underline', textAlign:'left',color:'steelblue',fontStyle:'italic'}} 
                                    href="https://alcoholchange.org.uk/help-and-support/get-help-now​">Get Help And Support</a>
                            </div>
                            </div>
                        </div>
                        }
                        {selectedTab === 'exercises' &&
                            <div style={{width:'100%', maxWidth: '100%', fontFamily: 'Arial, sans-serif'}}>
                            {/* <h2 style={{ textAlign: 'center', marginBottom: '2vh', fontWeight:'bold', fontStyle:'italic'}}>SMOKING</h2> */}
                            <div style={{display:'flex', flexDirection:'row'}}>
                                <div style={{marginBottom: '10px', padding:'20px',display:'flex',flex:3,alignItems:'flex-start', justifyContent:'flex-start', flexDirection:'column'}}>
                                    <DatabaseComponentView 
                                        onExerciseSelected={(exercise) => setEditingExercise(exercise)} 
                                        user={currentUser} />
                                </div>
                                {editingExercise ?
                                    <div style={{display:'flex', flex:1, borderLeftWidth:'1px',borderLeft:'1px solid #a3a3a388', paddingLeft:'15px',height:'70vh', flexDirection:'column',alignItems:'flex-start', justifyContent:'flex-start'}}>
                                        <label style={{ textAlign: 'left', fontWeight:'bold', fontStyle:'italic'}}>{`PREFERENCES FOR ${editingExercise?.name}`}</label>
                                        <div style={{ width: '100%',display: 'flex', alignItems: 'center', padding:0, marginTop:'10px'}}>
                                            <Checkbox 
                                                style={{color:'#454545', padding:0, margin:0, width:'50px', height:'50px'}}/>
                                            <label style={{color:colorPalette.steelBlue, padding:0, marginLeft:'5px'}}>I Have Trouble Doing This Exercise</label>
                                        </div>
                                    </div>
                                :
                                    <div style={{display:'flex', flex:1, borderLeftWidth:'1px',borderLeft:'1px solid #a3a3a388',height:'70vh', flexDirection:'column',alignItems:'center', justifyContent:'center'}}>
                                        <label style={{ textAlign: 'center', fontWeight:'bold', fontStyle:'italic'}}>{`SELECT AN EXERCISE TO START`}</label>
                                    </div>
                                }

                            </div>
                        </div>
                        }
                        {selectedTab === 'sleep' &&
                            <div style={{width:'100%', maxWidth: '100%', fontFamily: 'Arial, sans-serif'}}>
                            {/* <h2 style={{ textAlign: 'center', marginBottom: '2vh', fontWeight:'bold', fontStyle:'italic'}}>SMOKING</h2> */}
                            <div  style={{display:'flex', flexDirection:'row'}}>
                            <div style={{marginBottom: '10px', display:'flex',flex:3,alignItems:'flex-start', justifyContent:'flex-start', flexDirection:'column'}}>
                                <label style={{ textAlign: 'center', fontWeight:'bold', fontStyle:'italic'}}>{'SLEEP TRACKER'}</label>
                                <label style={{ textAlign: 'center', marginBottom: '5px', fontStyle:'italic', fontSize:'1em'}}>{'Track Your Sleeping Habits Through Our Check-In System'}</label>
                                <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>      
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: '10px',}}>
                                        {!hasSubmittedSleepingDataToday() &&
                                            <label 
                                                style={{ 
                                                fontStyle: 'italic', 
                                                fontWeight: 'bold', 
                                                color: colorPalette.steelBlue, 
                                                fontSize: '1em' 
                                                }}
                                            >
                                                HOW MANY HOURS OF SLEEP DID YOU GET LAST NIGHT?
                                            </label>
                                        }
                                        {!hasSubmittedSleepingDataToday() &&
                                        <input 
                                            type="number" 
                                            disabled={hasSubmittedSleepingDataToday()}
                                            min={0}
                                            style={{ 
                                            fontStyle: 'italic', 
                                            fontWeight: 'bold', 
                                            color: colorPalette.steelBlue, 
                                            fontSize: '1em', 
                                            width: '5em', 
                                            borderRadius: '5px', 
                                            border: 'none',
                                            textAlign:'center',
                                            outline:'none', 
                                            height: '40px', // Consistent height
                                            }}
                                            value={sleepingInput}
                                            onChange={(e) => setSleepingInput(Number(e.currentTarget.value))}
                                        />
                                        }
                                        <button 
                                            // type="submit" 
                                            style={{ 
                                            borderRadius: '5px', 
                                            height: '40px', // Same height as input
                                            padding: '0 10px', // Adjust padding for consistent size
                                            fontSize: '1em',
                                            margin:0,
                                            width:'auto',
                                            fontStyle:'italic',
                                            fontWeight:'bold',
                                            backgroundColor:colorPalette.steelBlue
                                            }} 
                                            className="submit-button"
                                            onClick={() => submitSleepingValue()}
                                            disabled={hasSubmittedSleepingDataToday()}
                                            title={
                                                hasSubmittedSleepingDataToday()
                                                ? "You have already submitted sleep data for today."
                                                : ""
                                            }
                                        >
                                            {hasSubmittedSleepingDataToday() ? 'SUBMITTED FOR TODAY' : 'SUBMIT FOR TODAY'}
                                        </button>
                                        </div>
                                        <div style={{ height: '100%', display: 'flex', marginTop: '6vh' }}>
                                            {currentUser.sleepingData && 
                                            <div style={{ width: '97%', height:'50vh', backgroundColor:'transparent'}}>
                                                <Bar
                                                data={{
                                                    labels: currentUser.sleepingData.map((o: DataObject) =>
                                                    new Intl.DateTimeFormat("en-GB", {
                                                        day: "2-digit",
                                                        month: "2-digit",
                                                        year: "2-digit",
                                                    }).format(new Date(o.date))
                                                    ),
                                                    datasets: [
                                                    {
                                                        label: "Hours Slept",
                                                        data: currentUser.sleepingData.map((o: DataObject) => o.value), // Use only the values for the data points
                                                        backgroundColor: currentUser.sleepingData.map((o: DataObject) =>
                                                        getSleepQualityColor(currentUser, Number(o.value))
                                                        ),
                                                        borderColor: currentUser.sleepingData.map((o: DataObject) =>
                                                        getSleepQualityColor(currentUser, Number(o.value))
                                                        ),
                                                        borderWidth: 1,
                                                    },
                                                    ],
                                                }}
                                                options={{
                                                    animation: {
                                                    duration: 200,
                                                    },
                                                    scales: {
                                                    y: {
                                                        type: "linear", // specify the scale type
                                                        beginAtZero: true,
                                                        ticks: {
                                                        display: true,
                                                        },
                                                        grid: {
                                                        display: false,
                                                        },
                                                    },
                                                    x: {
                                                        ticks: {
                                                        display: true, // hide the x-axis ticks
                                                        },
                                                        grid: {
                                                        display: false,
                                                        },
                                                    },
                                                    },
                                                    maintainAspectRatio: false, // prevent the chart from maintaining aspect ratio
                                                    responsive: true, // make the chart responsive
                                                    plugins: {
                                                    legend: {
                                                        display: false,
                                                        position: "top", // adjust legend position as needed
                                                    },
                                                    },
                                                    layout: {
                                                    padding: {
                                                        top: 10,
                                                        bottom: 10,
                                                        left: 10,
                                                        right: 10,
                                                    },
                                                    },
                                                    onClick: (event, elements) => {
                                                    if (elements.length > 0) {
                                                        const element = elements[0]; // The clicked element
                                                        const datasetIndex = element.datasetIndex; // Index of the dataset clicked
                                                        const dataIndex = element.index; // Index of the data point clicked

                                                        // Get the value and date of the clicked bar
                                                        const value = currentUser.sleepingData[dataIndex]?.value;
                                                        const date = currentUser.sleepingData[dataIndex]?.date;

                                                        if (value === -1) {
                                                        const newValue = prompt(
                                                            "Enter your retrospective sleeping value:"
                                                        );
                                                        if (newValue !== null) {
                                                            submitRetrospectiveSleepingValue(newValue, date);
                                                        }
                                                        }
                                                    }
                                                    },
                                                    onHover: (event: any, elements: Array<{ index: number }>) => {
                                                    if (event.native) {
                                                        if (elements.length > 0) {
                                                        const element = elements[0]; // The hovered element
                                                        const dataIndex = element.index; // Index of the data point hovered
                                                        const value = currentUser.sleepingData[dataIndex]?.value;

                                                        // Change cursor to pointer if value is -1
                                                        event.native.target.style.cursor = value === -1 ? "pointer" : "default";
                                                        } else {
                                                        event.native.target.style.cursor = "default";
                                                        }
                                                    }
                                                    },
                                                }}
                                                />



                                            </div>
                                            }
                                        </div>

                                </div>

                            </div>
                            <div style={{display:'flex', flex:1, borderLeftWidth:'1px',borderLeft:'1px solid #a3a3a388', paddingLeft:'15px',height:'70vh', flexDirection:'column',alignItems:'flex-start', justifyContent:'flex-start'}}>
                                <label style={{ textAlign: 'left', fontWeight:'bold', fontStyle:'italic'}}>NHS RESOURCE LINKS</label>
                                <a target='_blank' rel="noreferrer" style={{textDecoration:'underline', textAlign:'left', color:'steelblue',fontStyle:'italic'}} 
                                    href="https://www.mysouthernhealth.com/wp-content/uploads/2015/09/09_15_I_PowerofSleep-copy.jpg">Sleep Hygiene</a>
                                <a target='_blank' rel="noreferrer" style={{textDecoration:'underline', textAlign:'left', color:'steelblue',fontStyle:'italic'}} 
                                    href="http://www.nhs.uk/every-mind-matters/mental-health-issues/sleep">Sleep and Mental Health</a>
                                <a target='_blank' rel="noreferrer" style={{textDecoration:'underline', textAlign:'left', color:'steelblue',fontStyle:'italic'}} 
                                    href="https://www.gosh.nhs.uk/conditions-and-treatments/procedures-and-treatments/sleep-hygiene-children/">Children and Young People Sleep Hygiene</a>
                                <a target='_blank' rel="noreferrer" style={{textDecoration:'underline', textAlign:'left', color:'steelblue',fontStyle:'italic'}} 
                                    href="https://www.nhs.uk/every-mind-matters/mental-wellbeing-tips/how-to-fall-asleep-faster-and-sleep-better/">Children and Young People Sleep Hygiene</a>
                                <a target='_blank' rel="noreferrer" style={{textDecoration:'underline', textAlign:'left', color:'steelblue',fontStyle:'italic'}} 
                                    href="https://www.nhsborders.scot.nhs.uk/media/213568/sleep.pdf ">Tips For Better Sleep</a>

                                <label style={{ textAlign: 'left',  fontWeight:'bold', fontStyle:'italic', marginTop:'10px'}}>FURTHER READING</label>
                                <a target='_blank' rel="noreferrer" style={{textDecoration:'underline', textAlign:'left', color:'steelblue',fontStyle:'italic'}} 
                                    href="http://www.ageuk.org.uk/information-advice/health-wellbeing/mind-body/getting-a-good-nights-sleep">Getting A Good Night Sleep</a>
                                <a target='_blank' rel="noreferrer" style={{textDecoration:'underline', textAlign:'left', color:'steelblue',fontStyle:'italic'}} 
                                    href="http://www.mentalhealth.org.uk/explore-mental-health/publications/how-sleep-better">Mental Health And Sleep</a>
                                <a target='_blank' rel="noreferrer" style={{textDecoration:'underline', textAlign:'left', color:'steelblue',fontStyle:'italic'}} 
                                    href="http://www.headspace.com/sleep/sleep-hygiene">Sleep Hygiene</a>
                                <a target='_blank' rel="noreferrer" style={{textDecoration:'underline', textAlign:'left', color:'steelblue',fontStyle:'italic'}} 
                                    href="http://www.aasm.org/resources/pdf/products/howtosleepbetter_web.pdf">How To Sleep better</a>
                                <a target='_blank' rel="noreferrer" style={{textDecoration:'underline', textAlign:'left',color:'steelblue',fontStyle:'italic'}} 
                                    href="http://www.sleepeducation.org/healthy-sleep/healthy-sleep-habits​">Healthy Sleep Habits</a>
                                <a target='_blank' rel="noreferrer" style={{textDecoration:'underline', textAlign:'left',color:'steelblue',fontStyle:'italic'}} 
                                    href="https://www.sleep.com/sleep-health/how-many-hours-of-sleep ​">How Many Hours Of Sleep?</a>
                            </div>
                            </div>
                        </div>
                        }
                        {selectedTab === 'nutrition' &&
                            <div style={{width:'100%', maxWidth: '100%', fontFamily: 'Arial, sans-serif'}}>
                            {/* <h2 style={{ textAlign: 'center', marginBottom: '2vh', fontWeight:'bold', fontStyle:'italic'}}>SMOKING</h2> */}
                            <div  style={{display:'flex', flexDirection:'row'}}>
                            <div style={{marginBottom: '10px', display:'flex',flex:3,alignItems:'flex-start', justifyContent:'flex-start', flexDirection:'column'}}>                                
                                <label style={{ textAlign: 'center', fontWeight:'bold', fontStyle:'italic'}}>{'DIET TRACKER'}</label>
                                <label style={{ textAlign: 'center', marginBottom: '5px', fontStyle:'italic', fontSize:'1em'}}>{'Track Your Dietary Habits Through Our Check-In System'}</label>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '10px' }}>
                                    <label style={{ textAlign: 'left', fontWeight: 'bold', fontStyle: 'italic', marginTop:'10px'}}>{hasSubmittedNutritionDataToday() ? `SUBMITTED, SEE YOU TOMORROW!` : `DAILY NUTRITION CHECKLIST`}</label>
                                    {!hasSubmittedNutritionDataToday() && 
                                    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                    {nutritionInput.map((q, index) => (
                                        <div key={index} style={{ width: '100%', display: 'flex', alignItems: 'center', padding: 0 }}>
                                            <Checkbox 
                                                checked={q.checked} 
                                                disabled={hasSubmittedNutritionDataToday()}
                                                onChange={() => handleCheckboxChange(index)}
                                                style={{ color: '#454545', padding: 0, margin: 0 }}
                                            />
                                            <label style={{ color: '#4682B4', padding: 0, marginLeft: '5px' }}>{q.label}</label>
                                        </div>
                                    ))}
                                    </div>
                                    }
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: '10px', marginTop: '20px' }}>
                                        <button 
                                            style={{ 
                                                borderRadius: '5px', 
                                                height: '40px', 
                                                padding: '0 10px', 
                                                fontSize: '1em',
                                                margin: 0,
                                                width: 'auto',
                                                fontStyle: 'italic',
                                                fontWeight: 'bold',
                                                backgroundColor: colorPalette.steelBlue
                                            }} 
                                            className="submit-button"
                                            onClick={() => submitNutritionData()}
                                            disabled={hasSubmittedNutritionDataToday()}
                                            title={
                                                hasSubmittedNutritionDataToday()
                                                ? "You have already submitted diet data for today."
                                                : ""
                                            }
                                        >
                                            {hasSubmittedNutritionDataToday() ? 'SUBMITTED FOR TODAY' : 'SUBMIT FOR TODAY'}
                                        </button>
                                    </div>
                                </div>
                                <div style={{height:'1px', backgroundColor:'gray',opacity:'0.4', width:'97%', marginTop:'10px', marginBottom:'10px'}}></div>
                                <div style={{ width: '97%', height: '50vh', marginTop:'1vh',backgroundColor: 'transparent', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent:'flex-start'}}>
                                {/* PICTOGRAM */}
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '20px', width: '100%' }}>
                                    {/* Title for checklist score */}
                                    {/* <span style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '10px' }}>Checklist Score</span> */}

                                    {/* Table structure */}
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                        {/* Header row: Days across the top */}
                                        <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: '10px' }}>
                                            <div style={{ width: '40.5%', textAlign: 'left' }}></div> {/* Empty cell for labels */}
                                            {currentUser.nutritionData.slice(-7).map((o: ChecklistDataObject, index) => (
                                                <span
                                                    key={index}
                                                    style={{
                                                        textAlign: 'center',
                                                        fontSize: '14px',
                                                        fontWeight: 'bold',
                                                        width: '50px', // Ensure alignment with circle widths
                                                        display: 'inline-block', // Prevent stretching
                                                    }}
                                                >
                                                    {`${formatAsShortDayName(o.date)} ${formatDateWithOrdinal(o.date)}`}
                                                </span>
                                            ))}
                                        </div>


                                        {/* Body rows: Labels on the left, circles in columns */}
                                        {currentUser.nutritionData[0]?.value.map((checkbox, labelIndex) => (
                                            <div key={labelIndex} style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                                                {/* Label */}
                                                <div style={{ width: '40%', fontSize: '14px', fontWeight: 'bold', textAlign:'left'}}>{checkbox.label}</div>

                                                {/* Circles for each day */}
                                                {currentUser.nutritionData.slice(-7).map((o: ChecklistDataObject, dayIndex) => {
                                                    const isChecked = o.value[labelIndex]?.checked;

                                                    return (
                                                        <div
                                                            key={dayIndex}
                                                            onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                                                                // Get the date directly from the `nutritionData` array
                                                                const date = currentUser.nutritionData.slice(-7)[dayIndex]?.date;
                                                            
                                                                // Ask the user for confirmation
                                                                const isConfirmed = window.confirm("Are you sure you want to change this checklist item?");
                                                            
                                                                // Check if the user clicked "OK" (Yes)
                                                                if (isConfirmed) {
                                                                    // Call your function with the confirmation
                                                                    submitRetrospectiveNutritionValue(date, labelIndex);
                                                                } else {
                                                                    // Handle the case if the user clicks "Cancel"
                                                                    console.log("Action canceled");
                                                                }
                                                            }}
                                                            
                                                            style={{
                                                                width: '40px',
                                                                height: '40px',
                                                                borderRadius: '50%',
                                                                backgroundColor: o.value.length <= 0 ? '#34343466' : (isChecked
                                                                    ? colorPalette.seaGreen
                                                                    : colorPalette.softRed),
                                                                marginLeft: '10px',
                                                            }}
                                                        />
                                                    );
                                                })}

                                            </div>
                                        ))}

                                        {/* Average row */}
                                        <div style={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
                                            <div style={{ width: '40%', fontSize: '14px', fontWeight: 'bold', textAlign:'left'}}>Average</div>
                                            {currentUser.nutritionData.slice(-7).map((o: ChecklistDataObject, index) => {
                                                const checkedCount = o.value.filter(v => v.checked).length;

                                                let backgroundColor = '';
                                                if (o.value.length <= 0){
                                                    backgroundColor = '#34343466';
                                                } else if (checkedCount > 3) {
                                                    backgroundColor = colorPalette.seaGreen;
                                                } else if (checkedCount > 1) {
                                                    backgroundColor = colorPalette.orange;
                                                } else {
                                                    backgroundColor = colorPalette.softRed;
                                                }

                                                return (
                                                    <div
                                                        key={index}
                                                        style={{
                                                            width: '40px',
                                                            height: '40px',
                                                            borderRadius: '50%',
                                                            backgroundColor: backgroundColor,
                                                            marginLeft: '10px',
                                                        }}
                                                    />
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>



                            </div>


                            
                            </div>

   
                            
                            <div style={{display:'flex', flex:1, borderLeftWidth:'1px', borderLeft:'1px solid #a3a3a388', paddingLeft:'15px', height:'70vh', flexDirection:'column', alignItems:'flex-start', justifyContent:'flex-start'}}>
                            <label style={{ textAlign: 'left', fontWeight:'bold', fontStyle:'italic'}}>NHS RESOURCE LINKS</label>
                            <a target='_blank' rel="noreferrer" style={{textDecoration:'underline', textAlign:'left', color:'steelblue', fontStyle:'italic'}} 
                                href="https://www.nhs.uk/better-health/lose-weight">NHS services for weight management</a>
                            <a target='_blank' rel="noreferrer" style={{textDecoration:'underline', textAlign:'left', color:'steelblue', fontStyle:'italic'}} 
                                href="https://www.nhs.uk/live-well/eat-well/food-guidelines-and-food-labels/the-eatwell-guide/">Dietary advice regarding healthy eating</a>
                            <a target='_blank' rel="noreferrer" style={{textDecoration:'underline', textAlign:'left', color:'steelblue', fontStyle:'italic'}} 
                                href="https://www.england.nhs.uk/digital-weight-management">NHS Digital Weight Management Programme</a>
                            <label style={{ textAlign: 'left', fontWeight:'bold', fontStyle:'italic', marginTop:'10px'}}>WEIGHT MANAGEMENT SERVICES IN DEVON</label>
                            <a target='_blank' rel="noreferrer" style={{textDecoration:'underline', textAlign:'left', color:'steelblue', fontStyle:'italic'}} 
                                href="https://myhealth-devon.nhs.uk/index.php/my-condition/obesity">Weight management services in Devon</a>
                            <a target='_blank' rel="noreferrer" style={{textDecoration:'underline', textAlign:'left', color:'steelblue', fontStyle:'italic'}} 
                                href="https://www.best-you.org/">BestYou App (provided by ICE Creates)</a>
                            <a target='_blank' rel="noreferrer" style={{textDecoration:'underline', textAlign:'left', color:'steelblue', fontStyle:'italic'}} 
                                href="https://www.preventing-diabetes.co.uk/locations/devon/">Healthier You NHS Diabetes Prevention Programme</a>
                           <label style={{ textAlign: 'left', fontWeight:'bold', fontStyle:'italic', marginTop:'10px'}}>WIDER READING</label>
                            <a target='_blank' rel="noreferrer" style={{textDecoration:'underline', textAlign:'left', color:'steelblue', fontStyle:'italic'}} 
                                href="https://www.optifast.co.uk/">Low Calorie Diet Programmes (e.g., Optifast)</a>
                            </div>

                            </div>
                        </div>
                        }
                        {selectedTab === 'goals' &&
                            <div style={{width:'100%', maxWidth: '100%', fontFamily: 'Arial, sans-serif'}}>
                                <div
                                    style={{
                                        marginBottom: '20px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'flex-start',
                                    }}
                                    >
                                    <label
                                        style={{
                                        textAlign: 'left',
                                        fontWeight: 'bold',
                                        fontStyle: 'italic',
                                        fontSize: '1.5em',
                                        marginBottom: '10px',
                                        }}
                                    >
                                        {'GOALS TRACKER'}
                                    </label>
                                    <label
                                        style={{
                                        textAlign: 'left',
                                        marginBottom: '20px',
                                        fontStyle: 'italic',
                                        fontSize: '1em',
                                        color: '#555',
                                        }}
                                    >
                                        {'Track Your Goals Through Our Check-In System'}
                                    </label>
                                    <table
                                        style={{
                                        width: '100%',
                                        borderCollapse: 'collapse',
                                        textAlign: 'center',
                                        }}
                                    >
                                        <thead>
                                        <tr style={{ borderBottom: '2px solid #ccc' }}>
                                            <th>#</th>
                                            <th>Specific</th>
                                            <th>Measurable</th>
                                            <th>Achievable</th>
                                            <th>Relevant</th>
                                            <th>Time-Bound</th>
                                            <th>Action</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {currentUser.goals &&
                                            currentUser.goals.map((goal, index) => (
                                            <tr key={index} style={{ borderBottom: '1px solid #ccc' }}>
                                                <td>{index+1}</td>
                                                <td>{goal.content.specific}</td>
                                                <td>{goal.content.measurable}</td>
                                                <td>{goal.content.achievable}</td>
                                                <td>{goal.content.relevant}</td>
                                                <td>{goal.content.timeBound}</td>
                                                <td>
                                                {loadingRemoveGoal === goal ? 
                                                    <div
                                                    style={{
                                                        width: '30px',
                                                        height: '30px',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        margin: 'auto',
                                                    }}
                                                    >
                                                    <div
                                                        style={{
                                                        width: '25px',
                                                        height: '25px',
                                                        border: '3px solid #ccc',
                                                        borderTop: `3px solid ${colorPalette.softRed}`,
                                                        borderRadius: '50%',
                                                        animation: 'spin 1s linear infinite',
                                                        }}
                                                    ></div>
                                                    </div>
                                                    :
                                                    <FontAwesomeIcon icon={faMinus}
                                                        onClick={()=>loadingRemoveGoal !== undefined ? {} : handleRemoveGoal(goal)}
                                                        style={{
                                                            padding: '5px 10px',
                                                            cursor: 'pointer',
                                                            backgroundColor: colorPalette.softRed,
                                                            color: '#fff',
                                                            border: 'none',
                                                            borderRadius: '5px',
                                                            marginTop:'5px',
                                                            width:'15px',
                                                            height:'15px',
                                                            transition: 'background-color 0.3s ease',
                                                            fontWeight: 'bold',
                                                        }}
                                                        onMouseOver={(e) =>
                                                            (e.currentTarget.style.backgroundColor = colorPalette.vibrantRed)
                                                        }
                                                        onMouseOut={(e) =>
                                                            (e.currentTarget.style.backgroundColor = colorPalette.softRed)
                                                        }
                                                    ></FontAwesomeIcon>
                                                }

                                            </td>
                                            </tr>
                                            ))}
                                        <tr style={{ backgroundColor: '#f9f9f9' }}>
                                        <td>
                                            <div style={{ position: 'relative', textAlign: 'center', width: '100%'}}>
                                                <input
                                                type="text"
                                                value={currentUser.goals.length+1}
                                                onChange={(e) => handleInputChange('specific', e.target.value)}
                                                placeholder="Specific"
                                                disabled
                                                style={{
                                                    width: '90%',
                                                    border: 'none',
                                                    outline: 'none',
                                                    backgroundColor: 'transparent',
                                                    color: colorPalette.steelBlue,
                                                    fontStyle: 'italic',
                                                    textAlign: 'center',
                                                    borderBottom: '1px solid #ccc',
                                                }}
                                                onMouseOver={(e) =>
                                                    (e.currentTarget.style.opacity = '1')
                                                }
                                                onMouseOut={(e) =>
                                                    (e.currentTarget.style.opacity = '0.9')
                                                }
                                                />
                                            </div>
                                            </td>
                                            <td>
                                            <div style={{ position: 'relative', textAlign: 'center', width: '100%' }}>
                                                <input
                                                type="text"
                                                value={newGoal.specific}
                                                onChange={(e) => handleInputChange('specific', e.target.value)}
                                                placeholder="Specific"
                                                style={{
                                                    width: '90%',
                                                    border: 'none',
                                                    outline: 'none',
                                                    backgroundColor: 'transparent',
                                                    color: colorPalette.steelBlue,
                                                    fontWeight: 'bold',
                                                    fontStyle: 'italic',
                                                    textAlign: 'center',
                                                    borderBottom: '1px solid #ccc',
                                                }}
                                                onFocus={(e) =>
                                                    (e.currentTarget.style.borderBottom = `2px solid ${colorPalette.steelBlue}`)
                                                }
                                                onBlur={(e) =>
                                                    (e.currentTarget.style.borderBottom = '1px solid #ccc')
                                                }
                                                />
                                            </div>
                                            </td>
                                            <td>
                                            <div style={{ position: 'relative', textAlign: 'center', width: '100%' }}>
                                                <input
                                                type="text"
                                                value={newGoal.measurable}
                                                onChange={(e) => handleInputChange('measurable', e.target.value)}
                                                placeholder="Measurable"
                                                style={{
                                                    width: '90%',
                                                    border: 'none',
                                                    outline: 'none',
                                                    backgroundColor: 'transparent',
                                                    color: colorPalette.steelBlue,
                                                    fontWeight: 'bold',
                                                    fontStyle: 'italic',
                                                    textAlign: 'center',
                                                    borderBottom: '1px solid #ccc',
                                                }}
                                                onFocus={(e) =>
                                                    (e.currentTarget.style.borderBottom = `2px solid ${colorPalette.steelBlue}`)
                                                }
                                                onBlur={(e) =>
                                                    (e.currentTarget.style.borderBottom = '1px solid #ccc')
                                                }
                                                />
                                            </div>
                                            </td>
                                            <td>
                                            <div style={{ position: 'relative', textAlign: 'center', width: '100%' }}>
                                                <input
                                                type="text"
                                                value={newGoal.achievable}
                                                onChange={(e) => handleInputChange('achievable', e.target.value)}
                                                placeholder="Achievable"
                                                style={{
                                                    width: '90%',
                                                    border: 'none',
                                                    outline: 'none',
                                                    backgroundColor: 'transparent',
                                                    color: colorPalette.steelBlue,
                                                    fontWeight: 'bold',
                                                    fontStyle: 'italic',
                                                    textAlign: 'center',
                                                    borderBottom: '1px solid #ccc',
                                                }}
                                                onFocus={(e) =>
                                                    (e.currentTarget.style.borderBottom = `2px solid ${colorPalette.steelBlue}`)
                                                }
                                                onBlur={(e) =>
                                                    (e.currentTarget.style.borderBottom = '1px solid #ccc')
                                                }
                                                />
                                            </div>
                                            </td>
                                            <td>
                                            <div style={{ position: 'relative', textAlign: 'center', width: '100%' }}>
                                                <input
                                                type="text"
                                                value={newGoal.relevant}
                                                onChange={(e) => handleInputChange('relevant', e.target.value)}
                                                placeholder="Relevant"
                                                style={{
                                                    width: '90%',
                                                    border: 'none',
                                                    outline: 'none',
                                                    backgroundColor: 'transparent',
                                                    color: colorPalette.steelBlue,
                                                    fontWeight: 'bold',
                                                    fontStyle: 'italic',
                                                    textAlign: 'center',
                                                    borderBottom: '1px solid #ccc',
                                                }}
                                                onFocus={(e) =>
                                                    (e.currentTarget.style.borderBottom = `2px solid ${colorPalette.steelBlue}`)
                                                }
                                                onBlur={(e) =>
                                                    (e.currentTarget.style.borderBottom = '1px solid #ccc')
                                                }
                                                />
                                            </div>
                                            </td>
                                            <td>
                                            <div style={{ position: 'relative', textAlign: 'center', width: '100%' }}>
                                                <input
                                                type="text"
                                                value={newGoal.timeBound}
                                                onChange={(e) => handleInputChange('timeBound', e.target.value)}
                                                placeholder="Time-Bound"
                                                style={{
                                                    width: '90%',
                                                    border: 'none',
                                                    outline: 'none',
                                                    backgroundColor: 'transparent',
                                                    color: colorPalette.steelBlue,
                                                    fontWeight: 'bold',
                                                    fontStyle: 'italic',
                                                    textAlign: 'center',
                                                    borderBottom: '1px solid #ccc',
                                                }}
                                                onFocus={(e) =>
                                                    (e.currentTarget.style.borderBottom = `2px solid ${colorPalette.steelBlue}`)
                                                }
                                                onBlur={(e) =>
                                                    (e.currentTarget.style.borderBottom = '1px solid #ccc')
                                                }
                                                />
                                            </div>
                                            </td>
                                            <td>
                                            {loadingAddGoal ? 
                                                    <div
                                                    style={{
                                                        width: '30px',
                                                        height: '30px',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        margin: 'auto',
                                                    }}
                                                    >
                                                    <div
                                                        style={{
                                                        width: '25px',
                                                        height: '25px',
                                                        border: '3px solid #ccc',
                                                        borderTop: `3px solid ${colorPalette.mediumSeaGreen}`,
                                                        borderRadius: '50%',
                                                        animation: 'spin 1s linear infinite',
                                                        }}
                                                    ></div>
                                                    </div>
                                                    :
                                                <FontAwesomeIcon icon={faPlus}
                                                    onClick={()=>loadingAddGoal ? {} :handleSubmitModalValue()}
                                                    style={{
                                                        padding: '5px 10px',
                                                        cursor: 'pointer',
                                                        backgroundColor: colorPalette.mediumSeaGreen,
                                                        color: '#fff',
                                                        border: 'none',
                                                        borderRadius: '5px',
                                                        marginTop:'5px',
                                                        width:'15px',
                                                        height:'15px',
                                                        transition: 'background-color 0.3s ease',
                                                        fontWeight: 'bold',
                                                    }}
                                                    onMouseOver={(e) =>
                                                        (e.currentTarget.style.backgroundColor = colorPalette.seaGreen)
                                                    }
                                                    onMouseOut={(e) =>
                                                        (e.currentTarget.style.backgroundColor = colorPalette.mediumSeaGreen)
                                                    }
                                                ></FontAwesomeIcon>
                                            }
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        }
                        {selectedTab === 'myplan' &&
                            <div style={{width:'100%', maxWidth: '100%',fontFamily: 'Arial, sans-serif'}}>
                                <div
                                    style={{
                                        marginBottom: '20px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'flex-start',
                                    }}
                                    >
                                    <label
                                        style={{
                                        textAlign: 'left',
                                        fontWeight: 'bold',
                                        fontStyle: 'italic',
                                        fontSize: '1.5em',
                                        marginBottom: '10px',
                                        }}
                                    >
                                        {`PLAN CENTRE - ${currentUser.currentVitovaPlan.title.toUpperCase()}`}
                                    </label>
                                    <label
                                        style={{
                                        textAlign: 'left',
                                        // marginBottom: '20px',
                                        fontStyle: 'italic',
                                        fontSize: '1em',
                                        color: '#555',
                                        }}
                                    >
                                        Track Your Pathway Progress Through Our Timeline Of Your Time With Us
                                    </label>
                                </div>
                                {/* <div style={{display:'flex', flex:1, width:'100%', height:'100%', justifyContent:'flex-start', alignItems:'flex-start'}}>
                                    <RadarGraph user={currentUser} title={`${currentUser.currentVitovaPlan.title}`} key={''}/>
                                </div> */}

                                <div style={{ maxWidth: "100%", margin: "0 auto",marginTop:0, paddingTop:0, padding: "12px", display:"flex", flexDirection:'column',alignItems:'center'}}>
                                <div style={{height:'2px', width:'100vw', backgroundColor:`${colorPalette.steelBlue}aa`}}></div>

                                <h2
                                    style={{
                                    fontSize: "24px",
                                    fontWeight: "bold",
                                    textAlign: "center",
                                    marginTop:'32px',
                                    fontStyle: "italic",
                                    color: colorPalette.steelBlue,
                                    }}
                                >
                                    YOUR JOURNEY WITH US
                                </h2>
                                <h2
                                    style={{
                                    fontSize: "1em",
                                    textAlign: "center",
                                    marginTop: "0px",
                                    marginBottom:'16px',
                                    maxWidth:'70vw',
                                    fontStyle: "italic",
                                    color: `${colorPalette.steelBlue}`,
                                    }}
                                >
                                    {`Below is your timeline for your journey with us at VITOVA. To ensure that you achieve the best possible health by the end of your plan, we recommend following our pathway as closely as possible.`}
                                </h2>
                                <div
                                    style={{
                                    position: "relative",
                                    display: "flex",
                                    marginBottom: "32px",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    overflowX: "auto",
                                    padding: "16px",
                                    gap: "32px",
                                    }}
                                >
                                    <div
                                    style={{
                                        position: "absolute",
                                        top: "50%",
                                        left: "0",
                                        width: "100%",
                                        height: "4px",
                                        backgroundColor: "#d1d5db",
                                    }}
                                    ></div>
                                    {getUserTimeline(currentUser)
                                    .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
                                    .map((event, index) => {
                                        const isPast = new Date(event.date) < new Date();
                                        const circleColor = isPast ? colorPalette.rpeGreen : colorPalette.softRed;
                                        
                                        return (
                                        <motion.div
                                            key={index}
                                            initial={{ opacity: 0, x: -50 }}
                                            animate={{ opacity: 1, x: 0 }}
                                            transition={{ duration: 0.5, delay: index * 0.3 }}
                                            style={{
                                            position: "relative",
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            }}
                                        >
                                            <FontAwesomeIcon
                                            icon={faCircle}
                                            style={{ color: circleColor, marginBottom: "0.75em" }}
                                            size="2x"
                                            />
                                            <div
                                            style={{
                                                backgroundColor: "white",
                                                padding: "2em",
                                                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                                                borderRadius: "8px",
                                                overflowX:'scroll',
                                                width: `calc(70vw/${getUserTimeline(currentUser).length})`,
                                                // height: "100%",
                                                height:'15em',
                                                textAlign: "center",
                                            }}
                                            >
                                            <h3 style={{ fontSize: "18px", fontWeight: "600" }}>
                                                {event.date.toDateString()}
                                            </h3>
                                            <p
                                                style={{
                                                color: colorPalette.steelBlue,
                                                fontWeight: "bold",
                                                fontStyle: "italic",
                                                fontSize: "1.5em",
                                                }}
                                            >
                                                {event.title.toUpperCase()}
                                            </p>
                                            <p style={{ color: "#6b7280", marginTop: "4px" }}>
                                                {event.description}
                                            </p>
                                            </div>
                                        </motion.div>
                                        );
                                    })}
                                </div>
                                <div style={{height:'2px', width:'80vw', backgroundColor:`${colorPalette.steelBlue}aa`}}></div>

                                <h2
                                    style={{
                                    fontSize: "24px",
                                    fontWeight: "bold",
                                    textAlign: "center",
                                    marginTop: "32px",
                                    marginBottom:'8px',
                                    fontStyle: "italic",
                                    color: `${colorPalette.steelBlue}`,
                                    }}
                                >
                                    CONDITION COMPLIANCE OVERVIEW
                                </h2>
                                <h2
                                    style={{
                                    fontSize: "1em",
                                    textAlign: "center",
                                    marginTop: "0px",
                                    marginBottom:'32px',
                                    maxWidth:'70vw',
                                    fontStyle: "italic",
                                    color: `${colorPalette.steelBlue}`,
                                    }}
                                >
                                    {`Described below is how compliant your current plan (${currentUser.currentVitovaPlan.title.toUpperCase()}) is with the conditions that you have. This score is calculated from a multitude of health constraints outlined by our medical and exercise professionals.`}
                                </h2>
                                {currentUser.info.conditions.filter(c=>c.checked).length>0
                                ?
<div
  style={{
    display: 'grid',
    gridTemplateColumns: `repeat(${currentUser.info.conditions.filter(c => c.checked).length > 3 ? 3 : currentUser.info.conditions.filter(c => c.checked).length}, 1fr)`,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '2em',
  }}
>
  {currentUser.info.conditions.filter(c => c.checked).map((condition: CheckedCondition) => {
    const matchedCondition = conditions.find(
      c => c.name.toLowerCase() === condition.condition.name.toLowerCase()
    );

    const complianceScore = matchedCondition
      ? getPlanToConditionCompatibility(currentUser.currentVitovaPlan, matchedCondition).complianceScore
      : 100; // or some default value

    const getComplianceColor = (score: number) => {
      if (score >= 80) {
        return colorPalette.rpeGreen;
      }
      if (score >= 50) {
        return colorPalette.rpeYellow;
      }
      return colorPalette.softRed;
    };

    const complianceColor = getComplianceColor(complianceScore);

    return (
      <div
        key={condition.condition.name}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: 'white',
          padding: '2em',
          boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
          borderRadius: '8px',
          width: '100%',
          overflowX: 'scroll',
        }}
      >
        <div
          style={{
            width: `150px`,
            height: `150px`,
            borderRadius: '50%',
            backgroundColor: `${complianceColor}99`,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
          }}
        >
          <div
            style={{
              width: `${complianceScore}%`,
              height: `${complianceScore}%`,
              borderRadius: '50%',
              backgroundColor: complianceColor,
              position: 'absolute',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <label
              style={{
                fontSize: '1.5vw',
                fontStyle: 'italic',
                fontWeight: 'bold',
                color: 'white',
              }}
            >
              {complianceScore}%
            </label>
          </div>
        </div>
        <label
          style={{
            fontSize: '1.2vw',
            fontWeight: 'bold',
            fontStyle: 'italic',
            color: colorPalette.steelBlue,
            textAlign: 'center',
            marginTop: '1em',
          }}
        >
          {`COMPLIANT WITH ${condition.condition.name.toUpperCase()}`}
        </label>
      </div>
    );
  })}
</div>
                                :
                                <div style={{display:'flex', flexDirection:'column', width:'100%'}}>
                                    <label style={{fontSize:'1.2em', fontStyle:'italic', color:colorPalette.steelBlue}}>
                                        {`YOUR ACCOUNT IS NOT LINKED TO ANY CONDTIONS`}
                                    </label>
                                </div>
                                }
                                <div style={{height:'2px', width:'80vw', marginTop:'40px', backgroundColor:`${colorPalette.steelBlue}aa`}}></div>

                                <h2
                                    style={{
                                    fontSize: "24px",
                                    fontWeight: "bold",
                                    textAlign: "center",
                                    marginTop: "32px",
                                    marginBottom:'8px',
                                    fontStyle: "italic",
                                    color: `${colorPalette.steelBlue}`,
                                    }}
                                >
                                    MEDICATION COMPLIANCE OVERVIEW
                                </h2>
                                <h2
                                    style={{
                                    fontSize: "1em",
                                    textAlign: "center",
                                    marginTop: "0px",
                                    marginBottom:'32px',
                                    maxWidth:'70vw',
                                    fontStyle: "italic",
                                    color: `${colorPalette.steelBlue}`,
                                    }}
                                >
                                    {`Described below is how compliant your current plan (${currentUser.currentVitovaPlan.title.toUpperCase()}) is with the medications that you take. This score is calculated from a multitude of health constraints outlined by our medical and exercise professionals.`}
                                </h2>
                                {currentUser.info.medications.filter(m=>m.checked).length>0
                                ?
                                <div
                                style={{
                                    display: 'grid',
                                    gridTemplateColumns: `repeat(${currentUser.info.medications.filter(m => m.checked).length > 3 ? 3 : currentUser.info.medications.filter(m => m.checked).length}, 1fr)`,
                                    width: '100%',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: '2em',
                                }}
                                >
                                {currentUser.info.medications.filter(m => m.checked).map((medication: CheckedMedication) => {
                                    const matchedMedication = medications.find(
                                    m => m.name.toLowerCase() === medication.medication.name.toLowerCase()
                                    );

                                    const complianceScore = matchedMedication
                                    ? getPlanToMedicationCompatibility(currentUser.currentVitovaPlan, matchedMedication).complianceScore
                                    : 100; // or some default value

                                    const getComplianceColor = (score: number) => {
                                    if (score >= 80) {
                                        return colorPalette.rpeGreen;
                                    }
                                    if (score >= 50) {
                                        return colorPalette.rpeYellow;
                                    }
                                    return colorPalette.softRed;
                                    };

                                    const complianceColor = getComplianceColor(complianceScore);

                                    return (
                                    <div
                                        key={medication.medication.name}
                                        style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        backgroundColor: 'white',
                                        padding: '2em',
                                        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                                        borderRadius: '8px',
                                        width: '100%',
                                        overflowX: 'hidden',
                                        }}
                                    >
                                        <div
                                        style={{
                                            width: `150px`,
                                            height: `150px`,
                                            borderRadius: '50%',
                                            backgroundColor: `${complianceColor}99`,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            position: 'relative',
                                        }}
                                        >
                                        <div
                                            style={{
                                            width: `${complianceScore}%`,
                                            height: `${complianceScore}%`,
                                            borderRadius: '50%',
                                            backgroundColor: complianceColor,
                                            position: 'absolute',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            }}
                                        >
                                            <label
                                            style={{
                                                fontSize: '1.5vw',
                                                fontStyle: 'italic',
                                                fontWeight: 'bold',
                                                color: 'white',
                                            }}
                                            >
                                            {complianceScore}%
                                            </label>
                                        </div>
                                        </div>
                                        <label
                                        style={{
                                            fontSize: '1.2vw',
                                            fontWeight: 'bold',
                                            fontStyle: 'italic',
                                            color: colorPalette.steelBlue,
                                            textAlign: 'center',
                                            marginTop: '1em',
                                        }}
                                        >
                                        {`COMPLIANT WITH ${medication.medication.name.toUpperCase()}`}
                                        </label>
                                    </div>
                                    );
                                })}
                                </div>
                                :
                                <div style={{display:'flex', flexDirection:'column', width:'100%'}}>
                                    <label style={{fontSize:'1.2em', fontStyle:'italic', color:colorPalette.steelBlue}}>
                                        {`YOUR ACCOUNT IS NOT LINKED TO ANY MEDICATIONS`}
                                    </label>
                                </div>
                                }

                                </div>
                            </div>
                        }
                        {selectedTab === 'myprogress' &&
                            <div style={{width:'100%', maxWidth: '100%',fontFamily: 'Arial, sans-serif'}}>
                                <div
                                    style={{
                                        marginBottom: '20px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'flex-start',
                                    }}
                                    >
                                    <label
                                        style={{
                                        textAlign: 'left',
                                        fontWeight: 'bold',
                                        fontStyle: 'italic',
                                        fontSize: '1.5em',
                                        marginBottom: '10px',
                                        }}
                                    >
                                        {`YOUR PROGRESS - ${currentUser.currentVitovaPlan.title.toUpperCase()}`}
                                    </label>
                                    <label
                                        style={{
                                        textAlign: 'left',
                                        // marginBottom: '20px',
                                        fontStyle: 'italic',
                                        fontSize: '1em',
                                        color: '#555',
                                        }}
                                    >
                                        Track Your Pathway Progress Through Our Timeline Of Your Time With Us
                                    </label>
                                </div>
                                {/* <div style={{display:'flex', flex:1, width:'100%', height:'100%', justifyContent:'flex-start', alignItems:'flex-start'}}>
                                    <RadarGraph user={currentUser} title={`${currentUser.currentVitovaPlan.title}`} key={''}/>
                                </div> */}

                                <div style={{ maxWidth: "100%", margin: "0 auto",marginTop:0, paddingTop:0, padding: "12px", display:"flex", flexDirection:'column',alignItems:'center'}}>
                                <div style={{height:'2px', width:'100vw', backgroundColor:`${colorPalette.steelBlue}aa`}}></div>

                                <h2
                                    style={{
                                    fontSize: "24px",
                                    fontWeight: "bold",
                                    textAlign: "center",
                                    marginTop:'32px',
                                    fontStyle: "italic",
                                    color: colorPalette.steelBlue,
                                    }}
                                >
                                    YOUR JOURNEY WITH US
                                </h2>
                                <h2
                                    style={{
                                    fontSize: "1em",
                                    textAlign: "center",
                                    marginTop: "0px",
                                    marginBottom:'16px',
                                    maxWidth:'70vw',
                                    fontStyle: "italic",
                                    color: `${colorPalette.steelBlue}`,
                                    }}
                                >
                                    {`Below is your timeline for your journey with us at VITOVA. To ensure that you achieve the best possible health by the end of your plan, we recommend following our pathway as closely as possible.`}
                                </h2>
                                <div
                                    style={{
                                    position: "relative",
                                    display: "flex",
                                    marginBottom: "32px",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    overflowX: "auto",
                                    padding: "16px",
                                    gap: "32px",
                                    }}
                                >
                                    <div
                                    style={{
                                        position: "absolute",
                                        top: "50%",
                                        left: "0",
                                        width: "100%",
                                        height: "4px",
                                        backgroundColor: "#d1d5db",
                                    }}
                                    ></div>
                                    {getUserTimeline(currentUser)
                                    .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
                                    .map((event, index) => {
                                        const isPast = new Date(event.date) < new Date();
                                        const circleColor = isPast ? colorPalette.rpeGreen : colorPalette.softRed;
                                        
                                        return (
                                        <motion.div
                                            key={index}
                                            initial={{ opacity: 0, x: -50 }}
                                            animate={{ opacity: 1, x: 0 }}
                                            transition={{ duration: 0.5, delay: index * 0.3 }}
                                            style={{
                                            position: "relative",
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            }}
                                        >
                                            <FontAwesomeIcon
                                            icon={faCircle}
                                            style={{ color: circleColor, marginBottom: "0.75em" }}
                                            size="2x"
                                            />
                                            <div
                                            style={{
                                                backgroundColor: "white",
                                                padding: "2em",
                                                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                                                borderRadius: "8px",
                                                overflowX:'scroll',
                                                width: `calc(70vw/${getUserTimeline(currentUser).length})`,
                                                // height: "100%",
                                                height:'15em',
                                                textAlign: "center",
                                            }}
                                            >
                                            <h3 style={{ fontSize: "18px", fontWeight: "600" }}>
                                                {event.date.toDateString()}
                                            </h3>
                                            <p
                                                style={{
                                                color: colorPalette.steelBlue,
                                                fontWeight: "bold",
                                                fontStyle: "italic",
                                                fontSize: "1.5em",
                                                }}
                                            >
                                                {event.title.toUpperCase()}
                                            </p>
                                            <p style={{ color: "#6b7280", marginTop: "4px" }}>
                                                {event.description}
                                            </p>
                                            </div>
                                        </motion.div>
                                        );
                                    })}
                                </div>
                                <div style={{height:'2px', width:'80vw', backgroundColor:`${colorPalette.steelBlue}aa`}}></div>

                                <h2
                                    style={{
                                    fontSize: "24px",
                                    fontWeight: "bold",
                                    textAlign: "center",
                                    marginTop: "32px",
                                    marginBottom:'8px',
                                    fontStyle: "italic",
                                    color: `${colorPalette.steelBlue}`,
                                    }}
                                >
                                    CONDITION COMPLIANCE OVERVIEW
                                </h2>
                                <h2
                                    style={{
                                    fontSize: "1em",
                                    textAlign: "center",
                                    marginTop: "0px",
                                    marginBottom:'32px',
                                    maxWidth:'70vw',
                                    fontStyle: "italic",
                                    color: `${colorPalette.steelBlue}`,
                                    }}
                                >
                                    {`Described below is how compliant your current plan (${currentUser.currentVitovaPlan.title.toUpperCase()}) is with the conditions that you have. This score is calculated from a multitude of health constraints outlined by our medical and exercise professionals.`}
                                </h2>
                                {currentUser.info.conditions.filter(c=>c.checked).length>0
                                ?
                                <div
                                style={{
                                    display: 'grid',
                                    gridTemplateColumns: `repeat(${currentUser.info.conditions.filter(c => c.checked).length > 3 ? 3 : currentUser.info.conditions.filter(c => c.checked).length}, 1fr)`,
                                    width: '100%',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: '2em',
                                }}
                                >
                                {currentUser.info.conditions.filter(c => c.checked).map((condition: CheckedCondition) => {
                                    const matchedCondition = conditions.find(
                                    c => c.name.toLowerCase() === condition.condition.name.toLowerCase()
                                    );

                                    const complianceScore = matchedCondition
                                    ? getPlanToConditionCompatibility(currentUser.currentVitovaPlan, matchedCondition).complianceScore
                                    : 100; // or some default value

                                    const getComplianceColor = (score: number) => {
                                    if (score >= 80) {
                                        return colorPalette.rpeGreen;
                                    }
                                    if (score >= 50) {
                                        return colorPalette.rpeYellow;
                                    }
                                    return colorPalette.softRed;
                                    };

                                    const complianceColor = getComplianceColor(complianceScore);

                                    return (
                                    <div
                                        key={condition.condition.name}
                                        style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        backgroundColor: 'white',
                                        padding: '2em',
                                        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                                        borderRadius: '8px',
                                        width: '100%',
                                        overflowX: 'scroll',
                                        }}
                                    >
                                        <div
                                        style={{
                                            width: `150px`,
                                            height: `150px`,
                                            borderRadius: '50%',
                                            backgroundColor: `${complianceColor}99`,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            position: 'relative',
                                        }}
                                        >
                                        <div
                                            style={{
                                            width: `${complianceScore}%`,
                                            height: `${complianceScore}%`,
                                            borderRadius: '50%',
                                            backgroundColor: complianceColor,
                                            position: 'absolute',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            }}
                                        >
                                            <label
                                            style={{
                                                fontSize: '1.5vw',
                                                fontStyle: 'italic',
                                                fontWeight: 'bold',
                                                color: 'white',
                                            }}
                                            >
                                            {complianceScore}%
                                            </label>
                                        </div>
                                        </div>
                                        <label
                                        style={{
                                            fontSize: '1.2vw',
                                            fontWeight: 'bold',
                                            fontStyle: 'italic',
                                            color: colorPalette.steelBlue,
                                            textAlign: 'center',
                                            marginTop: '1em',
                                        }}
                                        >
                                        {`COMPLIANT WITH ${condition.condition.name.toUpperCase()}`}
                                        </label>
                                    </div>
                                    );
                                })}
                                </div>
                                :
                                <div style={{display:'flex', flexDirection:'column', width:'100%'}}>
                                    <label style={{fontSize:'1.2em', fontStyle:'italic', color:colorPalette.steelBlue}}>
                                        {`YOUR ACCOUNT IS NOT LINKED TO ANY CONDTIONS`}
                                    </label>
                                </div>
                                }
                                <div style={{height:'2px', width:'80vw', marginTop:'40px', backgroundColor:`${colorPalette.steelBlue}aa`}}></div>

                                <h2
                                    style={{
                                    fontSize: "24px",
                                    fontWeight: "bold",
                                    textAlign: "center",
                                    marginTop: "32px",
                                    marginBottom:'8px',
                                    fontStyle: "italic",
                                    color: `${colorPalette.steelBlue}`,
                                    }}
                                >
                                    MEDICATION COMPLIANCE OVERVIEW
                                </h2>
                                <h2
                                    style={{
                                    fontSize: "1em",
                                    textAlign: "center",
                                    marginTop: "0px",
                                    marginBottom:'32px',
                                    maxWidth:'70vw',
                                    fontStyle: "italic",
                                    color: `${colorPalette.steelBlue}`,
                                    }}
                                >
                                    {`Described below is how compliant your current plan (${currentUser.currentVitovaPlan.title.toUpperCase()}) is with the medications that you take. This score is calculated from a multitude of health constraints outlined by our medical and exercise professionals.`}
                                </h2>
                                {currentUser.info.medications.filter(m=>m.checked).length>0
                                ?
                                <div
                                style={{
                                    display: 'grid',
                                    gridTemplateColumns: `repeat(${currentUser.info.medications.filter(m => m.checked).length > 3 ? 3 : currentUser.info.medications.filter(m => m.checked).length}, 1fr)`,
                                    width: '100%',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: '2em',
                                }}
                                >
                                {currentUser.info.medications.filter(m => m.checked).map((medication: CheckedMedication) => {
                                    const matchedMedication = medications.find(
                                    m => m.name.toLowerCase() === medication.medication.name.toLowerCase()
                                    );

                                    const complianceScore = matchedMedication
                                    ? getPlanToMedicationCompatibility(currentUser.currentVitovaPlan, matchedMedication).complianceScore
                                    : 100; // or some default value

                                    const getComplianceColor = (score: number) => {
                                    if (score >= 80) {
                                        return colorPalette.rpeGreen;
                                    }
                                    if (score >= 50) {
                                        return colorPalette.rpeYellow;
                                    }
                                    return colorPalette.softRed;
                                    };

                                    const complianceColor = getComplianceColor(complianceScore);

                                    return (
                                    <div
                                        key={medication.medication.name}
                                        style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        backgroundColor: 'white',
                                        padding: '2em',
                                        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                                        borderRadius: '8px',
                                        width: '100%',
                                        overflowX: 'hidden',
                                        }}
                                    >
                                        <div
                                        style={{
                                            width: `150px`,
                                            height: `150px`,
                                            borderRadius: '50%',
                                            backgroundColor: `${complianceColor}99`,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            position: 'relative',
                                        }}
                                        >
                                        <div
                                            style={{
                                            width: `${complianceScore}%`,
                                            height: `${complianceScore}%`,
                                            borderRadius: '50%',
                                            backgroundColor: complianceColor,
                                            position: 'absolute',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            }}
                                        >
                                            <label
                                            style={{
                                                fontSize: '1.5vw',
                                                fontStyle: 'italic',
                                                fontWeight: 'bold',
                                                color: 'white',
                                            }}
                                            >
                                            {complianceScore}%
                                            </label>
                                        </div>
                                        </div>
                                        <label
                                        style={{
                                            fontSize: '1.2vw',
                                            fontWeight: 'bold',
                                            fontStyle: 'italic',
                                            color: colorPalette.steelBlue,
                                            textAlign: 'center',
                                            marginTop: '1em',
                                        }}
                                        >
                                        {`COMPLIANT WITH ${medication.medication.name.toUpperCase()}`}
                                        </label>
                                    </div>
                                    );
                                })}
                                </div>
                                :
                                <div style={{display:'flex', flexDirection:'column', width:'100%'}}>
                                    <label style={{fontSize:'1.2em', fontStyle:'italic', color:colorPalette.steelBlue}}>
                                        {`YOUR ACCOUNT IS NOT LINKED TO ANY MEDICATIONS`}
                                    </label>
                                </div>
                                }

                                </div>
                            </div>
                        }
                    </div>

            </div>
        );
        }else{
            return(
                <div className="dashboard-page">
                    <div className="main-content">
                        <Spinner text="Adapting Your Plan!"/>
                    </div>
                </div>
            )

        }
    }else{
        return (
            <div className="dashboard-page">
                <div className="main-content">
                    <h1 style={{fontSize:'1.5em', marginBottom:'20px', fontWeight:'bold', color:'#454545'}}>You haven't set a plan yet</h1>
                    <Link to={'/plans'} className="review-button" style={{fontSize:'1.5em', width:'auto'}}>Go To Plans</Link>
                </div>
            </div>
        )
    }

}

export default VitovaDashboardPageLoggedInView;