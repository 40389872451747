'use client'
import { Component } from "react";
import AnimatedBarChart from "./AnimatedBarChart";
import { Chart, registerables } from 'chart.js';
import { User } from "../models/user";
import colorPalette from "../utils/colors";

Chart.register(...registerables);

interface ChartProps {
  plannedData: number[];
  realData: number[];
  labelData: string[];
  user: User;
  mh?: number;
  mw?: number;
  bgc?: string;
}

interface ChartState {
  data: {
    labels: string[];
    datasets: {
      label?: string;
      data: number[];
      backgroundColor?: string;
      borderColor?: string;
      borderWidth?: number;
      type?: string;
      fill?: boolean;
      borderDash?: number[];
      barPercentage?: number;
      categoryPercentage?: number;
    }[];
  };
  options: any;
}

export default class ComparisonChartComponent extends Component<ChartProps, ChartState> {
  constructor(props: ChartProps) {
    super(props);

    this.state = {
      data: {
        labels: [],
        datasets: []
      },
      options: {
        animation: {
          duration: 200,
        },
        scales: {
          y: {
            type: 'linear',
            beginAtZero: false,
            stacked: false, // Ensure bars are not stacked
            ticks: {
              display: true,
            },
            grid: {
              display: false,
            },
          },
          x: {
            stacked: true,
            ticks: {
              display: true,
            },
            grid: {
              display: false,
            },
          },
        },
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
          legend: {
            display: true,
            position: 'top',
          },
        },
        layout: {
          padding: {
            top: 10,
            bottom: 10,
            left: 10,
            right: 10,
          },
        },
      },
    };
  }

  componentDidMount() {
    this.updateChartData();
  }

  componentDidUpdate(prevProps: ChartProps) {
    if (prevProps.plannedData !== this.props.plannedData || prevProps.realData !== this.props.realData) {
      this.updateChartData();
    }
  }


  updateChartData() {
    const { labelData, plannedData, realData } = this.props;
    // green = 80-100%, 
    // yellow = 60-80%, 
    // orange = 40-60%, 
    // red = <40%.
    const progressColors: string[] = realData.map((value, i) => {
        if (plannedData[i] === 0) return colorPalette.rpeGray; // Avoid division by zero
        const percentage = (value / plannedData[i]) * 100;
      
        if (percentage >= 80) return colorPalette.rpeGreen; // Fully achieved or exceeded
        if (percentage >= 60) return colorPalette.rpeYellow; // Medium progress
        if (percentage >= 40) return colorPalette.orange; // Low progress
        return colorPalette.rpeRed; // Below 40%
      });

    this.setState({
      data: {
        labels: labelData,
        datasets: [
            {
              label: "Planned ISS",
              data: plannedData,
              backgroundColor: `${colorPalette.steelBlue}33`, // Light transparent blue
              borderColor: 'steelblue',
              borderWidth: 2,
              barPercentage: 0.9,
              categoryPercentage: 0.85,
            },
            {
              label: "Your Progress",
              data: realData,
              backgroundColor: progressColors as unknown as string, // Dynamic colors per bar
              borderColor: progressColors as unknown as string,
              borderWidth: 2,
              barPercentage: 0.9,
              categoryPercentage: 0.7,
            },
          ],
      },
      options: {
        animation: {
          duration: 200,
        },
        scales: {
          y: {
            beginAtZero: false,
            stacked: false,
            ticks: {
              display: true,
            },
            grid: {
              display: false,
            },
          },
          x: {
            stacked: true, // Ensures both bars overlap instead of side by side
            ticks: {
              display: true,
            },
            grid: {
              display: false,
            },
          },
        },
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
          legend: {
            display: true,
            position: 'top',
          },
        },
        layout: {
          padding: {
            top: 10,
            bottom: 10,
            left: 10,
            right: 10,
          },
        },
      },
    });
}

  render() {
    return (
      <div style={{ backgroundColor: this.props.bgc, width: this.props.mw ? `${this.props.mw}%` : '100%', height: this.props.mh ? `${this.props.mh}%` : '40%', maxWidth: `${this.props.mw}%`, maxHeight: `${this.props.mh}%`, display: 'flex', marginTop: '3vh' }}>
        <AnimatedBarChart data={this.state.data} options={this.state.options} />
      </div>
    );
  }
}
