import { DBSideEffect } from "./sideEffect";

export enum MedicationClass {
    STATIN = 'statin',
    GLP1_AGONIST = 'glp1_agonist',
    SGLT2_INHIBITOR = 'sglt2_inhibitor',
    ACE_INHIBITOR = 'ace_inhibitor',
    ARB = 'arb',
    ALDOSTERONE_ANTAGONIST = 'aldosterone_antagonist',
    B_BLOCKER = 'b_blocker',
    A_BLOCKER = 'a_blocker',
    B_AGONIST = 'b_agonist',
    SSRI = 'ssri',
    SNRI = 'snri',
    TCA = 'tca',
    BENZODIAZEPINE = 'benzodiazepine',
    UNKNOWN = 'unknown',
    OPIOID = 'opioid',
    NSAID = 'nsaid',
    DMARD = 'dmard',
    STEROID = 'steroid',
    ANTI_MALARIAL = 'anti_malarial',
}

export interface DBMedication {
    _id: string;
    name: string;
    medId: string;
    verified?:boolean,
    medClass?:MedicationClass,
    sideEffects?:Array<DBSideEffect>,
    MOA?:string,
}