import React from "react";
import { Tooltip, Box, Typography } from "@mui/material";
import { VitovaActivityStep } from "../models/vitovaPlan";
import { getDurationFromDistanceAndUserZones } from "../utils/StressLoadFunctions";
import { User } from "../models/user";
import { getIntensityColour, getRpeColourFromStep } from "../utils/TSS";
import { Exercise } from "../models/exercise";
import { getExerciseById } from "../utils/Exercises";

const WorkoutStepsDiagram: React.FC<{bg?:string,floor?:boolean, user: User; steps: VitovaActivityStep[], exercises:Array<Exercise>}> = ({bg,floor,user, steps, exercises}) => {
    return (
<Box
    sx={{
        display: "flex",
        alignItems: "flex-end", // Align items to the bottom
        justifyContent: "center",
        backgroundColor: bg ? bg : "#f5f5f5",
        paddingLeft: 2,
        paddingRight: 2,
        paddingBottom: floor ? 0 : 2,
        paddingTop: 2,
        borderRadius: 2,
        overflowX: "auto",
        width: "100%",
    }}
>
    {(() => {
        // Compute total duration of all steps
        const totalDuration = steps
            .filter(step => step.cardio && typeof step.cardio === "object")
            .reduce((sum, step) => {
                const cardio = step.cardio;
                if (!cardio) return sum; // Ensure cardio is defined

                // Extract necessary properties safely
                const { duration, distance, rpe, zone} = cardio;

                const exercise = cardio.exercise ? cardio.exercise : getExerciseById(exercises, cardio.exerciseId);

                let computedDuration = 0;
                if (duration){
                    computedDuration = duration;
                }else if (distance && zone && exercise){
                    computedDuration = getDurationFromDistanceAndUserZones(user, distance, zone*2, exercise.name)
                }
                else if (distance && rpe && exercise){
                    computedDuration = getDurationFromDistanceAndUserZones(user, distance, rpe, exercise.name)
                }

                return sum + Number(computedDuration);
            }, 0);

        return steps
            .filter(step => step.cardio && typeof step.cardio === "object")
            .map((step, index) => {
                const { cardio } = step;
                const rpe = cardio?.rpe; // Default RPE to 1
                const zone = cardio?.zone;
                const duration = cardio?.duration
                const exercise = cardio?.exercise ? cardio?.exercise : getExerciseById(exercises, cardio?.exerciseId)
                const distance = cardio?.distance

                let computedDuration = 0;
                if (duration){
                    computedDuration = duration;
                }else if (distance && zone && exercise){
                    computedDuration = getDurationFromDistanceAndUserZones(user, distance, zone*2, exercise.name)
                }
                else if (distance && rpe && exercise){
                    computedDuration = getDurationFromDistanceAndUserZones(user, distance, rpe, exercise.name)
                }
                
                // Calculate width percentage
                const widthPercentage = totalDuration > 0 ? (computedDuration / totalDuration) * 100 : 0;

                return (
                    <Tooltip
                        key={index}
                        title={
                            <Box sx={{ p: 1 }}>
                                <Typography sx={{ fontWeight: "bold" }}>{step.label || "Unnamed Step"}</Typography>
                                <Typography variant="body2">
                                    {cardio?.duration ? `${cardio.duration} min` : cardio?.distance ? `${cardio.distance} km` : "Error"} @ {zone? `Zone ${zone}` : `${rpe} / 10 Effort`}
                                </Typography>
                            </Box>
                        }
                        arrow
                    >
                        <Box
                            sx={{
                                height: `${20 + (zone ? zone ** 3 : rpe ? rpe**2.25 : 1) * 2}px`, // Adjust height dynamically based on RPE
                                width: `${widthPercentage}%`, // Make width relative to total duration
                                backgroundColor: getRpeColourFromStep(step),
                                marginRight: "2px",
                                borderRadius: "4px 4px 0px 0px",
                                alignSelf: "flex-end", // Align at bottom
                            }}
                        />
                    </Tooltip>
                );
            });
    })()}
</Box>

    );
};
export default WorkoutStepsDiagram
