import React, { useState } from 'react';
import '../styles/BlogPage.css';
import { Article } from '../models/article';
import colorPalette from '../utils/colors';
import ArticleComponent from '../components/ArticleComponent';

// Updated PDF path from public directory
const blog1 = '/assets/blog1.pdf'; // Use correct public path after moving the file

const BlogPage: React.FC = () => {
    const [articles, setArticles] = useState<Array<Article>>([
        {
            _id: '0',
            title: 'Welcome To Vitova!',
            description: 'Introducing Vitova and the team behind it!',
            author: 'Jake Keast',
            date: new Date(),
            file: blog1,  // Direct path to the PDF in the public folder
        },
    ]);

    const [selectedArticle, setSelectedArticle] = useState<Article | null>(articles[0]);

    // Handle selecting an article
    const handleArticleClick = (article: Article) => {
        setSelectedArticle(article);
    };

    // Render the blog grid (default view)
    const renderBlogGrid = () => (
        <div style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
            gap: '20px',
            padding: '20px',
            overflowY: 'auto',
            height: articles.length > 1 ? '80vh' : '40vh',
            width: selectedArticle ? '20%' : '100%',  // Adjust grid width when article is selected
            transition: 'width 0.3s ease',
        }}>
            <h2 style={{ fontSize: '2em', fontWeight: 'bold', fontStyle: 'italic', textAlign: 'center', color: colorPalette.steelBlue }}>Read More</h2>

            {articles.map(article => (
                <div key={article._id} style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    padding: '20px',
                    border: '1px solid #ddd',
                    borderRadius: '5px',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    backgroundColor: '#fff',
                    cursor: 'pointer',
                    transition: 'transform 0.2s ease',
                }} onClick={() => handleArticleClick(article)}>
                    <h2 style={{ fontSize: '2em', fontWeight: 'bold', fontStyle: 'italic', color: colorPalette.steelBlue }}>{article.title}</h2>
                    <p>{article.description}</p>
                    <small style={{ color: '#555' }}>By {article.author} on {article.date.toDateString()}</small>
                </div>
            ))}
        </div>
    );

    // Render the article view with PDF on the same page using <embed>
    // const renderArticleView = (article: Article) => (
    //     <div style={{
    //         display: 'flex',
    //         flexDirection: 'column',
    //         justifyContent: 'flex-start',
    //         padding: '20px',
    //         height: '80vh',
    //         width: '80%', 
    //         transition: 'width 0.3s ease',
    //         overflowY: 'auto',
    //         backgroundColor: '#f7f7f7',
    //         borderLeft: '2px solid #ddd',
    //     }}>
    //         <small style={{ marginLeft: '80%' }}>By {article.author} on {article.date.toDateString()}</small>
    //         <div style={{ marginTop: '20px', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    //             {article.file ? (
    //                 <embed
    //                     key={article._id}  // Force re-rendering
    //                     src={`${article.file}?t=${Date.now()}`}  // Cache-busting query parameter
    //                     type="application/pdf"
    //                     width="100%"
    //                     height="100%"
    //                 />
    //             ) : (
    //                 <p>No PDF available for this article.</p>
    //             )}
    //         </div>
    //     </div>
    // );
    const renderArticleView = (article: Article) => (
        <ArticleComponent
            title="Welcome to VITOVA"
            author={article.author}
            date={article.date}
            content={[
                "Hello! Thanks for coming to take a look at VITOVA. We’re happy to introduce ourselves and tell you a little bit more about who we are and what we aim to do.",
    
                { subheading: "Who are we?" },
                "VITOVA is a bit of a cliché name coming from the Latin 'Vita Nova' – new life. It’s a simple play at the fact that you really can take back control of your life. Having lived experience on both the patient and professional sides of exercise prescription and the conditions we cater for, following informed exercise plans really can help to improve the quality of life and medical control of many conditions as outlined below.",
                "Our team has started small but is expanding. Jake is a final year medical student balancing his studies with both elite sports himself and many years of coaching experience. Jake’s endurance sports performance coaching business has seen him contribute to the success of professional athletes, world record holders, age-group world champions, and the journey of all age groups or demographics from around the world to their first start line or fitness goal. Jake is also a qualified GP Referral Instructor and holds a Level 4 diploma in Obesity and Weight Management.",
                "Nick, meanwhile, is the brains behind the machine. His expertise is pushing the boundaries of what both healthcare and performance sport software integration has done before. With some rather large tasks underway, it’s exciting to hear his opinion and share the developments as they come.",
    
                { subheading: "What do we do?" },
                "We aim to be the number one place to lead a healthy, active lifestyle. Reducing the barriers to getting your health back under control and using the most informed methods we can to take you there both safely and effectively. No plan is without risk but having insight into how we can optimize your training means that you can have reassurance that things are going in the right direction.",
                "We currently cater for a range of chronic conditions or situations and are constantly expanding our expertise. We offer more in-depth coaching and 1-1 support if wanted and have several exciting developments underway to make your training as adaptive and optimized as possible.",
    
                { subheading: "Why...?" },
                "When you first get diagnosed with one of the above conditions or even start exercising again after some time out, it’s a daunting process. You might have just walked out of a consultation with your GP and don’t quite know where to start. We help inform professionals, give you clarity in the process, and work to current exercise referral guidelines and up-to-date evidence on how to reach your goals most effectively.",
                "As things develop, we’ll help with sleep, nutrition, mindset, and more. But for now, we want to ensure we get the basics right.",
            ]}
        />
    );
    
    

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            height: '80vh',
            overflow: 'hidden',
        }}>
            {selectedArticle && renderArticleView(selectedArticle)}

            {/* Blog Grid (left side) */}
            {renderBlogGrid()}
        </div>
    );
}

export default BlogPage;
