import React from 'react';
import '../styles/Spinner.css'
import { Spinner } from 'react-bootstrap';


const SpinnerIcon: React.FC = () => {
    return (
        <div className="spinner-container" style={{display:'flex', justifyContent:'center', alignItems:'center', marginRight:'20px'}}>
            <Spinner/>
        </div>
    );
};

export default SpinnerIcon;
