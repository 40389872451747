import * as Api from "../network/api";
import { User } from '../models/user';
import '../styles/DatabasePage.css';
import { useEffect, useState } from "react";
import Spinner from "./Spinner";
import BodyHighlighterDiagram from "./BodyHighlighter";
import { Button } from "react-bootstrap";
import { IExerciseData } from "react-body-highlighter";
import localdataexercises from '../localdata/exercises'
import Fuse from 'fuse.js';
import { ObjectId } from 'bson'; // Import ObjectId

import colorPalette from "../utils/colors";
import { DBMedication, MedicationClass } from "../models/medication";
import { DBCondition } from "../models/condition";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDumbbell, faHeartPulse, faMinusCircle, faPerson, faPills, faSkull, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import * as XLSX from "xlsx";
import { PlanCondition } from "../models/plan";
import { Exercise, VitovaExerciseCategory, VitovaExerciseEquipmentNeeded, VitovaExerciseForceType, VitovaExerciseLevel, VitovaExerciseMechanic } from "../models/exercise";
import { VitovaStepType } from "../models/vitovaPlan";
import { Checkbox, Chip, FormControl, FormControlLabel, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material";
import ConditionsTable from "./ConditionsTable";
import { EMPTY_EXERCISE } from "./templateObjects/ObjectTemplates";
import { DBMuscle } from "../models/muscle";
import { DBSideEffect } from "../models/sideEffect";
interface DatabasePageLoggedInProps {
    user: User,
    onLogoutSuccessful: () => void,
    onPlanUpdated: (user: User) => void
}

interface Requirement {
    name:string,
    description:string,
    id:string,
}

interface Symptom {
    label:string,
    description:string,
    id:string,
}

const DatabasePageLoggedInView = ({ user, onLogoutSuccessful, onPlanUpdated }: DatabasePageLoggedInProps) => {
    const [loading, setLoading] = useState(true);
    const [filteredExercises, setFilteredExercises] = useState<Exercise[]>([]);
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [forceFilter, setForceFilter] = useState<string>("");
    const [levelFilter, setLevelFilter] = useState<string>("");
    const [musclesFilter, setMusclesFilter] = useState<string>("");
    const [conditionFilter, setConditionFilter] = useState<string>("");

    const [filteredMedications, setFilteredMedications] = useState<DBMedication[]>([]);
    const [filteredMuscles, setFilteredMuscles] = useState<DBMuscle[]>([]);
    const [filteredSideEffects, setFilteredSideEffects] = useState<DBSideEffect[]>([]);

    const [filteredConditions, setFilteredConditions] = useState<DBCondition[]>([]);

    const [exercises, setExercises] = useState<Exercise[]>([]);
    const [medications, setMedications] = useState<DBMedication[]>([]);
    const [conditions, setConditions] = useState<DBCondition[]>([]);

    const [muscles, setMuscles] = useState<DBMuscle[]>([]);
    const [sideEffects, setSideEffects] = useState<DBSideEffect[]>([]);

    const [showPopup, setShowPopup] = useState(false);

    const [selectedExerciseSymptom, setSelectedExerciseSymptom] = useState<Symptom>({id:'', label:'', description:''});
    const [selectedSymptom, setSelectedSymptom] = useState<Symptom>({id:'', label:'', description:''});
    const [selectedMedication, setSelectedMedication] = useState<DBMedication>({medId:'',name:'',_id:''});
    const [selectedMuscle, setSelectedMuscle] = useState<DBMuscle>({id:'',name:'',_id:''});
    const [selectedSideEffect, setSelectedSideEffect] = useState<DBSideEffect>({_id:'',id:'',name:'',description:'',exerciseConstraints:[], verified:true});

    const [selectedTab, setSelectedTab] = useState('exercises');

    const [isEditing, setIsEditing] = useState(false); // Flag to check if editing
    const [editIndex, setEditIndex] = useState<number | null>(null); // Store index of the exercise being edited
    const [currentStep, setCurrentStep] = useState(0); // Track current step in multi-step process
    const [newExercise, setNewExercise] = useState<Exercise>({...EMPTY_EXERCISE});
    const [newMedication, setNewMedication] = useState<DBMedication>({
        _id:'',
        medId:'',
        name:''
    });
    const [newMuscle, setNewMuscle] = useState<DBMuscle>({
        _id:'',
        id:'',
        name:''
    });
    const [newSideEffect, setNewSideEffect] = useState<DBSideEffect>({
        _id:'',
        id:'',
        name:'',
        
    });
    const [newCondition, setNewCondition] = useState<DBCondition>({
        _id:'',
        id:'',
        name:'',
        description:'',
        symptoms:[],
        commonMedications:[],
        cardioConstraints:{
            rpeMin:1,
            rpeMax:5,
            durationMin:30,
            durationMax:60,
            weeklyFreqMax:3,
            weeklyFreqMin:2
        },
        resistanceConstraints:{
            rpeMin:1,
            rpeMax:5,
            percentOf10RepMaxMin:40,
            percentOf10RepMaxMax:70,
            durationMin:30,
            durationMax:60,
            weeklyFreqMax:3,
            weeklyFreqMin:2,
            setsMin:1,
            setsMax:3,
            repsMin:10,
            repsMax:15,
            weeklyExercisesMin:5,
            weeklyExercisesMax:12,
        },
        flexibilityConstraints:{
            rpeMin:1,
            rpeMax:5,
            durationMin:30,
            durationMax:60,
            weeklyFreqMax:3,
            weeklyFreqMin:2
        }
    });

    interface MedicationRow {
        "ID": string;
        "Medication": string;
        "MedicationClass": string;
        "MOA": string;
        "SideEffects": string;
      }
      
    interface ConditionRow {
        "Condition ID": string;
        "Condition Name": string;
        "Description": string;
        Symptoms: string;
        Medications: string;
        "Cardio RPE Min": number;
        "Cardio RPE Max": number;
        "Cardio Weekly Freq Min": number;
        "Cardio Weekly Freq Max": number;
        "Cardio Duration Min": number;
        "Cardio Duration Max": number;
        "Resistance RPE Min": number;
        "Resistance RPE Max": number;
        "Resistance %10RM Min": number;
        "Resistance %10RM Max": number;
        "Resistance Weekly Freq Min": number;
        "Resistance Weekly Freq Max": number;
        "Resistance Duration Min": number;
        "Resistance Duration Max": number;
        "Resistance Sets Min": number;
        "Resistance Sets Max": number;
        "Resistance Reps Min": number;
        "Resistance Reps Max": number;
        "Resistance Weekly Exercises Min": number;
        "Resistance Weekly Exercises Max": number;
        "Flexibility RPE Min": number;
        "Flexibility RPE Max": number;
        "Flexibility Weekly Freq Min": number;
        "Flexibility Weekly Freq Max": number;
        "Flexibility Duration Min": number;
        "Flexibility Duration Max": number;
      }
    const [data, setData] = useState<DBCondition[]>([]);

    const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (!file) return;
  
      const reader = new FileReader();
      reader.onload = async (e) => {
        if (!e.target || !e.target.result) return;
  
        const data = new Uint8Array(e.target.result as ArrayBuffer);
        const workbook = XLSX.read(data, { type: "array" });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData: ConditionRow[] = XLSX.utils.sheet_to_json(worksheet);
  
        const formattedData: DBCondition[] = jsonData.map((row) => ({
            _id:'',
            id: row["Condition ID"],
            name: row["Condition Name"],
            description: row["Description"],
            symptoms: [],
            commonMedications: [],
            cardioConstraints: {
              rpeMin: row["Cardio RPE Min"],
              rpeMax: row["Cardio RPE Max"],
              weeklyFreqMin: row["Cardio Weekly Freq Min"],
              weeklyFreqMax: row["Cardio Weekly Freq Max"],
              durationMin: row["Cardio Duration Min"],
              durationMax: row["Cardio Duration Max"],
            },
            resistanceConstraints: {
              rpeMin: row["Resistance RPE Min"],
              rpeMax: row["Resistance RPE Max"],
              percentOf10RepMaxMin:row['Resistance %10RM Min'],
              percentOf10RepMaxMax:row['Resistance %10RM Max'],
              weeklyFreqMin: row["Resistance Weekly Freq Min"],
              weeklyFreqMax: row["Resistance Weekly Freq Max"],
              durationMin: row["Resistance Duration Min"],
              durationMax: row["Resistance Duration Max"],
              setsMin: row["Resistance Sets Min"],
              setsMax: row["Resistance Sets Max"],
              repsMin: row["Resistance Reps Min"],
              repsMax: row["Resistance Reps Max"],
              weeklyExercisesMin: row["Resistance Weekly Exercises Min"],
              weeklyExercisesMax: row["Resistance Weekly Exercises Max"],
            },
            flexibilityConstraints: {
              rpeMin: row["Flexibility RPE Min"],
              rpeMax: row["Flexibility RPE Max"],
              weeklyFreqMin: row["Flexibility Weekly Freq Min"],
              weeklyFreqMax: row["Flexibility Weekly Freq Max"],
              durationMin: row["Flexibility Duration Min"],
              durationMax: row["Flexibility Duration Max"],
            },
          }));
          for (const condition of formattedData) {
            console.log('Uploading condition:', condition);
            const response = await Api.createCondition(condition);
            console.log('Upload response:', response);
          }
          
          
      };
      reader.readAsArrayBuffer(file);
    };

    const handleMedicationFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const medicationEnumMap = {
            "Statin": "statin",
            "GLP1 agonist": "glp1_agonist",
            "SGLT2 inhibitor": "sglt2_inhibitor",
            "ACE Inhibitor": "ace_inhibitor",
            "ARB": "arb",
            "Aldosterone Antagonist": "aldosterone_antagonist",
            "B-blocker": "b_blocker",
            "a-blocker": "a_blocker",
            "B-agonist": "b_agonist",
            "SSRI": "ssri",
            "SNRI": "snri",
            "TCA": "tca",
            "Benzodiazepine": "benzodiazepine",
            "Unknown": "unknown",
            "Opioid": "opioid",
            "NSAID": "nsaid",
            "DMARD": "dmard",
            "Steroid": "steroid",
            "Anti-malarial": "anti_malarial"
          };
        const file = event.target.files?.[0];
        if (!file) return;
    
        const reader = new FileReader();
        reader.onload = async (e) => {
          if (!e.target || !e.target.result) return;
    
          const data = new Uint8Array(e.target.result as ArrayBuffer);
          const workbook = XLSX.read(data, { type: "array" });
          const worksheet = workbook.Sheets[workbook.SheetNames[0]];
          const jsonData: MedicationRow[] = XLSX.utils.sheet_to_json(worksheet);
    
          const formattedData: DBMedication[] = jsonData.map((row) => ({
            _id:new ObjectId().toHexString(),
            medId: row["ID"],
            name: row["Medication"],
            medClass: medicationEnumMap[row.MedicationClass as keyof typeof medicationEnumMap] as MedicationClass || MedicationClass.UNKNOWN,
            sideEffects:[],
            MOA:'',
            verified:true
              
            }));
            for (const medication of formattedData) {
              console.log('Uploading medication:', medication);
              const response = await Api.createMedication(medication);
              console.log('Upload response:', response);
            }
            
            
        };
        reader.readAsArrayBuffer(file);
      };

      interface ExerciseRow {
        "Exercise Name": string;
        "Reference Code":string;
        "Photo":string;	
        "Youtube Video":string;	
        "Description":string;
        "Functional Group":string;
        "Muscle Groups":string;
        "Joints": string; // Expected to be a comma-separated string
        "Home Suitable": number;
        "Barbell": number;
        "Dumbbell": number;
        "Bands": number;
        "Kettlebell": number;
        "Machine": number;
        "Isolation": number;
        "Compound": number;
        "Home/Gym Swap": string;
        "Overhead": number;
        "Floor": number;
        "Isometric": number;
        "Axial Load": number;
        "Abdominal Strain": number;
        "High Impact": number;
        "Dizziness Risk": number;
      }
      
      const handleExerciseFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (!file) return;
    
        const reader = new FileReader();
        reader.onload = async (e) => {
          if (!e.target || !e.target.result) return;
    
          const data = new Uint8Array(e.target.result as ArrayBuffer);
          const workbook = XLSX.read(data, { type: "array" });
          const worksheet = workbook.Sheets[workbook.SheetNames[0]];
          const jsonData: ExerciseRow[] = XLSX.utils.sheet_to_json(worksheet);
    
          const formattedData: Exercise[] = jsonData.map((row) => ({
            _id:new ObjectId().toHexString(),
            id: '',
            name: row['Exercise Name'],
            stepType: VitovaStepType.STRENGTH,
            force: VitovaExerciseForceType.STATIC,
            level: VitovaExerciseLevel.BEGINNER,
            mechanic: VitovaExerciseMechanic.COMPOUND,
            equipment: VitovaExerciseEquipmentNeeded.GYM,
            primaryMuscles: [],
            secondaryMuscles: [],
            instructions: [],
            category: VitovaExerciseCategory.STRENGTH,
            images: [],
            video: '',
            joints: [],
            homeSuitable: row['Home Suitable'] === 1,
            barbell: row['Barbell'] === 1,
            dumbbell: row['Dumbbell'] === 1,
            bands: row['Bands'] === 1,
            kettlebell: row['Kettlebell'] === 1,
            machine: row['Machine'] === 1,
            progression: [],
            regression: [],
            isolation: row['Isolation'] === 1,
            compound: row['Compound'] === 1,
            homeGymSwap: [],
            overhead: row['Overhead'] === 1,
            floor: row['Floor'] === 1,
            isometric: row['Isometric'] === 1,
            axialLoad: row['Axial Load'] === 1,
            abdominalStrain: row['Abdominal Strain'] === 1,
            highImpact: row['High Impact'] === 1
              
            }));
            for (const exercise of formattedData) {
                console.log(exercise)
              console.log('Uploading medication:', exercise);
              const response = await handleSaveExercise(exercise);
              console.log('Upload response:', response);
            }
            
            
        };
        reader.readAsArrayBuffer(file);
      };

    const [selectedMuscles, setSelectedMuscles] = useState<IExerciseData[]>([{muscles:['abductors'], name:''}]);

    const symptomOptions = [
        // Obesity-related symptoms
        { id: 's_0', label: 'Shortness of Breath', description: 'Difficulty breathing during physical activity, limiting endurance and aerobic capacity.' },
        { id: 's_1', label: 'Joint Pain', description: 'Pain in knees, hips, or lower back due to excess weight, making weight-bearing exercises difficult.' },
        { id: 's_2', label: 'Limited Range of Motion', description: 'Restricted movement in joints like the hips, knees, or shoulders, affecting flexibility exercises.' },
        { id: 's_3', label: 'Fatigue', description: 'General lack of energy, reducing stamina and ability to sustain workouts.' },
        { id: 's_4', label: 'Difficulty with Balance', description: 'Reduced stability during exercises requiring coordination, especially balance or agility exercises.' },
        
        // Diabetes-related symptoms
        { id: 's_5', label: 'Numbness or Tingling in Feet', description: 'Neuropathy causing loss of sensation in feet, increasing the risk of injury during exercises involving standing or running.' },
        { id: 's_6', label: 'Vision Problems', description: 'Blurred vision or eye strain, which may affect exercises that require precise coordination or balance.' },
        { id: 's_7', label: 'Slow Healing Sores', description: 'Increased risk of foot injuries, requiring caution during weight-bearing or high-impact exercises.' },
        { id: 's_8', label: 'Muscle Weakness', description: 'Reduced strength in muscles, limiting performance in resistance training or exercises requiring power.' },
        
        // Hypertension-related symptoms
        { id: 's_9', label: 'Dizziness', description: 'Lightheadedness during physical activity, particularly with quick movements or changes in position, affecting aerobic or balance exercises.' },
        { id: 's_10', label: 'Shortness of Breath', description: 'Difficulty breathing during intense physical activities, limiting aerobic capacity.' },
        { id: 's_11', label: 'Chest Pain', description: 'Tightness or discomfort in the chest during strenuous exercises, limiting intensity or requiring low-impact alternatives.' },
        { id: 's_12', label: 'Fatigue', description: 'General tiredness, impacting stamina and endurance during longer or high-intensity workouts.' },
        
        // Hypercholesterolemia-related symptoms
        { id: 's_13', label: 'Chest Pain (Angina)', description: 'Discomfort in the chest during exertion, requiring caution with high-intensity or cardiovascular exercises.' },
        { id: 's_14', label: 'Shortness of Breath', description: 'Breathing difficulties during aerobic exercises or physical exertion.' },
        { id: 's_15', label: 'Numbness in Extremities', description: 'Loss of sensation in hands or feet, which can affect grip or balance during exercises.' },
        { id: 's_16', label: 'Fatigue', description: 'Low energy, limiting endurance and ability to sustain physical activity.' },
    ];

    const [isSaving, setIsSaving] = useState(false);

    const medicationOptions: DBMedication[] = medications.map(medication => ({
        ...medication, // Spread original properties to maintain the DBMedication structure
        label: medication.name, // Add label and value if needed by react-select
        value: medication.medId
    }));
      
    const sideEffectOptions: DBSideEffect[] = sideEffects.map(sideEffect => ({
        ...sideEffect, // Spread original properties to maintain the DBMedication structure
        label: sideEffect.name, // Add label and value if needed by react-select
        value: sideEffect.id
    }));

    const hashStringToId = (name: string) => {
        let hash = 0;
        for (let i = 0; i < name.length; i++) {
            const char = name.charCodeAt(i);
            hash = (hash << 5) - hash + char;
            hash |= 0; // Convert to 32-bit integer
        }
        return Math.abs(hash).toString(36).substr(0, 9); // Convert to base-36 and limit to 9 characters
    };

    useEffect(() => {
        setFilteredExercises(exercises as Exercise[]);
    }, [exercises]);
    
    useEffect(() => {
        setFilteredMedications(medications as DBMedication[]);
    }, [medications]);
    
    useEffect(() => {
        setFilteredConditions(conditions as DBCondition[]);
    }, [conditions]);
    
    useEffect(() => {
        // This will run once when the component mounts
        getExercises();
        getMuscles();
        getSideEffects();
        getMedications();
        getConditions();
        setLoading(false);
    }, []); // Empty array means this effect runs only once, simulating a component mount
    
    

    async function uploadExercise(exerciseToAdd:Exercise){
        await Api.createExercise(exerciseToAdd);
    }

    async function uploadMedication(medicationToAdd:DBMedication){
        await Api.createMedication(medicationToAdd);
    }

    async function uploadCondition(conditionToAdd:DBCondition){
        await Api.createCondition(conditionToAdd);
    }

    async function getExercises(){
        const response = await Api.getExercises().then(res=>{
            setExercises(res);
            setFilteredExercises(res)
            console.log(res)
        });
        
    }

    async function getMedications(){
        const response = await Api.getMedications().then(res=>{
            setMedications(res);
            setFilteredMedications(res);
            console.log(res)
        });
    }

    async function getMuscles(){
        const response = await Api.getMuscles().then(res=>{
            setMuscles(res);
            setFilteredMuscles(res);
            console.log(res)
        });
    }
    async function getSideEffects(){
        const response = await Api.getSideEffects().then(res=>{
            setSideEffects(res);
            setFilteredSideEffects(res);
            console.log(res)
        });
    }

    async function getNHSMedications(){
        const response = await Api.getNHSMedications().then(res=>{
            console.log(res)
        });
    }

    async function getConditions(){
        const response = await Api.getConditions().then(res=>{
            setConditions(res);
            setFilteredConditions(res);
            console.log(res)
        });
    }

    const handleSearch = (value: string = searchTerm) => {
        // Use 'value' directly instead of 'searchTerm' since setSearchTerm is async
        setSearchTerm(value);
    
        if (selectedTab === 'exercises') {
            const filtered = exercises.length > 0 ? exercises.filter((exercise: Exercise) =>
                exercise.name.toLowerCase().trim().includes(value.toLowerCase().trim()) &&  // Use 'value' instead of 'searchTerm'
                (forceFilter ? exercise.stepType === forceFilter : true)
            ) : [];
    
            setFilteredExercises(filtered);
        } else if (selectedTab === 'medications') {
            const filtered = medications.length > 0 ? medications.filter((medication: DBMedication) => 
                medication.name.toLowerCase().trim().includes(value.toLowerCase().trim())
            ) : [];
    
            setFilteredMedications(filtered);
        } else if (selectedTab === 'conditions') {
            const filtered = conditions.length > 0 ? conditions.filter((condition: DBCondition) => 
                condition.name.toLowerCase().trim().includes(value.toLowerCase().trim())
            ) : [];
    
            setFilteredConditions(filtered);
        }
    };
    

    const handleExerciseSymptomChange = (selectedOption:any) => {
        setSelectedExerciseSymptom(selectedOption ? {
            description:selectedOption.label,
            label:'',
            id:''
        } : {
            description:'',
            label:'',
            id:''
        });
    };

    const handleSymptomChange = (selectedOptionId: string) => {
        const findSymptom = symptomOptions.find(s=>s.id===selectedOptionId);
        if (findSymptom){
            setSelectedSymptom(findSymptom); // selectedOption will be an object like { value: 'dizziness', label: 'Dizziness' }

        }
    };

    const handleMedicationOptionChange = (selectedOption: any) => {
        setSelectedMedication(selectedOption); 
    };

    const handleSideEffectOptionChange = (selectedOption: any) => {
        setSelectedSideEffect(selectedOption); 
    };

    const removeConditionSymptom = (symptom:Symptom) =>{
        // Create a new array that filters out the requirement to be removed
        const updatedSymptoms = newCondition.symptoms.filter(
            (s) => s !== symptom
        );

        // Update the state with the new array of physical requirements
        setNewCondition({
            ...newCondition, // Spread the existing newExercise object
            symptoms: updatedSymptoms, // Update the physicalRequirements array
        });
    }

    const removeConditionMedication = (medication:DBMedication) =>{
        // Create a new array that filters out the requirement to be removed
        const updatedMedications = newCondition.commonMedications.filter(
            (m) => m !== medication
        );

        // Update the state with the new array of physical requirements
        setNewCondition({
            ...newCondition, // Spread the existing newExercise object
            commonMedications: updatedMedications, // Update the physicalRequirements array
        });
    }

    const removeMedicationSideEffect = (sideEffect:DBSideEffect) =>{
        // Create a new array that filters out the requirement to be removed
        const updatedSideEffects = newMedication.sideEffects ? newMedication.sideEffects.filter(
            (s) => s!== sideEffect
        ) : [];

        // Update the state with the new array of physical requirements
        setNewMedication({
            ...newMedication, // Spread the existing newExercise object
            sideEffects: updatedSideEffects, // Update the physicalRequirements array
        });
    }
    const showAddExercisePopup = () => {
        setIsEditing(false);  // Set to false when adding a new exercise
        const emptyExercise: Exercise = { ...EMPTY_EXERCISE };

        setNewExercise(emptyExercise);
        setCurrentStep(0); // Start from step 0
        setShowPopup(true);
    };

    const showAddMedicationPopup = () => {
        setIsEditing(false);  // Set to false when adding a new medication
        setNewMedication({
            _id:'',
            medId:'',
            name:''
        });
        setCurrentStep(0); // Start from step 0
        setShowPopup(true);
    };

    const showAddMusclePopup = () => {
        setIsEditing(false);  // Set to false when adding a new medication
        setNewMuscle({
            _id:'',
            id:'',
            name:'',
            verified:true,
        });
        setCurrentStep(0); // Start from step 0
        setShowPopup(true);
    };

    const showAddSideEffectPopup = () => {
        setIsEditing(false);  // Set to false when adding a new medication
        setNewSideEffect({
            _id:'',
            id:'',
            name:'',
            verified:true,
        });
        setCurrentStep(0); // Start from step 0
        setShowPopup(true);
    };

    const showAddConditionPopup = () => {
        setIsEditing(false);  // Set to false when adding a new condition
        setNewCondition({
        _id:'',
        id:'',
        name:'',
        description:'',
        symptoms:[],
        commonMedications:[],
        cardioConstraints:{
            rpeMin:1,
            rpeMax:5,
            durationMin:30,
            durationMax:60,
            weeklyFreqMax:3,
            weeklyFreqMin:2
        },
        resistanceConstraints:{
            rpeMin:1,
            rpeMax:5,
            percentOf10RepMaxMin:40,
            percentOf10RepMaxMax:70,
            durationMin:30,
            durationMax:60,
            weeklyFreqMax:3,
            weeklyFreqMin:2,
            setsMin:1,
            setsMax:3,
            repsMin:10,
            repsMax:15,
            weeklyExercisesMin:5,
            weeklyExercisesMax:12,
        },
        flexibilityConstraints:{
            rpeMin:1,
            rpeMax:5,
            durationMin:30,
            durationMax:60,
            weeklyFreqMax:3,
            weeklyFreqMin:2
        }
    });
        setCurrentStep(0); // Start from step 0
        setShowPopup(true);
    };

    const editExercise = (index: number) => {
        const exerciseToEdit = filteredExercises[index];
        setNewExercise(exerciseToEdit);  // Populate the popup with the exercise details
        setIsEditing(true);              // Set the flag to true for editing mode
        setEditIndex(index);             // Store the index of the exercise being edited
        setCurrentStep(0);               // Start from step 0 when editing
        setShowPopup(true);              // Show the popup
    };

    const editMedication = (index: number) => {
        const medicationToEdit = filteredMedications[index];
        setNewMedication(medicationToEdit);  // Populate the popup with the exercise details
        setIsEditing(true);              // Set the flag to true for editing mode
        setEditIndex(index);             // Store the index of the exercise being edited
        setCurrentStep(0);               // Start from step 0 when editing
        setShowPopup(true);              // Show the popup
    };

    const editMuscle = (index: number) => {
        const muscleToEdit = filteredMuscles[index];
        setNewMuscle(muscleToEdit);  // Populate the popup with the exercise details
        setIsEditing(true);              // Set the flag to true for editing mode
        setEditIndex(index);             // Store the index of the exercise being edited
        setCurrentStep(0);               // Start from step 0 when editing
        setShowPopup(true);              // Show the popup
    };

    const editSideEffect = (index: number) => {
        const sideEffectToEdit = filteredSideEffects[index];
        setNewSideEffect(sideEffectToEdit);  // Populate the popup with the exercise details
        setIsEditing(true);              // Set the flag to true for editing mode
        setEditIndex(index);             // Store the index of the exercise being edited
        setCurrentStep(0);               // Start from step 0 when editing
        setShowPopup(true);              // Show the popup
    };

    const editCondition = (index: number) => {
        const conditionToEdit = filteredConditions[index];
        setNewCondition(conditionToEdit);  // Populate the popup with the exercise details
        setIsEditing(true);              // Set the flag to true for editing mode
        setEditIndex(index);             // Store the index of the exercise being edited
        setCurrentStep(0);               // Start from step 0 when editing
        setShowPopup(true);              // Show the popup
    };
    
    const handleAddSymptom = () => {
        // Create a copy of the current newExercise object
        const updatedCondition = {
            ...newCondition,
            symptoms: [...newCondition.symptoms, selectedSymptom],
        };
    
        // Update the state with the new object
        setNewCondition(updatedCondition);
        console.log(newCondition)
    };

    const handleAddSideEffect = () => {
        // Create a copy of the current newExercise object
        const updatedMedication = {
            ...newMedication,
            sideEffects: [...(newMedication.sideEffects ?? []), selectedSideEffect],
        };
        // Update the state with the new object
        setNewMedication(updatedMedication);
        console.log(newMedication)
    };

    const handleAddMedication = () => {
        // Create a copy of the current newExercise object
        const updatedCondition = {
            ...newCondition,
            commonMedications: [...newCondition.commonMedications, selectedMedication],
        };
    
        // Update the state with the new object
        setNewCondition(updatedCondition);
        console.log(newCondition)

    };

    const closePopup = () => {
        setShowPopup(false);
        setIsEditing(false);
        setEditIndex(null);
    };

    const handleExerciseChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, type, checked, value } = event.target;
    
        setNewExercise((prev) => ({
            ...prev,
            [name]: type === "checkbox" ? checked : value
        }));
    };

    const handleMedicationChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setNewMedication({ ...newMedication, [name]: value });
    };

    const handleMedicationCheckedChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setNewMedication({ ...newMedication, [name]: Boolean(value) });
    };

    const handleMuscleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setNewMuscle({ ...newMuscle, [name]: value });
    };

    const handleSideEffectChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setNewSideEffect({ ...newSideEffect, [name]: value });
    };

    const handleConditionChange = (event:any) => {
        const { name, value } = event.target;
    
        setNewCondition((prevCondition:any) => {
            if (name.includes('.')) {
                const [category, field] = name.split('.');
                return {
                    ...prevCondition,
                    [category]: {
                        ...prevCondition[category],
                        [field]: value,
                    },
                };
            } else {
                return {
                    ...prevCondition,
                    [name]: value,
                };
            }
        });
    };
    const handleAddSideEffectExerciseConstraint = (field: string) => {    
        setNewSideEffect((prevSideEffect) => {
            const exerciseConstraints = prevSideEffect.exerciseConstraints || [];
    
            return {
                ...prevSideEffect,
                exerciseConstraints: exerciseConstraints.includes(field)
                    ? exerciseConstraints.filter((f:string) => f !== field) // Remove field if already present
                    : [...exerciseConstraints, field], // Add field if not present
            };
        });
    };
    const handleAddConditionExerciseConstraint = (field: string) => {    
        setNewCondition((prevCondition) => {
            const exerciseConstraints = prevCondition.exerciseConstraints || [];
    
            return {
                ...prevCondition,
                exerciseConstraints: exerciseConstraints.includes(field)
                    ? exerciseConstraints.filter((f:string) => f !== field) // Remove field if already present
                    : [...exerciseConstraints, field], // Add field if not present
            };
        });
    };
    

    const handleMultiSelectChange = (event: SelectChangeEvent<string[]>, name: keyof Exercise) => {
        const { value } = event.target;
    
        setNewExercise((prev) => ({
            ...prev,
            [name]: typeof value === "string" ? value.split(",") : value, // Ensure it's an array
        }));
    };
    
    
    
    const handleNext = () => {
        setCurrentStep(currentStep + 1);
    };

    const handleBack = () => {
        if (currentStep > 0){
            setCurrentStep(currentStep - 1);

        }
    };

    const handleSaveExercise = async (exercise?:Exercise) => {
        setIsSaving(true);

        const exerciseToAdd = exercise ? exercise : newExercise;

        try {
            // Search YouTube for an instructional video
            const searchQuery = `How to do a ${exerciseToAdd.name} pure gym`;
            const videoData = await Api.searchYouTubeVideo(searchQuery);

            const fuse = new Fuse(localdataexercises, {
                keys: ['name'], // Key to search for
                threshold: 0.3, // Adjust to control the fuzziness (0.0 = exact match, 1.0 = very loose)
              });
              
            const results = fuse.search(exerciseToAdd.name);
            const bestMatch = results.length > 0 ? results[0].item : null; // Get the best match (or null if no match)

            const updatedExercise = { ...exerciseToAdd, 
                video: exerciseToAdd.video.length > 0 ? exerciseToAdd.video : `${videoData.videoUrl}`,
                images:bestMatch?.images || [],
                instructions:bestMatch?.instructions || [],
            };

    
            if (isEditing && editIndex !== null) {
                const updatedExercises = [...filteredExercises];
                updatedExercises[editIndex] = updatedExercise; // Replace the exercise at the current index
                setFilteredExercises(updatedExercises);
            } else {
                setFilteredExercises([updatedExercise, ...filteredExercises]);
            }
            console.log(updatedExercise)
    
            // Save the updated exercise to the backend
            await Api.createExercise(updatedExercise);
        } catch (error) {
            console.error("Error fetching YouTube video:", error);
        } finally {
            closePopup();
            setIsSaving(false);
        }
    };
    const handleRemoveExercise = async (id: string) => {
        try {
            const response = await Api.removeExercise(id);
            console.log("Exercise removed:", response.message);
    
            // Remove the deleted exercise from state
            setExercises(prevExercises => prevExercises.filter(e => e.id !== id));
            setFilteredExercises(prevFilteredExercises => prevFilteredExercises.filter(e => e.id !== id));
    
        } catch (error) {
            console.error("Error removing exercise:", error);
        } finally{
            closePopup();
            setIsSaving(false);
        }
    };

    const handleSaveMedication = async () => {
        setIsSaving(true);

        if (isEditing && editIndex !== null) {
            const updatedMedications = [...filteredMedications];
            updatedMedications[editIndex] = newMedication; // Replace the exercise at the current index
            setFilteredMedications(updatedMedications);
        } else {
            setFilteredMedications([newMedication, ...filteredMedications]);
        }
        await Api.createMedication(newMedication);
        closePopup();
        setIsSaving(false)
    };

    const handleSaveMuscle = async () => {
        setIsSaving(true);

        if (isEditing && editIndex !== null) {
            const updatedMuscles = [...filteredMuscles];
            updatedMuscles[editIndex] = newMuscle; // Replace the exercise at the current index
            setFilteredMuscles(updatedMuscles);
        } else {
            setFilteredMuscles([newMuscle, ...filteredMuscles]);
        }
        await Api.createMuscle(newMuscle);
        closePopup();
        setIsSaving(false)
    };

    const handleSaveSideEffect = async () => {
        setIsSaving(true);

        if (isEditing && editIndex !== null) {
            const updatedSideEffects = [...filteredSideEffects];
            updatedSideEffects[editIndex] = newSideEffect; // Replace the exercise at the current index
            setFilteredSideEffects(updatedSideEffects);
        } else {
            setFilteredSideEffects([newSideEffect, ...filteredSideEffects]);
        }
        await Api.createSideEffect(newSideEffect);
        closePopup();
        setIsSaving(false)
    };

    const [uploading, setUploading] = useState(false);

    const handleSaveCondition = async () => {
        setIsSaving(true);
        if (newCondition.name.length > 0){
            if (isEditing && editIndex !== null) {
                const updatedConditions = [...filteredConditions];
                updatedConditions[editIndex] = newCondition; // Replace the exercise at the current index
                setFilteredConditions(updatedConditions);
            } else {
                setFilteredConditions([newCondition, ...filteredConditions]);
            }
            await Api.createCondition(newCondition);
            closePopup();
        }else{
            alert('Save failed - condition must have a name')
        }
        setIsSaving(false);

    };

    const renderExerciseStepContent = () => {
        switch (currentStep) {
            case 0:
                return (
                    <div style={{display:'flex', maxHeight:'70vh', overflow:'scroll', flexDirection:'column', gap:'20px', alignItems:'center'}}>
                        <label className="step-title">New Exercise Form</label>

                        {/* Exercise Name */}
                        {/* <label style={{ marginTop: '20px' }}>Exercise Name</label> */}
                        <TextField
                            className="exercise-info-input"
                            type="text"
                            name="name"
                            placeholder="Push Ups..."
                            value={newExercise.name}
                            onChange={handleExerciseChange}
                            label="Exercise Name"
                            fullWidth
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            style={{ fontSize: "1.2em", backgroundColor: "transparent", }}
                        />

                        {/* Step Type */}
                        {/* <label style={{ marginTop: '20px' }}>Type</label> */}
                        <TextField
                            className="exercise-info-input"
                            name="stepType"
                            select
                            value={newExercise.stepType}
                            onChange={handleExerciseChange}
                            label="Activity Type"
                            fullWidth
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            style={{ fontSize: "1.2em", backgroundColor: "transparent" }}
                        >
                            <MenuItem disabled value="">
                                Select Activity Type
                            </MenuItem>
                            {Object.values(VitovaStepType).map((type) => (
                                <MenuItem key={type} value={type}>
                                    {type.toUpperCase()}
                                </MenuItem>
                            ))}
                        </TextField>

                        {/* Force Type */}
                        {/* <label style={{ marginTop: '20px' }}>Force Type</label> */}
                        <TextField
                            className="exercise-info-input"
                            name="force"
                            select
                            value={newExercise.force}
                            onChange={handleExerciseChange}
                            label="Force Type"
                            fullWidth
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                        >
                            {Object.values(VitovaExerciseForceType).map((force) => (
                                <MenuItem key={force} value={force}>
                                    {force.toUpperCase()}
                                </MenuItem>
                            ))}
                        </TextField>

                        {/* Level */}
                        {/* <label style={{ marginTop: '20px' }}>Difficulty Level</label> */}
                        <TextField
                            className="exercise-info-input"
                            name="level"
                            select
                            value={newExercise.level}
                            onChange={handleExerciseChange}
                            label="Difficulty Level"
                            fullWidth
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                        >
                            {Object.values(VitovaExerciseLevel).map((level) => (
                                <MenuItem key={level} value={level}>
                                    {level.toUpperCase()}
                                </MenuItem>
                            ))}
                        </TextField>

                        {/* Mechanic */}
                        {/* <label style={{ marginTop: '20px' }}>Mechanic</label> */}
                        <TextField
                            className="exercise-info-input"
                            name="mechanic"
                            select
                            value={newExercise.mechanic}
                            onChange={handleExerciseChange}
                            label="Mechanic"
                            fullWidth
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                        >
                            {Object.values(VitovaExerciseMechanic).map((mechanic) => (
                                <MenuItem key={mechanic} value={mechanic}>
                                    {mechanic.toUpperCase()}
                                </MenuItem>
                            ))}
                        </TextField>

                        {/* Equipment */}
                        {/* <label style={{ marginTop: '20px' }}>Equipment</label> */}
                        <TextField
                            className="exercise-info-input"
                            name="equipment"
                            select
                            value={newExercise.equipment}
                            onChange={handleExerciseChange}
                            label="Equipment Needed"
                            fullWidth
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                        >
                            {Object.values(VitovaExerciseEquipmentNeeded).map((equipment) => (
                                <MenuItem key={equipment} value={equipment}>
                                    {equipment.toUpperCase()}
                                </MenuItem>
                            ))}
                        </TextField>

                        {/* Video URL */}
                        {/* <label style={{ marginTop: '20px' }}>Video</label> */}
                        <TextField
                            className="exercise-info-input"
                            type="text"
                            name="video"
                            placeholder="https://youtube.com..."
                            value={newExercise.video}
                            onChange={handleExerciseChange}
                            label="Video URL"
                            fullWidth
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            style={{ fontSize: "1.2em", backgroundColor: "transparent" }}
                        />

                        {/* Boolean Fields */}
                        <label style={{ marginTop: '20px' }}>Exercise Features</label>
                        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                            {[
                                'homeSuitable',
                                'barbell',
                                'dumbbell',
                                'bands',
                                'kettlebell',
                                'machine',
                                'isolation',
                                'compound',
                                'overhead',
                                'floor',
                                'isometric',
                                'axialLoad',
                                'abdominalStrain',
                                'highImpact'
                            ].map((field) => (
                                <FormControlLabel
                                    key={field}
                                    control={
                                        <Checkbox
                                            checked={newExercise[field as keyof Exercise] as boolean} // Type assertion
                                            onChange={handleExerciseChange}
                                            name={field}
                                        />

                                    }
                                    label={field.replace(/([A-Z])/g, " $1").trim()}
                                />
                            ))}
                        </div>


                    <FormControl fullWidth variant="outlined" className="exercise-info-input">
                        <InputLabel>Primary Muscles</InputLabel>
                        <Select
                            multiple
                            name="primaryMuscles"
                            value={newExercise.primaryMuscles}
                            onChange={(event) => handleMultiSelectChange(event, "primaryMuscles")}
                            renderValue={(selected) => (
                                <div>
                                    {selected.map((id: string) => {
                                        const muscle = muscles.find((m) => m._id === id);
                                        return <Chip key={id} label={muscle ? muscle.name : id} style={{ margin: 2 }} />;
                                    })}
                                </div>
                            )}
                            label="Primary Muscles"
                        >
                            {muscles.map((muscle: DBMuscle) => (
                                <MenuItem key={muscle._id} value={muscle._id}>
                                    {muscle.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl fullWidth variant="outlined" className="exercise-info-input" style={{}}>
                        <InputLabel>Secondary Muscles</InputLabel>
                        <Select
                            multiple
                            name="secondaryMuscles"
                            value={newExercise.secondaryMuscles}
                            onChange={(event) => handleMultiSelectChange(event, "secondaryMuscles")}
                            renderValue={(selected) => (
                                <div>
                                    {selected.map((id: string) => {
                                        const muscle = muscles.find((m) => m._id === id);
                                        return <Chip key={id} label={muscle ? muscle.name : id} style={{ margin: 2 }} />;
                                    })}
                                </div>
                            )}
                            label="Secondary Muscles"
                        >
                            {muscles.map((muscle: DBMuscle) => (
                                <MenuItem key={muscle._id} value={muscle._id}>
                                    {muscle.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>


<FormControl fullWidth variant="outlined" className="exercise-info-input">
    <InputLabel>Progressions</InputLabel>
    <Select
        name="progression"
        multiple
        value={newExercise.progression} // Array of selected IDs
        onChange={(event) => handleMultiSelectChange(event, "progression")}
        renderValue={(selected) => (
            <div>
                {selected.map((id: string) => {
                    const exercise = exercises.find((ex) => ex.id === id);
                    return (
                        <Chip key={id} label={exercise ? exercise.name : id} style={{ margin: 2 }} />
                    );
                })}
            </div>
        )}
        label="Progressions"
    >
        {exercises.map((exercise) => (
            <MenuItem key={exercise.id} value={exercise.id}>
                {exercise.name}
            </MenuItem>
        ))}
    </Select>
</FormControl>

<FormControl fullWidth variant="outlined" className="exercise-info-input" >
    <InputLabel>Regressions</InputLabel>
    <Select
        name="regression"
        multiple
        value={newExercise.regression} // Array of selected IDs
        onChange={(event) => handleMultiSelectChange(event, "regression")}
        renderValue={(selected) => (
            <div>
                {selected.map((id: string) => {
                    const exercise = exercises.find((ex) => ex.id === id);
                    return (
                        <Chip key={id} label={exercise ? exercise.name : id} style={{ margin: 2 }} />
                    );
                })}
            </div>
        )}
        label="Regressions"
    >
        {exercises.map((exercise) => (
            <MenuItem key={exercise.id} value={exercise.id}>
                {exercise.name}
            </MenuItem>
        ))}
    </Select>
</FormControl>


                    </div>

                );
            default:
                return null;
        }
    };

    const renderMedicationStepContent = () => {
        switch (currentStep) {
            case 0:
                return (
                    <>
                        <label className="step-title">Step 1: Basic Information</label>
                        <label style={{marginTop:'20px'}}>Medication Name</label>

                        <input
                            className="exercise-info-input"
                            type="text"
                            name="name"
                            placeholder="Medication Name"
                            value={newMedication.name}
                            onChange={handleMedicationChange}
                        />
                        <label style={{marginTop:'20px'}}>Medication ID</label>

                        <input
                            className="exercise-info-input"
                            type="text"
                            disabled
                            name="medId"
                            placeholder="Medication ID"
                            value={newMedication.medId}
                            onChange={handleMedicationChange}
                        />
                        <label style={{ marginTop: '20px' }}>
                            Verified
                            <input
                                className="exercise-info-input"
                                type="checkbox"
                                name="verified"
                                checked={!!newMedication.verified} // Ensures it's always a boolean
                                onChange={
                                    handleMedicationCheckedChange
                                }
                            />
                        </label>


                    </>
                );
            case 1:
                return (
                    <>
                        <label className="step-title">Step 2: Common Side Effects</label>
                        <label style={{ marginTop: '20px' }}>{`List all the common side effects of ${newMedication.name}`}</label>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>

                            <Select
                            style={{fontSize:'1em',maxWidth:'80%',}}
                            className="exercise-info-input"
                            displayEmpty
                            value={selectedSideEffect?.id || ""}
                            onChange={(e) =>
                                handleSideEffectOptionChange(
                                sideEffectOptions.find((s) => s.id === e.target.value) || null
                                )
                            }
                            renderValue={(selectedId) =>
                                sideEffectOptions.find((s) => s.id === selectedId)?.name || "Side Effects"
                            }
                            >
                            <MenuItem value="" disabled>
                                Side Effects
                            </MenuItem>
                            {sideEffectOptions.map((s) => (
                                <MenuItem key={s.id} value={s.id} style={{fontSize:'1.75em'}}>
                                {s.name}
                                </MenuItem>
                            ))}
                            </Select>

                        <Button className="form-next-button" style={{ marginTop: '20px', marginLeft: '5px', fontSize: '1em' }} onClick={handleAddSideEffect}>
                            Add Side Effect
                        </Button>
                        </div>

                        {/* Displaying selected symptoms */}
                        <div className="symptom-list">
                            {newMedication.sideEffects && newMedication.sideEffects.map((sideEffect: DBSideEffect) => (
                                <div className="symptom-item" key={sideEffect.id} >
                                <FontAwesomeIcon onClick={() => removeMedicationSideEffect(sideEffect)} icon={faMinusCircle} className="symptom-icon" />
                                <label className="symptom-description">{sideEffect.name}</label>
                                </div>
                            ))}
                            </div>
                    </>
                );
            default:
                return null;
        }
    };

    const renderMuscleStepContent = () => {
        switch (currentStep) {
            case 0:
                return (
                    <>
                        <label className="step-title">Step 1: Basic Information</label>
                        <label style={{marginTop:'20px'}}>Muscle Name</label>

                        <input
                            className="exercise-info-input"
                            type="text"
                            name="name"
                            placeholder="Muscle Name"
                            value={newMuscle.name}
                            onChange={handleMuscleChange}
                        />
                        <label style={{marginTop:'20px'}}>Muscle ID</label>

                        <input
                            className="exercise-info-input"
                            type="text"
                            disabled
                            name="id"
                            placeholder="Muscle ID"
                            value={newMuscle.id}
                            onChange={handleMuscleChange}
                        />
                        <label style={{marginTop:'20px'}}>Muscle Description</label>
                        <input
                            className="exercise-info-input"
                            type="text"
                            name="description"
                            placeholder="Muscle Description"
                            value={newMuscle.description}
                            onChange={handleMuscleChange}
                        />
                    </>
                );
            default:
                return null;
        }
    };
    const renderSideEffectStepContent = () => {
        switch (currentStep) {
            case 0:
                return (
                    <>
                        <label className="step-title">Step 1: Basic Information</label>
                        <label style={{marginTop:'20px'}}>Side Effect</label>

                        <input
                            className="exercise-info-input"
                            type="text"
                            name="name"
                            placeholder="Side Effect Name"
                            value={newSideEffect.name}
                            onChange={handleSideEffectChange}
                        />
                        <label style={{marginTop:'20px'}}>Side Effect ID</label>

                        <input
                            className="exercise-info-input"
                            type="text"
                            disabled
                            name="id"
                            placeholder="Side Effect ID"
                            value={newSideEffect.id}
                            onChange={handleSideEffectChange}
                        />
                        <label style={{marginTop:'20px'}}>Side Effect Description</label>
                        <input
                            className="exercise-info-input"
                            type="text"
                            name="description"
                            placeholder="Side Effect Description"
                            value={newSideEffect.description}
                            onChange={handleSideEffectChange}
                        />
                    </>
                );
               case 1:
                return( 
                <>
                        <label className="step-title">Step 3: Exercise Constraints</label>
                        <label style={{ marginTop: '20px' }}>{`Check all the properties to be avoided for patients with ${newMedication.name}`}</label>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                            {[
                                'homeSuitable',
                                'barbell',
                                'dumbbell',
                                'bands',
                                'kettlebell',
                                'machine',
                                'isolation',
                                'compound',
                                'overhead',
                                'floor',
                                'isometric',
                                'axialLoad',
                                'abdominalStrain',
                                'highImpact'
                            ].map((field) => (
                                <FormControlLabel
                                    key={field}
                                    control={
                                        <Checkbox
                                            checked={newSideEffect.exerciseConstraints && newSideEffect.exerciseConstraints.includes(field)} // Type assertion
                                            onChange={()=>handleAddSideEffectExerciseConstraint(field)}
                                            name={field}
                                        />

                                    }
                                    label={field.replace(/([A-Z])/g, " $1").trim()}
                                />
                            ))}
                        </div>
                        </div>

                        {/* Displaying selected symptoms */}
                        <div className="symptom-list">
                            {newCondition.symptoms.map((symptom: Symptom) => (
                                <div className="symptom-item" key={symptom.id} >
                                <FontAwesomeIcon onClick={() => removeConditionSymptom(symptom)} icon={faMinusCircle} className="symptom-icon" /> {/* Icon next to symptom */}
                                <label className="symptom-description">{symptom.label}</label>
                                </div>
                            ))}
                            </div>
                    </>
                    )
            default:
                return null;
        }
    };
    const renderConditionStepContent = () => {
        switch (currentStep) {
            case 0:
                return (
                    <div style={{ display:'flex', flexDirection:'column', alignItems:'center'}}>
                        <label className="step-title" style={{fontSize:'2em'}}>Step 1: Basic Information</label>
                        <label style={{marginTop:'20px'}}>Condition Name</label>

                        <input
                            style={{fontSize:'1.75em',maxWidth:'70%',}}
                            className="exercise-info-input"
                            type="text"
                            name="name"
                            placeholder="Condition Name"
                            value={newCondition.name}
                            onChange={handleConditionChange}
                        />
                        <label style={{marginTop:'20px'}}>Condition ID</label>

                        <input
                            style={{fontSize:'1.75em',maxWidth:'70%',}}
                            className="exercise-info-input"
                            type="text"
                            disabled
                            name="id"
                            placeholder="Condition ID"
                            value={newCondition.id}
                            onChange={handleConditionChange}
                        />
                         <label style={{marginTop:'20px'}}>Condition Description</label>

                        <input
                            style={{fontSize:'1.75em',maxWidth:'70%',}}
                            className="exercise-info-input"
                            type="text"
                            name="description"
                            placeholder="Condition Description"
                            value={newCondition.description}
                            onChange={handleConditionChange}
                        />
                    </div>
                );
            case 1:
                return (
                    <>
                        <label className="step-title">Step 2: Common Medications</label>
                        <label style={{ marginTop: '20px' }}>{`List all the common medications of ${newCondition.name}`}</label>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>

                            <Select
                            style={{fontSize:'1.75em',maxWidth:'40%',}}
                            className="exercise-info-input"
                            displayEmpty
                            value={selectedMedication?.medId || ""}
                            onChange={(e) =>
                                handleMedicationOptionChange(
                                medicationOptions.find((m) => m.medId === e.target.value) || null
                                )
                            }
                            renderValue={(selectedId) =>
                                medicationOptions.find((m) => m.medId === selectedId)?.name || "Medications"
                            }
                            >
                            <MenuItem value="" disabled>
                                Medications
                            </MenuItem>
                            {medicationOptions.filter(m=>m.verified).map((m) => (
                                <MenuItem key={m.medId} value={m.medId} style={{fontSize:'1.75em'}}>
                                {m.name}
                                </MenuItem>
                            ))}
                            </Select>

                        <Button className="form-next-button" style={{ marginTop: '20px', marginLeft: '5px', fontSize: '1em' }} onClick={handleAddMedication}>
                            Add Medication
                        </Button>
                        </div>

                        {/* Displaying selected symptoms */}
                        <div className="symptom-list">
                            {newCondition.commonMedications.map((medication: DBMedication) => (
                                <div className="symptom-item" key={medication.medId} >
                                <FontAwesomeIcon onClick={() => removeConditionMedication(medication)} icon={faMinusCircle} className="symptom-icon" /> {/* Icon next to symptom */}
                                <label className="symptom-description">{medication.name}</label>
                                </div>
                            ))}
                            </div>
                    </>
                );
            case 2:
                return (
                    <>
                        <label className="step-title">Step 3: Exercise Constraints</label>
                        <label style={{ marginTop: '20px' }}>{`Check all the properties to be avoided for patients with ${newCondition.name}`}</label>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                            {[
                                'homeSuitable',
                                'barbell',
                                'dumbbell',
                                'bands',
                                'kettlebell',
                                'machine',
                                'isolation',
                                'compound',
                                'overhead',
                                'floor',
                                'isometric',
                                'axialLoad',
                                'abdominalStrain',
                                'highImpact'
                            ].map((field) => (
                                <FormControlLabel
                                    key={field}
                                    control={
                                        <Checkbox
                                            checked={newCondition.exerciseConstraints && newCondition.exerciseConstraints.includes(field)} // Type assertion
                                            onChange={()=>handleAddConditionExerciseConstraint(field)}
                                            name={field}
                                        />

                                    }
                                    label={field.replace(/([A-Z])/g, " $1").trim()}
                                />
                            ))}
                        </div>
                        </div>

                        {/* Displaying selected symptoms */}
                        <div className="symptom-list">
                            {newCondition.symptoms.map((symptom: Symptom) => (
                                <div className="symptom-item" key={symptom.id} >
                                <FontAwesomeIcon onClick={() => removeConditionSymptom(symptom)} icon={faMinusCircle} className="symptom-icon" /> {/* Icon next to symptom */}
                                <label className="symptom-description">{symptom.label}</label>
                                </div>
                            ))}
                            </div>
                    </>

                );
            case 3:
                return (
                    <>
                        <label className="step-title">Step 4: Cardio Constraints</label>
                        <label style={{ marginTop: '20px' }}>{`Specify all the cardio constraints of ${newCondition.name}`}</label>

                        {/* RPE Min */}
                        <label style={{ marginTop: '10px' }}>RPE Min</label>
                        <input
                            style={{fontSize:'1.75em', maxWidth:'70%'}}
                            className="exercise-info-input"
                            type="text"
                            name="cardioConstraints.rpeMin"
                            placeholder="RPE Min"
                            value={newCondition.cardioConstraints.rpeMin}
                            onChange={handleConditionChange}
                        />

                        {/* RPE Max */}
                        <label style={{ marginTop: '10px' }}>RPE Max</label>
                        <input
                            style={{fontSize:'1.75em', maxWidth:'70%'}}
                            className="exercise-info-input"
                            type="text"
                            name="cardioConstraints.rpeMax"
                            placeholder="RPE Max"
                            value={newCondition.cardioConstraints.rpeMax}
                            onChange={handleConditionChange}
                        />

                        {/* Weekly Freq Min */}
                        <label style={{ marginTop: '10px' }}>Weekly Freq Min</label>
                        <input
                            style={{fontSize:'1.75em', maxWidth:'70%'}}
                            className="exercise-info-input"
                            type="text"
                            name="cardioConstraints.weeklyFreqMin"
                            placeholder="Weekly Frequency Min"
                            value={newCondition.cardioConstraints.weeklyFreqMin}
                            onChange={handleConditionChange}
                        />

                        {/* Weekly Freq Max */}
                        <label style={{ marginTop: '10px' }}>Weekly Freq Max</label>
                        <input
                            style={{fontSize:'1.75em', maxWidth:'70%'}}
                            className="exercise-info-input"
                            type="text"
                            name="cardioConstraints.weeklyFreqMax"
                            placeholder="Weekly Frequency Max"
                            value={newCondition.cardioConstraints.weeklyFreqMax}
                            onChange={handleConditionChange}
                        />

                        {/* Duration Min */}
                        <label style={{ marginTop: '10px' }}>Duration Min</label>
                        <input
                            style={{fontSize:'1.75em', maxWidth:'70%'}}
                            className="exercise-info-input"
                            type="text"
                            name="cardioConstraints.durationMin"
                            placeholder="Duration Min"
                            value={newCondition.cardioConstraints.durationMin}
                            onChange={handleConditionChange}
                        />

                        {/* Duration Max */}
                        <label style={{ marginTop: '10px' }}>Duration Max</label>
                        <input
                            style={{fontSize:'1.75em', maxWidth:'70%'}}
                            className="exercise-info-input"
                            type="text"
                            name="cardioConstraints.durationMax"
                            placeholder="Duration Max"
                            value={newCondition.cardioConstraints.durationMax}
                            onChange={handleConditionChange}
                        />
                    </>

                );
                case 4:
                return (
                    <>
                        <label className="step-title">Step 5: Resistance Constraints</label>
                        <label style={{ marginTop: '20px' }}>{`Specify all the resistance constraints of ${newCondition.name}`}</label>
            
                        {/* Resistance Constraints Inputs */}
                        <label style={{ marginTop: '10px' }}>RPE Min</label>
                        <input
                            style={{fontSize:'1.75em', maxWidth:'70%'}}
                            className="exercise-info-input"
                            type="text"
                            name="resistanceConstraints.rpeMin"
                            placeholder="RPE Min"
                            value={newCondition.resistanceConstraints.rpeMin}
                            onChange={handleConditionChange}
                        />
                        <label style={{ marginTop: '10px' }}>RPE Max</label>
                        <input
                            style={{fontSize:'1.75em', maxWidth:'70%'}}
                            className="exercise-info-input"
                            type="text"
                            name="resistanceConstraints.rpeMax"
                            placeholder="RPE Max"
                            value={newCondition.resistanceConstraints.rpeMax}
                            onChange={handleConditionChange}
                        />
                        <label style={{ marginTop: '10px' }}>10%RM Min</label>
                        <input
                            style={{fontSize:'1.75em', maxWidth:'70%'}}
                            className="exercise-info-input"
                            type="text"
                            name="resistanceConstraints.percentOf10RepMaxMin"
                            placeholder="10%RM Min"
                            value={newCondition.resistanceConstraints.percentOf10RepMaxMin}
                            onChange={handleConditionChange}
                        />
                        <label style={{ marginTop: '10px' }}>10%RM Max</label>
                        <input
                            style={{fontSize:'1.75em', maxWidth:'70%'}}
                            className="exercise-info-input"
                            type="text"
                            name="resistanceConstraints.percentOf10RepMaxMax"
                            placeholder="10%RM Max"
                            value={newCondition.resistanceConstraints.percentOf10RepMaxMax}
                            onChange={handleConditionChange}
                        />
                        <label style={{ marginTop: '10px' }}>Weekly Frequency Min</label>
                        <input
                            style={{fontSize:'1.75em', maxWidth:'70%'}}
                            className="exercise-info-input"
                            type="text"
                            name="resistanceConstraints.weeklyFreqMin"
                            placeholder="Weekly Frequency Min"
                            value={newCondition.resistanceConstraints.weeklyFreqMin}
                            onChange={handleConditionChange}
                        />
                        <label style={{ marginTop: '10px' }}>Weekly Frequency Max</label>
                        <input
                            style={{fontSize:'1.75em', maxWidth:'70%'}}
                            className="exercise-info-input"
                            type="text"
                            name="resistanceConstraints.weeklyFreqMax"
                            placeholder="Weekly Frequency Max"
                            value={newCondition.resistanceConstraints.weeklyFreqMax}
                            onChange={handleConditionChange}
                        />
                        <label style={{ marginTop: '10px' }}>Duration Min</label>
                        <input
                            style={{fontSize:'1.75em', maxWidth:'70%'}}
                            className="exercise-info-input"
                            type="text"
                            name="resistanceConstraints.durationMin"
                            placeholder="Duration Min"
                            value={newCondition.resistanceConstraints.durationMin}
                            onChange={handleConditionChange}
                        />
                        <label style={{ marginTop: '10px' }}>Duration Max</label>
                        <input
                            style={{fontSize:'1.75em', maxWidth:'70%'}}
                            className="exercise-info-input"
                            type="text"
                            name="resistanceConstraints.durationMax"
                            placeholder="Duration Max"
                            value={newCondition.resistanceConstraints.durationMax}
                            onChange={handleConditionChange}
                        />
                        <label style={{ marginTop: '10px' }}>Sets Min</label>
                        <input
                            style={{fontSize:'1.75em', maxWidth:'70%'}}
                            className="exercise-info-input"
                            type="text"
                            name="resistanceConstraints.setsMin"
                            placeholder="Sets Min"
                            value={newCondition.resistanceConstraints.setsMin}
                            onChange={handleConditionChange}
                        />
                        <label style={{ marginTop: '10px' }}>Sets Max</label>
                        <input
                            style={{fontSize:'1.75em', maxWidth:'70%'}}
                            className="exercise-info-input"
                            type="text"
                            name="resistanceConstraints.setsMax"
                            placeholder="Sets Max"
                            value={newCondition.resistanceConstraints.setsMax}
                            onChange={handleConditionChange}
                        />
                        <label style={{ marginTop: '10px' }}>Reps Min</label>
                        <input
                            style={{fontSize:'1.75em', maxWidth:'70%'}}
                            className="exercise-info-input"
                            type="text"
                            name="resistanceConstraints.repsMin"
                            placeholder="Reps Min"
                            value={newCondition.resistanceConstraints.repsMin}
                            onChange={handleConditionChange}
                        />
                        <label style={{ marginTop: '10px' }}>Reps Max</label>
                        <input
                            style={{fontSize:'1.75em', maxWidth:'70%'}}
                            className="exercise-info-input"
                            type="text"
                            name="resistanceConstraints.repsMax"
                            placeholder="Reps Max"
                            value={newCondition.resistanceConstraints.repsMax}
                            onChange={handleConditionChange}
                        />
                        <label style={{ marginTop: '10px' }}>Weekly Exercises Min</label>
                        <input
                            style={{fontSize:'1.75em', maxWidth:'70%'}}
                            className="exercise-info-input"
                            type="text"
                            name="resistanceConstraints.weeklyExercisesMin"
                            placeholder="Weekly Exercises Min"
                            value={newCondition.resistanceConstraints.weeklyExercisesMin}
                            onChange={handleConditionChange}
                        />
                        <label style={{ marginTop: '10px' }}>Weekly Exercises Max</label>
                        <input
                            style={{fontSize:'1.75em', maxWidth:'70%'}}
                            className="exercise-info-input"
                            type="text"
                            name="resistanceConstraints.weeklyExercisesMax"
                            placeholder="Weekly Exercises Max"
                            value={newCondition.resistanceConstraints.weeklyExercisesMax}
                            onChange={handleConditionChange}
                        />
                    </>
                );
            
            case 5:
                return (
                    <>
                        <label className="step-title">Step 6: Flexibility Constraints</label>
                        <label style={{ marginTop: '20px' }}>{`Specify all the flexibility constraints of ${newCondition.name}`}</label>
            
                        {/* Flexibility Constraints Inputs */}
                        <label style={{ marginTop: '10px' }}>RPE Min</label>
                        <input
                            style={{fontSize:'1.75em', maxWidth:'70%'}}
                            className="exercise-info-input"
                            type="text"
                            name="flexibilityConstraints.rpeMin"
                            placeholder="RPE Min"
                            value={newCondition.flexibilityConstraints.rpeMin}
                            onChange={handleConditionChange}
                        />
                        <label style={{ marginTop: '10px' }}>RPE Max</label>
                        <input
                            style={{fontSize:'1.75em', maxWidth:'70%'}}
                            className="exercise-info-input"
                            type="text"
                            name="flexibilityConstraints.rpeMax"
                            placeholder="RPE Max"
                            value={newCondition.flexibilityConstraints.rpeMax}
                            onChange={handleConditionChange}
                        />
                        <label style={{ marginTop: '10px' }}>Weekly Frequency Min</label>
                        <input
                            style={{fontSize:'1.75em', maxWidth:'70%'}}
                            className="exercise-info-input"
                            type="text"
                            name="flexibilityConstraints.weeklyFreqMin"
                            placeholder="Weekly Frequency Min"
                            value={newCondition.flexibilityConstraints.weeklyFreqMin}
                            onChange={handleConditionChange}
                        />
                        <label style={{ marginTop: '10px' }}>Weekly Frequency Max</label>
                        <input
                            style={{fontSize:'1.75em', maxWidth:'70%'}}
                            className="exercise-info-input"
                            type="text"
                            name="flexibilityConstraints.weeklyFreqMax"
                            placeholder="Weekly Frequency Max"
                            value={newCondition.flexibilityConstraints.weeklyFreqMax}
                            onChange={handleConditionChange}
                        />
                        <label style={{ marginTop: '10px' }}>Duration Min</label>
                        <input
                            style={{fontSize:'1.75em', maxWidth:'70%'}}
                            className="exercise-info-input"
                            type="text"
                            name="flexibilityConstraints.durationMin"
                            placeholder="Duration Min"
                            value={newCondition.flexibilityConstraints.durationMin}
                            onChange={handleConditionChange}
                        />
                        <label style={{ marginTop: '10px' }}>Duration Max</label>
                        <input
                            style={{fontSize:'1.75em', maxWidth:'70%'}}
                            className="exercise-info-input"
                            type="text"
                            name="flexibilityConstraints.durationMax"
                            placeholder="Duration Max"
                            value={newCondition.flexibilityConstraints.durationMax}
                            onChange={handleConditionChange}
                        />
                    </>
                );
            
            default:
                return null;
        }
    };
    
    return (
        <div className="database-page-container" style={{height:'100%'}}>
            {loading ? (
                <Spinner text="Loading Database" />
            ) : showPopup ? (
                    <div className="card-enlarged" style={{ display: 'flex', flexDirection: 'column', color:'#454545', justifyContent:'flex-start'}}>
                        <div style={{display:'flex', alignItems:'center',justifyContent:'flex-start', flexDirection:'column'}}>
                            {selectedTab === 'exercises' &&
                                renderExerciseStepContent()
                            }
                            {selectedTab === 'medications' &&
                                renderMedicationStepContent()  
                            }
                            {selectedTab === 'muscles' &&
                                renderMuscleStepContent()  
                            }
                            {selectedTab === 'sideeffects' &&
                                renderSideEffectStepContent()  
                            }
                            {selectedTab === 'conditions' && 
                                <div style={{ 
                                    display: 'flex', 
                                    height: '60vh', 
                                    maxHeight: '60vh', 
                                    flexDirection: 'column', 
                                    alignItems:'center',
                                    overflowY: 'auto' // Allows vertical scrolling if content exceeds 30vh
                                }}>
                                    {renderConditionStepContent()}
                                </div>

                            }
                        </div>
                        <div style={{width:'90%', display:'flex', justifyContent:'space-between', borderRadius:'5px',position:'absolute', bottom:'10vh'}}>
                            {currentStep <= 0 ? 
                                <Button className="form-back-button" onClick={closePopup}>Close Without Saving</Button>
                            :
                                <Button className="form-back-button" onClick={handleBack}>Previous</Button>
                            }
                            {(selectedTab === 'exercises' && currentStep < 0) || (selectedTab === 'medications' && currentStep < 1) || (selectedTab === 'conditions' && currentStep < 5) || (selectedTab === 'sideeffects' && currentStep < 1) ? 
                                <Button className="form-next-button" onClick={handleNext}>Next Step</Button>
                            :
                                selectedTab === 'exercises'?
                                <div style={{display:'flex', flexDirection:'row'}}>
                                    <Button className="form-next-button" style={{backgroundColor:colorPalette.softRed, color:'white'}} disabled={isSaving} onClick={()=>handleRemoveExercise(newExercise.id)}>{"Remove Exercise"}</Button>
                                    <Button className="form-next-button" disabled={isSaving} onClick={()=>handleSaveExercise()}>{isEditing ? "Update Exercise" : "Save Exercise"}</Button>
                                </div>

                                :
                                selectedTab === 'medications'?
                                <Button className="form-next-button" disabled={isSaving} onClick={handleSaveMedication}>{isEditing ? "Update Medication" : "Save Medication"}</Button>
                                :
                                selectedTab === 'muscles'?
                                <Button className="form-next-button" disabled={isSaving} onClick={handleSaveMuscle}>{isEditing ? "Update Muscle" : "Save Muscle"}</Button>
                                :
                                selectedTab === 'sideeffects'?
                                <Button className="form-next-button" disabled={isSaving} onClick={handleSaveSideEffect}>{isEditing ? "Update Side Effect" : "Save Side Effect"}</Button>
                                :
                                <Button className="form-next-button" disabled={isSaving} onClick={handleSaveCondition}>{isEditing ? "Update Condition" : "Save Condition"}</Button>


                            }
                        </div>
                    </div>
            ) : (
                <div style={{height:'auto', minHeight:'100vh', display:'flex', flexDirection:'column'}}>
                    <h1 className="database-page-header" style={{fontWeight:'normal'}}><b>VITOVA</b> DATABASE</h1>

                    <div style={{display:'flex', width:'100%', height:'8vh', backgroundColor:'transparent', marginBottom:'2vh'}}>
                        <div className="tab" onClick={()=>setSelectedTab('exercises')} style={{backgroundColor:`${colorPalette.steelBlue}`}}>
                            <label className="tab-label" style={{color:'#f1f1f1', fontWeight:selectedTab === 'exercises'?'bold':'normal', fontSize:'1.25em', fontStyle:'italic'}}>EXERCISES</label>
                            <FontAwesomeIcon style={{marginLeft:'10px'}} icon={faDumbbell} color="white"/>

                        </div>
                        <div className="tab" onClick={()=>setSelectedTab('medications')} style={{display:'flex', flex:1, justifyContent:'center', alignItems:'center', backgroundColor:`${colorPalette.steelBlue}ee`}}>
                            <label className="tab-label" style={{color:'#f1f1f1', fontWeight:selectedTab === 'medications'?'bold':'normal', fontSize:'1.25em', fontStyle:'italic'}}>MEDICATIONS</label>
                            <FontAwesomeIcon style={{marginLeft:'10px'}} icon={faPills} color="white"/>

                        </div>
                        <div className="tab" onClick={()=>setSelectedTab('conditions')} style={{display:'flex', flex:1, justifyContent:'center', alignItems:'center', backgroundColor:`${colorPalette.steelBlue}`}}>
                            <label className="tab-label" style={{color:'#f1f1f1', fontWeight:selectedTab === 'conditions'?'bold':'normal', fontSize:'1.25em', fontStyle:'italic'}}>CONDITIONS</label>
                            <FontAwesomeIcon style={{marginLeft:'10px'}} icon={faHeartPulse} color="white"/>
                        </div>
                        <div className="tab" onClick={()=>setSelectedTab('muscles')} style={{display:'flex', flex:1, justifyContent:'center', alignItems:'center', backgroundColor:`${colorPalette.steelBlue}ee`}}>
                            <label className="tab-label" style={{color:'#f1f1f1', fontWeight:selectedTab === 'muscles'?'bold':'normal', fontSize:'1.25em', fontStyle:'italic'}}>MUSCLES</label>
                            <FontAwesomeIcon style={{marginLeft:'10px'}} icon={faPerson} color="white"/>
                        </div>
                        <div className="tab" onClick={()=>setSelectedTab('sideeffects')} style={{display:'flex', flex:1, justifyContent:'center', alignItems:'center', backgroundColor:`${colorPalette.steelBlue}`}}>
                            <label className="tab-label" style={{color:'#f1f1f1', fontWeight:selectedTab === 'muscles'?'bold':'normal', fontSize:'1.25em', fontStyle:'italic'}}>SIDE EFFECTS</label>
                            <FontAwesomeIcon style={{marginLeft:'10px'}} icon={faPerson} color="white"/>
                        </div>

                    </div>
                    {selectedTab === 'exercises' &&
                        <div>
                        {/* Search Bar */}
                        <input
                            type="text"
                            placeholder="Search exercises..."
                            value={searchTerm}
                            className="database-page-search-bar"
                            onChange={(e) => handleSearch(e.target.value)}
                        />

                        {/* Filters */}
                        <div className="database-page-filters">
                            {/* <select value={forceFilter} className="database-page-filter-select" onChange={(e) => setForceFilter(e.target.value)}>
                                <option value="">All Forces</option>
                                <option value="pull">Pull</option>
                                <option value="push">Push</option>
                            </select>

                            <select value={levelFilter} className="database-page-filter-select" onChange={(e) => setLevelFilter(e.target.value)}>
                                <option value="">All Levels</option>
                                <option value="beginner">Beginner</option>
                                <option value="intermediate">Intermediate</option>
                                <option value="advanced">Advanced</option>
                            </select>

                            <select value={musclesFilter} className="database-page-filter-select" onChange={(e) => setMusclesFilter(e.target.value)}>
                                <option value="">All Muscle Groups</option>
                                <option value="quadriceps">Quads</option>
                                <option value="biceps">Biceps</option>
                                <option value="lats">Lats</option>
                                <option value="hamstring">Hamstrings</option>
                                <option value="abs">Abdominals</option>
                            </select>

                            <select value={conditionFilter} className="database-page-filter-select" onChange={(e) => setConditionFilter(e.target.value)}>
                                <option value="">No Conditions</option>
                                <option value="obesity">Obesity</option>
                                <option value="anxiety">Anxiety</option>
                                <option value="depression">Depression</option>
                            </select> */}

                            <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-start', alignItems: 'flex-start'}}>
                                        {/* File Upload Input */}
                                    <input
                                    type="file"
                                    accept=".csv,.json,.xlsx"
                                    onChange={handleExerciseFileUpload}
                                    style={{ marginLeft: "10px" }}
                                    />
                                <button style={{alignSelf:'flex-start', width:'100%'}} className="database-page-filter-button" onClick={showAddExercisePopup}>Add Exercise</button>
                                {/* <button className="database-page-filter-button" onClick={()=>handleSearch()}>Apply Filters</button> */}
                            </div>
                        </div>

                        {/* Exercise List */}
                        <ul className="database-page-exercise-list">
                            {filteredExercises.slice(0, 25).map((exercise, index) => (
                                // <li key={exercise.id} className="database-page-exercise-item" onClick={() => editExercise(index)}>
                                    <div className="exercise-card" onClick={() => editExercise(index)}>
                                        {/* Display the exercise image if available */}
                                        {exercise.images && exercise.images.length > 0 ?
                                            <div className="exercise-card-images" style={{ display: 'flex', gap: '10px', width: '100%', flexDirection:'column'}}>
                                                {exercise.images.map((image: string, index: number) => (
                                                    <div key={index} className="exercise-card-image" style={{ flex: '0 0 auto', height: 'calc(100% / ' + exercise.images.length + ')', padding: '5px' }}>
                                                        <img
                                                            src={`https://raw.githubusercontent.com/yuhonas/free-exercise-db/main/exercises/${image}`}
                                                            style={{ objectFit: 'contain', width: '100%', height: '100%' }}
                                                            alt={`exercise-image-${index}`}
                                                        />
                                                    </div>
                                                ))}
                                            </div>
                                            :
                                            <div className="exercise-card-image">
                                                <img     
                                                    src={`https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg?20200913095930`} 
                                                    alt={exercise.name} 
                                                />
                                            </div>
                                        }
                                        <div className="exercise-card-content">
                                            <h3 style={{ fontWeight:'bold', fontStyle:'italic', fontSize:'1.5em'}} className="exercise-card-title">{exercise.name.toUpperCase()}</h3>
                                            <p style={{ fontWeight:'bold', fontStyle:'italic'}}><strong>Type:</strong> {exercise.stepType as VitovaStepType}</p>
                                        </div>
                                    </div>

                                // </li>
                            ))}
                        </ul>

                    </div>
                    }
                    {selectedTab === 'medications' &&
                        <div>
      <div style={{ display: "flex", width: "100em", alignItems: "flex-start", flexDirection:'column', }}>
        {/* File Upload Input */}
        <input
          type="file"
          accept=".csv,.json,.xlsx"
          onChange={handleMedicationFileUpload}
          style={{ marginLeft: "10px" }}
        />

        {/* Filters */}
        <div
          className="database-page-filters"
          style={{ marginLeft: "10px", marginTop: "10px" }}
        >
          <button
            className="database-page-filter-button"
            onClick={showAddMedicationPopup}
            style={{ height: "6vh" }}
          >
            Add Medication
          </button>
        </div>
                {/* Search Bar */}
                <input
          style={{ height: "6vh", width: "50%" }}
          type="text"
          placeholder="Search medications..."
          value={searchTerm}
          className="database-page-search-bar"
          onChange={(e) => handleSearch(e.target.value)}
        />

      </div>

      <label style={{ marginLeft: "1em", marginBottom: "1em" }}>
        {`Number of results: ${filteredMedications.length}`}
      </label>

      {/* Medications List */}
      <ul className="medication-list">
        {filteredMedications.slice(0, 25).map((medication, index) => (
          <li
            key={medication._id || medication.medId}
            className="medication-item"
            onClick={() => editMedication(index)}
          >
            <div className="medication-card">
              <div className="medication-header">
                <h3 style={{ fontWeight: "bold", fontStyle: "italic" }}>
                  {medication.name.toUpperCase()}
                </h3>
              </div>
            </div>
          </li>
        ))}
      </ul>
                        </div>
                    }
                    {selectedTab === 'conditions' &&
                    <div>
                        <div style={{ display: 'flex', width: '100%', height:'100%',alignItems: 'flex-start', flexDirection:'column'}}>
                        <div>
                            <input type="file" accept=".xlsx, .xls" onChange={handleFileUpload} disabled={uploading} />
                                {uploading && <p>Uploading...</p>}
                            </div>
                            {/* Filters */}
                            <div className="database-page-filters" style={{ marginLeft: '5px', marginTop: '5px' }}>
                                <button
                                    className="database-page-filter-button"
                                    onClick={showAddConditionPopup}
                                    style={{ height: '4vh', fontSize: '0.8em', padding: '4px 6px' }}
                                >
                                    Add Condition
                                </button>
                            </div>

                            {/* Search Bar */}
                            <input
                                style={{ height: '4vh', width: '100%%', fontSize: '0.8em' }}
                                type="text"
                                placeholder="Search conditions..."
                                value={searchTerm}
                                className="database-page-search-bar"
                                onChange={(e) => handleSearch(e.target.value)}
                            />
                        </div>

                        <label style={{ marginLeft: '1em', marginBottom: '1em', fontSize: '0.8em' }}>
                            {`Number of results: ${filteredConditions.length}`}
                        </label>

                        {/* Conditions Table */}
                        <table className="conditions-table" style={{ width: '100%', borderCollapse: 'collapse', marginTop: '10px', fontSize: '0.7em' }}>
                        <thead>
                            <tr>
                            {/* General Information */}
                            <th rowSpan={2} style={{ border: '1px solid #ddd', padding: '4px', textAlign: 'center' }}>Name</th>
                            <th rowSpan={2} style={{ border: '1px solid #ddd', padding: '4px', textAlign: 'center' }}>Description</th>
                            <th rowSpan={2} style={{ border: '1px solid #ddd', padding: '4px', textAlign: 'center' }}>Medications</th>

                            {/* Grouped Categories */}
                            <th rowSpan={2} style={{ border: '1px solid #ddd', padding: '4px', textAlign: 'center' }}>Exercise Constraints</th>
                            <th colSpan={8} style={{ border: '1px solid #ddd', padding: '4px', textAlign: 'center' }}>Cardio Constraints</th>
                            <th colSpan={10} style={{ border: '1px solid #ddd', padding: '4px', textAlign: 'center' }}>Resistance Constraints</th>
                            <th colSpan={8} style={{ border: '1px solid #ddd', padding: '4px', textAlign: 'center' }}>Flexibility Constraints</th>
                            </tr>
                            <tr>
                            {/* Cardio Subheadings */}
                            <th style={{ border: '1px solid #ddd', padding: '4px' }}>RPE Min</th>
                            <th style={{ border: '1px solid #ddd', padding: '4px' }}>RPE Max</th>
                            <th style={{ border: '1px solid #ddd', padding: '4px' }}>Freq Min</th>
                            <th style={{ border: '1px solid #ddd', padding: '4px' }}>Freq Max</th>
                            <th style={{ border: '1px solid #ddd', padding: '4px' }}>Dur Min</th>
                            <th style={{ border: '1px solid #ddd', padding: '4px' }}>Dur Max</th>

                            {/* Resistance Subheadings */}
                            <th style={{ border: '1px solid #ddd', padding: '4px' }}>RPE Min</th>
                            <th style={{ border: '1px solid #ddd', padding: '4px' }}>RPE Max</th>
                            <th style={{ border: '1px solid #ddd', padding: '4px' }}>10%RM Min</th>
                            <th style={{ border: '1px solid #ddd', padding: '4px' }}>10%RM Max</th>
                            <th style={{ border: '1px solid #ddd', padding: '4px' }}>Freq Min</th>
                            <th style={{ border: '1px solid #ddd', padding: '4px' }}>Freq Max</th>
                            <th style={{ border: '1px solid #ddd', padding: '4px' }}>Dur Min</th>
                            <th style={{ border: '1px solid #ddd', padding: '4px' }}>Dur Max</th>
                            <th style={{ border: '1px solid #ddd', padding: '4px' }}>Sets Min</th>
                            <th style={{ border: '1px solid #ddd', padding: '4px' }}>Sets Max</th>
                            <th style={{ border: '1px solid #ddd', padding: '4px' }}>Reps Min</th>
                            <th style={{ border: '1px solid #ddd', padding: '4px' }}>Reps Max</th>
                            <th style={{ border: '1px solid #ddd', padding: '4px' }}>Weekly Ex Min</th>
                            <th style={{ border: '1px solid #ddd', padding: '4px' }}>Weekly Ex Max</th>

                            {/* Flexibility Subheadings */}
                            <th style={{ border: '1px solid #ddd', padding: '4px' }}>RPE Min</th>
                            <th style={{ border: '1px solid #ddd', padding: '4px' }}>RPE Max</th>
                            <th style={{ border: '1px solid #ddd', padding: '4px' }}>Freq Min</th>
                            <th style={{ border: '1px solid #ddd', padding: '4px' }}>Freq Max</th>
                            <th style={{ border: '1px solid #ddd', padding: '4px' }}>Dur Min</th>
                            <th style={{ border: '1px solid #ddd', padding: '4px' }}>Dur Max</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredConditions.map((condition, index) => (
                                <tr className="table-row" key={condition.id} onClick={() => editCondition(index)}>
                                    {/* General Information */}
                                    <td style={{ border: '1px solid #ddd', padding: '4px',}}>{condition.name.toUpperCase()}</td>
                                    <td style={{ border: '1px solid #ddd', padding: '4px' }}>{condition.description}</td>

                                    <td style={{ border: '1px solid #ddd', padding: '4px' }}>
                                        {condition.commonMedications.map((med) => med.name).join(', ')}
                                    </td>
                                    {condition.exerciseConstraints ?
                                    <td style={{ border: '1px solid #ddd', padding: '4px' }}>
                                        {condition.exerciseConstraints.map((c) => c).join(', ')}
                                    </td>
                                    :
                                    <td style={{ border: '1px solid #ddd', padding: '4px' }}>
                                    </td>
                                    }


                                    {/* Cardio Constraints */}
                                    <td style={{ border: '1px solid #ddd', padding: '4px' }}>{condition.cardioConstraints.rpeMin}</td>
                                    <td style={{ border: '1px solid #ddd', padding: '4px' }}>{condition.cardioConstraints.rpeMax}</td>
                                    <td style={{ border: '1px solid #ddd', padding: '4px' }}>{condition.cardioConstraints.weeklyFreqMin}</td>
                                    <td style={{ border: '1px solid #ddd', padding: '4px' }}>{condition.cardioConstraints.weeklyFreqMax}</td>
                                    <td style={{ border: '1px solid #ddd', padding: '4px' }}>{condition.cardioConstraints.durationMin}</td>
                                    <td style={{ border: '1px solid #ddd', padding: '4px' }}>{condition.cardioConstraints.durationMax}</td>

                                    {/* Resistance Constraints */}
                                    <td style={{ border: '1px solid #ddd', padding: '4px' }}>{condition.resistanceConstraints.rpeMin}</td>
                                    <td style={{ border: '1px solid #ddd', padding: '4px' }}>{condition.resistanceConstraints.rpeMax}</td>
                                    <td style={{ border: '1px solid #ddd', padding: '4px' }}>{condition.resistanceConstraints.percentOf10RepMaxMin}</td>
                                    <td style={{ border: '1px solid #ddd', padding: '4px' }}>{condition.resistanceConstraints.percentOf10RepMaxMax}</td>
                                    <td style={{ border: '1px solid #ddd', padding: '4px' }}>{condition.resistanceConstraints.weeklyFreqMin}</td>
                                    <td style={{ border: '1px solid #ddd', padding: '4px' }}>{condition.resistanceConstraints.weeklyFreqMax}</td>
                                    <td style={{ border: '1px solid #ddd', padding: '4px' }}>{condition.resistanceConstraints.durationMin}</td>
                                    <td style={{ border: '1px solid #ddd', padding: '4px' }}>{condition.resistanceConstraints.durationMax}</td>
                                    <td style={{ border: '1px solid #ddd', padding: '4px' }}>{condition.resistanceConstraints.setsMin}</td>
                                    <td style={{ border: '1px solid #ddd', padding: '4px' }}>{condition.resistanceConstraints.setsMax}</td>
                                    <td style={{ border: '1px solid #ddd', padding: '4px' }}>{condition.resistanceConstraints.repsMin}</td>
                                    <td style={{ border: '1px solid #ddd', padding: '4px' }}>{condition.resistanceConstraints.repsMax}</td>
                                    <td style={{ border: '1px solid #ddd', padding: '4px' }}>{condition.resistanceConstraints.weeklyExercisesMin}</td>
                                    <td style={{ border: '1px solid #ddd', padding: '4px' }}>{condition.resistanceConstraints.weeklyExercisesMax}</td>

                                    {/* Flexibility Constraints */}
                                    <td style={{ border: '1px solid #ddd', padding: '4px' }}>{condition.flexibilityConstraints.rpeMin}</td>
                                    <td style={{ border: '1px solid #ddd', padding: '4px' }}>{condition.flexibilityConstraints.rpeMax}</td>
                                    <td style={{ border: '1px solid #ddd', padding: '4px' }}>{condition.flexibilityConstraints.weeklyFreqMin}</td>
                                    <td style={{ border: '1px solid #ddd', padding: '4px' }}>{condition.flexibilityConstraints.weeklyFreqMax}</td>
                                    <td style={{ border: '1px solid #ddd', padding: '4px' }}>{condition.flexibilityConstraints.durationMin}</td>
                                    <td style={{ border: '1px solid #ddd', padding: '4px' }}>{condition.flexibilityConstraints.durationMax}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    </div>
                    // <ConditionsTable searchTerm={searchTerm} editCondition={editCondition} filteredConditions={filteredConditions} handleFileUpload={handleFileUpload}handleSearch={handleSearch} uploading={uploading} showAddConditionPopup={showAddConditionPopup}></ConditionsTable>
                    }
                    {selectedTab === 'muscles' &&
                        <div>
                            <div style={{ display: 'flex', width: '100em', alignItems: 'center' }}>
                            {/* Search Bar */}
                            <input
                                style={{ height: '6vh', width: '50%' }}
                                type="text"
                                placeholder="Search muscles..."
                                value={searchTerm}
                                className="database-page-search-bar"
                                onChange={(e) => handleSearch(e.target.value)}
                            />

                            {/* Filters */}
                            <div className="database-page-filters" style={{ marginLeft: '10px', marginTop:'10px'}}>
                                <button
                                className="database-page-filter-button"
                                onClick={showAddMusclePopup}
                                style={{ height: '6vh' }}
                                >
                                Add Muscle
                                </button>
                            </div>
                            </div>

                        <label style={{marginLeft:'1em', marginBottom:'1em'}}>{`Number of results: ${filteredMuscles.length}`}</label>

                        {/* MEdications List */}
                        <ul className="medication-list">
                            {filteredMuscles.slice(0, 25).map((muscle, index) => (
                                <li
                                key={muscle._id || muscle.id}
                                className="medication-item"
                                onClick={() => editMuscle(index)}
                                >
                                <div className="medication-card">
                                    <div className="medication-header">
                                    <h3 style={{ fontWeight:'bold', fontStyle:'italic'}}>{muscle.name.toUpperCase()}</h3>
                                    </div>
                                </div>
                                </li>
                            ))}
                        </ul>

                    </div>
                    }
                    {selectedTab === 'sideeffects' &&
                        <div>
                            <div style={{ display: 'flex', width: '100em', alignItems: 'center' }}>
                            {/* Search Bar */}
                            <input
                                style={{ height: '6vh', width: '50%' }}
                                type="text"
                                placeholder="Search side effects..."
                                value={searchTerm}
                                className="database-page-search-bar"
                                onChange={(e) => handleSearch(e.target.value)}
                            />

                            {/* Filters */}
                            <div className="database-page-filters" style={{ marginLeft: '10px', marginTop:'10px'}}>
                                <button
                                className="database-page-filter-button"
                                onClick={showAddSideEffectPopup}
                                style={{ height: '6vh' }}
                                >
                                Add Side Effect
                                </button>
                            </div>
                            </div>

                        <label style={{marginLeft:'1em', marginBottom:'1em'}}>{`Number of results: ${filteredSideEffects.length}`}</label>

                        {/* MEdications List */}
                        <ul className="medication-list">
                            {filteredSideEffects.slice(0, 25).map((se, index) => (
                                <li
                                key={se._id || se.id}
                                className="medication-item"
                                onClick={() => editSideEffect(index)}
                                >
                                <div className="medication-card">
                                    <div className="medication-header">
                                    <h3 style={{ fontWeight:'bold', fontStyle:'italic'}}>{se.name.toUpperCase()}</h3>
                                    </div>
                                </div>
                                </li>
                            ))}
                        </ul>

                    </div>
                    }
                </div>

            )}
        </div>
    );
};

export default DatabasePageLoggedInView;
