import { Box, Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import Spinner from "./Spinner";
import WorkoutStepsDiagram from "./WorkoutStepsDiagram";
import { User, VitovaReview } from "../models/user";
import { VitovaActivity, VitovaActivityStep, VitovaStepType } from "../models/vitovaPlan";
import { EMPTY_ACTIVITY } from "./templateObjects/ObjectTemplates";
import colorPalette from "../utils/colors";
import { Exercise } from "../models/exercise";
import { minutesToTimeString } from "../utils/FormattingFunctions";
import { Cancel, Delete, Remove, RemoveCircle } from "@mui/icons-material";
import { getActivityStressScore } from "../utils/StressLoadFunctions";
import ExerciseSelectionDialog from "./ExerciseSelectionDialog";
import { DBCondition } from "../models/condition";

type Props = {
  reviews: VitovaReview[];
  open:boolean;
  onDialogClose:()=>void;
  user:User;
  exercises:Exercise[];
  conditions:DBCondition[];
  onSaveAndSubmit:(reviews:VitovaReview[])=>void;
  onSave:(reviews:VitovaReview[])=>void;
};

const CustomActivityReviewDialog: React.FC<Props> = ({ reviews, open, onDialogClose, user, exercises, conditions, onSaveAndSubmit, onSave }) => {

    const [selectedReviewIndex, setSelectedReviewIndex] = useState<number>(0);
    const [userReviews, setUserReviews] = useState<VitovaReview[]>(reviews);
    const [currentUser, setCurrentUser] = useState<User>(user);

    const [isExerciseSelectionOpenId, setIsExerciseSelectionOpenId] = useState<string | null>(null);

    function addNewReview() {
        const today = new Date();

        let newActivity = { ...EMPTY_ACTIVITY };
        newActivity.id = `user_created_activity_${today}`;
        newActivity.compulsory = true;
        let newReview: VitovaReview = {
            id: `user_created_review_${today.getTime()}`, // Ensures a unique ID
            activityReviewData: newActivity,
            dateOfActivity: today,
            dateReviewed: today,
            saved: true,
            notes: ''
        };
    
        const updatedReviews = [...userReviews, newReview];
        setUserReviews(updatedReviews);
    }

    function addNewStep(stepType:Number){
        // ADD CARDIO STEP
        const currentSteps = userReviews[selectedReviewIndex].activityReviewData.steps;
        const newStep: VitovaActivityStep = {
            id: `step_${Date.now()}`,
            label: 'New Step',
            type: stepType === 1 ? VitovaStepType.CARDIO:stepType === 2? VitovaStepType.STRENGTH : VitovaStepType.FLEXIBILITY,
            description:'',
            strength:{
                rpe:5,
                percentOf10RepMax:50,
            },
            cardio:{
                rpe:5,
                zone:2
            },
            flexibility:{
                rpe:3,
                sets:3,
                holdDuration:30
            },
        };
        const updatedSteps = [...currentSteps, newStep];
        updateReviewField(userReviews[selectedReviewIndex].activityReviewData, 'activityReviewData.steps', updatedSteps);
    }

    function removeStep(stepId: string) {
        // Ensure selectedReviewIndex is valid
        if (selectedReviewIndex < 0 || selectedReviewIndex >= userReviews.length) {
            console.error("Invalid selectedReviewIndex:", selectedReviewIndex);
            return;
        }
    
        // Get current steps from the selected review
        const currentSteps = userReviews[selectedReviewIndex].activityReviewData.steps;
    
        // Remove the step with the matching stepId
        const updatedSteps = currentSteps.filter(step => step.id !== stepId);
    
        // Update the review with the new steps array
        updateReviewField(userReviews[selectedReviewIndex].activityReviewData, 'activityReviewData.steps', updatedSteps);
    }

    function removeReview(reviewId: string) {
        // Ensure selectedReviewIndex is valid
    
        // Get current steps from the selected review
        const currentReviews = [...userReviews];

        const updatedReviews = currentReviews.filter(r=>r.id!==reviewId)
    
        // Remove the step with the matching stepId
        setSelectedReviewIndex(0)
        setUserReviews(updatedReviews);
    }
    
    const selectExercise = (stepId: string, type: VitovaStepType, exercise: Exercise) => {
        // Clone the steps array deeply to avoid mutating state directly
        const currentSteps = structuredClone(userReviews[selectedReviewIndex].activityReviewData.steps);
    
        // Find the step that needs updating
        const stepIndex = currentSteps.findIndex((s:VitovaActivityStep) => s.id === stepId);
        if (stepIndex === -1) return; // Exit if the step isn't found
    
        // Ensure the property exists before modifying it
        if (!currentSteps[stepIndex][type]) {
            currentSteps[stepIndex][type] = {}; // Initialize if missing
        }
    
        // Assign the new exercise
        currentSteps[stepIndex][type].exercise = exercise;
    
        // Update the review field
        updateReviewField(userReviews[selectedReviewIndex].activityReviewData, `activityReviewData.steps`, currentSteps);
        setIsExerciseSelectionOpenId(null);
    };
    
    
    

    async function updateReviewField(activity: VitovaActivity, fieldPath: string, value: any) {
        const findReview = userReviews.find(r => r.activityReviewData.id === activity.id);
        
        if (!findReview) {
            console.error("Review not found for the given activity.");
            return;
        }
    
        // Helper function to update a nested field based on the provided fieldPath
        const updateNestedField = (obj: any, path: string, value: any): void => {
            const keys = path.split('.'); // Split path into an array of keys
            let currentObj = obj;
    
            // Traverse the object to the last key
            keys.slice(0, -1).forEach((key: string) => {
                if (!currentObj[key]) {
                    currentObj[key] = {}; // If the key doesn't exist, create an empty object
                }
                currentObj = currentObj[key];
            });
    
            // Set the value at the final key
            currentObj[keys[keys.length - 1]] = value;
        };
    
        // Update the specific field using the fieldPath
        const updatedReview = { 
            ...findReview,
        };
    
        updateNestedField(updatedReview, fieldPath, value);

        const updatedReviews = userReviews.map(r =>
            r.activityReviewData.id === activity.id ? updatedReview : r
        );
        setUserReviews(updatedReviews);
    
        // Ensure currentUser.vitovaReviews is being updated correctly
        // const updatedUser = {
        //     ...currentUser,
        //     vitovaReviews: currentUser.vitovaReviews.map(userReviews[selectedReviewIndex] => 
        //         userReviews[selectedReviewIndex].id === updatedReview.id ? updatedReview : userReviews[selectedReviewIndex]
        //     )
        // };
    

        // setCurrentUser(updatedUser);
        // setSelectedReview(updatedReview);
    }

  return (
    <Dialog open={open} onClose={onDialogClose} fullWidth maxWidth="lg" >

    <div style={{ display: 'flex', justifyContent: 'center', width: '100%', height:'100%'}}>
        <Container
            style={{
                color:"black",
                textAlign:'left',
                backgroundColor:'transparent',
                // maxWidth: '80em',  // Ensures it doesn't exceed 80% of viewport width
                width: '100%',  // Allows flexibility without overflowing
                maxHeight: '70vh',  // Limits height to prevent excessive stretching
                overflowY: 'auto',  // Enables scrolling if needed
                overflowX: 'hidden',  // Prevents horizontal overflow
                alignItems:'center',
                borderRadius: '10px',
                padding: '1rem',
            }}
        >
            <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                <Paper
                    sx={{
                        backgroundColor: 'white', 
                        padding: '0.75rem 1rem', 
                        borderRadius: '5px',
                        width:'96%',
                        boxShadow:0,
                        border:'1px solid lightgray',
                        textAlign: 'center', 
                    }}
                >
                    <DialogTitle
                        sx={{
                            fontStyle: 'italic',
                            fontWeight: 'bold',
                            margin: 0,  // Remove extra margin
                            padding: 0, // Adjust to fit within Paper
                        }}
                    >
                        {`LET'S SEE HOW YOU GOT ON WITH TODAYS'S ACTIVITIES!`}
                    </DialogTitle>
                </Paper>
            </Box>
            <DialogContent>
                {/* Activity Selector */}
                <FormControl style={{backgroundColor:'white', borderRadius:'5px'}} fullWidth sx={{ mb: 2, mt:2 }}>
                    <InputLabel>Select Activity</InputLabel>
                    <Select
                        label={'Select Activity'}
                        value={selectedReviewIndex}
                        onChange={(e) => {setSelectedReviewIndex(Number(e.target.value));}}
                    >
                        {userReviews.map(r=>r.activityReviewData).map((activity, index:number) => (
                            <MenuItem key={index} value={index}>
                                {activity.title.toUpperCase()}
                            </MenuItem>
                        ))}
                        <MenuItem value={userReviews.length} style={{fontWeight:'bold', fontStyle:'italic', color:colorPalette.steelBlue}} onClick={()=>{addNewReview();}}>
                            ADD NEW ACTIVITY
                        </MenuItem>
                    </Select>
                </FormControl>
                {!userReviews[selectedReviewIndex] ?
                // <Spinner text="SELECT An Activity To Get Review"/>
                <label style={{fontSize:'1.25em', fontStyle:'italic', color:colorPalette.steelBlue, width:'100%', textAlign:'center'}}>SELECT AN ACTIVITY TO START YOUR REVIEW</label>
                    :
                    // <></>
                <>
                {userReviews.length > 0 && selectedReviewIndex >= 0 && selectedReviewIndex < userReviews.length && userReviews[selectedReviewIndex].activityReviewData && (
                    <Paper variant="outlined" sx={{ p: 2, mt: 0 }}>
                        <Cancel style={{color:colorPalette.softRed, width:'1.5em', height:'1.5em', marginBottom:'0.5em'}} onClick={()=>{removeReview(userReviews[selectedReviewIndex].id)}}/>
                        <WorkoutStepsDiagram
                            user={currentUser}
                            steps={userReviews[selectedReviewIndex].activityReviewData.steps}
                            exercises={exercises}
                            key={userReviews[selectedReviewIndex].activityReviewData.id}
                        />
                        <TextField 
                        sx={{ mt: 2 }} 
                        style={{ fontStyle: 'italic', color: colorPalette.steelBlue }} 
                        // variant="h6"
                        fullWidth
                        onChange={(e)=>updateReviewField(userReviews[selectedReviewIndex].activityReviewData, 'activityReviewData.title', e.target.value)}
                        value={userReviews[selectedReviewIndex].activityReviewData.title?.toUpperCase() || ''}
                        placeholder="Activity Title"

                        >
                        </TextField>

                        <Grid container justifyContent="space-between" sx={{ mt: 1 }}>
                            <Typography variant="body2">
                                {minutesToTimeString(userReviews[selectedReviewIndex].activityReviewData.plannedDuration || 0)}
                            </Typography>
                            <Typography variant="body2">
                                {userReviews[selectedReviewIndex].activityReviewData.plannedDistance
                                    ? `${userReviews[selectedReviewIndex].activityReviewData.plannedDistance} KM`
                                    : '-- KM'}
                            </Typography>
                            <Typography variant="body2">
                                {userReviews[selectedReviewIndex].activityReviewData.plannedRPE
                                    ? userReviews[selectedReviewIndex].activityReviewData.plannedRPE
                                    : '-- '}{' '}
                                / 10 RPE
                            </Typography>
                            <Typography variant="body2">{`${getActivityStressScore(userReviews[selectedReviewIndex].activityReviewData, currentUser)} TSS`}</Typography>
                        </Grid>

                    </Paper>
                )}
                <Typography variant="subtitle1" sx={{ mt: 3 }}>
                    Steps
                </Typography>
                <FormControl style={{backgroundColor:'white', borderRadius:'5px'}} fullWidth sx={{ mb: 2, mt:2 }}>
                    <InputLabel>Add A Step</InputLabel>
                    <Select
                        label={'Select Step Type'}
                        value={0}
                        onChange={(e) => {addNewStep(Number(e.target.value)); e.preventDefault();e.stopPropagation()}}
                    >
                        <MenuItem disabled value={0}>
                            {`Select step type`}
                        </MenuItem>
                        {[VitovaStepType.CARDIO, VitovaStepType.STRENGTH, VitovaStepType.FLEXIBILITY].map((type, index) => (
                            <MenuItem key={index+1} value={index+1}>
                                {`Add a ${type.toUpperCase()} step`}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                
                {userReviews[selectedReviewIndex].activityReviewData.steps.map((step:VitovaActivityStep)=>(
                    step.type === VitovaStepType.STRENGTH ?
                    
                    (
                        
                        <TableContainer style={{backgroundColor:step.isTestEffort?`${colorPalette.softRed}33`:'white'}} component={Paper} sx={{ mt: 3 }}>
                        <ExerciseSelectionDialog stepId={step.id} key={step.id} open={isExerciseSelectionOpenId === step.id} conditions={conditions} exercises={exercises} onExerciseSelected={(exercise, stepId) => selectExercise(stepId, step.type, exercise)} onClose={()=>setIsExerciseSelectionOpenId(null)}/>

                        <Cancel style={{color:colorPalette.softRed, marginLeft:'5px', marginTop:'5px', width:'1.5em', height:'1.5em'}} onClick={()=>{removeStep(step.id)}}/>
                        <DialogTitle style={{ fontStyle: 'italic', fontSize:'1.25em'}}>
                            {step.strength?.exercise && step.strength?.exercise.name.toUpperCase()}
                        </DialogTitle>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell align="center">
                                        <Typography style={{ fontWeight: 'bold', fontStyle: 'italic' }} variant="subtitle1">
                                            Completed
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            <TableRow>
                                    <TableCell style={{ fontWeight: 'bold', fontStyle: 'italic' }} align="center">
                                        <Typography>Exercise</Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                    <TextField
                                            value={
                                                userReviews[selectedReviewIndex]?.activityReviewData.steps.find(s => s.id === step.id)?.strength?.exercise?.name || ''
                                            }
                                            onClick={
                                                ()=>{
                                                    setIsExerciseSelectionOpenId(step.id)
                                                }
                                            }
                                            variant="outlined"
                                            size="small"
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ fontWeight: 'bold', fontStyle: 'italic' }} align="center">
                                        <Typography>Sets</Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                    <TextField
                                        value={
                                            userReviews[selectedReviewIndex].activityReviewData.steps.find(s => s.id === step.id)?.strength?.sets || ''
                                        }
                                        onChange={(e) => {
                                            const updatedSteps = userReviews[selectedReviewIndex]?.activityReviewData.steps.map((s) =>
                                                s.id === step.id ? { ...s, strength: { ...s.strength, sets: e.target.value } } : s
                                            );

                                            updateReviewField(
                                                userReviews[selectedReviewIndex].activityReviewData,
                                                'activityReviewData.steps',
                                                updatedSteps
                                            );
                                        }}
                                        variant="outlined"
                                        size="small"
                                    />
                                </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ fontWeight: 'bold', fontStyle: 'italic' }} align="center">
                                        <Typography>Reps</Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <TextField
                                        value={
                                            userReviews[selectedReviewIndex]?.activityReviewData.steps.find(s => s.id === step.id)?.strength?.reps || ''
                                        }
                                        onChange={(e) => {
                                            const updatedSteps = userReviews[selectedReviewIndex]?.activityReviewData.steps.map((s) =>
                                                s.id === step.id ? { ...s, strength: { ...s.strength, reps: e.target.value } } : s
                                            );

                                            updateReviewField(
                                                userReviews[selectedReviewIndex].activityReviewData,
                                                'activityReviewData.steps',
                                                updatedSteps
                                            );
                                        }}
                                            variant="outlined"
                                            size="small"
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ fontWeight: 'bold', fontStyle: 'italic' }} align="center">
                                        <Typography>Weight (KG)</Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <TextField
                                            value={
                                                userReviews[selectedReviewIndex]?.activityReviewData.steps.find(s => s.id === step.id)?.strength?.weight || ''
                                            }
                                            onChange={(e) => {
                                                const updatedSteps = userReviews[selectedReviewIndex]?.activityReviewData.steps.map((s) =>
                                                    s.id === step.id ? { ...s, strength: { ...s.strength, weight: e.target.value } } : s
                                                );

                                                updateReviewField(
                                                    userReviews[selectedReviewIndex].activityReviewData,
                                                    'activityReviewData.steps',
                                                    updatedSteps
                                                );
                                            }}
                                            variant="outlined"
                                            size="small"
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ fontWeight: 'bold', fontStyle: 'italic' }} align="center">
                                        <Typography>RPE (/10)</Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <TextField
                                        value={
                                            userReviews[selectedReviewIndex]?.activityReviewData.steps.find(s => s.id === step.id)?.strength?.rpe || ''
                                        }
                                        onChange={(e) => {
                                            const updatedSteps = userReviews[selectedReviewIndex]?.activityReviewData.steps.map((s) =>
                                                s.id === step.id ? { ...s, strength: { ...s.strength, rpe: e.target.value } } : s
                                            );

                                            updateReviewField(
                                                userReviews[selectedReviewIndex].activityReviewData,
                                                'activityReviewData.steps',
                                                updatedSteps
                                            );
                                        }}
                                            variant="outlined"
                                            size="small"
                                        />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                        </TableContainer>
                    )
                    :
                    step.type === VitovaStepType.CARDIO ?
                    (
                        <TableContainer style={{backgroundColor:step.isTestEffort?`${colorPalette.softRed}33`:'white'}} component={Paper} sx={{ mt: 3 }}>
                        <ExerciseSelectionDialog stepId={step.id} key={step.id} open={isExerciseSelectionOpenId === step.id} conditions={conditions} exercises={exercises} onExerciseSelected={(exercise, stepId) => selectExercise(stepId, step.type, exercise)} onClose={()=>setIsExerciseSelectionOpenId(null)}/>
                        <Cancel style={{color:colorPalette.softRed, marginLeft:'5px', marginTop:'5px', width:'1.5em', height:'1.5em'}} onClick={()=>{removeStep(step.id)}}/>

                        <DialogTitle style={{ fontStyle: 'italic', fontSize:'1.25em'}}>
                            {step.cardio?.exercise && step.cardio?.exercise.name.toUpperCase()}
                        </DialogTitle>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell align="center">
                                        <Typography style={{ fontWeight: 'bold', fontStyle: 'italic' }} variant="subtitle1">
                                            Completed
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            <TableRow>
                                    <TableCell style={{ fontWeight: 'bold', fontStyle: 'italic' }} align="center">
                                        <Typography>Exercise</Typography>
                                    </TableCell>

                                    <TableCell align="center">
                                        <TextField
                                            value={
                                                userReviews[selectedReviewIndex]?.activityReviewData.steps.find(s => s.id === step.id)?.cardio?.exercise?.name || ''
                                            }
                                            onClick={
                                                ()=>{
                                                    setIsExerciseSelectionOpenId(step.id)
                                                }
                                            }
                                            variant="outlined"
                                            size="small"
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ fontWeight: 'bold', fontStyle: 'italic' }} align="center">
                                        <Typography>Duration</Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <TextField
                                            value={
                                                userReviews[selectedReviewIndex]?.activityReviewData.steps.find(s => s.id === step.id)?.cardio?.duration || ''
                                            }
                                            onChange={(e) => {
                                                const updatedSteps = userReviews[selectedReviewIndex]?.activityReviewData.steps.map((s) =>
                                                    s.id === step.id ? { ...s, cardio: { ...s.cardio, duration: e.target.value } } : s
                                                );

                                                updateReviewField(
                                                    userReviews[selectedReviewIndex].activityReviewData,
                                                    'activityReviewData.steps',
                                                    updatedSteps
                                                );
                                            }}
                                            variant="outlined"
                                            size="small"
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ fontWeight: 'bold', fontStyle: 'italic' }} align="center">
                                        <Typography>Distance</Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <TextField
                                        value={
                                            userReviews[selectedReviewIndex]?.activityReviewData.steps.find(s => s.id === step.id)?.cardio?.distance || ''
                                        }
                                        onChange={(e) => {
                                            const updatedSteps = userReviews[selectedReviewIndex]?.activityReviewData.steps.map((s) =>
                                                s.id === step.id ? { ...s, cardio: { ...s.cardio, distance: e.target.value } } : s
                                            );

                                            updateReviewField(
                                                userReviews[selectedReviewIndex].activityReviewData,
                                                'activityReviewData.steps',
                                                updatedSteps
                                            );
                                        }}
                                            variant="outlined"
                                            size="small"
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ fontWeight: 'bold', fontStyle: 'italic' }} align="center">
                                        <Typography>RPE (/10)</Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <TextField
                                        value={
                                            userReviews[selectedReviewIndex]?.activityReviewData.steps.find(s => s.id === step.id)?.cardio?.rpe || ''
                                        }
                                        onChange={(e) => {
                                            const updatedSteps = userReviews[selectedReviewIndex]?.activityReviewData.steps.map((s) =>
                                                s.id === step.id ? { ...s, cardio: { ...s.cardio, rpe: e.target.value } } : s
                                            );

                                            updateReviewField(
                                                userReviews[selectedReviewIndex].activityReviewData,
                                                'activityReviewData.steps',
                                                updatedSteps
                                            );
                                        }}
                                            variant="outlined"
                                            size="small"
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ fontWeight: 'bold', fontStyle: 'italic' }} align="center">
                                        <Typography>Heart Rate</Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <TextField
                                        value={
                                            userReviews[selectedReviewIndex]?.activityReviewData.steps.find(s => s.id === step.id)?.cardio?.heartRate || ''
                                        }
                                        onChange={(e) => {
                                            const updatedSteps = userReviews[selectedReviewIndex]?.activityReviewData.steps.map((s) =>
                                                s.id === step.id ? { ...s, cardio: { ...s.cardio, heartRate: e.target.value } } : s
                                            );

                                            updateReviewField(
                                                userReviews[selectedReviewIndex].activityReviewData,
                                                'activityReviewData.steps',
                                                updatedSteps
                                            );
                                        }}
                                            variant="outlined"
                                            size="small"
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ fontWeight: 'bold', fontStyle: 'italic' }} align="center">
                                        <Typography>Pace (m/s)</Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <TextField
                                        value={
                                            userReviews[selectedReviewIndex]?.activityReviewData.steps.find(s => s.id === step.id)?.cardio?.pace || ''
                                        }
                                        onChange={(e) => {
                                            const updatedSteps = userReviews[selectedReviewIndex]?.activityReviewData.steps.map((s) =>
                                                s.id === step.id ? { ...s, cardio: { ...s.cardio, pace: e.target.value } } : s
                                            );

                                            updateReviewField(
                                                userReviews[selectedReviewIndex].activityReviewData,
                                                'activityReviewData.steps',
                                                updatedSteps
                                            );
                                        }}
                                            variant="outlined"
                                            size="small"
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ fontWeight: 'bold', fontStyle: 'italic' }} align="center">
                                        <Typography>Power (watts)</Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <TextField
                                        value={
                                            userReviews[selectedReviewIndex]?.activityReviewData.steps.find(s => s.id === step.id)?.cardio?.power || ''
                                        }
                                        onChange={(e) => {
                                            const updatedSteps = userReviews[selectedReviewIndex]?.activityReviewData.steps.map((s) =>
                                                s.id === step.id ? { ...s, cardio: { ...s.cardio, power: e.target.value } } : s
                                            );

                                            updateReviewField(
                                                userReviews[selectedReviewIndex].activityReviewData,
                                                'activityReviewData.steps',
                                                updatedSteps
                                            );
                                        }}
                                            variant="outlined"
                                            size="small"
                                        />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                        </TableContainer>
                    )
                    :
                    step.type === VitovaStepType.FLEXIBILITY ?
                    (
                        <TableContainer style={{backgroundColor:step.isTestEffort?`${colorPalette.softRed}33`:'white'}} component={Paper} sx={{ mt: 3 }}>
                        <Cancel style={{color:colorPalette.softRed, marginLeft:'5px', marginTop:'5px', width:'1.5em', height:'1.5em'}} onClick={()=>{removeStep(step.id)}}/>

                        <DialogTitle style={{ fontStyle: 'italic', fontSize:'1.25em'}}>
                            {step.flexibility?.exercise && step.flexibility?.exercise.name.toUpperCase()}
                        </DialogTitle>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell align="center">
                                        <Typography style={{ fontWeight: 'bold', fontStyle: 'italic' }} variant="subtitle1">
                                            Completed
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell style={{ fontWeight: 'bold', fontStyle: 'italic' }} align="center">
                                        <Typography>Sets</Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <TextField
                                            value={
                                                userReviews[selectedReviewIndex]?.activityReviewData.steps.find(s => s.id === step.id)?.flexibility?.sets || ''
                                            }
                                            onChange={(e) => {
                                                const updatedSteps = userReviews[selectedReviewIndex]?.activityReviewData.steps.map((s) =>
                                                    s.id === step.id ? { ...s, flexibility: { ...s.flexibility, sets: e.target.value } } : s
                                                );

                                                updateReviewField(
                                                    userReviews[selectedReviewIndex].activityReviewData,
                                                    'activityReviewData.steps',
                                                    updatedSteps
                                                );
                                            }}
                                            variant="outlined"
                                            size="small"
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ fontWeight: 'bold', fontStyle: 'italic' }} align="center">
                                        <Typography>Hold Duration</Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <TextField
                                            value={
                                                userReviews[selectedReviewIndex]?.activityReviewData.steps.find(s => s.id === step.id)?.flexibility?.holdDuration || ''
                                            }
                                            onChange={(e) => {
                                                const updatedSteps = userReviews[selectedReviewIndex]?.activityReviewData.steps.map((s) =>
                                                    s.id === step.id ? { ...s, flexibility: { ...s.flexibility, holdDuration: e.target.value } } : s
                                                );

                                                updateReviewField(
                                                    userReviews[selectedReviewIndex].activityReviewData,
                                                    'activityReviewData.steps',
                                                    updatedSteps
                                                );
                                            }}
                                            variant="outlined"
                                            size="small"
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ fontWeight: 'bold', fontStyle: 'italic' }} align="center">
                                        <Typography>RPE (/10)</Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <TextField
                                            value={
                                                userReviews[selectedReviewIndex]?.activityReviewData.steps.find(s => s.id === step.id)?.flexibility?.rpe || ''
                                            }
                                            onChange={(e) => {
                                                const updatedSteps = userReviews[selectedReviewIndex]?.activityReviewData.steps.map((s) =>
                                                    s.id === step.id ? { ...s, flexibility: { ...s.flexibility, rpe: e.target.value } } : s
                                                );

                                                updateReviewField(
                                                    userReviews[selectedReviewIndex].activityReviewData,
                                                    'activityReviewData.steps',
                                                    updatedSteps
                                                );
                                            }}
                                            variant="outlined"
                                            size="small"
                                        />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                        </TableContainer>
                    )
                    :
                    (
                        <TableContainer component={Paper} sx={{ mt: 3 }}>
                        <Cancel style={{color:colorPalette.softRed, marginLeft:'5px', marginTop:'5px', width:'1.5em', height:'1.5em'}} onClick={()=>{removeStep(step.id)}}/>

                        <DialogTitle style={{ fontStyle: 'italic', fontSize:'1.25em'}}>
                            {'Daily Activity'}
                        </DialogTitle>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell align="center">
                                        <Typography style={{ fontWeight: 'bold', fontStyle: 'italic' }} variant="subtitle1">
                                            Completed
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell style={{ fontWeight: 'bold', fontStyle: 'italic' }} align="center">
                                        <Typography>Exercise</Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <TextField
                                            value={
                                                userReviews[selectedReviewIndex]?.activityReviewData.steps.find(s => s.id === step.id)?.choiceday?.exercise || ''
                                            }
                                            onChange={(e) => {
                                                const updatedSteps = userReviews[selectedReviewIndex]?.activityReviewData.steps.map((s) =>
                                                    s.id === step.id ? { ...s, choiceday: { ...s.choiceday, exercise: e.target.value } } : s
                                                );

                                                updateReviewField(
                                                    userReviews[selectedReviewIndex].activityReviewData,
                                                    'activityReviewData.steps',
                                                    updatedSteps
                                                );
                                            }}
                                            variant="outlined"
                                            size="small"
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ fontWeight: 'bold', fontStyle: 'italic' }} align="center">
                                        <Typography>Duration</Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <TextField
                                            value={
                                                userReviews[selectedReviewIndex]?.activityReviewData.steps.find(s => s.id === step.id)?.choiceday?.duration || ''
                                            }
                                            onChange={(e) => {
                                                const updatedSteps = userReviews[selectedReviewIndex]?.activityReviewData.steps.map((s) =>
                                                    s.id === step.id ? { ...s, choiceday: { ...s.choiceday, duration: e.target.value } } : s
                                                );

                                                updateReviewField(
                                                    userReviews[selectedReviewIndex].activityReviewData,
                                                    'activityReviewData.steps',
                                                    updatedSteps
                                                );
                                            }}
                                            variant="outlined"
                                            size="small"
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ fontWeight: 'bold', fontStyle: 'italic' }} align="center">
                                        <Typography>RPE (/10)</Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <TextField
                                            value={
                                                userReviews[selectedReviewIndex]?.activityReviewData.steps.find(s => s.id === step.id)?.choiceday?.rpe || ''
                                            }
                                            onChange={(e) => {
                                                const updatedSteps = userReviews[selectedReviewIndex]?.activityReviewData.steps.map((s) =>
                                                    s.id === step.id ? { ...s, choiceday: { ...s.choiceday, rpe: e.target.value } } : s
                                                );

                                                updateReviewField(
                                                    userReviews[selectedReviewIndex].activityReviewData,
                                                    'activityReviewData.steps',
                                                    updatedSteps
                                                );
                                            }}
                                            variant="outlined"
                                            size="small"
                                        />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                        </TableContainer>
                    )
                ))}

                <Typography variant="subtitle1" sx={{ mt: 3 }}>
                    Description
                </Typography>
                <TextField 
                        sx={{ mt: 2 }} 
                        style={{ fontStyle: 'italic', color: colorPalette.steelBlue }} 
                        // variant="h6"
                        fullWidth
                        onChange={(e)=>updateReviewField(userReviews[selectedReviewIndex].activityReviewData, 'activityReviewData.description', e.target.value)}
                        value={userReviews[selectedReviewIndex].activityReviewData.description || ''}
                        placeholder="Activity Description"
                        >
                        </TextField>

                {/* <Typography variant="body2" color="textSecondary">{userReviews[selectedReviewIndex].activityReviewData.description}
                </Typography> */}

                <Typography variant="subtitle1" sx={{ mt: 3 }}>
                    Post-activity comments
                </Typography>
                <TextField
                    value={userReviews[selectedReviewIndex]?.notes}
                    onChange={(e) => updateReviewField(userReviews[selectedReviewIndex].activityReviewData, 'notes', e.target.value)}
                    fullWidth
                    multiline
                    rows={3}
                    placeholder="Enter a new comment"
                    variant="outlined"
                />
                </>
                }

            </DialogContent>
            <DialogActions>
                <Button onClick={()=>onSaveAndSubmit(userReviews)} variant="contained" color="primary">Save Activities</Button>
                <Button onClick={()=>onDialogClose()} color="primary">Close Without Saving</Button>
            </DialogActions>
        </Container>
    </div>
    </Dialog>

  );
};

export default CustomActivityReviewDialog;
