import privacyPolicyPdf from '../localdata/vitovaprivacypolicy.pdf'
import colorPalette from '../utils/colors';
const PrivacyPage = () => {
    return ( 
        <div>
            <p style={{color:colorPalette.steelBlue, fontWeight:'bold', fontStyle:'italic', fontSize:'1.5vw', marginLeft:'5%', marginTop:'2%'}}>READ OUR PRIVACY POLICY BELOW</p>
            <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100vw',flexDirection:'column'}}>
            <div style={{height:'3px', backgroundColor:colorPalette.steelBlue, width:'90%', marginBottom:'20px'}}></div>

            <iframe
                src={privacyPolicyPdf}
                width="90%"
                height="600px"
                title={'PrivacyPolicy'}
                style={{ border: "none" }}
                ></iframe>
            </div>

        </div>
     );
}
 
export default PrivacyPage;