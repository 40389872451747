import { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { resetPassword, ResetPasswordBody } from "../network/api";
import { Button, Form } from "react-bootstrap";
import colorPalette from "../utils/colors";
import { User } from "../models/user";

interface HelpPageProps {
    loggedInUser: User | null
}

const HelpPage = ({ loggedInUser, }: HelpPageProps) => {
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e:any) => {

    };

    return (
        <div className="modal-overlay">
        <div className="modal-content fade-in" style={{ borderRadius: '5px', backgroundColor: '#ffffff', maxHeight:'80vh', maxWidth:'70em',overflow:'scroll'}}>
            <Form className="signup-form" style={{ borderRadius: '0px', textAlign:'center'}}>
                <h3 style={{ fontSize: '2em', fontWeight: 'bold', color: colorPalette.steelBlue, fontStyle: 'italic' }}>EXPERIENCING PROBLEMS?</h3>
                <p style={{ fontSize: '1.25em', color: colorPalette.steelBlue, fontStyle: 'italic' }}>
                    <b>VITOVA</b> is an ongoing project, and we sincerely appreciate your patience as we continue to enhance and refine the platform. If you encounter any issues or have feedback that could help us improve your experience, we would greatly value your input. Please feel free to share any concerns or suggestions with us.
                </p>
                <>
                <Form.Group controlId="formBasicMessage">
                    <Form.Label style={{ color: '#454545', fontStyle:'italic'}}>LET US KNOW THROUGH AN EMAIL</Form.Label>
                    {/* <Form.Control 
                        as="textarea" 
                        rows={6} 
                        placeholder="Enter your message here..." 
                        onChange={(e) => setMessage(e.currentTarget.value)}
                        value={message}
                        className="user-form-input"
                    /> */}
                </Form.Group>

                <div className="button-group">
                    <Button 
                        variant="primary" 
                        style={{ borderRadius: '0px', width: '100%', backgroundColor: colorPalette.steelBlue }} 
                        className="submit-button"
                        disabled={loading}
                        onClick={() => window.location.href = "mailto:info@vitovaltd.com?subject=Trial%20Feedback"}
                    >
                        CONTACT US
                    </Button>
                </div>

                </>
            </Form>
        </div>
    </div>
    );
};

export default HelpPage;
