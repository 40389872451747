import * as Api from "../network/api";
import {User } from '../models/user';
import '../styles/PlanBuilderPage.css';
import '../styles/SessionReview.css'
import { useState, useEffect, useRef, act } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight, faBan, faBarsProgress, faBed, faBicycle, faBorderNone, faCalendar, faCalendarCheck, faChevronLeft, faCopy, faCross, faDumbbell, faExclamationTriangle, faHandSparkles, faNotdef, faPerson, faPlus, faRemove, faRunning, faSave, faStar, faStarAndCrescent, faSwimmer, faTrash, faUpload } from "@fortawesome/free-solid-svg-icons";
import colorPalette from "../utils/colors";
import { Alert, Button, Spinner } from "react-bootstrap";
import { Exercise, VitovaExerciseCategory, VitovaExerciseEquipmentNeeded, VitovaExerciseForceType, VitovaExerciseLevel, VitovaExerciseMechanic } from "../models/exercise";
import { DBCondition, Symptom } from "../models/condition";
import { InputLabel, MenuItem, Select, TextField, FormControl, FormLabel, DialogActions, Dialog, DialogTitle, DialogContent, Paper, FormControlLabel, Checkbox, alertTitleClasses } from "@mui/material";
import { VitovaActivity, VitovaActivityStep, VitovaActivityType, VitovaDay, VitovaPlan, VitovaStepType, VitovaWeek } from "../models/vitovaPlan";
import { CheckBox, Label } from "@mui/icons-material";
import { getStressScore } from "../utils/StressLoadFunctions";
import GeneralChartComponent from "./GeneralChartComponent";
import { ObjectId } from 'bson'; // Import ObjectId
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { EMPTY_EXERCISE } from "./templateObjects/ObjectTemplates";
import { loadChoiceDayFromTemplate, loadFlexibilityActivityFromTemplate, loadFlexibilityTestDayFromTemplate, loadRestDayFromTemplate, loadRockportTestDayFromTemplate } from "../localdata/templateactivities";
import { getExerciseById } from "../utils/Exercises";
import ExerciseSelectionDialog from "./ExerciseSelectionDialog";
interface WorkoutBuilderPageLoggedInProps {
    user: User,
    onLogoutSuccessful: () => void,
    onInfoSet: (user: User) => void,
}

interface SuggestionObject{
    suggestion:Exercise,
    suffix:string,
    triggers:Array<RegExp>
}

interface SelectCondition {
    label: string;
    value: string;
}

const daysOfTheWeek = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
]

const WorkoutBuilderPageLoggedInView = ({user, onLogoutSuccessful, onInfoSet}: WorkoutBuilderPageLoggedInProps) => {
    // const [user, onInfoSet] = useState<User>(user);
    const [saving, setSaving] = useState(false);
    const [changesMade, setChangesMade] = useState(false);
    const [view, setView] = useState('plans');

    const [isCreateActivityModalOpen, setIsCreateActivityModalOpen] = useState(false);

    const [dbExercises, setDBExercises] = useState<Exercise[]>([]);
    const [dbConditions, setConditions] = useState<Array<DBCondition>>();

    const [selectedPlan, setSelectedPlan] = useState<VitovaPlan | undefined>(undefined);
    const [selectedWeek, setSelectedWeek] = useState<VitovaWeek | undefined>(undefined);
    const [selectedDay, setSelectedDay] = useState<VitovaDay | undefined>(undefined);
    const [selectedActivity, setSelectedActivity] = useState<VitovaActivity | undefined>(undefined);

    const [openExerciseDialog, setOpenExerciseDialog] = useState(false);
    const [openStrengthExerciseDialog, setOpenStrengthExerciseDialog] = useState(false);
    const [openFlexibilityExerciseDialog, setOpenFlexibilityExerciseDialog] = useState(false);

    const [openDialogStepId, setOpenDialogStepId] = useState<string | null>(null);

    const [expandedSections, setExpandedSections] = useState<{ [key: string]: string | null }>({});

    const [filteredExercises, setFilteredExercises] = useState<Exercise[]>([]);
    const [showDropdown, setShowDropdown] = useState(false);

    const [searchStates, setSearchStates] = useState<Record<string, { searchTerm: string; filteredExercises: Exercise[]; showDropdown: boolean }>>({});

    const [selectedConditions, setSelectedConditions] = useState<string[]>([]);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  
    const toggleCondition = (id: string) => {
      setSelectedConditions((prev) =>
        prev.includes(id) ? prev.filter((cond) => cond !== id) : [...prev, id]
      );
    };

    const handleSearch = (stepId: string, value: string) => {
        // Get all exercise constraints based on selected conditions
        const exerciseConstraints = selectedConditions
            .map((id) => dbConditions?.find((con) => con._id === id)?.exerciseConstraints || [])
            .flat();
    
        setSearchStates((prev) => {
            const filtered = value
                ? dbExercises.filter(ex => {
                    const matchesSearch = ex.name.toLowerCase().includes(value.toLowerCase()) ||
                                          ex.id.toLowerCase().includes(value.toLowerCase());
    
                    // Ensure constraint is a key of Exercise before checking its value
                    const violatesConstraint = exerciseConstraints.some(
                        (constraint) => (constraint as keyof Exercise) in ex && ex[constraint as keyof Exercise] === true
                    );
    
                    return matchesSearch && !violatesConstraint;
                })
                : [];
    
            return {
                ...prev,
                [stepId]: { searchTerm: value, filteredExercises: filtered, showDropdown: !!value }
            };
        });
    };
    
    const selectExercise = (stepId: string, type:string, exercise: Exercise) => {
        // setSearchStates((prev) => ({
        //     ...prev,
        //     [stepId]: { searchTerm: exercise.name, filteredExercises: [], showDropdown: false }
        // }));
        updateStepField(stepId, `${type}.exercise`, exercise);
        updateStepField(stepId, `${type}.exerciseId`, exercise.id);

    };

    useEffect(() => {
        // Load Exercies
        getExercises();
        getConditions();

        const handleBeforeUnload = (event:any) => {
            if (changesMade) {
                // Standard message for most browsers
                event.preventDefault(); 
                event.returnValue = ''; 
            }
        };

        // Attach the beforeunload event listener
        window.addEventListener('beforeunload', handleBeforeUnload);

        // Cleanup the event listener on unmount
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [changesMade]);

    function generateTemplatePlan(){
        const randomInt = Math.round((Math.random() * 1000000 ))
        const templatePlan: VitovaPlan = {
            _id:`vitovaplan_${randomInt}`,
            title: 'Untitled Plan',
            description: '',
            content:[]
        };
        return templatePlan;
    }

    function isDayComplete(day:VitovaDay){
        if (day.activities.length > 0){
            return true;
        }
        return false;
    }

    function isWeekComplete(week:VitovaWeek){
        for (const day of week.days){
            if (!isDayComplete(day)){
                return false;
            }
        }
        return true;
    }

    async function getExercises(){
        try{
            await Api.getExercises().then(res=>{
                setDBExercises(res);    
            });
        }catch(error){
            console.error("Error fetching exercises: ", error);
        }

    }

    async function getConditions() {
        try {
            await Api.getConditions().then(res=>{
                setConditions(res);
            });
        } catch (error) {
            console.error("Error fetching conditions: ", error);
        }
    }

    async function createPlanClicked() {
        var newPlan = {...generateTemplatePlan()};
        newPlan._id = new ObjectId().toHexString();        
        try {

            // Clone the user object to avoid direct mutation
            const newUser = { ...user };

            const newEditablePlans = newUser.editableVitovaPlans;
            newEditablePlans.push(newPlan)
            
            newUser.editableVitovaPlans = newEditablePlans;
        
            // Update the user data via API
            await Api.updateUser(user._id, newUser);
            onInfoSet(newUser);

        } catch (error) {
            console.error('Error updating user:', error);
            alert('Failed to save changes');
        }
    }

    const toggleSection = (plan: VitovaPlan, section: string) => {
        setSelectedPlan(plan)
        setExpandedSections((prev) => ({
            ...prev,
            [plan._id]: prev[plan._id] === section ? null : section, // Toggle the section for the specific patient
        }));
    };

    const addWeekClicked = () => {    
        if (selectedPlan){
            const days: Array<VitovaDay> = [
                {
                    id: `day_${Math.round(Math.random() * 10000)}`,
                    activities: [],
                },
                {
                    id: `day_${Math.round(Math.random() * 10000)}`,
                    activities: [],
                },
                {
                    id: `day_${Math.round(Math.random() * 10000)}`,
                    activities: [],
                },
                {
                    id: `day_${Math.round(Math.random() * 10000)}`,
                    activities: [],
                },
                {
                    id: `day_${Math.round(Math.random() * 10000)}`,
                    activities: [],
                },
                {
                    id: `day_${Math.round(Math.random() * 10000)}`,
                    activities: [],
                },
                {
                    id: `day_${Math.round(Math.random() * 10000)}`,
                    activities: [],
                },
            ];
            const id = new ObjectId().toHexString()
            const week: VitovaWeek = {
                id: id,
                title: '',
                description: '',
                days: days,
            };
        
            // Ensure selectedPlan.content exists
            const updatedPlan = {
                ...selectedPlan,
                content: [...(selectedPlan.content || []), week], // Default to empty array if undefined
            };

            setSelectedPlan(updatedPlan)
            
            // Find the plan in editable plans
            const planIndex = user.editableVitovaPlans.findIndex(p => p._id === updatedPlan._id);
        
            if (planIndex !== -1) {
                // Create a new updated array to maintain immutability
                const updatedEditablePlans = [...user.editableVitovaPlans];
                updatedEditablePlans[planIndex] = updatedPlan;
        
                // Create a new user object with updated plans
                const updatedUser = {
                    ...user,
                    editableVitovaPlans: updatedEditablePlans,
                };
        
                onInfoSet(updatedUser);

            }
        }
    };

    // const getExerciseById = (exerciseId:string | undefined)=>{
    //     if (!exerciseId){
    //         const emptyExercise: Exercise = { ...EMPTY_EXERCISE };
    //         return emptyExercise;
    //     }
    //     const findExercise = dbExercises.find(e=>e.id===exerciseId);

    //     if (findExercise){
    //         return findExercise
    //     }else{
    //         console.log('exercise not found')
            
    //         // Usage (if modification is needed)
    //         const emptyExercise: Exercise = { ...EMPTY_EXERCISE };

    //         return emptyExercise;
            
    //     }
    // }

    const addActivity = (presetActivity?:VitovaActivity) => {
        // let newPresetActivity = 

        // Define the activity to be added
        const activity: VitovaActivity = presetActivity ? {...presetActivity, id:`activity_${Date.now()}`} : {
            id: `activity_${Date.now()}`,
            type: VitovaActivityType.STANDARD,
            title: `New Activity`,
            description: "",
            plannedDuration:'60',
            plannedRPE:'5',
            steps: [],
            compulsory:true,
        };    

    
        // Ensure that all necessary states are defined
        if (selectedPlan && selectedWeek && selectedDay) {
            // Find the index of the week to update
            const weekIndex = selectedPlan.content.findIndex(week => week.id === selectedWeek.id);
            if (weekIndex === -1) {
                console.error('Week not found');
                return; // Week not found
            }
    
            // Find the index of the day to update
            const dayIndex = selectedPlan.content[weekIndex].days.findIndex(day => day.id === selectedDay.id);
            if (dayIndex === -1) {
                console.error('Day not found');
                return; // Day not found
            }
    
            // Get the current week and its days
            const updatedWeek = selectedPlan.content[weekIndex];
            const updatedDays = [...updatedWeek.days];
    
            // Ensure the activities array is initialized
            if (!updatedDays[dayIndex].activities) {
                updatedDays[dayIndex].activities = [];
            }
    
            // Add the activity to the specified day's activities
            const updatedDay = {
                ...updatedDays[dayIndex],
                activities: [...updatedDays[dayIndex].activities, activity],
            };
    
            // Replace the updated day in the days array
            updatedDays[dayIndex] = updatedDay;
    
            // Create the new content array with the updated week
            const updatedContent = [...selectedPlan.content];
            updatedContent[weekIndex] = {
                ...updatedWeek,
                days: updatedDays,
            };
    
            // Create a new editingPlan object to ensure immutability
            const updatedPlan = {
                ...selectedPlan,
                content: updatedContent,
            };
    
            // Update the selected plan state
            setSelectedPlan(updatedPlan);
            setSelectedWeek(updatedWeek);
            setSelectedDay(updatedDay);
    
            // Find the index of the plan in the editable plans list
            const planIndex = user.editableVitovaPlans.findIndex(p => p._id === updatedPlan._id);
            if (planIndex !== -1) {
                // Create a new updated array to maintain immutability
                const updatedEditablePlans = [...user.editableVitovaPlans];
                updatedEditablePlans[planIndex] = updatedPlan;
    
                // Create a new user object with updated plans
                const updatedUser = {
                    ...user,
                    editableVitovaPlans: updatedEditablePlans,
                };
    
                // Update the user state
                onInfoSet(updatedUser);

            }
    
        } else {
            console.error('Missing required state: selectedPlan, selectedWeek, or selectedDay');
        }
    };


    const loadDayFromTemplate = (template:string, duration:number) => {
        let activities: Array<VitovaActivity> = []
        if (template === 'choiceday'){
            activities = loadChoiceDayFromTemplate(duration);
        }
        if (template === 'restday'){
            activities = loadRestDayFromTemplate();
        }
        if (template === 'flexibilityactivity'){
            activities = loadFlexibilityActivityFromTemplate()
        }
        if (template === 'rockporttest'){
            activities = loadRockportTestDayFromTemplate()
        }
        if (template === 'flexibilitytest'){
            activities = loadFlexibilityTestDayFromTemplate()
        }
    
        // Get the latest selected plan
        setSelectedPlan(prevPlan => {
            if (!prevPlan || !selectedWeek || !selectedDay) return prevPlan;
    
            // Make a deep copy of prevPlan before updating
            const updatedPlan = { ...prevPlan };
            const weekIndex = updatedPlan.content.findIndex(week => week.id === selectedWeek.id);
            
            if (weekIndex === -1) return prevPlan;
    
            const dayIndex = updatedPlan.content[weekIndex].days.findIndex(day => day.id === selectedDay.id);
            if (dayIndex === -1) return prevPlan;
    
            // Ensure the activities array is initialized
            if (!updatedPlan.content[weekIndex].days[dayIndex].activities) {
                updatedPlan.content[weekIndex].days[dayIndex].activities = [];
            }
    
            // Add all activities at once
            updatedPlan.content[weekIndex].days[dayIndex].activities = [
                ...updatedPlan.content[weekIndex].days[dayIndex].activities,
                ...activities,
            ];
    
            return updatedPlan;
        });
    };
    
    const deleteActivity = (activityId: string) => {
        // Ensure that all necessary states are defined
        if (selectedPlan && selectedWeek && selectedDay) {
            // Find the index of the week to update
            const weekIndex = selectedPlan.content.findIndex(week => week.id === selectedWeek.id);
            if (weekIndex === -1) {
                console.error('Week not found');
                return; // Week not found
            }
    
            // Find the index of the day to update
            const dayIndex = selectedPlan.content[weekIndex].days.findIndex(day => day.id === selectedDay.id);
            if (dayIndex === -1) {
                console.error('Day not found');
                return; // Day not found
            }
    
            // Get the current week and its days
            const updatedWeek = selectedPlan.content[weekIndex];
            const updatedDays = [...updatedWeek.days];
    
            // Find the activity to delete and filter it out
            const updatedDay = updatedDays[dayIndex];
            const updatedActivities = updatedDay.activities?.filter(activity => activity.id !== activityId) || [];
    
            // Replace the updated day in the days array
            updatedDays[dayIndex] = {
                ...updatedDay,
                activities: updatedActivities,
            };
    
            // Create the new content array with the updated week
            const updatedContent = [...selectedPlan.content];
            updatedContent[weekIndex] = {
                ...updatedWeek,
                days: updatedDays,
            };
    
            // Create a new updated plan object
            const updatedPlan = {
                ...selectedPlan,
                content: updatedContent,
            };
    
            // Update the selected plan state
            setSelectedPlan(updatedPlan);
            setSelectedWeek(updatedWeek);
            setSelectedDay(updatedDay);
    
            // Find the index of the plan in the editable plans list
            const planIndex = user.editableVitovaPlans.findIndex(p => p._id === updatedPlan._id);
            if (planIndex !== -1) {
                // Create a new updated array to maintain immutability
                const updatedEditablePlans = [...user.editableVitovaPlans];
                updatedEditablePlans[planIndex] = updatedPlan;
    
                // Create a new user object with updated plans
                const updatedUser = {
                    ...user,
                    editableVitovaPlans: updatedEditablePlans,
                };
    
                // Update the user state
                onInfoSet(updatedUser);
            }
    
        } else {
            console.error('Missing required state: selectedPlan, selectedWeek, or selectedDay');
        }
    };
    
    const deleteWeek = (weekId: string) => {
        if (selectedPlan) {
            // Filter out the week with the specified ID
            const updatedContent = selectedPlan.content?.filter(week => week.id !== weekId) || [];
    
            const updatedPlan = {
                ...selectedPlan,
                content: updatedContent,
            };
    
            setSelectedPlan(updatedPlan);
    
            // Find the plan in editable plans
            const planIndex = user.editableVitovaPlans.findIndex(p => p._id === updatedPlan._id);
    
            if (planIndex !== -1) {
                // Create a new updated array to maintain immutability
                const updatedEditablePlans = [...user.editableVitovaPlans];
                updatedEditablePlans[planIndex] = updatedPlan;
    
                // Create a new user object with updated plans
                const updatedUser = {
                    ...user,
                    editableVitovaPlans: updatedEditablePlans,
                };
    
                onInfoSet(updatedUser);


            }else{
            }
        }
    };

    async function deletePlan(planToDelete: VitovaPlan) {
        // Show confirmation dialog
        const confirmDelete = window.confirm(`Are you sure you want to delete the plan: "${planToDelete.title}"?`);

        if (!confirmDelete) {
            // Exit if the user cancels
            return;
        }

        try {
            // Clone the user object to avoid direct mutation
            const newUser = { ...user };
        
            // Create a new array without the plan to be deleted
            const newEditablePlans = newUser.editableVitovaPlans.filter(p => p._id !== planToDelete._id);
            
            // Assign the filtered array to newUser's editablePlans
            newUser.editableVitovaPlans = newEditablePlans;
        
            // Update the user data via API
            onInfoSet(newUser)
        } catch (error) {
            console.error('Error updating user:', error);
            alert('Failed to save changes');
        }
    }
    function addStep(){
        // Define the step to be added
        const step: VitovaActivityStep = {
            id: `step_${Date.now()}`, // New step ID
            label: 'New Step',
            type: VitovaStepType.CARDIO, // The default type
            description:'',
            strength:{
                rpe:5,
                percentOf10RepMax:50,
            },
            cardio:{
                rpe:5,
                zone:2
            },
            flexibility:{
                rpe:3,
                sets:3,
                holdDuration:30
            },
        };

        const findPlan = user.editableVitovaPlans.find(p=>p._id===selectedPlan?._id);
    
        // Ensure that all necessary states are defined
        if (findPlan && selectedWeek && selectedDay && selectedActivity) {
            // Find the index of the week to update
            const weekIndex = findPlan.content.findIndex(week => week.id === selectedWeek.id);
            if (weekIndex === -1) {
                console.error('Week not found');
                return; // Week not found
            }
    
            // Find the index of the day to update
            const dayIndex = findPlan.content[weekIndex].days.findIndex(day => day.id === selectedDay.id);
            if (dayIndex === -1) {
                console.error('Day not found');
                return; // Day not found
            }
    
            // Find the index of the activity to update
            const activityIndex = findPlan.content[weekIndex].days[dayIndex].activities.findIndex(a => a.id === selectedActivity.id);
            if (activityIndex === -1) {
                console.error('Activity not found');
                return; // Activity not found
            }
    
            // Get the current week, days, and activity
            const updatedWeek = { ...findPlan.content[weekIndex] };
            const updatedDay = { ...updatedWeek.days[dayIndex] };
            const updatedActivities = [...updatedDay.activities]; // Clone the existing activities
    
            // // Add the new step to the selected activity's steps
            // const updatedActivity = {
            //     ...updatedActivities[activityIndex], // Copy the existing activity
            //     steps: [...(updatedActivities[activityIndex].steps || []), step],
            // };
            // Get the last step in the current activity's steps list
            const previousSteps = updatedActivities[activityIndex].steps || [];
            const previousStep = previousSteps.length > 0 ? previousSteps[previousSteps.length - 1] : null;

            // Ensure the new step has the same type as the previous step, or default to CARDIO if none exist
            const newStep = { 
                ...step,
                type: previousStep ? previousStep.type : VitovaStepType.CARDIO,
                cardio:previousStep?.type === VitovaStepType.CARDIO ? {
                    exercise:previousStep?.cardio?.exercise,
                    exerciseId:previousStep?.cardio?.exerciseId
                }:undefined
            };

            // Add the new step to the selected activity's steps
            const updatedActivity = {
                ...updatedActivities[activityIndex], // Copy the existing activity
                steps: [...previousSteps, newStep],
            };
                
            // Replace the updated activity in the activities array
            updatedActivities[activityIndex] = updatedActivity;
    
            // Update the day with the new activities list
            updatedDay.activities = updatedActivities;
    
            // Replace the updated day in the days array
            const updatedDays = [...updatedWeek.days];
            updatedDays[dayIndex] = updatedDay;
    
            // Update the week with the new days
            updatedWeek.days = updatedDays;
    
            // Create the updated content array
            const updatedContent = [...findPlan.content];
            updatedContent[weekIndex] = updatedWeek;
    
            // Create a new updated plan
            const updatedPlan = {
                ...findPlan,
                content: updatedContent,
            };
    
            // Find the plan index in editableVitovaPlans and update it
            const planIndex = user.editableVitovaPlans.findIndex(p => p._id === updatedPlan._id);
            if (planIndex !== -1) {
                // Update editable plans
                const updatedEditablePlans = [...user.editableVitovaPlans];
                updatedEditablePlans[planIndex] = updatedPlan;
    
                // Create a new user object with the updated plans
                const updatedUser = {
                    ...user,
                    editableVitovaPlans: updatedEditablePlans,
                };
    
                // Save the updated user
                onInfoSet(updatedUser);
                console.log(updatedPlan)
            }
    
        } else {
            console.error('Missing required state: selectedPlan, selectedWeek, selectedDay, or selectedActivity');
        }
    };
    
    const deleteStep = (stepId: string) => {
        // Ensure that all necessary states are defined
        if (selectedPlan && selectedWeek && selectedDay && selectedActivity) {
            // Find the index of the week to update
            const planIndex = user.editableVitovaPlans.findIndex(p => p._id === selectedPlan._id);
            if (planIndex === -1) {
                console.error('Week not found');
                return; // Week not found
            }
            // Find the index of the week to update
            const weekIndex = user.editableVitovaPlans[planIndex].content.findIndex(week => week.id === selectedWeek.id);
            if (weekIndex === -1) {
                console.error('Week not found');
                return; // Week not found
            }
    
            // Find the index of the day to update
            const dayIndex = user.editableVitovaPlans[planIndex].content[weekIndex].days.findIndex(day => day.id === selectedDay.id);
            if (dayIndex === -1) {
                console.error('Day not found');
                return; // Day not found
            }
    
            // Find the index of the activity to update
            const activityIndex = user.editableVitovaPlans[planIndex].content[weekIndex].days[dayIndex].activities.findIndex(a => a.id === selectedActivity.id);
            if (activityIndex === -1) {
                console.error('Activity not found');
                return; // Activity not found
            }
    
            // Get the current week, days, and activity
            const updatedWeek = { ...user.editableVitovaPlans[planIndex].content[weekIndex] };
            const updatedDay = { ...updatedWeek.days[dayIndex] };
            const updatedActivities = [...updatedDay.activities]; // Clone the existing activities
    
            // Find the index of the step to delete
            const stepIndex = updatedActivities[activityIndex].steps.findIndex(step => step.id === stepId);
            console.log(updatedActivities[activityIndex].steps)
            console.log(stepId)
            if (stepIndex === -1) {
                console.error('Step not found');
                return; // Step not found
            }
    
            // Remove the step from the activity's steps array
            updatedActivities[activityIndex].steps.splice(stepIndex, 1);
    
            // Update the activities array with the removed step
            updatedDay.activities = updatedActivities;
    
            // Replace the updated day in the days array
            const updatedDays = [...updatedWeek.days];
            updatedDays[dayIndex] = updatedDay;
    
            // Update the week with the new days
            updatedWeek.days = updatedDays;
    
            // Create the updated content array
            const updatedContent = [...selectedPlan.content];
            updatedContent[weekIndex] = updatedWeek;
    
            // Create a new updated plan
            const updatedPlan = {
                ...selectedPlan,
                content: updatedContent,
            };
    
            // Update the selected plan state
            setSelectedPlan(updatedPlan);
            setSelectedWeek(updatedWeek);
            setSelectedDay(updatedDay);
    
            // Find the plan index in editableVitovaPlans and update it
            // Update editable plans
            const updatedEditablePlans = [...user.editableVitovaPlans];
            updatedEditablePlans[planIndex] = updatedPlan;

            // Create a new user object with the updated plans
            const updatedUser = {
                ...user,
                editableVitovaPlans: updatedEditablePlans,
            };

            // Save the updated user
            onInfoSet(updatedUser);
        
    
        } else {
            console.error('Missing required state: selectedPlan, selectedWeek, selectedDay, or selectedActivity');
        }
    };

    async function uploadPlanToLibrary(plan:VitovaPlan) {
        console.log(user)
        const planToUpload = user.editableVitovaPlans.find(p=>p._id===plan._id);
        if (planToUpload){
            try{
                // Uplaod the plan data via API
                await Api.createVitovaPlan(planToUpload).then(res=>{
                    alert("Plan Published To Library!");
                })
            }catch (e:any){
                alert('Something Went Wrong')
            }
        }else{
            alert('Invalid Plan')
        }
    }

    async function savePlan(newPlan: VitovaPlan) {
        // Create a deep copy to avoid mutating the original object
        const updatedPlan = JSON.parse(JSON.stringify(newPlan));
    
        // Update exercises in the plan
        function updateExercise(step: any) {
            if (step.cardio) {
                step.cardio.exercise = getExerciseById(dbExercises, step.cardio.exerciseId) || step.cardio.exercise;
            }
            if (step.strength) {
                step.strength.exercise = getExerciseById(dbExercises, step.strength.exerciseId) || step.strength.exercise;
            }
            if (step.flexibility) {
                step.flexibility.exercise = getExerciseById(dbExercises, step.flexibility.exerciseId) || step.flexibility.exercise;
            }
        }
    
        for (const week of updatedPlan.content) {
            for (const day of week.days) {
                for (const activity of day.activities) {
                    for (const step of activity.steps) {
                        updateExercise(step);
                    }
                }
            }
        }
    
        // Find the index of the plan to update
        const planIndex = user.editableVitovaPlans.findIndex(p => p._id === updatedPlan._id);
        
        if (planIndex !== -1) {
            // Replace the existing plan with the updated plan
            const updatedPlans = [...user.editableVitovaPlans];
            updatedPlans[planIndex] = updatedPlan;
    
            // Create a new user object (ensuring immutability)
            const newUser = { ...user, editableVitovaPlans: updatedPlans };
    
            // Update the user state and save
            onInfoSet(newUser);
            try {
                const res = await Api.updateUser(user._id, newUser);
                console.log(res);
                alert('Saved successfully');
            } catch (error) {
                console.error('Error saving plan:', error);
                alert('Failed to save plan');
            }
        } else {
            alert('Plan not found');
        }
    }

    async function copyPlan(plan:VitovaPlan){
        var newPlan = {...plan};
        newPlan.title = `${newPlan.title} - copy`
        newPlan._id = new ObjectId().toHexString();        
        try {
            // Clone the user object to avoid direct mutation
            const newUser = { ...user };

            const newEditablePlans = newUser.editableVitovaPlans;
            newEditablePlans.push(newPlan)
            
            newUser.editableVitovaPlans = newEditablePlans;
        
            // Update the user data via API
            await Api.updateUser(user._id, newUser);
            onInfoSet(newUser);

        } catch (error) {
            console.error('Error updating user:', error);
            alert('Failed to save changes');
        }
    }
    
    async function saveUser(newUser:User){
        await Api.updateUser(user._id, newUser);
    }
    // interface SearchState {
    //     searchTerm: string;
    //     filteredExercises: Exercise[];
    //     showDropdown: boolean;
    // }

    // Add types for your functions and variables
    const renderStepFields = (
        step: VitovaActivityStep,
        dbExercises: Exercise[], // Type the dbExercises parameter
        // searchStates: Record<string, SearchState>, // Type searchStates to be an object keyed by step.id
        handleSearch: (stepId: string, value: string) => void, // Type handleSearch as a function
        selectExercise: (stepId: string, type:string,exercise: Exercise) => void // Type selectExercise as a function
    ) => {
        switch (step.type) {
            case VitovaStepType.CARDIO:
                return (
                    <div key={step.id} style={{display:'flex', padding:'15px', gap:'10px'}}>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <TextField
                                label="Activity Type"
                                select
                                fullWidth
                                variant="outlined"
                                value={step?.type || ''}
                                onChange={(e) => updateStepField(step.id, 'type', e.target.value)}
                                InputLabelProps={{ shrink: true }}
                                style={{ fontSize: "1.2em", backgroundColor: "transparent" }}
                            >
                                <MenuItem disabled value="">
                                    Select Activity Type
                                </MenuItem>
                                {Object.values(VitovaStepType).map((type) => (
                                    <MenuItem key={type} value={type}>
                                        {type.toUpperCase()}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <div onClick={()=>setOpenDialogStepId(step.id)} style={{ position: 'relative'}}> {/* Ensures dropdown is positioned correctly */}
                            <TextField
                                label="Selected Exercise"
                                fullWidth
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                style={{ fontSize: "1.2em", backgroundColor: "transparent", width:'100%'}}
                                type="text"
                                placeholder={`${step.cardio ? step.cardio.exercise ? step.cardio.exercise.name : getExerciseById(dbExercises, step.cardio.exerciseId).name : 'unselected'}`}
                                />

                        </div>
                        <ExerciseSelectionDialog 
                            conditions={dbConditions || []}
                            exercises={dbExercises}
                            stepId={step.id}
                            key={step.id}
                            open={openDialogStepId === step.id} 
                            onClose={() => setOpenDialogStepId(null)} 
                            onExerciseSelected={(exercise, stepId) => { 
                                // alert("Selecting exercise for step:" + stepId);
                                selectExercise(stepId, "cardio", exercise); 
                                setOpenDialogStepId(null); 
                            }} 
                            />
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <TextField
                                label="Duration (minutes)"
                                type="text"
                                fullWidth
                                variant="outlined"
                                value={step.cardio?.duration || ''} 
                                onChange={(e) => updateStepField(step.id, 'cardio.duration', e.target.value)}
                                InputLabelProps={{ shrink: true }}
                                style={{ fontSize: "1.2em", backgroundColor: "transparent" }}
                                />
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <TextField
                                label="Distance (km)"
                                type="text"
                                fullWidth
                                variant="outlined"
                                value={step.cardio?.distance || ''}
                                onChange={(e) => updateStepField(step.id, 'cardio.distance', e.target.value)} 
                                InputLabelProps={{ shrink: true }}
                                style={{ fontSize: "1.2em", backgroundColor: "transparent" }}
                                />
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <TextField
                                label="RPE (/10)"
                                type="text"
                                fullWidth
                                variant="outlined"
                                value={step.cardio?.rpe || ''}
                                onChange={(e) => updateStepField(step.id, 'cardio.rpe', e.target.value)}
                                InputLabelProps={{ shrink: true }}
                                style={{ fontSize: "1.2em", backgroundColor: "transparent" }}
                                />
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <TextField
                                label="Zone"
                                type="text"
                                fullWidth
                                variant="outlined"
                                value={step.cardio?.zone || ''}
                                onChange={(e) => updateStepField(step.id, 'cardio.zone', e.target.value)}
                                InputLabelProps={{ shrink: true }}
                                style={{ fontSize: "1.2em", backgroundColor: "transparent" }}
                                />
                        </div>

                        {step.cardio?.exercise && step.cardio?.exercise.name === 'Cycling' &&
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <TextField
                                label="Power (Watts)"
                                type="text"
                                fullWidth
                                variant="outlined"
                                value={step.cardio?.power || ''}
                                onChange={(e) => updateStepField(step.id, 'cardio.power', e.target.value)}
                                InputLabelProps={{ shrink: true }}
                                style={{ fontSize: "1.2em", backgroundColor: "transparent" }}
                                />
                        </div>
                        }
                        {((step.cardio?.exercise && step.cardio?.exercise.name === 'Running') || (step.cardio?.exercise && step.cardio?.exercise.name === 'Swimming')) &&
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <TextField
                                label="Pace (m/s)"
                                type="text"
                                fullWidth
                                variant="outlined"
                                value={step.cardio?.pace || ''}
                                onChange={(e) => updateStepField(step.id, 'cardio.pace', e.target.value)}
                                InputLabelProps={{ shrink: true }}
                                style={{ fontSize: "1.2em", backgroundColor: "transparent" }}
                                />
                        </div>
                        }
                    </div>
                );

            case VitovaStepType.STRENGTH:
                return (
                    <div style={{display:'flex', padding:'15px', gap:'10px'}}>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <TextField
                                label="Activity Type"
                                select
                                fullWidth
                                variant="outlined"
                                value={step?.type || ''}
                                onChange={(e) => {updateStepField(step.id, 'type', e.target.value)}}
                                InputLabelProps={{ shrink: true }}
                                style={{ fontSize: "1.2em", backgroundColor: "transparent" }}
                            >
                                <MenuItem disabled value="">
                                    Select Activity Type
                                </MenuItem>
                                {Object.values(VitovaStepType).map((type) => (
                                    <MenuItem key={type} value={type}>
                                        {type.toUpperCase()}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <div onClick={()=>setOpenDialogStepId(step.id)} style={{ position: 'relative'}}> {/* Ensures dropdown is positioned correctly */}
                            <TextField
                                label="Selected Exercise"
                                fullWidth
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                style={{ fontSize: "1.2em", backgroundColor: "transparent", width:'100%'}}
                                type="text"
                                // value={`${step.strength ? step.strength.exercise ? step.strength.exercise.name : getExerciseById(dbExercises, step.strength.exerciseId).name : 'unselected'}`}
                                // value={step.cardio?.exercise}
                                placeholder={`${step.strength ? step.strength.exercise ? step.strength.exercise.name : getExerciseById(dbExercises, step.strength.exerciseId).name : 'unselected'}`}
                                // onChange={(e) => handleSearch(step.id, e.target.value)}
                                // onFocus={() => handleSearch(step.id, searchStates[step.id]?.searchTerm || "")}
                                // onBlur={() => setTimeout(() => handleSearch(step.id, ""), 200)}
                                />
                            {/* {searchStates[step.id]?.showDropdown && searchStates[step.id]?.filteredExercises.length > 0 && (
                                <div className="dropdown" 
                                style={{
                                    width:'40em', zIndex:10000
                                }}
                                >
                                    {searchStates[step.id].filteredExercises.map((ex: Exercise) => (
                                        <div key={ex.id} className="dropdown-item" onClick={() => selectExercise(step.id, 'strength',ex)}>
                                            {ex.name}
                                        </div>
                                    ))}
                                </div>
                            )} */}
                        </div>
                        <ExerciseSelectionDialog 
                            conditions={dbConditions || []}
                            exercises={dbExercises}
                            stepId={step.id}
                            key={step.id}
                            open={openDialogStepId === step.id} 
                            onClose={() => setOpenDialogStepId(null)} 
                            onExerciseSelected={(exercise, stepId) => { 
                                // alert("Selecting exercise for step:" + stepId);
                                selectExercise(stepId, "strength", exercise); 
                                setOpenDialogStepId(null); 
                            }} 
                            />
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <TextField
                                label="Sets"
                                type="text"
                                fullWidth
                                variant="outlined"
                                value={step.strength?.sets || ''} 
                                onChange={(e) => updateStepField(step.id, 'strength.sets', Number(e.target.value))}
                                InputLabelProps={{ shrink: true }}
                                style={{ fontSize: "1.2em", backgroundColor: "transparent" }}
                                />
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <TextField
                                label="Reps"
                                type="text"
                                fullWidth
                                variant="outlined"
                                value={step.strength?.reps || ''}
                                onChange={(e) => updateStepField(step.id, 'strength.reps', Number(e.target.value))} 
                                InputLabelProps={{ shrink: true }}
                                style={{ fontSize: "1.2em", backgroundColor: "transparent" }}
                                />
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <TextField
                                label="Reps In Seconds"
                                type="text"
                                fullWidth
                                variant="outlined"
                                value={step.strength?.repsInSeconds || ''}
                                onChange={(e) => updateStepField(step.id, 'strength.repsInSeconds', Number(e.target.value))} 
                                InputLabelProps={{ shrink: true }}
                                style={{ fontSize: "1.2em", backgroundColor: "transparent" }}
                                />
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <TextField
                                label="% Of 10 Rep Max"
                                type="text"
                                fullWidth
                                variant="outlined"
                                value={step.strength?.percentOf10RepMax || ''}
                                onChange={(e) => updateStepField(step.id, 'strength.percentOf10RepMax', Number(e.target.value))}
                                InputLabelProps={{ shrink: true }}
                                style={{ fontSize: "1.2em", backgroundColor: "transparent" }}
                                />
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <TextField
                                label="RPE (/10)"
                                type="text"
                                fullWidth
                                variant="outlined"
                                value={step.strength?.rpe || ''}
                                onChange={(e) => updateStepField(step.id, 'strength.rpe', Number(e.target.value))}
                                InputLabelProps={{ shrink: true }}
                                style={{ fontSize: "1.2em", backgroundColor: "transparent" }}
                                />
                        </div>
                    </div>
                );

            case VitovaStepType.FLEXIBILITY:
                return (
                    <div style={{display:'flex', padding:'15px', gap:'10px'}}>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <TextField
                                label="Activity Type"
                                select
                                fullWidth
                                variant="outlined"
                                value={step?.type || ''}
                                onChange={(e) => updateStepField(step.id, 'type', e.target.value)}
                                InputLabelProps={{ shrink: true }}
                                style={{ fontSize: "1.2em", backgroundColor: "transparent" }}
                            >
                                <MenuItem disabled value="">
                                    Select Activity Type
                                </MenuItem>
                                {Object.values(VitovaStepType).map((type) => (
                                    <MenuItem key={type} value={type}>
                                        {type.toUpperCase()}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <div style={{ position: 'relative'}}> {/* Ensures dropdown is positioned correctly */}
                            <TextField
                                label="Selected Exercise"
                                fullWidth
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                style={{ fontSize: "1.2em", backgroundColor: "transparent", width:'100%'}}
                                type="text"
                                value={searchStates[step.id]?.searchTerm || ""}
                                placeholder={`${step.flexibility ? step.flexibility.exercise ? step.flexibility.exercise.name : getExerciseById(dbExercises, step.flexibility.exerciseId).name : 'unselected'}`}
                                onChange={(e) => handleSearch(step.id, e.target.value)}
                                onFocus={() => handleSearch(step.id, searchStates[step.id]?.searchTerm || "")}
                                onBlur={() => setTimeout(() => handleSearch(step.id, ""), 200)}
                                />
                            {/* {searchStates[step.id]?.showDropdown && searchStates[step.id]?.filteredExercises.length > 0 && (
                                <div className="dropdown" style={{width:'40em', zIndex:10000}}>
                                    {searchStates[step.id].filteredExercises.map((ex: Exercise) => (
                                        <div key={ex.id} className="dropdown-item" onClick={() => selectExercise(step.id, 'flexibility', ex)}>
                                            {ex.name}
                                        </div>
                                    ))}
                                </div>
                            )} */}
                        </div>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <TextField
                                label="Sets"
                                type="text"
                                fullWidth
                                variant="outlined"
                                value={step.flexibility?.sets || ''} 
                                onChange={(e) => updateStepField(step.id, 'flexibility.sets', Number(e.target.value))}
                                InputLabelProps={{ shrink: true }}
                                style={{ fontSize: "1.2em", backgroundColor: "transparent" }}
                                />
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <TextField
                                label="Hold Duration (seconds)"
                                type="text"
                                fullWidth
                                variant="outlined"
                                value={step.flexibility?.holdDuration || ''}
                                onChange={(e) => updateStepField(step.id, 'flexibility.holdDuration', Number(e.target.value))} 
                                InputLabelProps={{ shrink: true }}
                                style={{ fontSize: "1.2em", backgroundColor: "transparent" }}
                                />
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <TextField
                                label="RPE (/10)"
                                type="text"
                                fullWidth
                                variant="outlined"
                                value={step.flexibility?.rpe || ''}
                                onChange={(e) => updateStepField(step.id, 'flexibility.rpe', Number(e.target.value))}
                                InputLabelProps={{ shrink: true }}
                                style={{ fontSize: "1.2em", backgroundColor: "transparent" }}
                                />
                        </div>
                    </div>
                );

            case VitovaStepType.CHOICE:
                return (
                    <div style={{display:'flex', padding:'15px', gap:'10px'}}>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <TextField
                                label="Activity Type"
                                select
                                fullWidth
                                variant="outlined"
                                value={step?.type || ''}
                                onChange={(e) => updateStepField(step.id, 'type', e.target.value)}
                                InputLabelProps={{ shrink: true }}
                                style={{ fontSize: "1.2em", backgroundColor: "transparent" }}
                            >
                                <MenuItem disabled value="">
                                    Select Activity Type
                                </MenuItem>
                                {Object.values(VitovaStepType).map((type) => (
                                    <MenuItem key={type} value={type}>
                                        {type.toUpperCase()}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <TextField
                                label="Duration (minutes)"
                                type="text"
                                fullWidth
                                variant="outlined"
                                value={step.choiceday?.duration || ''}
                                onChange={(e) => updateStepField(step.id, 'choiceday.duration', Number(e.target.value))} 
                                InputLabelProps={{ shrink: true }}
                                style={{ fontSize: "1.2em", backgroundColor: "transparent" }}
                                />
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <TextField
                                label="RPE (/10)"
                                type="text"
                                fullWidth
                                variant="outlined"
                                value={step.choiceday?.rpe || ''}
                                onChange={(e) => updateStepField(step.id, 'choiceday.rpe', Number(e.target.value))}
                                InputLabelProps={{ shrink: true }}
                                style={{ fontSize: "1.2em", backgroundColor: "transparent" }}
                                />
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <TextField
                                label="Notes"
                                multiline
                                type="text"
                            
                                fullWidth
                                variant="outlined"
                                value={step.choiceday?.notes || ''}
                                onChange={(e) => updateStepField(step.id, 'choiceday.notes', e.target.value)} 
                                InputLabelProps={{ shrink: true }}
                                style={{ fontSize: "1.2em", backgroundColor: "transparent", width:'30em'}}
                                />
                        </div>
                    </div>
                );
        
            case VitovaStepType.REST:
                return (
                    <div style={{display:'flex', padding:'15px', gap:'10px'}}>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width:'100%'}}>
                            <TextField
                                label="Activity Type"
                                select
                                fullWidth
                                variant="outlined"
                                value={step?.type || ''}
                                onChange={(e) => updateStepField(step.id, 'type', e.target.value)}
                                InputLabelProps={{ shrink: true }}
                                style={{ fontSize: "1.2em", backgroundColor: "transparent" }}
                            >
                                <MenuItem disabled value="">
                                    Select Activity Type
                                </MenuItem>
                                {Object.values(VitovaStepType).map((type) => (
                                    <MenuItem key={type} value={type}>
                                        {type.toUpperCase()}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent:'center', width:'100%'}}>
                            <label style={{ color: "#888"}}>No fields needed for rest</label>
                        </div>
                    </div>
                )
            default:
                return null;
        }
    };
    
    const updateStepField = (stepId: string, fieldPath: string, value: any) => {
        if (!selectedPlan || !selectedWeek || !selectedDay || !selectedActivity) return;
    
        // Create a deep copy of the user prop to avoid mutating it directly
        const updatedUser = {
            ...user,
            editableVitovaPlans: user.editableVitovaPlans.map(plan => {
                if (plan._id !== selectedPlan?._id) return plan;
    
                return {
                    ...plan,
                    content: plan.content.map(week => {
                        if (week.id !== selectedWeek?.id) return week;
    
                        return {
                            ...week,
                            days: week.days.map(day => {
                                if (day.id !== selectedDay?.id) return day;
    
                                return {
                                    ...day,
                                    activities: day.activities.map(activity => {
                                        if (activity.id !== selectedActivity?.id) return activity;
    
                                        return {
                                            ...activity,
                                            steps: activity.steps.map(step => {
                                                if (step.id !== stepId) return step;
    
                                                // Clone the step object
                                                const updatedStep = { ...step };
                                                const keys = fieldPath.split('.');
                                                let obj: any = updatedStep;
    
                                                // Traverse through the nested structure to update the value
                                                for (let i = 0; i < keys.length - 1; i++) {
                                                    if (!obj[keys[i]]) obj[keys[i]] = {};
                                                    obj = obj[keys[i]];
                                                }
    
                                                obj[keys[keys.length - 1]] = value;
                                                return updatedStep;
                                            })
                                        };
                                    })
                                };
                            })
                        };
                    })
                };
            })
        };
    
        // Call the prop function to update the user data
        onInfoSet(updatedUser);
    };
    
    const getWeekNumber = () => {
        if (selectedPlan && selectedWeek){
            const findWeek = selectedPlan.content.find(w=>w.id===selectedWeek.id);
            if (findWeek){
                return selectedPlan.content.indexOf(findWeek) + 1
            }
        }
        return -1
    }

    const getDayIndex = () => {
        if (selectedWeek && selectedDay){
            const findDay = selectedWeek.days.find(d=>d.id===selectedDay.id);
            if (findDay){
                return selectedWeek.days.indexOf(findDay)
            }
        }
        return -1
    }

    const renderPlanWeeks = ()=>{
        return (
            selectedPlan && user?.editableVitovaPlans?.length > 0 && (
                <div style={{ display: 'flex', width: '100%', flexDirection: 'column',
                }}>
                    <label style={{ paddingLeft:0,fontWeight: 'bold', fontStyle: 'italic',alignSelf:'flex-start', textAlign:'left', fontSize:'1.5em'}} className="plan-name-label">
                        {`DETAILS`}
                    </label>
                    <input 
                        placeholder="PLAN TITLE"
                        style={{padding:'5px 10px', cursor:'text', outline:'none', border:'2px solid steelblue', borderRadius:'5px', marginBottom:'10px'}}
                        defaultValue={selectedPlan.title}
                        onChange={(e) => {
                            const updatedPlan = {
                                ...selectedPlan,
                                title: e.target.value, // Update the title with the new value
                            };

                            // Update the selected plan state
                            setSelectedPlan(updatedPlan);

                            // Find the index of the plan in editableVitovaPlans
                            const planIndex = user.editableVitovaPlans.findIndex(p => p._id === updatedPlan._id);
                            if (planIndex !== -1) {
                                // Update the editableVitovaPlans array with the updated plan
                                const updatedEditablePlans = [...user.editableVitovaPlans];
                                updatedEditablePlans[planIndex] = updatedPlan;

                                // Create a new user object with the updated plans
                                const updatedUser = {
                                    ...user,
                                    editableVitovaPlans: updatedEditablePlans,
                                };

                                // Call saveUser to persist the changes
                                onInfoSet(updatedUser);
                            }
                        }}
                    />
                    <textarea 
                        placeholder="PLAN DESCRIPTION"
                        style={{padding:'5px 10px', cursor:'text', outline:'none', border:'2px solid steelblue', borderRadius:'5px', marginBottom:'10px', height:'20vh'}}
                        defaultValue={selectedPlan.description}
                        onChange={(e) => {
                            const updatedPlan = {
                                ...selectedPlan,
                                description: e.target.value, // Update the title with the new value
                            };

                            // Update the selected plan state
                            setSelectedPlan(updatedPlan);

                            // Find the index of the plan in editableVitovaPlans
                            const planIndex = user.editableVitovaPlans.findIndex(p => p._id === updatedPlan._id);
                            if (planIndex !== -1) {
                                // Update the editableVitovaPlans array with the updated plan
                                const updatedEditablePlans = [...user.editableVitovaPlans];
                                updatedEditablePlans[planIndex] = updatedPlan;

                                // Create a new user object with the updated plans
                                const updatedUser = {
                                    ...user,
                                    editableVitovaPlans: updatedEditablePlans,
                                };

                                // Call saveUser to persist the changes
                                onInfoSet(updatedUser);
                            }
                        }}
                    />
                    <label style={{paddingLeft:0, fontWeight: 'bold', fontStyle: 'italic',alignSelf:'flex-start', textAlign:'left', fontSize:'1.5em'}} className="plan-name-label">
                        {`WEEKS`}
                    </label>
                    {user.editableVitovaPlans
                        .find(p => p._id === selectedPlan._id)?.content.map((week: VitovaWeek, index) => (
                            <div
                                onClick={() => { toggleSection(selectedPlan, 'days'); setSelectedWeek(week); }}
                                className="item-div"
                                style={{backgroundColor: isWeekComplete(week)?'#f1f1f1':colorPalette.softRed,
                                }}
                                key={week.id}
                            >
                                <label style={{ fontWeight: 'bold', fontStyle: 'italic',fontSize:'1em'}} className="plan-name-label">
                                    {`WEEK ${index + 1}`}
                                </label>
                                <FontAwesomeIcon
                                    className="arrow-left"
                                    onClick={(e) => { deleteWeek(week.id); e.stopPropagation(); }}
                                    style={{ marginRight: '20px', fontSize: '2em', color: colorPalette.softRed }}
                                    icon={faRemove}
                                />
                            </div>
                        ))}
                    <button onClick={() => addWeekClicked()} className="global-button" style={{ margin: 0, alignSelf: 'center' }}>
                        ADD WEEK
                    </button>
                </div>
            )
        );
        
    }

    const renderPlanDays = () => {
        const onDragEnd = (result: any) => {
            const { destination, source } = result;
        
            if (!destination || !selectedWeek || !selectedPlan) return; // Ensure selectedWeek and selectedPlan are defined
        
            const items = [...selectedWeek.days];
            const [removed] = items.splice(source.index, 1); // Remove the item
            items.splice(destination.index, 0, removed); // Reinsert it at the new position
        
            // Update selectedWeek's days
            setSelectedWeek((prev) => ({
                ...prev,
                days: items,
                id: prev?.id || '', // Provide fallback for id if undefined
                title: prev?.title || '',
                description: prev?.description || '',
            }));
        
            // Find the plan to update within user state
            const findPlan = user.editableVitovaPlans.find((p) => p._id === selectedPlan._id);
            if (!findPlan) {
                console.error('Plan not found');
                return;
            }
        
            // Find the index of the week within the plan
            const weekIndex = selectedPlan.content.findIndex((week) => week.id === selectedWeek.id);
            if (weekIndex === -1) {
                console.error('Week not found in selected plan');
                return;
            }
        
            // Update the plan's content with the modified week
            const updatedContent = [...selectedPlan.content];
            updatedContent[weekIndex] = { ...selectedPlan.content[weekIndex], days: items };
        
            // Ensure id is defined in the updated plan
            const updatedPlan = {
                ...selectedPlan,
                content: updatedContent,
                _id: selectedPlan._id || '', // Provide fallback for _id if undefined
            };
        
            // Update the selected plan state
            setSelectedPlan(updatedPlan);
        
            // Update the editableVitovaPlans in the user state
            const planIndex = user.editableVitovaPlans.findIndex((p) => p._id === updatedPlan._id);
            if (planIndex !== -1) {
                const updatedEditablePlans = [...user.editableVitovaPlans];
                updatedEditablePlans[planIndex] = updatedPlan;
        
                const updatedUser = {
                    ...user,
                    editableVitovaPlans: updatedEditablePlans,
                };
        
                // Persist the changes to the user state (call onInfoSet)
                onInfoSet(updatedUser);
            }
        };
        
        const updateWeekField = (weekId: string, fieldPath: string, value: any) => {
            if (!selectedPlan) return;
        
            // Create a deep copy of the user prop to avoid mutating it directly
            const updatedUser = {
                ...user,
                editableVitovaPlans: user.editableVitovaPlans.map(plan => {
                    if (plan._id !== selectedPlan._id) return plan;
        
                    return {
                        ...plan,
                        content: plan.content.map(week => {
                            if (week.id !== weekId) return week;
        
                            // Clone the week object
                            const updatedWeek = { ...week };
                            const keys = fieldPath.split('.');
                            let obj: any = updatedWeek;
        
                            // Traverse through the nested structure to update the value
                            for (let i = 0; i < keys.length - 1; i++) {
                                if (!obj[keys[i]]) obj[keys[i]] = {};
                                obj = obj[keys[i]];
                            }
        
                            obj[keys[keys.length - 1]] = value;
                            return updatedWeek;
                        })
                    };
                })
            };
        
            // Call the prop function to update the user data
            onInfoSet(updatedUser);
        };
        
    
        return (
            selectedPlan && selectedWeek && (
                <div style={{display:'flex', flexDirection:'column', justifyContent:'flex-start', alignItems:'flex-start',width:'100%'}}>
                    <label style={{fontWeight:'bold', fontStyle:'italic', color:colorPalette.steelBlue}}>DETAILS</label>

                        <TextField
                            label="Week Description"
                            multiline
                            type="text"
                            fullWidth
                            variant="outlined"
                            value={
                                user.editableVitovaPlans
                                  .find((p) => p._id === selectedPlan?._id)
                                  ?.content.find((week) => week.id === selectedWeek?.id)
                                  ?.description || ''
                              }
                            onChange={(e) => updateWeekField(selectedWeek.id, 'description', e.target.value)} 
                            InputLabelProps={{ shrink: true }}
                            style={{ fontSize: "1.2em", backgroundColor: "transparent", width:'100%', marginTop:'20px'}}
                            />
                <label style={{fontWeight:'bold', fontStyle:'italic', color:colorPalette.steelBlue, marginBottom:'10px', marginTop:'15px'}}>DAYS</label>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="days-list">
                        {(provided) => (
                            <div
                                style={{ display: 'flex', width: '100%', flexDirection: 'column' }}
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                {selectedWeek.days.map((day: VitovaDay, index: number) => (
                                    <Draggable key={day.id} draggableId={day.id} index={index}>
                                        {(provided) => (
                                            <div
                                                className="item-div"
                                                onClick={() => {
                                                    setView('day');
                                                    console.log(JSON.stringify(day))
                                                    setSelectedDay(day);
                                                }}
                                                
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={{
                                                    ...provided.draggableProps.style,
                                                    fontWeight: 'bold',
                                                    fontStyle: 'italic',
                                                    marginBottom: '8px',
                                                    padding: '10px',
                                                    backgroundColor: isDayComplete(day)?'#f1f1f1':colorPalette.softRed,
                                                    borderRadius: '5px', // optional
                                                    cursor: 'move', // optional
                                                }}
                                            >
                                                <label className="plan-name-label" style={{fontSize:'1em'}}>
                                                    {daysOfTheWeek[index].toUpperCase()}
                                                </label>
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
                </div>
            )
        );
    }; 
    
    const renderPlansView = () => {
        return (
            <>
            <div style={{display:"flex", width:'100%', height:'100%', backgroundColor:'#fafafa', justifyContent:'flex-start', alignItems:'flex-start', flexDirection:'column'}}>
                {/* HEADER */}
                <div style={{display:"flex", width:'100%', height:'100%', backgroundColor:'#fafafa', justifyContent:'space-between', alignItems:'center', flexDirection:'row'}}>
                    <div>
                        {view === 'activity' && 
                            <FontAwesomeIcon  style={{marginLeft:'15px',fontSize:'2em',color:colorPalette.steelBlue}} icon={faArrowLeft}></FontAwesomeIcon>
                        }

                        <label style={{marginLeft:'15px',color:colorPalette.steelBlue, fontStyle:'italic', fontWeight:'bold', fontSize:'2em', textAlign:'center'}}>{view === 'plans' ? 'PLAN BUILDER' : 'ACTIVITY BUILDER'}</label>

                    </div>
                    
                    <button onClick={()=>createPlanClicked()} className="global-button" style={{ marginLeft: 0,alignSelf:'flex-end',}}>CREATE NEW PLAN</button>
                </div>
                <div style={{display:'flex', width:'100%', height:'50px', backgroundColor:'#fafafa', flexDirection:'row', justifyContent:'center', alignItems:'center'}}>
                    <div style={{display:'flex', width:'98%', height:'2px', backgroundColor:colorPalette.steelBlue, flexDirection:'row'}}>
                    
                    </div>
                </div>
                <div style={{display:"flex", width:'100%', height:'100%', backgroundColor:'#fafafa', justifyContent:'flex-start', alignItems:'flex-start', flexDirection:'column'}}>
                    <div className="plan-page" style={{ backgroundColor: '#fafafa', width:'100%',justifyContent: 'flex-start'}}>
                    <input
                            style={{fontSize:'1.75em'}}
                            type="text"
                            placeholder="Search plans..."
                            // value={searchTerm}
                            className="search-bar"
                            autoFocus
                            // onChange={(e) => handleSearch(e.target.value)}
                        />

                    <div style={{paddingBottom:'10vh'}}  className="plan-grid">
                        {user.editableVitovaPlans && user.editableVitovaPlans.map((plan:VitovaPlan)=>(
                        <div className="plan-card" key={plan._id}>
                            {/* PLAN DETAILS */}
                            <div  className="plan-details">
                                    <div style={{backgroundColor:'#ffffff'}} onClick={()=>toggleSection(plan, 'weeks')}  className="plan-column steel-blue">
                                        <label style={{fontWeight:'bold', fontStyle:'italic', height:'100%', backgroundColor:'#ffffff', fontSize:'1.25em'}} className="plan-name-label">
                                            {plan.title.toUpperCase()}
                                        </label>
                                    </div>
                                    {!expandedSections[plan._id] &&
                                    <div style={{display:'flex', flexDirection:'row'}}>
                                        <div style={{backgroundColor:'#ffffff'}} onClick={()=>copyPlan(plan)}  className="plan-column steel-blue arrow-left">
                                            <FontAwesomeIcon  style={{marginRight:'5px', fontSize:'2em',color:colorPalette.steelBlue}} icon={faCopy}></FontAwesomeIcon>
                                        </div>
                                        <div style={{backgroundColor:'#ffffff'}} onClick={()=>savePlan(plan)}  className="plan-column steel-blue arrow-left">
                                            <FontAwesomeIcon  style={{marginRight:'5px', fontSize:'2em',color:colorPalette.steelBlue}} icon={faSave}></FontAwesomeIcon>
                                        </div>
                                        <div style={{backgroundColor:'#ffffff'}} onClick={()=>uploadPlanToLibrary(plan)}  className="plan-column steel-blue arrow-left">
                                            <FontAwesomeIcon  style={{marginRight:'5px', fontSize:'2em',color:colorPalette.steelBlue}} icon={faUpload}></FontAwesomeIcon>
                                        </div>
                                        <div style={{backgroundColor:'#ffffff'}} onClick={()=>deletePlan(plan)}  className="plan-column steel-blue arrow-left">
                                            <FontAwesomeIcon  style={{marginRight:'20px',fontSize:'2em',color:colorPalette.softRed}} icon={faRemove}></FontAwesomeIcon>
                                        </div>
                                    </div> 
                                    }
                                </div>

                                <div
                                style={{display:'flex', width:'100%',  marginBottom:'10px', backgroundColor:'#ffffff'}}
                                    className={`expanded-section ${
                                        expandedSections[plan._id] ? 'expanded' : ''
                                    }`}
                                    >
                                    {expandedSections[plan._id] === 'weeks' && renderPlanWeeks()}
                                    {expandedSections[plan._id] === 'days' && renderPlanDays()}
                                </div>
                            </div>
                        ))}
                    </div>
                    </div>

                </div>
            </div>
            </>
        )
    }
    const renderPlanView = (planToRender: VitovaPlan) => {
        if (!planToRender) {
            return <div>No Plan Selected</div>;
        }
    
        const plan = user.editableVitovaPlans.find(p => p._id === planToRender._id);
    
        if (!plan) {
            return <div>Plan not found</div>;
        }
    
        return (
            <div style={{
                display: "flex",
                width: '100%',
                height: '100%',
                backgroundColor: '#fafafa',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'flex-start'
            }}>
                {/* HEADER */}
                <div style={{
                    display: "flex",
                    width: '100%',
                    height: '100%',
                    backgroundColor: '#fafafa',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}>
                    <label style={{
                        marginLeft: '15px',
                        color: colorPalette.steelBlue,
                        fontStyle: 'italic',
                        fontWeight: 'bold',
                        fontSize: '2em'
                    }}>
                        {plan.title}
                    </label>
    
                    <button className="global-button">
                        ADD WEEK
                    </button>
                </div>
            </div>
        );
    };
    const renderDayView = () => {
        return (
            <>
            <div style={{display:"flex", width:'100%', height:'100%', backgroundColor:'#fafafa', justifyContent:'flex-start', alignItems:'flex-start', flexDirection:'column'}}>
                {/* HEADER */}
                <div style={{display:"flex", width:'100%', height:'100%', backgroundColor:'#fafafa', justifyContent:'space-between', alignItems:'center', flexDirection:'row'}}>
                    <div>
                        <FontAwesomeIcon className="arrow-left" onClick={()=>setView('plans')} style={{marginLeft:'15px',fontSize:'2em',color:colorPalette.steelBlue}} icon={faArrowLeft}></FontAwesomeIcon>
                        <label style={{marginLeft:'15px',color:colorPalette.steelBlue, fontStyle:'italic', fontWeight:'bold', fontSize:'2em', textAlign:'center'}}>{`DAY BUILDER`}</label>
                        <label style={{marginLeft:'15px',color:colorPalette.steelBlue, fontStyle:'italic', fontSize:'1.25em', textAlign:'center'}}>{`${selectedPlan?.title.toUpperCase()} | WEEK ${getWeekNumber()} | ${selectedWeek && selectedDay && daysOfTheWeek[getDayIndex()].toUpperCase()}`}</label>


                    </div>
                    
                    <button onClick={()=>setIsCreateActivityModalOpen(true)} className="global-button" style={{ marginLeft: 0,alignSelf:'flex-end',}}>CREATE NEW ACTIVITY</button>
                    {isCreateActivityModalOpen && (
                        <Dialog open={isCreateActivityModalOpen} fullWidth maxWidth={'md'} onClose={() => setIsCreateActivityModalOpen(false)}>
                        <DialogTitle>New Activity</DialogTitle>
                        <DialogContent>
                        <button onClick={()=>addActivity()} className="global-button" style={{ marginLeft: 0,alignSelf:'flex-end',}}>CREATE NEW ACTIVITY</button>
                        <button
                            onClick={() => {
                                const minutes = prompt("Enter the number of minutes for the session:");
                                if (minutes && Number(minutes)) {
                                loadDayFromTemplate('choiceday', Number(minutes));
                                }
                            }}
                            className="global-button"
                            style={{ marginLeft: 0, alignSelf: "flex-end" }}
                            >
                            DAILY ACTIVITY DAY TEMPLATE
                            </button>
                            <button
                            onClick={() => loadDayFromTemplate('restday', 0)}
                            className="global-button"
                            style={{ marginLeft: 0, alignSelf: "flex-end" }}
                            >
                            REST ACTIVITY DAY TEMPLATE
                            </button>

                            <button
                            onClick={() => loadDayFromTemplate('flexibilityactivity', 0)}
                            className="global-button"
                            style={{ marginLeft: 0, alignSelf: "flex-end" }}
                            >
                            FLEXIBILITY ACTIVITY TEMPLATE
                            </button>
                            <button
                            onClick={() => loadDayFromTemplate('rockporttest', 0)}
                            className="global-button"
                            style={{ marginLeft: 0, alignSelf: "flex-end" }}
                            >
                            ROCKPORT TEST DAY TEMPLATE
                            </button>
                            <button
                            onClick={() => loadDayFromTemplate('flexibilitytest', 0)}
                            className="global-button"
                            style={{ marginLeft: 0, alignSelf: "flex-end" }}
                            >
                            FLEXIBILITY TEST DAY TEMPLATE
                            </button>
                        </DialogContent>
                        <DialogActions>
                            {/* <Button onClick={()=>{}} variant="contained" color="primary">Submit</Button> */}
                            <Button onClick={() => setIsCreateActivityModalOpen(false)} variant="outlined">Close</Button>
                        </DialogActions>
                    </Dialog>
                    )}

                </div>
                <div style={{display:'flex', width:'100%', height:'50px', backgroundColor:'#fafafa', flexDirection:'row', justifyContent:'center', alignItems:'center'}}>
                    <div style={{display:'flex', width:'98%', height:'2px', backgroundColor:colorPalette.steelBlue, flexDirection:'row'}}>
                    
                    </div>
                </div>
                <div style={{display:"flex", width:'100%', height:'100%', backgroundColor:'#fafafa', justifyContent:'flex-start', alignItems:'flex-start', flexDirection:'column'}}>
                    {/* <label style={{marginBottom:'10px',marginLeft:'15px',color:colorPalette.steelBlue, fontStyle:'italic', fontWeight:'bold', fontSize:'2em', textAlign:'center'}}>{}</label> */}
                    {selectedPlan && selectedWeek && selectedDay && (
                        <div style={{ display: 'flex', width: '100%', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            {user?.editableVitovaPlans
                                .find(p => p._id === selectedPlan._id)?.content
                                .find(w => w.id === selectedWeek.id)?.days
                                .find(d => d.id === selectedDay.id)?.activities?.map((activity: VitovaActivity, index) => (
                                    <div
                                        className="item-div"
                                        style={{width:'98%'}}
                                        key={activity.id}
                                        onClick={()=>{setSelectedActivity(activity); setView('activity')}}
                                    >
                                        <label style={{ fontWeight: 'bold', fontStyle: 'italic' }} className="plan-name-label">
                                            {`${activity.title.toUpperCase()}`}
                                        </label>
                                        <label style={{ fontWeight: 'bold', fontStyle: 'italic' }} className="plan-name-label">
                                            {`${activity.type.toUpperCase()}`}
                                        </label>
                                        <label style={{ fontWeight: 'bold', fontStyle: 'italic' }} className="plan-name-label">
                                            {`${activity.plannedDuration.toString().toUpperCase()}`}
                                        </label>
                                        <FontAwesomeIcon
                                        onClick={(e)=>{addActivity(activity);e.stopPropagation()}}
                                            className="arrow-left"
                                            style={{ marginRight: '20px', fontSize: '2em', color: colorPalette.steelBlue }}
                                            icon={faCopy}
                                        />
                                        <FontAwesomeIcon
                                        onClick={(e)=>{deleteActivity(activity.id);e.stopPropagation()}}
                                            className="arrow-left"
                                            style={{ marginRight: '20px', fontSize: '2em', color: colorPalette.softRed }}
                                            icon={faRemove}
                                        />
                                    </div>
                                ))}
                        </div>
                    )}

                </div>
            </div>
            </>
        )
    }
    const renderActivityView = () => {
        return (
            <>
            <div style={{display:"flex", width:'100%', height:'100%', backgroundColor:'#fafafa', justifyContent:'flex-start', alignItems:'flex-start', flexDirection:'column'}}>
                {/* HEADER */}
                <div style={{display:"flex", width:'100%', height:'100%', backgroundColor:'#fafafa', justifyContent:'space-between', alignItems:'center', flexDirection:'row'}}>
                    <div>
                        <FontAwesomeIcon className="arrow-left" onClick={()=>setView('day')} style={{marginLeft:'15px',fontSize:'2em',color:colorPalette.steelBlue}} icon={faArrowLeft}></FontAwesomeIcon>
                        <label style={{marginLeft:'15px',color:colorPalette.steelBlue, fontStyle:'italic', fontWeight:'bold', fontSize:'2em', textAlign:'center'}}>{`ACTIVITY BUILDER`}</label>
                        <label style={{marginLeft:'15px',color:colorPalette.steelBlue, fontStyle:'italic', fontSize:'1.25em', textAlign:'center'}}>{`${selectedPlan?.title.toUpperCase()} | WEEK ${getWeekNumber()} | ${selectedWeek && selectedDay && daysOfTheWeek[getDayIndex()].toUpperCase()} | ${selectedActivity?.title.toUpperCase()}`}</label>
                    </div>
                    
                    <button onClick={()=>addStep()} className="global-button" style={{ marginLeft: 0,alignSelf:'flex-end',}}>ADD STEP</button>
                </div>
                <div 
                    style={{
                        display: 'flex',
                        width: '100%',
                        backgroundColor: '#fafafa',
                        flexDirection: 'column',
                        alignItems: 'center',
                        padding: '10px 0'
                    }}
                >
                    {/* DETAILS Section */}
                    <label 
                        style={{
                            paddingLeft: 0, 
                            fontWeight: 'bold', 
                            fontStyle: 'italic', 
                            alignSelf: 'flex-start', 
                            textAlign: 'left', 
                            fontSize: '1.5em'
                        }} 
                        className="plan-name-label"
                    >
                        {`DETAILS`}
                    </label>

                    <input 
                        placeholder="ACTIVITY TITLE"
                        style={{
                            width:'100%',

                            padding: '5px 10px', 
                            cursor: 'text', 
                            outline: 'none', 
                            border: '2px solid steelblue', 
                            borderRadius: '5px', 
                            marginBottom: '10px',
                        }}
                        value={selectedActivity?.title || ''} 
                        onChange={(e) => {
                            if (!selectedActivity || !selectedPlan || !selectedWeek || !selectedDay) {
                                console.error('Missing required state');
                                return;
                            }

                            const findPlan = user.editableVitovaPlans.find(p=>p._id===selectedPlan._id);

                            if (!findPlan) {
                                console.error('Missing required state');
                                return;
                            }

                            // Find the index of the week and day in the plan
                            const weekIndex = selectedPlan.content.findIndex(week => week.id === selectedWeek.id);
                            const dayIndex = selectedPlan.content[weekIndex]?.days.findIndex(day => day.id === selectedDay.id);
                            const activityIndex = selectedPlan.content[weekIndex]?.days[dayIndex]?.activities.findIndex(a => a.id === selectedActivity.id);

                            if (weekIndex !== -1 && dayIndex !== -1 && activityIndex !== -1) {
                                const updatedActivity = {
                                    ...findPlan.content[weekIndex].days[dayIndex].activities[activityIndex],
                                    title: e.target.value, // Update the title with the new value
                                };
    
                                // Update the selected activity state
                                setSelectedActivity(updatedActivity);

                                const updatedWeek = {...selectedPlan.content[weekIndex]};
                                const updatedDay = {...updatedWeek.days[dayIndex]};
                                updatedDay.activities[activityIndex] = updatedActivity;

                                updatedWeek.days[dayIndex] = updatedDay;
                                const updatedContent = [...selectedPlan.content];
                                updatedContent[weekIndex] = updatedWeek;

                                const updatedPlan = {
                                    ...selectedPlan,
                                    content: updatedContent,
                                };

                                // Update the selected plan state
                                setSelectedPlan(updatedPlan);

                                const planIndex = user.editableVitovaPlans.findIndex(p => p._id === updatedPlan._id);
                                if (planIndex !== -1) {
                                    const updatedEditablePlans = [...user.editableVitovaPlans];
                                    updatedEditablePlans[planIndex] = updatedPlan;

                                    const updatedUser = {
                                        ...user,
                                        editableVitovaPlans: updatedEditablePlans,
                                    };

                                    // Call saveUser to persist the changes
                                    onInfoSet(updatedUser);
                                }
                            }
                        }}
                    />
                    <select
                        style={{
                            width:'100%',
                            padding: '5px 10px',
                            cursor: 'text',
                            outline: 'none',
                            appearance: 'none',
                            border: '2px solid steelblue',
                            borderRadius: '5px',
                            marginBottom: '10px',
                        }}
                        value={selectedActivity?.type || VitovaActivityType.STANDARD}
                        onChange={(e) => {
                            if (!selectedActivity || !selectedPlan || !selectedWeek || !selectedDay) {
                                console.error('Missing required state');
                                return;
                            }

                            const findPlan = user.editableVitovaPlans.find(p=>p._id===selectedPlan._id);

                            if (!findPlan) {
                                console.error('Missing required state');
                                return;
                            }
                        
                            // Locate indexes
                            const weekIndex = findPlan.content.findIndex((week) => week.id === selectedWeek.id);
                            if (weekIndex === -1) return;
                        
                            const dayIndex = findPlan.content[weekIndex].days.findIndex((day) => day.id === selectedDay.id);
                            if (dayIndex === -1) return;
                        
                            const activityIndex = findPlan.content[weekIndex].days[dayIndex].activities.findIndex(
                                (a) => a.id === selectedActivity.id
                            );
                            if (activityIndex === -1) return;

                            const newType = e.target.value as VitovaActivityType; // Explicitly cast
                        
                            const updatedActivity: VitovaActivity = {
                                ...findPlan.content[weekIndex].days[dayIndex].activities[activityIndex],
                                type: newType, // Now correctly typed
                            };
                        
                            // Update plan immutably
                            const updatedPlan = { ...findPlan };
                            updatedPlan.content = [...updatedPlan.content];
                            updatedPlan.content[weekIndex] = { ...updatedPlan.content[weekIndex] };
                            updatedPlan.content[weekIndex].days = [...updatedPlan.content[weekIndex].days];
                            updatedPlan.content[weekIndex].days[dayIndex] = { ...updatedPlan.content[weekIndex].days[dayIndex] };
                            updatedPlan.content[weekIndex].days[dayIndex].activities = [
                                ...updatedPlan.content[weekIndex].days[dayIndex].activities,
                            ];
                            updatedPlan.content[weekIndex].days[dayIndex].activities[activityIndex] = updatedActivity;
                        
                            // Update state
                            setSelectedActivity(updatedActivity);
                            setSelectedPlan(updatedPlan);
                        
                            // Update user data
                            const planIndex = user.editableVitovaPlans.findIndex((p) => p._id === updatedPlan._id);
                            if (planIndex !== -1) {
                                const updatedUser = {
                                    ...user,
                                    editableVitovaPlans: user.editableVitovaPlans.map((plan, index) =>
                                        index === planIndex ? updatedPlan : plan
                                    ),
                                };
                        
                                onInfoSet(updatedUser);
                            }
                        }}
                    >
                        <option disabled value="">Select Activity Type</option>
                        {Object.values(VitovaActivityType).map((type) => (
                            <option key={type} value={type}>{type.toUpperCase()}</option>
                        ))}
                    </select>
                    <select
                        style={{
                            width: '100%',
                            padding: '5px 10px',
                            cursor: 'pointer',
                            outline: 'none',
                            appearance: 'none',
                            border: '2px solid steelblue',
                            borderRadius: '5px',
                            marginBottom: '10px',
                        }}
                        value={selectedActivity?.compulsory === true ? "true" : "false"}
                        onChange={(e) => {
                            if (!selectedActivity || !selectedPlan || !selectedWeek || !selectedDay) {
                                console.error('Missing required state');
                                return;
                            }

                            const findPlan = user.editableVitovaPlans.find(p => p._id === selectedPlan._id);
                            if (!findPlan) {
                                console.error('Plan not found');
                                return;
                            }

                            // Locate indexes
                            const weekIndex = findPlan.content.findIndex(week => week.id === selectedWeek.id);
                            if (weekIndex === -1) return;

                            const dayIndex = findPlan.content[weekIndex].days.findIndex(day => day.id === selectedDay.id);
                            if (dayIndex === -1) return;

                            const activityIndex = findPlan.content[weekIndex].days[dayIndex].activities.findIndex(
                                a => a.id === selectedActivity.id
                            );
                            if (activityIndex === -1) return;

                            // Convert value from string to boolean
                            const newValue = JSON.parse(e.target.value.toLowerCase());

                            const updatedActivity = {
                                ...findPlan.content[weekIndex].days[dayIndex].activities[activityIndex],
                                compulsory: newValue,
                            };

                            // Update plan immutably
                            const updatedPlan = { ...findPlan };
                            updatedPlan.content = [...updatedPlan.content];
                            updatedPlan.content[weekIndex] = { ...updatedPlan.content[weekIndex] };
                            updatedPlan.content[weekIndex].days = [...updatedPlan.content[weekIndex].days];
                            updatedPlan.content[weekIndex].days[dayIndex] = { ...updatedPlan.content[weekIndex].days[dayIndex] };
                            updatedPlan.content[weekIndex].days[dayIndex].activities = [...updatedPlan.content[weekIndex].days[dayIndex].activities];
                            updatedPlan.content[weekIndex].days[dayIndex].activities[activityIndex] = updatedActivity;

                            // Update state
                            setSelectedActivity(updatedActivity);
                            setSelectedPlan(updatedPlan);

                            // Update user data
                            const planIndex = user.editableVitovaPlans.findIndex(p => p._id === updatedPlan._id);
                            if (planIndex !== -1) {
                                const updatedUser = {
                                    ...user,
                                    editableVitovaPlans: user.editableVitovaPlans.map((plan, index) =>
                                        index === planIndex ? updatedPlan : plan
                                    ),
                                };

                                onInfoSet(updatedUser);
                            }
                        }}
                    >
                        <option disabled value="">Compulsory</option>
                        <option key={1} value="true">Yes</option>
                        <option key={2} value="false">No</option>
                    </select>

                    <textarea 
                        placeholder="Description"
                        style={{
                            width:'100%',

                            padding: '5px 10px', 
                            cursor: 'text', 
                            outline: 'none', 
                            border: '2px solid steelblue', 
                            borderRadius: '5px', 
                            marginBottom: '10px',
                        }}
                        value={selectedActivity?.description || ''} 
                        onChange={(e) => {
                            if (!selectedActivity || !selectedPlan || !selectedWeek || !selectedDay) {
                                console.error('Missing required state');
                                return;
                            }

                            const findPlan = user.editableVitovaPlans.find(p=>p._id===selectedPlan._id);

                            if (!findPlan) {
                                console.error('Missing required state');
                                return;
                            }

                            // Find the index of the week and day in the plan
                            const weekIndex = selectedPlan.content.findIndex(week => week.id === selectedWeek.id);
                            const dayIndex = selectedPlan.content[weekIndex]?.days.findIndex(day => day.id === selectedDay.id);
                            const activityIndex = selectedPlan.content[weekIndex]?.days[dayIndex]?.activities.findIndex(a => a.id === selectedActivity.id);

                            if (weekIndex !== -1 && dayIndex !== -1 && activityIndex !== -1) {
                                const updatedActivity: VitovaActivity = {
                                    ...findPlan.content[weekIndex].days[dayIndex].activities[activityIndex],
                                    description: e.target.value, // Now correctly typed
                                };
                                // Update the selected activity state
                                setSelectedActivity(updatedActivity);

                                const updatedWeek = {...selectedPlan.content[weekIndex]};
                                const updatedDay = {...updatedWeek.days[dayIndex]};
                                updatedDay.activities[activityIndex] = updatedActivity;

                                updatedWeek.days[dayIndex] = updatedDay;
                                const updatedContent = [...selectedPlan.content];
                                updatedContent[weekIndex] = updatedWeek;

                                const updatedPlan = {
                                    ...selectedPlan,
                                    content: updatedContent,
                                };

                                // Update the selected plan state
                                setSelectedPlan(updatedPlan);

                                const planIndex = user.editableVitovaPlans.findIndex(p => p._id === updatedPlan._id);
                                if (planIndex !== -1) {
                                    const updatedEditablePlans = [...user.editableVitovaPlans];
                                    updatedEditablePlans[planIndex] = updatedPlan;

                                    const updatedUser = {
                                        ...user,
                                        editableVitovaPlans: updatedEditablePlans,
                                    };

                                    // Call saveUser to persist the changes
                                    onInfoSet(updatedUser);
                                }
                            }
                        }}
                    />
                    <input 
                        placeholder="Planned Duration"
                        style={{
                            width:'100%',

                            padding: '5px 10px', 
                            cursor: 'text', 
                            outline: 'none', 
                            border: '2px solid steelblue', 
                            borderRadius: '5px', 
                            marginBottom: '10px',
                        }}
                        value={selectedActivity?.plannedDuration || ''} 
                        onChange={(e) => {
                            if (!selectedActivity || !selectedPlan || !selectedWeek || !selectedDay) {
                                console.error('Missing required state');
                                return;
                            }

                            const findPlan = user.editableVitovaPlans.find(p=>p._id===selectedPlan._id);

                            if (!findPlan) {
                                console.error('Missing required state');
                                return;
                            }

                            // Find the index of the week and day in the plan
                            const weekIndex = selectedPlan.content.findIndex(week => week.id === selectedWeek.id);
                            const dayIndex = selectedPlan.content[weekIndex]?.days.findIndex(day => day.id === selectedDay.id);
                            const activityIndex = selectedPlan.content[weekIndex]?.days[dayIndex]?.activities.findIndex(a => a.id === selectedActivity.id);

                            if (weekIndex !== -1 && dayIndex !== -1 && activityIndex !== -1) {
                                const updatedActivity: VitovaActivity = {
                                    ...findPlan.content[weekIndex].days[dayIndex].activities[activityIndex],
                                    plannedDuration: e.target.value, // Now correctly typed
                                };
                                // Update the selected activity state
                                setSelectedActivity(updatedActivity);

                                const updatedWeek = {...selectedPlan.content[weekIndex]};
                                const updatedDay = {...updatedWeek.days[dayIndex]};
                                updatedDay.activities[activityIndex] = updatedActivity;

                                updatedWeek.days[dayIndex] = updatedDay;
                                const updatedContent = [...selectedPlan.content];
                                updatedContent[weekIndex] = updatedWeek;

                                const updatedPlan = {
                                    ...selectedPlan,
                                    content: updatedContent,
                                };

                                // Update the selected plan state
                                setSelectedPlan(updatedPlan);

                                const planIndex = user.editableVitovaPlans.findIndex(p => p._id === updatedPlan._id);
                                if (planIndex !== -1) {
                                    const updatedEditablePlans = [...user.editableVitovaPlans];
                                    updatedEditablePlans[planIndex] = updatedPlan;

                                    const updatedUser = {
                                        ...user,
                                        editableVitovaPlans: updatedEditablePlans,
                                    };

                                    // Call saveUser to persist the changes
                                    onInfoSet(updatedUser);
                                }
                            }
                        }}
                    />
                                        <input 
                        placeholder="Planned Distance"
                        style={{
                            width:'100%',

                            padding: '5px 10px', 
                            cursor: 'text', 
                            outline: 'none', 
                            border: '2px solid steelblue', 
                            borderRadius: '5px', 
                            marginBottom: '10px',
                        }}
                        value={selectedActivity?.plannedDistance || ''} 
                        onChange={(e) => {
                            if (!selectedActivity || !selectedPlan || !selectedWeek || !selectedDay) {
                                console.error('Missing required state');
                                return;
                            }

                            const findPlan = user.editableVitovaPlans.find(p=>p._id===selectedPlan._id);

                            if (!findPlan) {
                                console.error('Missing required state');
                                return;
                            }

                            // Find the index of the week and day in the plan
                            const weekIndex = selectedPlan.content.findIndex(week => week.id === selectedWeek.id);
                            const dayIndex = selectedPlan.content[weekIndex]?.days.findIndex(day => day.id === selectedDay.id);
                            const activityIndex = selectedPlan.content[weekIndex]?.days[dayIndex]?.activities.findIndex(a => a.id === selectedActivity.id);

                            if (weekIndex !== -1 && dayIndex !== -1 && activityIndex !== -1) {
                                const updatedActivity: VitovaActivity = {
                                    ...findPlan.content[weekIndex].days[dayIndex].activities[activityIndex],
                                    plannedDistance: e.target.value, // Now correctly typed
                                };
                                // Update the selected activity state
                                setSelectedActivity(updatedActivity);

                                const updatedWeek = {...selectedPlan.content[weekIndex]};
                                const updatedDay = {...updatedWeek.days[dayIndex]};
                                updatedDay.activities[activityIndex] = updatedActivity;

                                updatedWeek.days[dayIndex] = updatedDay;
                                const updatedContent = [...selectedPlan.content];
                                updatedContent[weekIndex] = updatedWeek;

                                const updatedPlan = {
                                    ...selectedPlan,
                                    content: updatedContent,
                                };

                                // Update the selected plan state
                                setSelectedPlan(updatedPlan);

                                const planIndex = user.editableVitovaPlans.findIndex(p => p._id === updatedPlan._id);
                                if (planIndex !== -1) {
                                    const updatedEditablePlans = [...user.editableVitovaPlans];
                                    updatedEditablePlans[planIndex] = updatedPlan;

                                    const updatedUser = {
                                        ...user,
                                        editableVitovaPlans: updatedEditablePlans,
                                    };

                                    // Call saveUser to persist the changes
                                    onInfoSet(updatedUser);
                                }
                            }
                        }}
                    />
                    <input 
                        placeholder="Planned RPE"
                        style={{
                            width:'100%',

                            padding: '5px 10px', 
                            cursor: 'text', 
                            outline: 'none', 
                            border: '2px solid steelblue', 
                            borderRadius: '5px', 
                            marginBottom: '10px',
                        }}
                        value={selectedActivity?.plannedRPE || ''} 
                        onChange={(e) => {
                            if (!selectedActivity || !selectedPlan || !selectedWeek || !selectedDay) {
                                console.error('Missing required state');
                                return;
                            }

                            const findPlan = user.editableVitovaPlans.find(p=>p._id===selectedPlan._id);

                            if (!findPlan) {
                                console.error('Missing required state');
                                return;
                            }

                            // Find the index of the week and day in the plan
                            const weekIndex = selectedPlan.content.findIndex(week => week.id === selectedWeek.id);
                            const dayIndex = selectedPlan.content[weekIndex]?.days.findIndex(day => day.id === selectedDay.id);
                            const activityIndex = selectedPlan.content[weekIndex]?.days[dayIndex]?.activities.findIndex(a => a.id === selectedActivity.id);

                            if (weekIndex !== -1 && dayIndex !== -1 && activityIndex !== -1) {
                                const updatedActivity: VitovaActivity = {
                                    ...findPlan.content[weekIndex].days[dayIndex].activities[activityIndex],
                                    plannedRPE: e.target.value, // Now correctly typed
                                };
                                // Update the selected activity state
                                setSelectedActivity(updatedActivity);

                                const updatedWeek = {...selectedPlan.content[weekIndex]};
                                const updatedDay = {...updatedWeek.days[dayIndex]};
                                updatedDay.activities[activityIndex] = updatedActivity;

                                updatedWeek.days[dayIndex] = updatedDay;
                                const updatedContent = [...selectedPlan.content];
                                updatedContent[weekIndex] = updatedWeek;

                                const updatedPlan = {
                                    ...selectedPlan,
                                    content: updatedContent,
                                };

                                // Update the selected plan state
                                setSelectedPlan(updatedPlan);

                                const planIndex = user.editableVitovaPlans.findIndex(p => p._id === updatedPlan._id);
                                if (planIndex !== -1) {
                                    const updatedEditablePlans = [...user.editableVitovaPlans];
                                    updatedEditablePlans[planIndex] = updatedPlan;

                                    const updatedUser = {
                                        ...user,
                                        editableVitovaPlans: updatedEditablePlans,
                                    };

                                    // Call saveUser to persist the changes
                                    onInfoSet(updatedUser);
                                }
                            }
                        }}
                    />

                    <input 
                        placeholder="Planned Heart Rate"
                        style={{
                            width:'100%',

                            padding: '5px 10px', 
                            cursor: 'text', 
                            outline: 'none', 
                            border: '2px solid steelblue', 
                            borderRadius: '5px', 
                            marginBottom: '10px',
                        }}
                        value={selectedActivity?.plannedHeartRate || ''} 
                        onChange={(e) => {
                            if (!selectedActivity || !selectedPlan || !selectedWeek || !selectedDay) {
                                console.error('Missing required state');
                                return;
                            }

                            const findPlan = user.editableVitovaPlans.find(p=>p._id===selectedPlan._id);

                            if (!findPlan) {
                                console.error('Missing required state');
                                return;
                            }

                            // Find the index of the week and day in the plan
                            const weekIndex = selectedPlan.content.findIndex(week => week.id === selectedWeek.id);
                            const dayIndex = selectedPlan.content[weekIndex]?.days.findIndex(day => day.id === selectedDay.id);
                            const activityIndex = selectedPlan.content[weekIndex]?.days[dayIndex]?.activities.findIndex(a => a.id === selectedActivity.id);

                            if (weekIndex !== -1 && dayIndex !== -1 && activityIndex !== -1) {
                                const updatedActivity: VitovaActivity = {
                                    ...findPlan.content[weekIndex].days[dayIndex].activities[activityIndex],
                                    plannedHeartRate: e.target.value, // Now correctly typed
                                };
                                // Update the selected activity state
                                setSelectedActivity(updatedActivity);

                                const updatedWeek = {...selectedPlan.content[weekIndex]};
                                const updatedDay = {...updatedWeek.days[dayIndex]};
                                updatedDay.activities[activityIndex] = updatedActivity;

                                updatedWeek.days[dayIndex] = updatedDay;
                                const updatedContent = [...selectedPlan.content];
                                updatedContent[weekIndex] = updatedWeek;

                                const updatedPlan = {
                                    ...selectedPlan,
                                    content: updatedContent,
                                };

                                // Update the selected plan state
                                setSelectedPlan(updatedPlan);

                                const planIndex = user.editableVitovaPlans.findIndex(p => p._id === updatedPlan._id);
                                if (planIndex !== -1) {
                                    const updatedEditablePlans = [...user.editableVitovaPlans];
                                    updatedEditablePlans[planIndex] = updatedPlan;

                                    const updatedUser = {
                                        ...user,
                                        editableVitovaPlans: updatedEditablePlans,
                                    };

                                    // Call saveUser to persist the changes
                                    onInfoSet(updatedUser);
                                }
                            }
                        }}
                    />

                    <input 
                        placeholder="Planned Pace (m/s)"
                        style={{
                            width:'100%',

                            padding: '5px 10px', 
                            cursor: 'text', 
                            outline: 'none', 
                            border: '2px solid steelblue', 
                            borderRadius: '5px', 
                            marginBottom: '10px',
                        }}
                        value={selectedActivity?.plannedPace || ''} 
                        onChange={(e) => {
                            if (!selectedActivity || !selectedPlan || !selectedWeek || !selectedDay) {
                                console.error('Missing required state');
                                return;
                            }

                            const findPlan = user.editableVitovaPlans.find(p=>p._id===selectedPlan._id);

                            if (!findPlan) {
                                console.error('Missing required state');
                                return;
                            }

                            // Find the index of the week and day in the plan
                            const weekIndex = selectedPlan.content.findIndex(week => week.id === selectedWeek.id);
                            const dayIndex = selectedPlan.content[weekIndex]?.days.findIndex(day => day.id === selectedDay.id);
                            const activityIndex = selectedPlan.content[weekIndex]?.days[dayIndex]?.activities.findIndex(a => a.id === selectedActivity.id);

                            if (weekIndex !== -1 && dayIndex !== -1 && activityIndex !== -1) {
                                const updatedActivity: VitovaActivity = {
                                    ...findPlan.content[weekIndex].days[dayIndex].activities[activityIndex],
                                    plannedPace: e.target.value, // Now correctly typed
                                };
                                // Update the selected activity state
                                setSelectedActivity(updatedActivity);

                                const updatedWeek = {...selectedPlan.content[weekIndex]};
                                const updatedDay = {...updatedWeek.days[dayIndex]};
                                updatedDay.activities[activityIndex] = updatedActivity;

                                updatedWeek.days[dayIndex] = updatedDay;
                                const updatedContent = [...selectedPlan.content];
                                updatedContent[weekIndex] = updatedWeek;

                                const updatedPlan = {
                                    ...selectedPlan,
                                    content: updatedContent,
                                };

                                // Update the selected plan state
                                setSelectedPlan(updatedPlan);

                                const planIndex = user.editableVitovaPlans.findIndex(p => p._id === updatedPlan._id);
                                if (planIndex !== -1) {
                                    const updatedEditablePlans = [...user.editableVitovaPlans];
                                    updatedEditablePlans[planIndex] = updatedPlan;

                                    const updatedUser = {
                                        ...user,
                                        editableVitovaPlans: updatedEditablePlans,
                                    };

                                    // Call saveUser to persist the changes
                                    onInfoSet(updatedUser);
                                }
                            }
                        }}
                    />

                    <input 
                        placeholder="Planned Power (watts)"
                        style={{
                            width:'100%',

                            padding: '5px 10px', 
                            cursor: 'text', 
                            outline: 'none', 
                            border: '2px solid steelblue', 
                            borderRadius: '5px', 
                            marginBottom: '10px',
                        }}
                        value={selectedActivity?.plannedPower || ''} 
                        onChange={(e) => {
                            if (!selectedActivity || !selectedPlan || !selectedWeek || !selectedDay) {
                                console.error('Missing required state');
                                return;
                            }

                            const findPlan = user.editableVitovaPlans.find(p=>p._id===selectedPlan._id);

                            if (!findPlan) {
                                console.error('Missing required state');
                                return;
                            }

                            // Find the index of the week and day in the plan
                            const weekIndex = selectedPlan.content.findIndex(week => week.id === selectedWeek.id);
                            const dayIndex = selectedPlan.content[weekIndex]?.days.findIndex(day => day.id === selectedDay.id);
                            const activityIndex = selectedPlan.content[weekIndex]?.days[dayIndex]?.activities.findIndex(a => a.id === selectedActivity.id);

                            if (weekIndex !== -1 && dayIndex !== -1 && activityIndex !== -1) {
                                const updatedActivity: VitovaActivity = {
                                    ...findPlan.content[weekIndex].days[dayIndex].activities[activityIndex],
                                    plannedPower: e.target.value, // Now correctly typed
                                };
                                // Update the selected activity state
                                setSelectedActivity(updatedActivity);

                                const updatedWeek = {...selectedPlan.content[weekIndex]};
                                const updatedDay = {...updatedWeek.days[dayIndex]};
                                updatedDay.activities[activityIndex] = updatedActivity;

                                updatedWeek.days[dayIndex] = updatedDay;
                                const updatedContent = [...selectedPlan.content];
                                updatedContent[weekIndex] = updatedWeek;

                                const updatedPlan = {
                                    ...selectedPlan,
                                    content: updatedContent,
                                };

                                // Update the selected plan state
                                setSelectedPlan(updatedPlan);

                                const planIndex = user.editableVitovaPlans.findIndex(p => p._id === updatedPlan._id);
                                if (planIndex !== -1) {
                                    const updatedEditablePlans = [...user.editableVitovaPlans];
                                    updatedEditablePlans[planIndex] = updatedPlan;

                                    const updatedUser = {
                                        ...user,
                                        editableVitovaPlans: updatedEditablePlans,
                                    };

                                    // Call saveUser to persist the changes
                                    onInfoSet(updatedUser);
                                }
                            }
                        }}
                    />

                    {/* STEPS Section */}
                    <label 
                        style={{
                            paddingLeft: 0, 
                            fontWeight: 'bold', 
                            fontStyle: 'italic', 
                            alignSelf: 'flex-start', 
                            textAlign: 'left', 
                            fontSize: '1.5em'
                        }} 
                        className="plan-name-label"
                    >
                        {`STEPS`}
                    </label>

                    <div 
                        style={{ 
                            display: "flex", 
                            width: "100%", 
                            flexDirection: "column", 
                            justifyContent: "flex-start", 
                            alignItems: "flex-start"
                        }}
                    >
                        {selectedPlan && selectedWeek && selectedDay && selectedActivity && (
                            <div 
                                style={{ 
                                    display: 'flex', 
                                    width: '100%', 
                                    flexDirection: 'column', 
                                    justifyContent: 'center', 
                                    alignItems: 'center' 
                                }}
                            >
                                {user?.editableVitovaPlans
                                    .find(p => p._id === selectedPlan._id)?.content
                                    .find(w => w.id === selectedWeek.id)?.days
                                    .find(d => d.id === selectedDay.id)?.activities
                                    .find(a => a.id === selectedActivity?.id)?.steps
                                    .map((step: VitovaActivityStep, index) => (
                                        <div className="item-div" style={{ width: "100%", marginTop:'0px',zIndex:1,backgroundColor:step.isTestEffort? `${colorPalette.softRed}33`:'transparent'}} key={index} onDoubleClick={()=>updateStepField(step.id,'isTestEffort', !step.isTestEffort)}>
                                            {renderStepFields(step, dbExercises, handleSearch, selectExercise)}
                                            <FontAwesomeIcon
                                                onClick={() => deleteStep(step.id)}
                                                className="arrow-left"
                                                style={{ marginRight: "20px", fontSize: "2em", color: colorPalette.softRed }}
                                                icon={faRemove}
                                            />
                                        </div>
                                    ))}
                            </div>
                        )}
                    </div>

                    {/* Add some spacing between steps and the chart */}
                    <div style={{ height: '20px' }}></div> 

                    {/* STEP STRESS SCORE OVERVIEW */}
                    <label 
                        style={{
                            paddingLeft: 0, 
                            fontWeight: 'bold', 
                            fontStyle: 'italic', 
                            alignSelf: 'flex-start', 
                            textAlign: 'left', 
                            fontSize: '1.5em'
                        }} 
                        className="plan-name-label"
                    >
                        {`STEP STRESS SCORE OVERVIEW`}
                    </label>

                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                        {selectedPlan && selectedWeek && selectedDay && selectedActivity && (() => {
                            const selectedPlanData = user?.editableVitovaPlans.find(p => p._id === selectedPlan._id);
                            const selectedWeekData = selectedPlanData?.content.find(w => w.id === selectedWeek.id);
                            const selectedDayData = selectedWeekData?.days.find(d => d.id === selectedDay.id);
                            const selectedActivityData = selectedDayData?.activities.find(a => a.id === selectedActivity.id);

                            const chartData = selectedActivityData?.steps.map(s=>getStressScore(s, user)) || [];
                            const labelData = selectedActivityData?.steps.map((_, index) => index.toString()) || [];

                            return <GeneralChartComponent user={user} chartData={chartData} labelData={labelData} />;
                        })()}
                    </div>
                </div>

            </div>
            </>
        )
    }

    return (() => {
        if (view === 'plans'){
            return renderPlansView();
        }else if (view==='day'){
            return renderDayView()
        }else{
            return renderActivityView();
        }
    })();
    
}

export default WorkoutBuilderPageLoggedInView;
