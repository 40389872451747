import * as Api from "../network/api";
import { CheckedCondition, CheckedMedication, Review, User, VitovaReview } from '../models/user';
import '../styles/ProfilePage.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faBarsProgress, faCheck, faEdit, faHeart, faHeartbeat, faInfo, faLayerGroup, faLineChart, faList, faMedal, faPills} from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from "react";
import { secondsToTimeString, timeStringToSeconds } from "../utility";
import colorPalette from "../utils/colors";
// import { Spinner } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import ChartComponent from "./ChartComponent";
import { Day } from "../models/plan";
import { getActivityStressScore, getDayStressScore, getPaceFromStep, getStressScore, getThresholdPace, getWeekStressScore, getZonesFromThreshold, metersPerSecondToMinPerKmString, speedToCyclingPower, VitovaZone } from "../utils/StressLoadFunctions";
import ComparisonChartComponent from "./ComparisonChartComponent";
import { VitovaDay, VitovaWeek } from "../models/vitovaPlan";
import Spinner from "./Spinner";
import { getDateAfterPlan } from "../utils/GPFunctions";

interface ProfilePageLoggedInProps {
    user: User,
    onLogoutSuccessful: () => void,
}

const ProfilePageLoggedInView = ({user, onLogoutSuccessful}:  ProfilePageLoggedInProps) => {
    const [viewingUser, setViewingUser] = useState<User | undefined>(undefined);
    const { id } = useParams<{ id: string }>();

    useEffect(() => {
        if (id) {
            getUser(id);
        } else {
            alert('No User Specified');
        }
    }, [id]); // Only run when `id` changes
    
    async function getUser(id:string){
        try{
            await Api.getUserById(id).then(res=>{
                setViewingUser(res);
            });
        }catch(error){
            alert("This Profile Could'nt Be Retrieved")
        }
    }

    const [currentActivityView, setCurrentActivityView] = useState('Run');

    const formatDate = (dateString: string | Date): string => {
        if (!dateString) return "";
        
        const date = new Date(dateString);
        if (isNaN(date.getTime())) return "Invalid Date";
    
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
        const year = date.getFullYear();
    
        return `${day}/${month}/${year}`;
    };
    
    function getUserReviewsGroupedIntoWeeks() {
        if (viewingUser && viewingUser.currentVitovaPlan){
            const reviews = viewingUser.vitovaReviews;
            const planStartDate = new Date(viewingUser.userPlanStartDate).getTime(); // Convert to timestamp
            let weeks: VitovaDay[] = viewingUser.currentVitovaPlan.content.map((w): VitovaDay => {
                return {
                    id: '',
                    activities: []
                };
            });
            
            for (const review of reviews.filter(r=>r.saved)) {
                const reviewDate = new Date(review.dateOfActivity).getTime(); // Convert to timestamp
                const weekIndex = Math.floor((reviewDate - planStartDate) / (7 * 24 * 60 * 60 * 1000)); // Calculate week index
                if (weekIndex>= 0 && weekIndex < weeks.length){
                    weeks[weekIndex].activities.push(review.activityReviewData);

                }
            }
        
            return weeks;
        }else{
            return []
        }

    }

    return (
        <div className="profile-page" style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
            {viewingUser ?
            <div style={{display:'flex', width:'100%', height:'auto'}}>
                <div style={{display:'flex', flex:3,width:'100%'}}>
                <div className="side-container" style={{flexDirection:'column', justifyContent:'flex-start', alignItems:'center', paddingBottom:'15px'}}>
                    <div style={{backgroundColor:'white', height:'2px', width:'100%',marginTop:'20px', marginBottom:'20px'}}></div>
                        
                        <div style={{display:'flex', width:'100%',justifyContent:'flex-start', alignItems:'flex-start'}}>

                        <div style={{display:'flex', width:'100%',justifyContent:'flex-start', alignItems:'flex-start', }}>
                        <FontAwesomeIcon icon={faList} style={{color:`#454545`, width:'25px', height:'25px', marginLeft:'20px'}}></FontAwesomeIcon>
                            <div style={{display:'flex', width:'100%', flex:1,justifyContent:'flex-start', flexDirection:'column', alignItems:'flex-start'}}>
                                <label className="stats-label" style={{fontSize:'1.25em',marginLeft:'10px', marginBottom:'10px', color:`${colorPalette.steelBlue}`, fontStyle:'italic',fontWeight:'bold'}}>ACTIVITY HISTORY</label>
                            </div>

                        </div>
                        </div>
                            {viewingUser.vitovaReviews.filter(r=>r.saved).sort((a, b) => {
                                const dateA = new Date(a.dateOfActivity).getTime();
                                const dateB = new Date(b.dateOfActivity).getTime();
                                return dateB - dateA; // Sort descending (newest first)
                            }).map((review:VitovaReview)=>(
                                <div style={{display:'flex', width:'96%',borderRadius:'5px',backgroundColor:`#f1f1f1`, border:`2px solid ${colorPalette.steelBlue}aa`,marginBottom:'10px', marginLeft:'20px',padding:'10px', flexDirection:'column',justifyContent:'flex-start', alignItems:'flex-start'}}>
                                    {review.dateOfActivity && 
                                        <label style={{fontSize:'0.75em',color:`${colorPalette.steelBlue}`, alignSelf:'flex-end', marginRight:'10px',fontStyle:'italic'}}>{formatDate(review.dateOfActivity.toString())}</label>
                                    }
                                    <label style={{fontSize:'1.25em',color:`${colorPalette.steelBlue}`, fontStyle:'italic',fontWeight:'bold'}}>{review.activityReviewData.title}</label>
                                    <label style={{fontSize:'1.125em',color:`${colorPalette.steelBlue}`, fontStyle:'italic',fontWeight:'normal'}}>{`${review.activityReviewData.plannedDuration} Minutes At ${review.activityReviewData.plannedRPE}/10 Effort`}</label>
                                    <label style={{fontSize:'1.125em',color:`${colorPalette.steelBlue}`, fontStyle:'italic',fontWeight:'normal'}}>{`${Math.round(getActivityStressScore(review.activityReviewData, viewingUser))} ISS`}</label>

                                </div>
                            ))}
                            {viewingUser.vitovaReviews.filter(r=>r.saved).length === 0 && 
                                <label style={{fontSize:'1.125em',color:`${colorPalette.steelBlue}`, fontStyle:'italic',alignSelf:'flex-start', textAlign:'start', marginLeft:'20px'}}>{`${viewingUser.fullname} Hasn't Completed Any Activities Yet`}</label>

                            }
                                            <div style={{backgroundColor:'white', height:'2px', width:'100%',marginTop:'20px', marginBottom:'20px'}}></div>
                        
                        <div style={{display:'flex', width:'100%',justifyContent:'flex-start', alignItems:'flex-start'}}>

                        <div style={{display:'flex', width:'100%',justifyContent:'flex-start', alignItems:'flex-start'}}>
                        <FontAwesomeIcon icon={faBarsProgress} style={{color:`#454545`, width:'25px', height:'25px', marginLeft:'20px'}}></FontAwesomeIcon>
                            <div style={{display:'flex', width:'100%', flex:1,justifyContent:'flex-start', flexDirection:'column', alignItems:'flex-start'}}>
                                <label className="stats-label" style={{fontSize:'1.25em',marginLeft:'10px', marginBottom:'10px', color:`${colorPalette.steelBlue}`, fontStyle:'italic',fontWeight:'bold'}}>PLAN PROGRESS</label>

                            </div>

                        </div>
                        </div>
                {viewingUser &&
                    <ComparisonChartComponent mh={30} mw={100} 
                    plannedData={viewingUser.currentVitovaPlan.content.map((week:VitovaWeek)=>getWeekStressScore(week, viewingUser, false))} 
                    realData={getUserReviewsGroupedIntoWeeks().map((week:VitovaDay)=>getDayStressScore(week, viewingUser, true))} 
                    labelData={viewingUser.currentVitovaPlan.content.map((week:VitovaWeek, index:number)=>`WEEK ${index+1}`)}
                    user={viewingUser}></ComparisonChartComponent>
                }
                        </div>
                    </div>
                <div style={{display:'flex', flex:2,width:'100%'}}>
                    
                    <div className="side-container" style={{flexDirection:'column', justifyContent:'flex-start', alignItems:'center', paddingBottom:'15px'}}>
                    <div style={{backgroundColor:'white', height:'2px', width:'100%',marginTop:'20px', marginBottom:'20px'}}></div>
                        
                        <div style={{display:'flex', width:'100%',justifyContent:'flex-start', alignItems:'flex-start'}}>

                        <div style={{display:'flex', width:'100%',justifyContent:'flex-start', alignItems:'flex-start'}}>
                        <FontAwesomeIcon icon={faList} style={{color:`#454545`, width:'25px', height:'25px', marginLeft:'20px'}}></FontAwesomeIcon>
                            <div style={{display:'flex', width:'100%', flex:1,justifyContent:'flex-start'}}>
                                <label className="stats-label" style={{fontSize:'1.25em',marginLeft:'10px', marginBottom:'10px', color:`${colorPalette.steelBlue}`, fontStyle:'italic',fontWeight:'bold'}}>PATIENT INFO</label>
                            </div>
                        </div>
                        </div>
                        <div style={{display:'flex', width:'100%', height:'auto', flexDirection:'column', alignItems:'center', textAlign:'left'}}>
                            <div style={{backgroundColor:`white`, width:'96%',margin:'8px 0px', borderRadius:'5px', flexDirection:'row', display:'flex'}}>
                                <div style={{display:'flex',  width:'100%', flex:1,backgroundColor:'#d1d1d1'}}>
                                    <label style={{fontSize:'1em', color:`${colorPalette.steelBlue}`,textAlign:'left', padding:'5px 8px', fontWeight:'bold', fontStyle:'italic'}}>FULLNAME</label>
                                </div>
                                <div style={{display:'flex',  width:'100%', flex:4,backgroundColor:'#e1e1e1'}}>
                                    <label style={{fontSize:'1em', color:`#454545`,textAlign:'left', padding:'5px 8px', fontWeight:'bold'}}>{viewingUser.fullname}</label>
                                </div>
                            </div>
                            <div style={{backgroundColor:`white`, width:'96%',margin:'8px 0px', borderRadius:'5px', flexDirection:'row', display:'flex'}}>
                                <div style={{display:'flex',  width:'100%', flex:1,backgroundColor:'#d1d1d1'}}>
                                    <label style={{fontSize:'1em', color:`${colorPalette.steelBlue}`,textAlign:'left', padding:'5px 8px', fontWeight:'bold', fontStyle:'italic'}}>EMAIL</label>
                                </div>
                                <div style={{display:'flex',  width:'100%', flex:4,backgroundColor:'#e1e1e1'}}>
                                    <label style={{fontSize:'1em', color:`#454545`,textAlign:'left', padding:'5px 8px', fontWeight:'bold'}}>{viewingUser.email}</label>
                                </div>
                            </div>
                            <div style={{backgroundColor:`white`, width:'96%',margin:'8px 0px', borderRadius:'5px', flexDirection:'row', display:'flex'}}>
                                <div style={{display:'flex',  width:'100%', flex:1,backgroundColor:'#d1d1d1'}}>
                                    <label style={{fontSize:'1em', color:`${colorPalette.steelBlue}`,textAlign:'left', padding:'5px 8px', fontWeight:'bold', fontStyle:'italic'}}>DOB</label>
                                </div>
                                <div style={{display:'flex',  width:'100%', flex:4,backgroundColor:'#e1e1e1'}}>
                                    <label style={{fontSize:'1em', color:`#454545`,textAlign:'left', padding:'5px 8px', fontWeight:'bold'}}>{viewingUser.info.dob &&  formatDate(viewingUser.info.dob.toString())}</label>
                                </div>
                            </div>
                        </div>
                        <div style={{backgroundColor:'white', height:'2px', width:'100%',marginTop:'20px', marginBottom:'20px'}}></div>

                        <div style={{display:'flex', width:'100%',justifyContent:'flex-start', alignItems:'flex-start'}}>

                        <div style={{display:'flex', width:'100%',justifyContent:'flex-start', alignItems:'flex-start'}}>
                        <FontAwesomeIcon icon={faList} style={{color:`#454545`, width:'25px', height:'25px', marginLeft:'20px'}}></FontAwesomeIcon>
                            <div style={{display:'flex', width:'100%', flex:1,justifyContent:'flex-start'}}>
                                <label className="stats-label" style={{fontSize:'1.25em',marginLeft:'10px', marginBottom:'10px', color:`${colorPalette.steelBlue}`, fontStyle:'italic',fontWeight:'bold'}}>SURGERY DETAILS</label>
                            </div>
                        </div>
                        </div>
                        <div style={{display:'flex', width:'100%', height:'auto', flexDirection:'column', alignItems:'center', textAlign:'left'}}>

                            <div style={{backgroundColor:`white`, width:'96%',margin:'8px 0px', borderRadius:'5px', flexDirection:'row', display:'flex'}}>
                                <div style={{display:'flex',  width:'100%', flex:1,backgroundColor:'#d1d1d1'}}>
                                    <label style={{fontSize:'1em', color:`${colorPalette.steelBlue}`,textAlign:'left', padding:'5px 8px', fontWeight:'bold', fontStyle:'italic'}}>EXPECTED SURGERY</label>
                                </div>
                                <div style={{display:'flex',  width:'100%', flex:4,backgroundColor:'#e1e1e1'}}>
                                    <label style={{fontSize:'1em', color:`#454545`,textAlign:'left', padding:'5px 8px', fontWeight:'bold'}}>{viewingUser.expectedSurgery && viewingUser.expectedSurgery}</label>
                                </div>
                            </div>
                            <div style={{backgroundColor:`white`, width:'96%',margin:'8px 0px', borderRadius:'5px', flexDirection:'row', display:'flex'}}>
                                <div style={{display:'flex',  width:'100%', flex:1,backgroundColor:'#d1d1d1'}}>
                                    <label style={{fontSize:'1em', color:`${colorPalette.steelBlue}`,textAlign:'left', padding:'5px 8px', fontWeight:'bold', fontStyle:'italic'}}>EXPECTED SURGERY DATE</label>
                                </div>
                                <div style={{display:'flex',  width:'100%', flex:4,backgroundColor:'#e1e1e1'}}>
                                    <label style={{fontSize:'1em', color:`#454545`,textAlign:'left', padding:'5px 8px', fontWeight:'bold'}}>{viewingUser.surgeryDate &&  formatDate(viewingUser.surgeryDate.toString())}</label>
                                </div>
                            </div>
                        </div>
                        <div style={{backgroundColor:'white', height:'2px', width:'100%',marginTop:'20px', marginBottom:'20px'}}></div>

                        <div style={{display:'flex', width:'100%',justifyContent:'flex-start', alignItems:'flex-start'}}>

                        <div style={{display:'flex', width:'100%',justifyContent:'flex-start', alignItems:'flex-start'}}>
                        <FontAwesomeIcon icon={faList} style={{color:`#454545`, width:'25px', height:'25px', marginLeft:'20px'}}></FontAwesomeIcon>
                            <div style={{display:'flex', width:'100%', flex:1,justifyContent:'flex-start'}}>
                                <label className="stats-label" style={{fontSize:'1.25em',marginLeft:'10px', marginBottom:'10px', color:`${colorPalette.steelBlue}`, fontStyle:'italic',fontWeight:'bold'}}>PLAN DETAILS</label>
                            </div>
                        </div>
                        
                        </div>

 
                        <div style={{display:'flex', width:'100%', height:'auto', flexDirection:'column', alignItems:'center', textAlign:'left'}}>

                            <div style={{backgroundColor:`white`, width:'96%',margin:'8px 0px', borderRadius:'5px', flexDirection:'row', display:'flex'}}>
                                <div style={{display:'flex',  width:'100%', flex:1,backgroundColor:'#d1d1d1'}}>
                                    <label style={{fontSize:'1em', color:`${colorPalette.steelBlue}`,textAlign:'left', padding:'5px 8px', fontWeight:'bold', fontStyle:'italic'}}>START DATE</label>
                                </div>
                                <div style={{display:'flex',  width:'100%', flex:4,backgroundColor:'#e1e1e1'}}>
                                    <label style={{fontSize:'1em', color:`#454545`,textAlign:'left', padding:'5px 8px', fontWeight:'bold'}}>{viewingUser.userPlanStartDate && formatDate(viewingUser.userPlanStartDate.toString())}</label>
                                </div>
                            </div>
                            <div style={{backgroundColor:`white`, width:'96%',margin:'8px 0px', borderRadius:'5px', flexDirection:'row', display:'flex'}}>
                                <div style={{display:'flex',  width:'100%', flex:1,backgroundColor:'#d1d1d1'}}>
                                    <label style={{fontSize:'1em', color:`${colorPalette.steelBlue}`,textAlign:'left', padding:'5px 8px', fontWeight:'bold', fontStyle:'italic'}}>EXPECTED PLAN END DATE</label>
                                </div>
                                <div style={{display:'flex',  width:'100%', flex:4,backgroundColor:'#e1e1e1'}}>
                                    <label style={{fontSize:'1em', color:`#454545`,textAlign:'left', padding:'5px 8px', fontWeight:'bold'}}>{getDateAfterPlan(viewingUser.userPlanStartDate.toString(), viewingUser.currentVitovaPlan.content.length)}</label>
                                </div>
                            </div>
                        </div>
                        {/* Conditions */}
                        <div style={{backgroundColor:'white', height:'2px', width:'100%',marginTop:'20px', marginBottom:'20px'}}></div>
                        
                        <div style={{display:'flex', width:'100%',justifyContent:'flex-start', alignItems:'flex-start'}}>

                        <div style={{display:'flex', width:'100%',justifyContent:'flex-start', alignItems:'flex-start'}}>
                        <FontAwesomeIcon icon={faHeartbeat} style={{color:`#454545`, width:'25px', height:'25px', marginLeft:'20px'}}></FontAwesomeIcon>
                            <div style={{display:'flex', width:'100%', flex:1,justifyContent:'flex-start'}}>
                                <label className="stats-label" style={{fontSize:'1.25em',marginLeft:'10px', marginBottom:'10px', color:`${colorPalette.steelBlue}`, fontWeight:'bold', fontStyle:'italic'}}>CURRENT CONDITIONS</label>
                            </div>
                        </div>
                        </div>
                        <div style={{display:'flex', width:'100%', height:'auto', flexDirection:'column', alignItems:'center', textAlign:'left'}}>
                            {viewingUser.info.conditions.map((condition:CheckedCondition)=>( condition.checked && 
                                <div style={{backgroundColor:`white`, width:'96%',margin:'8px 0px', borderRadius:'5px', flexDirection:'row', display:'flex'}}>
                                    <div style={{display:'flex',  width:'100%', flex:1,backgroundColor:'#d1d1d1'}}>
                                        <label style={{fontSize:'1em', color:`#454545`,textAlign:'left', padding:'5px 8px', fontWeight:'bold'}}>• {condition.condition.name}</label>
                                    </div>

                                </div>
                            ))}
                            {viewingUser.info.conditions.filter(c=>c.checked===true).length === 0 && 
                                <label className="stats-label" style={{fontSize:'1em', color:`${colorPalette.steelBlue}`, fontStyle:'italic',alignSelf:'flex-start'}}>{`${viewingUser.fullname.toUpperCase()} HAS NO CONDITIONS`}</label>
                            }
                        </div>
                        {/* Medications */}
                        <div style={{backgroundColor:'white', height:'2px', width:'100%',marginTop:'20px', marginBottom:'20px'}}></div>
                        <div style={{display:'flex', width:'100%',justifyContent:'flex-start', alignItems:'flex-start'}}>
                        <FontAwesomeIcon icon={faPills} style={{color:`#454545`, width:'25px', height:'25px', marginLeft:'20px'}}></FontAwesomeIcon>
                            <div style={{display:'flex', width:'100%', flex:1,justifyContent:'flex-start'}}>
                                <label className="stats-label" style={{fontSize:'1.25em',marginLeft:'10px', marginBottom:'10px', color:`${colorPalette.steelBlue}`, fontWeight:'bold', fontStyle:'italic'}}>CURRENTLY TAKING MEDICATIONS</label>
                            </div>
                        </div>
                        <div style={{display:'flex', width:'100%', height:'auto', flexDirection:'column', alignItems:'center', textAlign:'left'}}>
                            {viewingUser.info.medications.map((medication:CheckedMedication)=>( medication.checked && 
                                <div style={{backgroundColor:`white`, width:'96%',margin:'8px 0px', borderRadius:'5px', flexDirection:'row', display:'flex'}}>
                                    <div style={{display:'flex',  width:'100%', flex:1,backgroundColor:'#d1d1d1'}}>
                                        <label style={{fontSize:'1em', color:`#454545`,textAlign:'left', padding:'5px 8px', fontWeight:'bold'}}>• {medication.medication.name}</label>
                                    </div>

                                </div>
                            ))}
                            {viewingUser.info.medications.filter(c=>c.checked===true).length === 0 && 
                                <label className="stats-label" style={{fontSize:'1em', color:`${colorPalette.steelBlue}`, fontStyle:'italic',alignSelf:'flex-start'}}>{`${viewingUser.fullname.toUpperCase()} TAKES NO MEDICATIONS`}</label>
                            }
                        </div>
                        <div style={{ backgroundColor: 'white', height: '2px', width: '100%', margin: '20px auto' }}></div>
                        <div style={{ width: '100%'}}>
                        <div style={{width:'100%', height:'auto', backgroundColor:`ffffff33`, flexDirection:'row', display:"flex", justifyContent:'flex-start', alignItems:'flex-start'}}>
                        <div style={{width:'100%', height:'auto', backgroundColor:'transparent', flexDirection:'row', display:"flex", justifyContent:'flex-start', alignItems:'flex-start'}}>
                                {viewingUser.activityPreferences.includes(0) && 
                                    <div className="section-buttons-white" onClick={()=>setCurrentActivityView('Run')} 
                                    style={{
                                        backgroundColor:currentActivityView==='Run'?`#ffffffaa`:`#ffffffff`,
                                        borderStyle:'solid',
                                        borderWidth:'0px',
                                        borderBottomWidth:currentActivityView==='Run'?'2px':'0px',
                                        borderColor:'steelblue',
                                        width:'auto'
                                        }}>
                                        <label style={{
                                            padding:'5px 20px', 
                                            fontSize:'1em', 
                                            fontWeight:currentActivityView==='Run'?'bold':'normal',
                                            color:'#454545',
                                            pointerEvents:'none',
                                            }}>Run</label>
                                    </div>
                                }

                                {viewingUser.activityPreferences.includes(1) && 
                                    <div className="section-buttons-white" onClick={()=>setCurrentActivityView('Cycle')} 
                                    style={{
                                        backgroundColor:currentActivityView==='Cycle'?`#ffffffaa`:`#ffffffff`,
                                        borderStyle:'solid',
                                        borderWidth:'0px',
                                        borderBottomWidth:currentActivityView==='Cycle'?'2px':'0px',
                                        borderColor:'steelblue',
                                        width:'auto'
                                        }}>
                                        <label 
                                            style={{
                                                padding:'5px 20px', 
                                                fontSize:'1em', 
                                                color:'#454545',
                                                fontWeight:currentActivityView==='Cycle'?'bold':'normal',
                                                pointerEvents:'none'
                                                }}>Cycle</label>
                                    </div>
                                }
                                
                                {viewingUser.activityPreferences.includes(2) && 
                                    <div className="section-buttons-white" onClick={()=>setCurrentActivityView('Swim')} 
                                    style={{
                                        backgroundColor:currentActivityView==='Swim'?`#ffffffaa`:`#ffffffff`,
                                        borderStyle:'solid',
                                        borderWidth:'0px',
                                        borderBottomWidth:currentActivityView==='Swim'?'2px':'0px',
                                        borderColor:'steelblue',
                                        width:'auto'
                                        }}>
                                        <label 
                                            style={{
                                                padding:'5px 20px', 
                                                fontSize:'1em', 
                                                color:'#454545',
                                                fontWeight:currentActivityView==='Swim'?'bold':'normal',
                                                pointerEvents:'none'
                                                }}>Swim</label>
                                    </div>
                                }
                                {viewingUser.activityPreferences.includes(3) && 
                                    <div className="section-buttons-white" onClick={()=>setCurrentActivityView('Strength')} 
                                    style={{
                                        backgroundColor:currentActivityView==='Strength'?`#ffffffaa`:`#ffffffff`,
                                        borderStyle:'solid',
                                        borderWidth:'0px',
                                        borderBottomWidth:currentActivityView==='Strength'?'2px':'0px',
                                        borderColor:'steelblue',
                                        width:'auto'
                                        }}>
                                        <label 
                                            style={{
                                                padding:'5px 20px', 
                                                fontSize:'1em', 
                                                color:'#454545',
                                                fontWeight:currentActivityView==='Strength'?'bold':'normal',
                                                pointerEvents:'none'
                                                }}>Strength</label>
                                    </div>
                                }

                            </div>

                        </div>

                        <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-start', alignItems: 'flex-start', marginTop:'3vh'}}>
                            <FontAwesomeIcon icon={faLayerGroup} style={{ color: '#454545', width: '25px', height: '25px', marginLeft: '20px' }} />
                            <div style={{ display: 'flex', width: '100%', flex: 1, justifyContent: 'flex-start' }}>
                                <label className="stats-label" style={{ fontSize: '1.25em', marginLeft: '10px', marginBottom: '10px', color: '#454545', fontWeight: 'bold' }}>
                                    {currentActivityView === 'Run' ? 'Run Zones' : currentActivityView === 'Cycle'? `Cycling Zones (based on your weight of ${viewingUser.weightData.length>0?viewingUser.weightData[viewingUser.weightData.length-1].value:viewingUser.info.weight}KG)`:currentActivityView==='Swim' ? 'Swim Zones' : 'Strength Zones'}
                                </label>
                            </div>
                        </div>

                        <div style={{ display: 'flex', width: '100%', height: 'auto', flexDirection: 'column', alignItems: 'center', textAlign: 'left' }}>
                                {currentActivityView === 'Run' && (
                                    getZonesFromThreshold('Walking', getThresholdPace(viewingUser, 'Walking')).map((zone:VitovaZone) => (
                                        <div key={zone.zoneName} style={{ backgroundColor: 'white', width: '96%', margin: '8px 0px', borderRadius: '5px', flexDirection: 'row', display: 'flex' }}>
                                            <div style={{ display: 'flex', width: '100%', flex: 1, backgroundColor: '#d1d1d1', justifyContent: 'center' }}>
                                                <label style={{ fontSize: '1em', color: '#454545', textAlign: 'left', padding: '5px 15px', fontWeight: 'bold' }}>{zone.zoneName}</label>
                                            </div>
                                            <div style={{ display: 'flex', width: '100%', flex: 2.5, backgroundColor: '#e1e1e1', justifyContent: 'center' }}>
                                                <label
                                                    style={{ fontSize: '1em', color: '#454545', textAlign: 'center', padding: '5px 5px', border: 'none', outline: 'none', marginTop: 0 }}
                                                    className="card-title"
                                                >
                                                    {`${metersPerSecondToMinPerKmString(zone.paceRange[0])} - ${metersPerSecondToMinPerKmString(zone.paceRange[1])}`}
                                                </label>
                                            </div>
                                            <div style={{ display: 'flex', width: '100%', flex: 1.5, backgroundColor: '#ffffff', justifyContent: 'center', alignItems: 'center' }}>
                                                <label
                                                    style={{ fontSize: '1em', color: '#454545', textAlign: 'center', padding: '5px 5px', border: 'none', outline: 'none', marginTop: 0 }}
                                                    className="card-title"
                                                >
                                                    {`${zone.heartRateRange[0]} - ${zone.heartRateRange[1]} BPM`}
                                                </label>
                                            </div>
                                        </div>
                                    ))
                                ) 
                                }
                                {currentActivityView === 'Cycle' && (
                                    getZonesFromThreshold('Cycling', getThresholdPace(viewingUser, 'Cycling')).map((zone:VitovaZone) => (
                                        <div key={zone.zoneName} style={{ backgroundColor: 'white', width: '96%', margin: '8px 0px', borderRadius: '5px', flexDirection: 'row', display: 'flex' }}>
                                            <div style={{ display: 'flex', width: '100%', flex: 1, backgroundColor: '#d1d1d1', justifyContent: 'center' }}>
                                                <label style={{ fontSize: '1em', color: '#454545', textAlign: 'left', padding: '5px 15px', fontWeight: 'bold' }}>{zone.zoneName}</label>
                                            </div>
                                            <div style={{ display: 'flex', width: '100%', flex: 2.5, backgroundColor: '#e1e1e1', justifyContent: 'center' }}>
                                                <label
                                                    style={{ fontSize: '1em', color: '#454545', textAlign: 'center', padding: '5px 5px', border: 'none', outline: 'none', marginTop: 0 }}
                                                    className="card-title"
                                                >
                                                    {`${speedToCyclingPower(viewingUser,zone.paceRange[0])} - ${speedToCyclingPower(viewingUser,zone.paceRange[1])}`}
                                                </label>
                                            </div>
                                            <div style={{ display: 'flex', width: '100%', flex: 1.5, backgroundColor: '#ffffff', justifyContent: 'center', alignItems: 'center' }}>
                                                <label
                                                    style={{ fontSize: '1em', color: '#454545', textAlign: 'center', padding: '5px 5px', border: 'none', outline: 'none', marginTop: 0 }}
                                                    className="card-title"
                                                >
                                                    {`${zone.heartRateRange[0]} - ${zone.heartRateRange[1]} BPM`}
                                                </label>
                                            </div>
                                        </div>
                                    ))
                                ) 
                                }
                            </div>
                        </div>
                        <>
                        {currentActivityView === 'Run' &&
                        (
                        <div style={{width:'100%'}}>
                            <div style={{backgroundColor:'white', height:'2px', width:'100%',marginTop:'20px', marginBottom:'20px'}}></div>
                            <div style={{display:'flex', width:'100%',justifyContent:'flex-start', alignItems:'flex-start'}}>
                            <FontAwesomeIcon icon={faMedal} style={{color:`${colorPalette.gold}`, width:'25px', height:'25px', marginLeft:'20px'}}></FontAwesomeIcon>
                                <div style={{display:'flex', width:'100%', flex:1,justifyContent:'flex-start'}}>
                                    <label className="stats-label" style={{fontSize:'1.25em',marginLeft:'10px', marginBottom:'10px', color:`${colorPalette.gold}`, fontWeight:'bold'}}>Best Efforts (Run)</label>
                                </div>

                            </div>
                            <div style={{display:'flex', width:'100%', height:'auto', flexDirection:'column', alignItems:'center', textAlign:'left'}}>
                                {
                                viewingUser.bestEfforts
                                .filter(e => e?.step?.cardio?.exercise?.name && 
                                  (e.step.cardio.exercise.name === 'Running' || 
                                   e.step.cardio.exercise.name === 'Walking' || 
                                   e.step.cardio.exercise.name === 'Trail Running'))
                                .map((effort) => (
                                    <div style={{backgroundColor:`white`, width:'96%',margin:'8px 0px',borderRadius:'5px', flexDirection:'row', display:'flex'}}>
                                        <div style={{display:'flex',  width:'100%', flex:2 , backgroundColor:'#e1e1e1', justifyContent: 'center',alignItems:'center' }}>
                                            <label style={{fontSize:'1em', color:`#454545`,textAlign:'center', padding:'5px 15px', fontWeight:'bold'}}>{effort.activity.title}</label>
                                        </div>
                                        <div style={{display:'flex',  width:'100%', flex:1, backgroundColor:'#dadada',justifyContent:'center', alignItems:'center'}}>
                                            <label
                                                style={{fontSize:'1em', color:`#454545`, textAlign:'center', marginRight:'6px', padding:'5px 5px', border:'none', outline:'none', marginTop:0}}
                                                className="card-title" 
                                            >{effort.step.cardio?.exercise && effort.step.cardio?.exercise.name}</label>
                                        </div>
                                        <div style={{display:'flex',  width:'100%', flex:1, backgroundColor:'#e1e1e1',justifyContent:'center', alignItems:'center'}}>
                                            <label
                                                style={{fontSize:'1em', color:`#454545`, textAlign:'center', marginRight:'6px', padding:'5px 5px', border:'none', outline:'none', marginTop:0}}
                                                className="card-title" 
                                            >{effort.step.cardio?.duration} Minutes</label>
                                        </div>
                                        <div style={{display:'flex',  width:'100%', flex:1, backgroundColor:'#dadada',justifyContent:'center', alignItems:'center'}}>
                                            <label
                                                style={{fontSize:'1em', color:`#454545`, textAlign:'center', marginRight:'6px', padding:'5px 5px', border:'none', outline:'none', marginTop:0}}
                                                className="card-title" 
                                            >{effort.step.cardio?.distance}KM</label>
                                        </div>
                                        <div style={{display:'flex',  width:'100%', flex:1, backgroundColor:colorPalette.gold,justifyContent:'center', alignItems:'center'}}>
                                            <label
                                                style={{fontSize:'1em', color:`white`, textAlign:'center', marginRight:'6px', padding:'5px 5px', border:'none', outline:'none', marginTop:0}}
                                                className="card-title" 
                                            >{metersPerSecondToMinPerKmString(getPaceFromStep(effort.step))}</label>
                                        </div>
                                    </div>
                                ))}

                            {viewingUser.bestEfforts
                                .filter(e => e?.step?.cardio?.exercise?.name && 
                                  (e.step.cardio.exercise.name === 'Running' || 
                                   e.step.cardio.exercise.name === 'Walking' || 
                                   e.step.cardio.exercise.name === 'Trail Running'))
                                .length === 0 && 
                                <label className="stats-label" style={{fontSize:'1em', color:`${colorPalette.steelBlue}`, fontStyle:'italic',alignSelf:'flex-start'}}>{`${viewingUser.fullname.toUpperCase()} HASN'T COMPLETED A BEST EFFORT IN THIS ACTIVITY`}</label>
                            }
                            </div>
                        </div>
                        )
                        }
                        {currentActivityView === 'Cycle' &&
                        (
                        <div style={{width:'100%'}}>
                            <div style={{backgroundColor:'white', height:'2px', width:'100%',marginTop:'20px', marginBottom:'20px'}}></div>
                            <div style={{display:'flex', width:'100%',justifyContent:'flex-start', alignItems:'flex-start'}}>
                            <FontAwesomeIcon icon={faMedal} style={{color:`${colorPalette.gold}`, width:'25px', height:'25px', marginLeft:'20px'}}></FontAwesomeIcon>
                                <div style={{display:'flex', width:'100%', flex:1,justifyContent:'flex-start'}}>
                                    <label className="stats-label" style={{fontSize:'1.25em',marginLeft:'10px', marginBottom:'10px', color:`${colorPalette.gold}`, fontWeight:'bold'}}>Best Efforts (Cycle)</label>
                                </div>

                            </div>
                            <div style={{display:'flex', width:'100%', height:'auto', flexDirection:'column', alignItems:'center', textAlign:'left'}}>
                                {viewingUser.bestEfforts.filter(e=>e.step.cardio?.exercise && e.step.cardio?.exercise.name==='Cycling').map((effort)=>(
                                    <div style={{backgroundColor:`white`, width:'96%',margin:'8px 0px',borderRadius:'5px', flexDirection:'row', display:'flex'}}>
                                        <div style={{display:'flex',  width:'100%', flex:2 , backgroundColor:'#e1e1e1', justifyContent: 'center' , alignItems:'center'}}>
                                            <label style={{fontSize:'1em', color:`#454545`,textAlign:'center', padding:'5px 15px', fontWeight:'bold'}}>{effort.activity.title}</label>
                                        </div>
                                        <div style={{display:'flex',  width:'100%', flex:1, backgroundColor:'#dadada',justifyContent:'center', alignItems:'center'}}>
                                            <label
                                                style={{fontSize:'1em', color:`#454545`, textAlign:'center', marginRight:'6px', padding:'5px 5px', border:'none', outline:'none', marginTop:0}}
                                                className="card-title" 
                                            >{effort.step.cardio?.exercise && effort.step.cardio?.exercise.name}</label>
                                        </div>
                                        <div style={{display:'flex',  width:'100%', flex:1, backgroundColor:'#e1e1e1',justifyContent:'center', alignItems:'center'}}>
                                            <label
                                                style={{fontSize:'1em', color:`#454545`, textAlign:'center', marginRight:'6px', padding:'5px 5px', border:'none', outline:'none', marginTop:0}}
                                                className="card-title" 
                                            >{effort.step.cardio?.duration} Minutes</label>
                                        </div>
                                        <div style={{display:'flex',  width:'100%', flex:1, backgroundColor:'#dadada',justifyContent:'center', alignItems:'center'}}>
                                            <label
                                                style={{fontSize:'1em', color:`#454545`, textAlign:'center', marginRight:'6px', padding:'5px 5px', border:'none', outline:'none', marginTop:0}}
                                                className="card-title" 
                                            >{effort.step.cardio?.distance}KM</label>
                                        </div>
                                        <div style={{display:'flex',  width:'100%', flex:1, backgroundColor:colorPalette.gold,justifyContent:'center', alignItems:'center'}}>
                                            <label
                                                style={{fontSize:'1em', color:`white`, textAlign:'center', marginRight:'6px', padding:'5px 5px', border:'none', outline:'none', marginTop:0}}
                                                className="card-title" 
                                            >{speedToCyclingPower(viewingUser, getPaceFromStep(effort.step))}</label>
                                        </div>
                                    </div>
                                ))}
                                {viewingUser.bestEfforts.filter(e=>e.step.cardio?.exercise && e.step.cardio?.exercise.name==='Cycling').length === 0 && 
                                <label className="stats-label" style={{fontSize:'1em', color:`${colorPalette.steelBlue}`, fontStyle:'italic',alignSelf:'flex-start'}}>{`${viewingUser.fullname.toUpperCase()} HASN'T COMPLETED A BEST EFFORT IN THIS ACTIVITY`}</label>
                            }
                            </div>
                        </div>
                        )
                        }
                        {currentActivityView === 'Strength' &&
                        (
                        <div style={{width:'100%'}}>
                            <div style={{backgroundColor:'white', height:'2px', width:'100%',marginTop:'20px', marginBottom:'20px'}}></div>
                            <div style={{display:'flex', width:'100%',justifyContent:'flex-start', alignItems:'flex-start'}}>
                            <FontAwesomeIcon icon={faMedal} style={{color:`${colorPalette.gold}`, width:'25px', height:'25px', marginLeft:'20px'}}></FontAwesomeIcon>
                                <div style={{display:'flex', width:'100%', flex:1,justifyContent:'flex-start'}}>
                                    <label className="stats-label" style={{fontSize:'1.25em',marginLeft:'10px', marginBottom:'10px', color:`${colorPalette.gold}`, fontWeight:'bold'}}>Best Efforts (Strength)</label>
                                </div>

                            </div>
                            <div style={{display:'flex', width:'100%', height:'auto', flexDirection:'column', alignItems:'center', textAlign:'left'}}>
                                {viewingUser.bestEfforts.filter(e=>e.step.strength?.exercise).map((effort)=>(
                                    <div style={{backgroundColor:`white`, width:'96%',margin:'8px 0px',borderRadius:'5px', flexDirection:'row', display:'flex'}}>
                                        <div style={{display:'flex',  width:'100%', flex:2 , backgroundColor:'#e1e1e1', justifyContent: 'center' , alignItems:'center'}}>
                                            <label style={{fontSize:'1em', color:`#454545`,textAlign:'center', padding:'5px 15px', fontWeight:'bold'}}>{effort.activity.title}</label>
                                        </div>
                                        <div style={{display:'flex',  width:'100%', flex:1, backgroundColor:'#dadada',justifyContent:'center', alignItems:'center'}}>
                                            <label
                                                style={{fontSize:'1em', color:`#454545`, textAlign:'center', marginRight:'6px', padding:'5px 5px', border:'none', outline:'none', marginTop:0}}
                                                className="card-title" 
                                            >{effort.step.strength?.exercise && effort.step.strength?.exercise.name}</label>
                                        </div>
                                        <div style={{display:'flex',  width:'100%', flex:1, backgroundColor:'#e1e1e1',justifyContent:'center', alignItems:'center'}}>
                                            <label
                                                style={{fontSize:'1em', color:`#454545`, textAlign:'center', marginRight:'6px', padding:'5px 5px', border:'none', outline:'none', marginTop:0}}
                                                className="card-title" 
                                            >{effort.step.strength?.repsInSeconds}s</label>
                                        </div>
                                        <div style={{display:'flex',  width:'100%', flex:1, backgroundColor:colorPalette.gold,justifyContent:'center', alignItems:'center'}}>
                                            <label
                                                style={{fontSize:'1em', color:`white`, textAlign:'center', marginRight:'6px', padding:'5px 5px', border:'none', outline:'none', marginTop:0}}
                                                className="card-title" 
                                            >
                                                {effort.step.strength?.reps} reps
                                                </label>
                                        </div>
                                    </div>
                                ))}
                            {viewingUser.bestEfforts.filter(e=>e.step.strength?.exercise).length === 0 && 
                                <label className="stats-label" style={{fontSize:'1em', color:`${colorPalette.steelBlue}`, fontStyle:'italic',alignSelf:'flex-start'}}>{`${viewingUser.fullname.toUpperCase()} HASN'T COMPLETED A BEST EFFORT IN THIS ACTIVITY`}</label>
                            }
                            </div>
                        </div>
                        )
                        }
                         </>
                    </div>
                    
                </div>

            </div>
            :
            <div style={{width:'100%', height:'100vh'}}>
                <Spinner text={`LOADING PROFILE`}/>
            </div>
            }
        </div>
    );

}

export default ProfilePageLoggedInView;