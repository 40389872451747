import { DBCondition } from "../models/condition";
import { Exercise } from "../models/exercise";
import { DBMedication } from "../models/medication";
import { ChecklistDataObject, DataObject, User } from "../models/user";
import { VitovaPlan, VitovaStepType } from "../models/vitovaPlan";
import colorPalette from "./colors";

function hasSubmittedSmokingDataToday(patient:User){
    const today = new Date();
    const isValueForToday = patient.smokingData.some(data => {
        const dataDate = new Date(data.date);
        return (
            dataDate.getFullYear() === today.getFullYear() &&
            dataDate.getMonth() === today.getMonth() &&
            dataDate.getDate() === today.getDate()
        );
    });

    if (!isValueForToday) {
        return false;
    } else {
        return true;
    }
}
function hasSubmittedDrinkingDataToday (patient:User){
    const today = new Date();
    const isValueForToday = patient.drinkingData.some(data => {
    const dataDate = new Date(data.date);
    return (
        dataDate.getFullYear() === today.getFullYear() &&
        dataDate.getMonth() === today.getMonth() &&
        dataDate.getDate() === today.getDate()
    );
    });

    if (!isValueForToday) {
        return false;
    } else {
        return true;
    }

}
export function getSleepQualityColor(patient:User, value?: number): string {
    // Calculate the patient's age
    const dob = new Date(patient.info.dob);
    const today = new Date();
    const age = today.getFullYear() - dob.getFullYear() - (today < new Date(today.getFullYear(), dob.getMonth(), dob.getDate()) ? 1 : 0);

    // Get the most recent sleep data
    const sleepHours = value ? value : patient.sleepingData.at(-1)?.value
    if (sleepHours === undefined || sleepHours === -1){
        // NOT SUBMITTED
        return '#34343466'
    }
    
    // Determine the age group and return the corresponding color
    if (age >= 65) {
        if (sleepHours >= 7) return colorPalette.seaGreen;
        else if (sleepHours >= 6 && sleepHours < 7) return colorPalette.orange;
        else if (sleepHours < 6) return colorPalette.softRed;
    } else if (age >= 18 && age <= 64) {
        if (sleepHours >= 7) return colorPalette.seaGreen;
        else if (sleepHours >= 6 && sleepHours < 7) return colorPalette.orange;
        else if (sleepHours < 6) return colorPalette.softRed;
    } else if (age >= 13 && age <= 17) {
        if (sleepHours >= 8) return colorPalette.seaGreen;
        else if (sleepHours >= 7 && sleepHours < 8) return colorPalette.orange;
        else if (sleepHours < 7) return colorPalette.softRed;
    } else if (age >= 6 && age <= 12) {
        if (sleepHours >= 9) return colorPalette.seaGreen;
        else if (sleepHours >= 8 && sleepHours < 9) return colorPalette.orange;
        else if (sleepHours < 8) return colorPalette.softRed;
    }
    // NOT SUBMITTED
    return '#34343466'
}
export function getAlcoholScoreColor(patient: User, value?: number) {
    if (patient.drinker) {
        if (hasSubmittedDrinkingDataToday(patient)) {
            const today = new Date();
            const todaysDrinkingValue = patient.drinkingData.find(o => {
                const drinkingDate = new Date(o.date); // Convert ISO 8601 string to Date object
                return drinkingDate.getFullYear() === today.getFullYear() &&
                       drinkingDate.getMonth() === today.getMonth() &&
                       drinkingDate.getDate() === today.getDate();
            })?.value;

            // Use the provided value if available, otherwise fallback to today's drinking value
            const drinkingValue = value !== undefined ? value : todaysDrinkingValue || 0;
            if (drinkingValue < 0){
                return '#34343466'
            }else if (drinkingValue > 0) {
                // SUBMITTED RED
                return colorPalette.softRed;
            } else {
                // SUBMITTED GREEN
                return colorPalette.seaGreen;
            }
        } else {
            // NOT SUBMITTED
            return '#34343466';
        }
    } else {
        // NON SMOKER
        return '#34343422';
    }
}
export function getNutritionDataColor(patient:User, value?:ChecklistDataObject): string {
    const latestData = value ? value : patient.nutritionData.at(-1);

    if (latestData && latestData.value.length > 0) { // Check if there is data and its length is > 0
        const checkedCount = latestData.value.filter(v => v.checked === true).length; // Number of checked items
        
        // Mapping checkedCount to color
        if (checkedCount >= 0 && checkedCount <= 1) {
            return colorPalette.softRed;
        } else if (checkedCount > 1 && checkedCount <= 3) {
            return colorPalette.orange;
        } else {
            return colorPalette.seaGreen;
        }
    } else {
        // NOT SUBMITTED
        return '#34343466'
    }
}
export function getSmokingScoreColor(patient: User, value?: number) {
    if (patient.smoker) {
        if (hasSubmittedSmokingDataToday(patient)) {
            const today = new Date();
            const todaysSmokingValue = patient.smokingData.find(o => {
                const smokingDate = new Date(o.date); // Convert ISO 8601 string to Date object
                return smokingDate.getFullYear() === today.getFullYear() &&
                       smokingDate.getMonth() === today.getMonth() &&
                       smokingDate.getDate() === today.getDate();
            })?.value;

            // Use the provided value if available, otherwise fallback to today's smoking value
            const smokingValue = value !== undefined ? value : todaysSmokingValue || 0;
            if (smokingValue < 0){
                return '#34343466'
            }else if (smokingValue > 0) {
                // SUBMITTED RED
                return colorPalette.softRed;
            } else {
                // SUBMITTED GREEN
                return colorPalette.seaGreen;
            }
        } else {
            // NOT SUBMITTED
            return '#34343466';
        }
    } else {
        // NON SMOKER
        return '#34343422';
    }
}
// AVERAGED FUNCTIONS
export function getAverageSleepQualityColor(patient: User) {
    if (patient.sleepingData.length > 0) {
        // Filter out any values below 0 and calculate the total of valid values
        const total = patient.sleepingData.reduce((sum, o) => sum + (o.value >= 0 ? Number(o.value) : 0), 0);
        
        // Count the valid data points (values above or equal to 0)
        const count = patient.sleepingData.filter(o => o.value >= 0).length;
        
        // Avoid division by zero if there are no valid data points
        const average = count > 0 ? total / count : 0;
        
        // Determine color based on the average sleep quality
        if (average >= 7) {
            return colorPalette.seaGreen; // Healthy sleep range
        } else if (average >= 6 && average < 7) {
            return colorPalette.orange; // Moderate sleep range
        } else {
            return colorPalette.softRed; // Poor sleep range
        }
    } else {
        // Return default color if no data is available
        return '#34343466'; // Not submitted
    }
}
export function getAverageNutritionColor(patient: User) {
    if (patient.nutritionData.length > 0) {
        // Calculate the total number of checked items
        const totalCheckedCount = patient.nutritionData.reduce((sum, o) => 
            sum + (o.value.filter(v => v.checked).length), 0);
        
        // Count the valid nutrition data points
        const count = patient.nutritionData.length;
        
        // Avoid division by zero if there are no valid data points
        const averageCheckedCount = count > 0 ? totalCheckedCount / count : 0;
        
        // Determine color based on the average number of checked items
        if (averageCheckedCount <= 1) {
            return colorPalette.softRed; // Low number of healthy items
        } else if (averageCheckedCount <= 3) {
            return colorPalette.orange; // Moderate number of healthy items
        } else {
            return colorPalette.seaGreen; // High number of healthy items
        }
    } else {
        // Return default color if no data is available
        return '#34343466'; // Not submitted
    }
}
export function getAverageSmokingScoreColor(patient: User) {
    if (patient.smokingData.length > 0) {
        // Filter out any values below 0 and calculate the total of valid values
        const total = patient.smokingData.reduce((sum, o) => sum + (o.value >= 0 ? Number(o.value) : 0), 0);
        
        // Count the valid smoking data points
        const count = patient.smokingData.filter(o => o.value >= 0).length;
        
        // Avoid division by zero if there are no valid data points
        const average = count > 0 ? total / count : 0;
        
        // Determine color based on the average smoking score
        if (average === 0) {
            return colorPalette.seaGreen; // Non-smoker or no smoking
        } else if (average > 0) {
            return colorPalette.softRed; // Smoker
        } else {
            return '#34343466'; // Not submitted
        }
    } else {
        // Return default color if no data is available
        return '#34343466'; // Not submitted
    }
}
export function getAverageAlcoholScoreColor(patient: User) {
    if (!patient.drinker){
        return '#34343422'
    }
    if (patient.drinkingData.length > 0) {
        // Calculate the total of positive values (ignore values below 0)
        const total = patient.drinkingData.reduce((sum, o) => sum + (Number(o.value) >= 0 ? Number(o.value) : 0), 0);

        // Count the number of valid (non-negative) data points
        const count = patient.drinkingData.filter(o => Number(o.value) >= 0).length;

        // Avoid division by zero if no valid values
        const average = count > 0 ? total / count : 0;

        // Determine color based on the average alcohol value
        if (average <= 2) {
            // SAFE RANGE (GREEN)
            return colorPalette.seaGreen;
        } else if (average > 2 && average <= 4) {
            // MODERATE RANGE (YELLOW or other available color)
            // Change to an existing color from colorPalette if softYellow is unavailable
            return colorPalette.gold;  // Example alternative
        } else {
            // HIGH RANGE (RED)
            return colorPalette.softRed;
        }
    } else {
        // Return a default color if the patient is not a drinker or has no data
        return '#34343466';
    }
}

export function getAverageSleepQualityScore(patient: User) {
    if (patient.sleepingData.length > 0) {
        // Filter out any values below 0 and calculate the total of valid values
        const total = patient.sleepingData.reduce((sum, o) => sum + (o.value >= 0 ? Number(o.value) : 0), 0);
        
        // Count the valid data points (values above or equal to 0)
        const count = patient.sleepingData.filter(o => o.value >= 0).length;
        
        // Avoid division by zero if there are no valid data points
        const average = count > 0 ? total / count : 0;

        return average;
    } else {
        // Return default color if no data is available
        return 1; // Not submitted
    }
}
export function getAverageNutritionScore(patient: User) {
    if (patient.nutritionData.length > 0) {
        // Calculate the total number of checked items
        const totalCheckedCount = patient.nutritionData.reduce((sum, o) => 
            sum + (o.value.filter(v => v.checked).length), 0);
        
        // Count the valid nutrition data points
        const count = patient.nutritionData.length;
        
        // Avoid division by zero if there are no valid data points
        const averageCheckedCount = count > 0 ? totalCheckedCount / count : 0;

        return averageCheckedCount;
    } else {
        // Return default color if no data is available
        return 1; // Not submitted
    }
}
export function getAverageSmokingScore(patient: User) {
    if (patient.smokingData.length > 0) {
        // Filter out any values below 0 and calculate the total of valid values
        const total = patient.smokingData.reduce((sum, o) => sum + (o.value >= 0 ? Number(o.value) : 0), 0);
        
        // Count the valid smoking data points
        const count = patient.smokingData.filter(o => o.value >= 0).length;
        
        // Avoid division by zero if there are no valid data points
        const average = count > 0 ? total / count : 0;

        return average;
        
    } else {
        // Return default color if no data is available
        return 1; // Not submitted
    }
}
export function getAverageAlcoholScore(patient: User) {
    if (!patient.drinker){
        return 1
    }
    if (patient.drinkingData.length > 0) {
        // Calculate the total of positive values (ignore values below 0)
        const total = patient.drinkingData.reduce((sum, o) => sum + (Number(o.value) >= 0 ? Number(o.value) : 0), 0);

        // Count the number of valid (non-negative) data points
        const count = patient.drinkingData.filter(o => Number(o.value) >= 0).length;

        // Avoid division by zero if no valid values
        const average = count > 0 ? total / count : 0;

        return average;
    } else {
        // Return a default color if the patient is not a drinker or has no data
        return 1;
    }
}

export function getDateAfterPlan(startDate: string | Date, weeks: number): string{
    if (!startDate || isNaN(weeks)) return "Invalid Date";

    const date = new Date(startDate);
    if (isNaN(date.getTime())) return "Invalid Date";

    date.setDate(date.getDate() + (weeks * 7)); // Add the plan duration in days

    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
};

function getViolatedConstraints(exercise: Exercise, constraints: (keyof Exercise)[]): string[] {
    return constraints.filter(constraint => exercise[constraint] === true);
}

export function getPlanToConditionCompatibility(plan: VitovaPlan, condition: DBCondition) {
    const cardioConstraints = condition.cardioConstraints ?? {};
    const resistanceConstraints = condition.resistanceConstraints ?? {};
    const flexibilityConstraints = condition.flexibilityConstraints ?? {};
    const exerciseConstraints: (keyof Exercise)[] = (condition.exerciseConstraints as (keyof Exercise)[]) || [];

    let totalActivities = 0;
    let incompliantActivities = 0;
    const errorList: string[] = [];

    for (const week of plan.content) {
        let cardioFrequency = 0;
        let strengthFrequency = 0;

        for (const day of week.days) {
            for (const activity of day.activities) {
                if (activity.steps.length > 0 && activity.steps[0].type === VitovaStepType.REST) {
                    continue;
                }

                totalActivities += 1;
                let activityIsCompliant = true;
                const plannedDuration = Number(activity.plannedDuration ?? 0);

                // INCREMENT WEEKLY STRENGTH & CARDIO FREQUENCY
                if (activity.steps.length > 0 && activity.compulsory) {
                    if (activity.steps[0].type === VitovaStepType.STRENGTH) strengthFrequency += 1;
                    if (activity.steps[0].type === VitovaStepType.CARDIO) cardioFrequency += 1;
                }

                // CARDIO DURATION CHECK
                if (plannedDuration > (cardioConstraints.durationMax ?? Infinity)) {
                    errorList.push(`DURATION Of ${plannedDuration} Exceeds CARDIO Constraint Of ${cardioConstraints.durationMax} - (${activity.title})`);
                    activityIsCompliant = false;
                }
                if (plannedDuration < (cardioConstraints.durationMin ?? 0)) {
                    errorList.push(`DURATION Of ${plannedDuration} Subceeds CARDIO Constraint Of ${cardioConstraints.durationMin} - (${activity.title})`);
                    activityIsCompliant = false;
                }

                for (const step of activity.steps) {
                    // CARDIO CONSTRAINTS
                    if (step.cardio?.rpe) {
                        if (step.cardio.rpe > (cardioConstraints.rpeMax ?? Infinity)) {
                            errorList.push(`RPE Of ${step.cardio.rpe} Exceeds CARDIO Constraint Of ${cardioConstraints.rpeMax} - (${activity.title})`);
                            activityIsCompliant = false;
                        }
                        if (step.cardio.rpe < (cardioConstraints.rpeMin ?? 0)) {
                            errorList.push(`RPE Of ${step.cardio.rpe} Subceeds CARDIO Constraint Of ${cardioConstraints.rpeMin} - (${activity.title})`);
                            activityIsCompliant = false;
                        }
                    }
                    if (step.cardio?.exercise && exerciseConstraints.length > 0) {
                        const violatedConstraints = getViolatedConstraints(step.cardio.exercise, exerciseConstraints);
                        if (violatedConstraints.length > 0) {
                            errorList.push(`EXERCISE Of ${step.cardio.exercise.name} Violates Constraints: ${violatedConstraints.join(", ").toUpperCase()} - (${activity.title})`);
                            activityIsCompliant = false;
                        }
                    }

                    // RESISTANCE CONSTRAINTS
                    if (step.strength?.rpe) {
                        if (step.strength.rpe > (resistanceConstraints.rpeMax ?? Infinity)) {
                            errorList.push(`RPE Of ${step.strength.rpe} Exceeds RESISTANCE Constraint Of ${resistanceConstraints.rpeMax} - (${activity.title})`);
                            activityIsCompliant = false;
                        }
                        if (step.strength.rpe < (resistanceConstraints.rpeMin ?? 0)) {
                            errorList.push(`RPE Of ${step.strength.rpe} Subceeds RESISTANCE Constraint Of ${resistanceConstraints.rpeMin} - (${activity.title})`);
                            activityIsCompliant = false;
                        }
                    }
                    if (step.strength?.sets) {
                        if (step.strength.sets > (resistanceConstraints.setsMax ?? Infinity)) {
                            errorList.push(`SETS Of ${step.strength.sets} Exceeds RESISTANCE Constraint Of ${resistanceConstraints.setsMax} - (${activity.title})`);
                            activityIsCompliant = false;
                        }
                        if (step.strength.sets < (resistanceConstraints.setsMin ?? 0)) {
                            errorList.push(`SETS Of ${step.strength.sets} Subceeds RESISTANCE Constraint Of ${resistanceConstraints.setsMin} - (${activity.title})`);
                            activityIsCompliant = false;
                        }
                    }
                    if (step.strength?.reps) {
                        if (step.strength.reps > (resistanceConstraints.repsMax ?? Infinity)) {
                            errorList.push(`REPS Of ${step.strength.reps} Exceeds RESISTANCE Constraint Of ${resistanceConstraints.repsMax} - (${activity.title})`);
                            activityIsCompliant = false;
                        }
                        if (step.strength.reps < (resistanceConstraints.repsMin ?? 0)) {
                            errorList.push(`REPS Of ${step.strength.reps} Subceeds RESISTANCE Constraint Of ${resistanceConstraints.repsMin} - (${activity.title})`);
                            activityIsCompliant = false;
                        }
                    }
                }

                if (!activityIsCompliant) incompliantActivities += 1;
            }
        }

        // WEEKLY FREQUENCY CHECKS
        if (cardioFrequency > (cardioConstraints.weeklyFreqMax ?? Infinity)) errorList.push(`WEEKLY CARDIO FREQUENCY Of ${cardioFrequency} Exceeds Constraint Of ${cardioConstraints.weeklyFreqMax}`);
        if (cardioFrequency < (cardioConstraints.weeklyFreqMin ?? 0)) errorList.push(`WEEKLY CARDIO FREQUENCY Of ${cardioFrequency} Subceeds Constraint Of ${cardioConstraints.weeklyFreqMin}`);
    }

    const complianceScore = totalActivities > 0 ? Math.round((1 - incompliantActivities / totalActivities) * 100) : 100;
    return { errors: errorList, complianceScore };
}
    

export function getPlanToMedicationCompatibility(plan:VitovaPlan, medication:DBMedication){
    const exerciseConstraints: (keyof Exercise)[] = ((medication.sideEffects ?? [])
    .map(s => s.exerciseConstraints)
    .flat() as (keyof Exercise)[]) || [];

    let totalActivities = 0;
    let incompliantActivities = 0;

    const errorList:Array<string> = [];
    for (const week of plan.content){
        for (const day of week.days){
            for (const activity of day.activities){
                if (activity.steps.length>0&&activity.steps[0].type === VitovaStepType.REST){
                    break;
                }
                totalActivities += 1;
                for (const step of activity.steps){
                    // CARDIO CONSTRAINTS
                    // EXERCISE
                    if (step.cardio?.exercise && exerciseConstraints){
                        const violatedConstraints = getViolatedConstraints(step.cardio.exercise, exerciseConstraints); // Change to correct property

                        if (violatedConstraints.length > 0){
                            errorList.push(`EXERCISE Of ${step.cardio.exercise.name} Violates The Following Exercise Constraints: ${violatedConstraints.join(", ").toUpperCase()} - (${activity.title})`)
                            incompliantActivities +=1;
                            break;
                        }
                    }

                    // RESISTANCE CONSTRAINTS
                    // EXERCISE
                    if (step.strength?.exercise && exerciseConstraints){
                        const violatedConstraints = getViolatedConstraints(step.strength.exercise, exerciseConstraints); // Change to correct property

                        if (violatedConstraints.length > 0){
                            errorList.push(`EXERCISE Of ${step.strength.exercise.name} Violates The Following Exercise Constraints: ${violatedConstraints.join(", ").toUpperCase()} - (${activity.title})`)
                            incompliantActivities +=1;
                            break;
                        }
                    }
                }
            }
        }
    }
    const complianceScore = Math.round((1-incompliantActivities / totalActivities)*100)

    return {errors:errorList, complianceScore:complianceScore};
}

// function isDayInThePast(day: VitovaDay) {
//     // Assuming currentUser and next7Days are accessible in this scope
//     const userPlanStartDate = new Date(currentUser.userPlanStartDate); // Convert start date to Date object
//     const today = new Date(); // Get today's date

//     // Get the number of weeks and convert it to days (1 week = 7 days)
//     const weekNumber = getWeekNumber(day);
//     const daysFromWeeks = (weekNumber * 7) + 1;

//     // Find the index of the day in next7Days
//     const dayIndex = next7Days.indexOf(day);
    
//     if (dayIndex === -1) {
//         throw new Error("The specified day is not in the next7Days array.");
//     }

//     // Calculate the final date by adding the days from weeks and dayIndex to the userPlanStartDate
//     const finalDate = new Date(userPlanStartDate);
//     finalDate.setDate(finalDate.getDate() + daysFromWeeks + dayIndex);

//     // Check if the final date is before today
//     return finalDate < today;
// }

export function getUserToPlanCompliance(plan: VitovaPlan, user: User) {
    let totalActivities = 0;
    let incompleteActivities = 0;
    const userReviews = user.vitovaReviews;
    const today = new Date()

    plan.content.forEach((week, weekIndex) => {
        week.days.forEach((day, dayIndex) => {
            day.activities.forEach(activity => {
                const findActivity = userReviews.find(r=>r.activityReviewData.id === activity.id);
                const finalDate = new Date(user.userPlanStartDate);
                finalDate.setDate(finalDate.getDate() + (((weekIndex) * 7)) + dayIndex);

                if (activity.compulsory && (finalDate < today)) {
                    totalActivities += 1;
                    if ((!findActivity)){
                        incompleteActivities += 1;
                    }
                }
            });
        });
    });



    const complianceScore = totalActivities > 0 ? Math.round((1 - incompleteActivities / totalActivities) * 100) : 100;
    return { score:complianceScore };
}