import { VitovaActivity, VitovaActivityType, VitovaDay, VitovaStepType } from "../models/vitovaPlan";

const getRandom11DigitNumber = () => Math.floor(10000000000 + Math.random() * 90000000000);

export const loadChoiceDayFromTemplate = (totalDuration: number):Array<VitovaActivity> => {
    const day: Array<VitovaActivity> = [
            {
                id: `activity_${getRandom11DigitNumber()}`,
                type: VitovaActivityType.STANDARD,
                title: "Daily Activity",
                description: `Today is a day for some general activity. Make it enjoyable, get moving, and note that it doesn't have to be too intense. We recommend a few minutes warm up and cool down, with a block of activity then done at an low-medium intensity. For example a walk, powerwalk, gentle cycle, playing outdoors with family or friends, or even things such as gardening and cleaning if you prefer. If you need some inspiration then check out the NHS home workout videos below, and see which ones you enjoy!`,
                plannedDuration: totalDuration.toString(),
                plannedRPE: '4',
                steps: [
                    {
                        id: `step_${getRandom11DigitNumber()}`,
                        label: "New Step",
                        type: VitovaStepType.CHOICE,
                        choiceday: {
                            duration: totalDuration,
                            rpe: 3,
                            notes: "Do Something Fun!"
                        }
                    }
                ],
                compulsory: true
            },
            {
                id: `activity_${getRandom11DigitNumber()}`,
                type: VitovaActivityType.STANDARD,
                title: "Basic Daily Activity",
                description: "Struggling to find something to do? Here's a basic little workout for today.",
                plannedDuration: totalDuration.toString(),
                plannedRPE: '3',
                steps: [
                    {
                        id: `step_${getRandom11DigitNumber()}`,
                        label: "New Step",
                        type: VitovaStepType.CARDIO,
                        description: "",
                        cardio: {
                            exerciseId:"67af3340295f12889ce6453d",
                            duration: 7,
                            rpe: 2
                        }
                    },
                    {
                        id: `step_${getRandom11DigitNumber()}`,
                        label: "New Step",
                        type: VitovaStepType.CARDIO,
                        description: "",
                        cardio: {
                            exerciseId:"67af3340295f12889ce6453d",
                            duration: (totalDuration-12),
                            rpe: 4
                        }
                    },
                    {
                        id: `step_${getRandom11DigitNumber()}`,
                        label: "New Step",
                        type: VitovaStepType.CARDIO,
                        description: "",
                        cardio: {
                            exerciseId:"67af3340295f12889ce6453d",
                            duration: 5,
                            rpe: 2
                        }
                    }
                ],
                compulsory: false
            }
        ]

    return day;
};

export const loadRestDayFromTemplate = ():Array<VitovaActivity> => {
    const day: Array<VitovaActivity> = [
            {
                id: `activity_${getRandom11DigitNumber()}`,
                type: VitovaActivityType.STANDARD,
                title: "Rest Day",
                description: `Full rest day is planned. However, doing some light activity is great if possible.`,
                plannedDuration: '0',
                plannedRPE: '0',
                steps: [
                    {
                        id: `step_${getRandom11DigitNumber()}`,
                        label: "New Step",
                        type: VitovaStepType.REST,
                        choiceday: {

                        }
                    }
                ],
                compulsory: true
            }
        ]

    return day;
};

export const loadFlexibilityActivityFromTemplate = ():Array<VitovaActivity> => {
    const day: Array<VitovaActivity> = [
            {
                "id": `activity_${getRandom11DigitNumber()}`,
                "type": VitovaActivityType.STANDARD,
                "title": "Flexibility - or follow along to an NHS pilates / stretching video!",
                "description": "Done after the session below on today's schedule! We're taking things easy, but a key part of fitness, injury prevention, and healthy living is flexibility. Nothing too challenging, and don't take stretches to a point of pain. Hold each stretch for up to 30 seconds, and then relax.",
                "plannedDuration": "15",
                "plannedRPE": "3",
                "steps": [
                    {
                        "id": `step_${getRandom11DigitNumber()}`,
                        "label": "New Step",
                        "type": VitovaStepType.FLEXIBILITY,
                        "description": "",
                        "flexibility": {
                            "rpe": 3,
                            "sets": 3,
                            "holdDuration": 30,
                            "exerciseId": "hxzrrh2fe"
                        }
                    },
                    {
                        "id": `step_${getRandom11DigitNumber()}`,
                        "label": "New Step",
                        "type": VitovaStepType.FLEXIBILITY,
                        "description": "",
                        "flexibility": {
                            "rpe": 3,
                            "sets": 3,
                            "holdDuration": 30,
                            "exerciseId":"mvn6uj5yq"
                        }
                    },
                    {
                        "id": `step_${getRandom11DigitNumber()}`,
                        "label": "New Step",
                        "type": VitovaStepType.FLEXIBILITY,
                        "description": "",
                        "flexibility": {
                            "rpe": 3,
                            "sets": 3,
                            "holdDuration": 30,
                            "exerciseId":"67b62c1806c50108262fd4bb"
                        }
                    },
                    {
                        "id": `step_${getRandom11DigitNumber()}`,
                        "label": "New Step",
                        "type": VitovaStepType.FLEXIBILITY,
                        "description": "",
                        "flexibility": {
                            "rpe": 3,
                            "sets": 3,
                            "holdDuration": 30,
                            "exerciseId":"k90s4of88"
                        }
                    },
                    {
                        "id": `step_${getRandom11DigitNumber()}`,
                        "label": "New Step",
                        "type": VitovaStepType.FLEXIBILITY,
                        "description": "",
                        "flexibility": {
                            "rpe": 3,
                            "sets": 3,
                            "holdDuration": 30,
                            "exerciseId":"67b62c2d06c50108262fd4bf"
                        }
                    }
                ],
                "compulsory": true
            }
        ]

    return day;
};

export const loadRockportTestDayFromTemplate = ():Array<VitovaActivity> => {
    const day: Array<VitovaActivity> = [
        {
            "id": `activity_${getRandom11DigitNumber()}`,
            "type": VitovaActivityType.TEST,
            "title": "Rockport1 Mile Walking Test",
            "description": "Warm up: 5-10 minutes walking with some moving (dynamic) stretching if wanted.\n\nTest effort: 1 mile timed WALK test, take as many breaks as needed but you must WALK the mile as fast as possible. Do it safely and we'll make some targets off of this.\n\nIf you also have access to a heart rate monitor to review then this can be really useful.\n\nCool down: 5 minutes - easy stretching and gentle walking post 1 mile. Well done on a qualiy test session.\n\nIf swapping for a bike test!\nComplete a similar warm up and cool down, followed by a 10 minute test 'effort'. Keep this effort controlled - making sure to note either how far you get, the power (watts) put out, and your heart rate.",
            "plannedDuration": "30",
            "plannedRPE": "6",
            "steps": [
              {
                "id": `step_${getRandom11DigitNumber()}`,
                "label": "New Step",
                "type": VitovaStepType.CARDIO,
                "cardio": {
                  "exerciseId":"67af3340295f12889ce6453d",
                  "duration": 10,
                  "intensityFactor": "0.55",
                  "rpe": 2
                }
              },
              {
                "id": `step_${getRandom11DigitNumber()}`,
                "label": "New Step",
                "type": VitovaStepType.CARDIO,
                "cardio": {
                  "exerciseId":"67af3340295f12889ce6453d",
                  "distance": 1.6,
                  "intensityFactor": "1",
                  "rpe": 6
                },
                "isTestEffort": true
              },
              {
                "id": `step_${getRandom11DigitNumber()}`,
                "label": "New Step",
                "type": VitovaStepType.CARDIO,
                "cardio": {
                  "exerciseId":"67af3340295f12889ce6453d",
                  "duration": 5,
                  "intensityFactor": "0.4",
                  "rpe": 2
                },
                "isTestEffort": false
              }
            ],
            "compulsory": true
          },
          {
            "id": "activity_1738764395711",
            "type": VitovaActivityType.TEST,
            "title": "Rockport1 Mile Walking Test (Cycle Alternative)",
            "description": "Warm up: 5-10 minutes walking with some moving (dynamic) stretching if wanted.\n\nTest effort: 1 mile timed WALK test, take as many breaks as needed but you must WALK the mile as fast as possible. Do it safely and we'll make some targets off of this.\n\nIf you also have access to a heart rate monitor to review then this can be really useful.\n\nCool down: 5 minutes - easy stretching and gentle walking post 1 mile. Well done on a qualiy test session.\n\nIf swapping for a bike test!\nComplete a similar warm up and cool down, followed by a 10 minute test 'effort'. Keep this effort controlled - making sure to note either how far you get, the power (watts) put out, and your heart rate.",
            "plannedDuration": "30",
            "plannedRPE": "6",
            "steps": [
              {
                "id": `step_${getRandom11DigitNumber()}`,
                "label": "New Step",
                "type": VitovaStepType.CARDIO,
                "cardio": {
                  "exerciseId":"z5qwada0u",
                  "duration": 10,
                  "intensityFactor": "0.55",
                  "rpe": 2
                }
              },
              {
                "id": `step_${getRandom11DigitNumber()}`,
                "label": "New Step",
                "type": VitovaStepType.CARDIO,
                "cardio": {
                  "exerciseId":"z5qwada0u",
                  "intensityFactor": "1",
                  "distance": 1.6,
                  "rpe": 6
                },
                "isTestEffort": true
              },
              {
                "id": `step_${getRandom11DigitNumber()}`,
                "label": "New Step",
                "type": VitovaStepType.CARDIO,
                "cardio": {
                  "exerciseId":"z5qwada0u",
                  "intensityFactor": "0.4",
                  "duration": 5,
                  "rpe": 2
                }
              }
            ],
            "compulsory": false
          }
        ]

    return day;
};
export const loadFlexibilityTestDayFromTemplate = ():Array<VitovaActivity> => {
    const day: Array<VitovaActivity> = [
              {
                "id": `activity_${getRandom11DigitNumber()}`,
                "type": VitovaActivityType.TEST,
                "title": "Flexibility Testing at the End!",
                "description": "We're taking things easy, but a key part of fitness, injury prevention, and healthy living is flexibility. Nothing too challenging, and don't take stretches to a point of pain. Hold each stretch for up to 30 seconds, and then relax.",
                "plannedDuration": "30",
                "plannedRPE": "5",
                "steps": [
                  {
                    "id": `step_${getRandom11DigitNumber()}`,
                    "label": "New Step",
                    "type": VitovaStepType.STRENGTH,
                    "strength": {
                      "sets": 3,
                      "reps": 12,
                      "rpe": 3,
                      "exerciseId": "67af5363ed2c2d2ccf8b2aed"
                    }
                  },
                  {
                    "id": `step_${getRandom11DigitNumber()}`,
                    "label": "New Step",
                    "type": VitovaStepType.FLEXIBILITY,
                    "flexibility": {
                      "sets": 3,
                      "holdDuration": 30,
                      "rpe": 2,
                      "exerciseId": "hxzrrh2fe"
                    }
                  },
                  {
                    "id": `step_${getRandom11DigitNumber()}`,
                    "label": "New Step",
                    "type": VitovaStepType.FLEXIBILITY,
                    "flexibility": {
                      "sets": 3,
                      "holdDuration": 30,
                      "rpe": 2,
                      "exerciseId": "mvn6uj5yq"
                    }
                  },
                  {
                    "id": `step_${getRandom11DigitNumber()}`,
                    "label": "New Step",
                    "type": VitovaStepType.FLEXIBILITY,
                    "flexibility": {
                      "sets": 3,
                      "holdDuration": 30,
                      "rpe": 2,
                      "exerciseId": "evz1fw9wd"
                    }
                  },
                  {
                    "id": `step_${getRandom11DigitNumber()}`,
                    "label": "New Step",
                    "type": VitovaStepType.FLEXIBILITY,
                    "flexibility": {
                      "sets": 3,
                      "holdDuration": 30,
                      "rpe": 2,
                      "exerciseId": "k90s4of88"
                    }
                  },
                  {
                    "id": `step_${getRandom11DigitNumber()}`,
                    "label": "New Step",
                    "type": VitovaStepType.FLEXIBILITY,
                    "flexibility": {
                      "sets": 1,
                      "holdDuration": 30,
                      "rpe": 2,
                      "exerciseId": "neeur6rbn"
                    },
                    "isTestEffort": true
                  }
                ],
                "compulsory": true
            }
            ]

    return day;
};

  
  