import { useState } from "react";
import { User } from "../models/user";
import { VitovaActivity, VitovaActivityStep, VitovaDay, VitovaStepType, VitovaWeek } from "../models/vitovaPlan";
import { Exercise } from "../models/exercise";

export function getStressScore(step: VitovaActivityStep, athlete:User) {
    switch (step.type) {
        case VitovaStepType.CARDIO:
            const intensityFactor = step.cardio?.intensityFactor ? step.cardio.intensityFactor : step.cardio?.rpe ? rpeToIntensityFactor(Number(step.cardio.rpe)) : 0;
            // Duration * intensityFactor
            if (step.cardio?.duration){
                return step.cardio.duration * Number(intensityFactor)
            }
            // Distance To Duration * intensityFactor
            if (step.cardio?.distance && step.cardio?.rpe){
                return getDurationFromDistanceAndUserZones(athlete, Number(step.cardio.distance), Number(step.cardio.rpe), step.cardio.exercise ? step.cardio.exercise.name:'Unknown Exercise') * Number(intensityFactor)
            }
            return 0;
        case VitovaStepType.STRENGTH:
            // Using strength properties and intensityFactor to calculate equivalent intensity
            if (step.strength?.sets && step.strength?.reps && step.strength?.rpe) {
                const sets = Number(step.strength.sets);
                const reps = Number(step.strength.reps);
                const rpe = Number(step.strength.rpe); // Rating of perceived exertion
                const baseIntensity = Number(rpeToIntensityFactor(rpe)); // Assuming intensityFactor is a number
        
                // Calculate intensity based on sets, rpe, and intensityFactor
                return sets * 3 * 0.75 * baseIntensity;
            }
            if (step.strength?.sets && step.strength?.repsInSeconds && step.strength?.rpe) {
                const repsInSeconds = Number(step.strength.repsInSeconds);
                const rpe = Number(step.strength.rpe); // Rating of perceived exertion
                const baseIntensity = Number(rpeToIntensityFactor(rpe)); // Assuming intensityFactor is a number
        
                // Calculate intensity based on sets, rpe, and intensityFactor
                return ((Number(step.strength.sets) * repsInSeconds) / 60) * baseIntensity;
            }
            return 0;
        case VitovaStepType.FLEXIBILITY:
            // Using strength properties and intensityFactor to calculate equivalent intensity
            if (step.flexibility?.sets) {
                const sets = Number(step.flexibility.sets);
        
                // Calculate intensity based on sets, rpe, and intensityFactor
                return sets * 1;
            }
            return 0;
        case VitovaStepType.CHOICE:
            const choiceIntensityFactor = step.choiceday?.rpe ? rpeToIntensityFactor(Number(step.choiceday.rpe)) : 0;
            console.log(choiceIntensityFactor)
            if (step.choiceday?.duration){
                console.log(step)
                return Number(step.choiceday.duration) * Number(choiceIntensityFactor)
            }
            return 0;
        case VitovaStepType.REST:
            return 1;
        default:
            return 0;
    }
}

export function getActivityStressScore(activity: VitovaActivity, athlete:User) {
    let sum = 0;
    for (const step of activity.steps){
        sum += getStressScore(step, athlete)
    }

    return sum;
}

export function getDayStressScore(day: VitovaDay, athlete:User, isReview:boolean) {
    let sum = 0;

    // if (day.activities.length > 0 && day.activities[0].steps[0].type===VitovaStepType.CHOICE){
    //     for (const activity of day.activities.filter(a=>a.compulsory)){
    //         sum += getActivityStressScore(activity, athlete)
    //     }
    //     return Math.round(sum);
    // }else{
        for (const activity of day.activities){
            if(activity.compulsory === true || isReview){
                sum += getActivityStressScore(activity, athlete)
            }
        }
        return Math.round(sum);
    // }    
}

export function getWeekStressScore(week: VitovaWeek, athlete:User, isReview:boolean) {
    let sum = 0;
    for (const day of week.days){
        sum += getDayStressScore(day, athlete, isReview)
    }
    return Math.round(sum);
}

export function rpeToIntensityFactor(rpe:number){
    switch (rpe){
        case 2:
            return 0.3;
        case 3:
            return 0.4;
        case 4:
            return 0.55;
        case 5:
            return 0.65;
        case 6:
            return 0.8;
        case 7:
            return 0.9;
        case 8:
            return 1;
        case 9:
            return 1.1;
        case 10:
            return 1.3;
        default:
            return 0.3
    }
}

export function getDurationFromDistanceAndUserZones(user: User, distance: number, rpe: number, exercise: string): number {
    switch (exercise) {
        case 'Walking': {
            let thresholdPace = getThresholdPace(user, 'Walking') || 1.5; // Threshold pace in m/s
            if (thresholdPace <= 0){
                thresholdPace = 1.5;
            }

            // Convert threshold pace (m/s) to minutes per km
            const pacePerKM = 1000 / (thresholdPace * 60); // min/km

            // Adjust pace based on RPE
            const adjustedPaceFactor = 1 + (5 - rpe) * 0.1; // RPE 5 → base, RPE 3 → slower
            const adjustedPacePerKM = pacePerKM * adjustedPaceFactor; // min/km

            // Convert to seconds per km
            const adjustedPacePerSecondPerKM = adjustedPacePerKM * 60; // sec/km

            // Calculate estimated total duration in seconds
            const estimatedTimeInSeconds = (adjustedPacePerSecondPerKM * distance); // Convert meters to km

            return estimatedTimeInSeconds / 60; // Return in minutes
        }
        case 'Running': {
            let thresholdPace = getThresholdPace(user, 'Running') || 2; // Threshold pace in m/s
            if (thresholdPace <= 0){
                thresholdPace = 2;
            }

            // Convert threshold pace (m/s) to minutes per km
            const pacePerKM = 1000 / (thresholdPace * 60); // min/km

            // Adjust pace based on RPE
            const adjustedPaceFactor = 1 + (5 - rpe) * 0.1; // RPE 5 → base, RPE 3 → slower
            const adjustedPacePerKM = pacePerKM * adjustedPaceFactor; // min/km

            // Convert to seconds per km
            const adjustedPacePerSecondPerKM = adjustedPacePerKM * 60; // sec/km

            // Calculate estimated total duration in seconds
            const estimatedTimeInSeconds = (adjustedPacePerSecondPerKM * distance); // Convert meters to km

            return estimatedTimeInSeconds / 60; // Return in minutes
        }
        case 'Trail Running': {
            let thresholdPace = getThresholdPace(user, 'Trail Running') || 2; // Threshold pace in m/s
            if (thresholdPace <= 0){
                thresholdPace = 2;
            }

            // Convert threshold pace (m/s) to minutes per km
            const pacePerKM = 1000 / (thresholdPace * 60); // min/km

            // Adjust pace based on RPE
            const adjustedPaceFactor = 1 + (5 - rpe) * 0.1; // RPE 5 → base, RPE 3 → slower
            const adjustedPacePerKM = pacePerKM * adjustedPaceFactor; // min/km

            // Convert to seconds per km
            const adjustedPacePerSecondPerKM = adjustedPacePerKM * 60; // sec/km

            // Calculate estimated total duration in seconds
            const estimatedTimeInSeconds = (adjustedPacePerSecondPerKM * distance); // Convert meters to km

            return estimatedTimeInSeconds / 60; // Return in minutes
        }

        case 'Cycling': {
            const thresholdSpeed = getThresholdPace(user, 'Cycling') || 3; // Threshold pace in m/s
            // Convert threshold pace (m/s) to speed in km/h
            const speedKmh = thresholdSpeed * 3.6; // km/h

            // Adjust speed based on RPE (higher RPE → faster speed)
            const adjustedSpeedFactor = 1 + (rpe - 5) * 0.05; // Example: RPE 5 → base speed, RPE 7 → faster
            const adjustedSpeedKmh = speedKmh * adjustedSpeedFactor; // km/h

            // Calculate estimated time in hours
            const estimatedTimeInHours = distance / adjustedSpeedKmh; // Convert meters to km
            return estimatedTimeInHours * 60; // Convert to minutes
        }
    }
    return 0;
}


export function getThresholdPace(user: User, exercise: string) {
    const bestEffort = user.bestEfforts.find(e => e?.step?.cardio?.exercise?.name === exercise);

    if (bestEffort && bestEffort.step) {
        return getPaceFromStep(bestEffort.step);
    }
    
    return 0;
}

export function getPaceFromStep(step:VitovaActivityStep){
    const distance = Number(step.cardio?.distance) * 1000;
    const duration = Number(step.cardio?.duration) * 60;

    // Pace in m/s
    if (distance && duration){
        return distance/duration;
    }
    return 0;
}

export function metersPerSecondToMinPerKmString(ms: number): string {
    if (ms <= 0) return "No Data"; // Handle non-positive speeds

    const minPerKm = 1000 / (ms * 60); // Convert m/s to min/km
    const minutes = Math.floor(minPerKm);
    const seconds = Math.round((minPerKm - minutes) * 60);

    return `${minutes}:${seconds.toString().padStart(2, "0")} min/km`;
}

export function speedToCyclingPower(user: User, ms: number): string {
    if (ms <= 0) return "No Data"; // Handle non-positive speeds

    const weight = user.weightData.length > 0 ? Number(user.weightData[user.weightData.length-1].value) : Number(user.info.weight); // User's weight in kg

    // Constants for cycling power model
    const rollingResistance = 0.005;  // Rolling resistance coefficient
    const airDensity = 1.225;        // Air density (kg/m³)
    const frontalArea = 0.5;         // Frontal area of the cyclist (m²)
    const dragCoefficient = 0.9;     // Drag coefficient (depends on posture)

    // Calculate the rolling resistance force (F_rolling)
    const rollingResistanceForce = rollingResistance * weight * 9.81; // Force in N (Newtons)

    // Calculate the air resistance force (F_air)
    const airResistanceForce = 0.5 * airDensity * frontalArea * dragCoefficient * Math.pow(ms, 2); // Force in N

    // Total force required to overcome resistance
    const totalResistanceForce = rollingResistanceForce + airResistanceForce;

    // Power in watts (P = Force * Speed)
    const powerWatts = totalResistanceForce * ms; // Watts

    return `${powerWatts.toFixed(0)} Watts`;
}


export interface VitovaZone {
    zoneName: string; // Zone 1, Zone 2, etc.
    paceRange: Array<number>; // Pace range in meters per second
    heartRateRange: Array<number>; // Heart rate range in BPM
}

export function getZonesFromThreshold(exercise: string, thresholdInMs: number): Array<VitovaZone> {
    let zones: Array<VitovaZone> = [];

    switch (exercise) {
        case 'Walking':
            zones = [
                { zoneName: "Zone 1", paceRange: [0.5 * thresholdInMs, 0.6 * thresholdInMs], heartRateRange: [90, 100] },
                { zoneName: "Zone 2", paceRange: [0.6 * thresholdInMs, 0.7 * thresholdInMs], heartRateRange: [100, 110] },
                { zoneName: "Zone 3", paceRange: [0.7 * thresholdInMs, 0.8 * thresholdInMs], heartRateRange: [110, 120] },
                { zoneName: "Zone 4", paceRange: [0.8 * thresholdInMs, 0.9 * thresholdInMs], heartRateRange: [120, 130] },
                { zoneName: "Zone 5", paceRange: [0.9 * thresholdInMs, thresholdInMs], heartRateRange: [130, 140] }
            ];
            break;

        case 'Running':
            zones = [
                { zoneName: "Zone 1", paceRange: [0.5 * thresholdInMs, 0.6 * thresholdInMs], heartRateRange: [100, 120] },
                { zoneName: "Zone 2", paceRange: [0.6 * thresholdInMs, 0.7 * thresholdInMs], heartRateRange: [120, 140] },
                { zoneName: "Zone 3", paceRange: [0.7 * thresholdInMs, 0.8 * thresholdInMs], heartRateRange: [140, 160] },
                { zoneName: "Zone 4", paceRange: [0.8 * thresholdInMs, 0.9 * thresholdInMs], heartRateRange: [160, 175] },
                { zoneName: "Zone 5", paceRange: [0.9 * thresholdInMs, thresholdInMs], heartRateRange: [175, 190] }
            ];
            break;
        case 'Trail Running':
                zones = [
                    { zoneName: "Zone 1", paceRange: [0.5 * thresholdInMs, 0.6 * thresholdInMs], heartRateRange: [100, 120] },
                    { zoneName: "Zone 2", paceRange: [0.6 * thresholdInMs, 0.7 * thresholdInMs], heartRateRange: [120, 140] },
                    { zoneName: "Zone 3", paceRange: [0.7 * thresholdInMs, 0.8 * thresholdInMs], heartRateRange: [140, 160] },
                    { zoneName: "Zone 4", paceRange: [0.8 * thresholdInMs, 0.9 * thresholdInMs], heartRateRange: [160, 175] },
                    { zoneName: "Zone 5", paceRange: [0.9 * thresholdInMs, thresholdInMs], heartRateRange: [175, 190] }
                ];
                break;
        case 'Cycling':
            zones = [
                { zoneName: "Zone 1", paceRange: [0.5 * thresholdInMs, 0.6 * thresholdInMs], heartRateRange: [100, 115] },
                { zoneName: "Zone 2", paceRange: [0.6 * thresholdInMs, 0.7 * thresholdInMs], heartRateRange: [115, 130] },
                { zoneName: "Zone 3", paceRange: [0.7 * thresholdInMs, 0.8 * thresholdInMs], heartRateRange: [130, 145] },
                { zoneName: "Zone 4", paceRange: [0.8 * thresholdInMs, 0.9 * thresholdInMs], heartRateRange: [145, 160] },
                { zoneName: "Zone 5", paceRange: [0.9 * thresholdInMs, thresholdInMs], heartRateRange: [160, 180] }
            ];
            break;

        case 'Swimming':
            zones = [
                { zoneName: "Zone 1", paceRange: [0.7 * thresholdInMs, 0.8 * thresholdInMs], heartRateRange: [100, 115] },
                { zoneName: "Zone 2", paceRange: [0.8 * thresholdInMs, 0.9 * thresholdInMs], heartRateRange: [115, 130] },
                { zoneName: "Zone 3", paceRange: [0.9 * thresholdInMs, 1.0 * thresholdInMs], heartRateRange: [130, 145] },
                { zoneName: "Zone 4", paceRange: [1.0 * thresholdInMs, 1.1 * thresholdInMs], heartRateRange: [145, 160] },
                { zoneName: "Zone 5", paceRange: [1.1 * thresholdInMs, 1.2 * thresholdInMs], heartRateRange: [160, 175] }
            ];
            break;

        default:
            throw new Error("Unsupported exercise type");
    }

    return zones;
}

export function getPaceFromRpe(user:User, exercise:string, rpe:number) {
    const thresholdPace = getThresholdPace(user, exercise); // Get threshold pace in meters per second
    const zones = getZonesFromThreshold(exercise, thresholdPace); // Get exercise zones based on threshold pace

    // Normalize RPE to be within the range of 1 to 10 (adjust if necessary)
    const normalizedRpe = Math.min(Math.max(rpe, 1), 10); // Ensure RPE is between 1 and 10

    // Map RPE to a specific zone
    let targetZone;
    if (normalizedRpe <= 2) {
        targetZone = zones[0]; // Zone 1: Very light intensity
    } else if (normalizedRpe <= 4) {
        targetZone = zones[1]; // Zone 2: Light intensity
    } else if (normalizedRpe <= 6) {
        targetZone = zones[2]; // Zone 3: Moderate intensity (around threshold)
    } else if (normalizedRpe <= 8) {
        targetZone = zones[3]; // Zone 4: High intensity
    } else {
        targetZone = zones[4]; // Zone 5: Maximum intensity
    }

    // Extract the pace range from the selected zone
    const paceRange = targetZone.paceRange; // paceRange is in meters per second

    // Estimate the pace by interpolating within the zone based on the RPE
    const paceAdjustment = ((normalizedRpe - 1) / 9); // Scale RPE between 0 and 1
    const estimatedPace = paceRange[0] + paceAdjustment * (paceRange[1] - paceRange[0]);

    return estimatedPace;
}
