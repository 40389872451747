import { Test } from "../models/user";

export const phq9:Test = {
    "id": 1,
    "description":"PHQ-9 Depression Screening measures ...",
    "testName": "PHQ-9 Depression Screening",
    "questions": [
      {
        "id": 1,
        "question": "Little interest or pleasure in doing things?",
      },
      {
        "id": 2,
        "question": "Feeling down, depressed, or hopeless?",
      },
      {
        "id": 3,
        "question": "Trouble falling or staying asleep, or sleeping too much?",
      },
      {
        "id": 4,
        "question": "Feeling tired or having little energy?",
      },
      {
        "id": 5,
        "question": "Poor appetite or overeating?",
      },
      {
        "id": 6,
        "question": "Feeling bad about yourself - or that you are a failure or have let yourself or your family down?",
      },
      {
        "id": 7,
        "question": "Trouble concentrating on things, such as reading the newspaper or watching television?",
      },
      {
        "id": 8,
        "question": "Moving or speaking so slowly that other people could have noticed? Or the opposite - being so fidgety or restless that you have been moving around a lot more than usual?",
      },
      {
        "id": 9,
        "question": "Thoughts that you would be better off dead, or of hurting yourself in some way?",
      }
    ],
  }

export const gad7: Test = {
    id: 1,
    testName: "GAD-7 Anxiety Test",
    description: "Over the last two weeks, how often have you been bothered by any of the following problems?",
    questions: [
        {
            question: "Feeling nervous, anxious or on edge?",
            id: 1
        },
        {
            question: "Not being able to stop or control worrying?",
            id: 2
        },
        {
            question: "Worrying too much about different things?",
            id: 3
        },
        {
            question: "Trouble relaxing?",
            id: 4
        },
        {
            question: "Being so restless that it is hard to sit still?",
            id: 5
        },
        {
            question: "Becoming easily annoyed or irritable?",
            id: 6
        },
        {
            question: "Feeling afraid as if something awful might happen?",
            id: 7
        }
    ]
}
  