import { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { resetPassword, ResetPasswordBody } from "../network/api";
import { Button, Form } from "react-bootstrap";
import colorPalette from "../utils/colors";

const ResetPassword = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const email = searchParams.get("email");
  const navigate = useNavigate();

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [passwordReset, sentPasswordReset] = useState(false);

  useEffect(() => {
    if (!token || !email) {
      setMessage("Invalid or missing token.");
    }
  }, [token, email]);

  const handleSubmit = async (e:any) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setMessage("Passwords do not match.");
      return;
    }

    setLoading(true);
    if (token && email && newPassword){
        try {
            const body:ResetPasswordBody = {
                email:email,
                token:token,
                newPassword:newPassword
            }
            const response = await resetPassword(body).then(()=>{
                sentPasswordReset(true);
            });
        } catch (error) {
            console.log(error)
        setMessage("An error occurred. Please try again.");
        } finally {
        setLoading(false);
        }
    }
  };

  return (
    <div className="modal-overlay">
    <div className="modal-content fade-in" style={{ borderRadius: '5px', backgroundColor: '#ffffff', maxHeight:'80vh', overflow:'scroll'}}>
        <Form className="signup-form" style={{ borderRadius: '0px', textAlign:'center'}}>
            <h3 style={{ fontSize: '2.5vw', fontWeight: 'bold', color: colorPalette.steelBlue, fontStyle: 'italic' }}>RESET PASSWORD</h3>
            {!passwordReset ? 
            <>
            {message && <p style={{color:colorPalette.softRed}} className="text-center text-red-500">{message}</p>}
            <Form.Group controlId="formBasicEmail">
                <Form.Label style={{ color: '#454545' }}>New Password</Form.Label>
                <Form.Control 
                    type="password" 
                    placeholder="New Password" 
                    onChange={(e)=>setNewPassword(e.currentTarget.value)}
                    value={newPassword}
                    className="user-form-input"
                />
            </Form.Group>

            <Form.Group controlId="formBasicEmail">
                <Form.Label style={{ color: '#454545' }}>Confirm New Password</Form.Label>
                <Form.Control 
                    type="password" 
                    placeholder="Confirm Password" 
                    onChange={(e)=>setConfirmPassword(e.currentTarget.value)}
                    value={confirmPassword}
                    className="user-form-input"
                />
            </Form.Group>
            <div className="button-group">
                <Button variant="primary" onClick={(e)=>handleSubmit(e)} style={{ borderRadius: '0px', width: '100%', backgroundColor: colorPalette.steelBlue }} disabled={loading} className="submit-button">Reset Password</Button>
            </div>
            </>
            :
            <>
                        <p style={{ fontSize: '1.25vw', color: colorPalette.steelBlue, fontStyle: 'italic' }}>{`Password reset successfully!`}</p>
                        <label className="label-link" onClick={() => {navigate('/home')}} style={{ color: '#454545' }}>Return To Login</label>
            </>

            }
        </Form>
    </div>
</div>
  );
};

export default ResetPassword;
