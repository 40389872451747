import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Paper,
  FormControlLabel,
  Checkbox,
  Button,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { DBCondition } from "../models/condition";
import { Exercise } from "../models/exercise";
import { VitovaStepType } from "../models/vitovaPlan";

interface Props {
  open: boolean;
  onClose: () => void;
  exercises: Exercise[];
  conditions: DBCondition[];
  stepId:string,
  onExerciseSelected: (exercise: Exercise, stepId:string) => void;
}

const ExerciseSelectionDialog: React.FC<Props> = ({
  open,
  onClose,
  exercises,
  conditions,
  stepId,
  onExerciseSelected,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedConditions, setSelectedConditions] = useState<string[]>([]);

  const toggleCondition = (conditionId: string) => {
    setSelectedConditions((prev) =>
      prev.includes(conditionId)
        ? prev.filter((id) => id !== conditionId)
        : [...prev, conditionId]
    );
  };

  const filteredExercises = exercises.filter((exercise) =>
    exercise.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Dialog open={open} fullWidth maxWidth="xl" onClose={onClose}>
      <DialogTitle>{`FIND AN EXERCISE`}</DialogTitle>
      <DialogContent>
        <TextField
          label="Search Exercise"
          fullWidth
          variant="outlined"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ marginBottom: "10px" }}
        />

        <Paper sx={{ p: 2, mb: 2, display: "flex", flexWrap: "wrap" }}>
          {conditions.map((condition) => (
            <FormControlLabel
              key={condition._id}
              control={
                <Checkbox
                  checked={selectedConditions.includes(condition._id)}
                  onChange={() => toggleCondition(condition._id)}
                />
              }
              label={condition.name}
            />
          ))}
        </Paper>
        <ul className="database-page-exercise-list">
            {(filteredExercises.length > 0 
                ? filteredExercises 
                : exercises
            ).map((exercise, index) => {
                const exerciseConstraints = selectedConditions
                .map((id) => conditions?.find((con) => con._id === id)?.exerciseConstraints || [])
                .flat();
                // Find all non-compliant constraints for this exercise
                const nonCompliantConstraints = exerciseConstraints.filter(
                    (constraint) => (constraint as keyof Exercise) in exercise && exercise[constraint as keyof Exercise] === true
                );

                return (
                    <div 
                        key={exercise.id} 
                        className="exercise-card" 
                        onClick={()=>onExerciseSelected(exercise, stepId)}
                        style={{ position: "relative" }} // Ensure absolute positioning works
                    >
                        {/* Not Recommended Badge (Only if there are non-compliant constraints) */}
                        {nonCompliantConstraints.length > 0 && (
                            <div 
                                style={{
                                    position: "absolute",
                                    top: "10px",
                                    right: "10px",
                                    backgroundColor: "rgba(255, 69, 58, 0.9)", // Red warning color
                                    color: "white",
                                    padding: "10px",
                                    borderRadius: "5px",
                                    fontWeight: "bold",
                                    fontSize: "0.9em",
                                    zIndex: 10,
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                                    maxWidth: "150px"
                                }}
                            >
                                <div style={{ display: "flex", alignItems: "center", gap: "5px", marginBottom: "5px" }}>
                                    <FontAwesomeIcon icon={faExclamationTriangle} />
                                    <span>Not Recommended</span>
                                </div>
                                {/* List non-compliant constraints */}
                                {nonCompliantConstraints.map((constraint, idx) => (
                                    <div key={idx} style={{ fontSize: "0.85em", marginLeft: "15px", textAlign: "left" }}>
                                        ({constraint})
                                    </div>
                                ))}
                            </div>
                        )}

                        {/* Display the exercise image if available */}
                        {exercise.images && exercise.images.length > 0 ? (
                            <div className="exercise-card-images" style={{ display: "flex", gap: "10px", width: "100%", flexDirection: "column" }}>
                                {exercise.images.map((image: string, index: number) => (
                                    <div 
                                        key={index} 
                                        className="exercise-card-image" 
                                        style={{ flex: "0 0 auto", height: `calc(100% / ${exercise.images.length})`, padding: "5px" }}
                                    >
                                        <img
                                            src={`https://raw.githubusercontent.com/yuhonas/free-exercise-db/main/exercises/${image}`}
                                            style={{ objectFit: "contain", width: "100%", height: "100%" }}
                                            alt={`exercise-image-${index}`}
                                        />
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div className="exercise-card-image">
                                <img 
                                    src="https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg?20200913095930" 
                                    alt={exercise.name} 
                                />
                            </div>
                        )}

                        {/* Exercise Info */}
                        <div className="exercise-card-content">
                            <h3 style={{ fontWeight: "bold", fontStyle: "italic", fontSize: "1.5em" }} className="exercise-card-title">
                                {exercise.name.toUpperCase()}
                            </h3>
                            <p style={{ fontWeight: "bold", fontStyle: "italic" }}>
                                <strong>Type:</strong> {exercise.stepType as VitovaStepType}
                            </p>
                        </div>
                    </div>
                );
            })}
        </ul>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ExerciseSelectionDialog;
