import * as Api from "../network/api";
import { CheckedCondition, CheckedMedication, User } from '../models/user';
import '../styles/HomePage.css';
import '../styles/SessionReview.css'
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, Slider, TextField } from "@mui/material";
import { faArrowLeft, faCircle, faCircleCheck, faHeart, faHeartPulse, faL, faNotdef, faPlus, faRemove, faRotateRight, faRunning, faSwimmer } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";
import colorPalette from "../utils/colors";
import { Document, Page, pdfjs } from "react-pdf";
import pdfFile from '../localdata/vitovatandcs.pdf'
import privacyPolicyPdf from '../localdata/vitovaprivacypolicy.pdf'

import { OnDocumentLoadSuccess } from "react-pdf/dist/cjs/shared/types";
import Spinner from "./Spinner";
import { DBMedication } from "../models/medication";
import { DBCondition } from "../models/condition";
import { VitovaDay, VitovaPlan } from "../models/vitovaPlan";
import GeneralChartComponent from "./GeneralChartComponent";
import { getDayStressScore } from "../utils/StressLoadFunctions";
import { CheckBox } from "@mui/icons-material";
import { gad7, phq9 } from "../localdata/tests";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const UserTypeMap = ['ffs','haw', 'gpr']

interface HomePageLoggedInProps {
    user: User,
    onLogoutSuccessful: () => void,
    onInfoSet: (user: User) => void,
}

const enableEmailVerification = true;

const HomePageLoggedInView = ({user, onLogoutSuccessful, onInfoSet}:  HomePageLoggedInProps) => {
    const [questionStage, setQuestionStage] = useState<number>(0);

    const [currentUser, setCurrentUser] = useState<User>(user);

    const [surgeryDate, setSurgeryDate] = useState('')
    const [dob, setDOB] = useState('');
    const [height, setHeight] = useState(180);
    const [weight, setWeight] = useState(80);
    const [waist, setWaist] = useState(34);
    const [bp, setBp] = useState("120/80"); // Blood Pressure (text for "120/80" format)
    const [hba1c, setHba1c] = useState(37); // HbA1c Level
    const [hdl, setHdl] = useState(1.5); // HDL Cholesterol
    const [ldl, setLdl] = useState(2.5); // LDL Cholesterol
    const [cholesterol, setCholesterol] = useState(4.5); 
    const [smoking, setSmoking] = useState(2);
    const [drinking, setDrinking] = useState(2);
    const [exerciseHistory, setExerciseHistory] = useState(2);
    const [expectedSurgery, setExpectedSurgery] = useState('');
    const [gender, setGender] = useState('m');
    const [expectedSurgeryError, setExpectedSurgeryError] = useState(false);
    // const [conditions, setConditions] = useState<Array<DBCondition>>([]);
    // const [medications, setMedications] = useState<Array<DBMedication>>([]);
    const [tcChecked, setTcChecked] = useState(false);
    const [ppChecked, setPpChecked] = useState(false)
    const [checkedConditions, setCheckedConditions] = useState<Array<CheckedCondition>>(
        []
    );  
    const [phq9Result, setPhq9Result] = useState(Array(9).fill(0));
    const [gad7Result, setCad9Result] = useState(Array(7).fill(0));

    const handleSliderChange = (index: number, value: number) => {
        const updatedResults = [...phq9Result];
        updatedResults[index] = value;
        setPhq9Result(updatedResults);
    };

    const handleCad9SliderChange = (index: number, value: number) => {
        const updatedResults = [...gad7Result];
        updatedResults[index] = value;
        setCad9Result(updatedResults);
    };
    const [averageWeeklyUnits, setAverageWeeklyUnits] = useState(0);
    const [customCondition, setCustomCondition] = useState<string>('');
    const [customMedication, setCustomMedication] = useState<string>('');

    const [checkedMedications, setCheckedMedications] = useState<Array<CheckedMedication>>(
        []
    );

    const [open, setOpen] = useState<string | undefined>(undefined);
    const [numPages, setNumPages] = useState<number | null>(null);

    const handleClose = () => setOpen(undefined);

    const onDocumentLoadSuccess: OnDocumentLoadSuccess = ({ numPages }) => setNumPages(numPages);
    
    const [selectedGP, setSelectedGP] = useState<User | undefined>(undefined);

    const [selectedCondition, setSelectedCondition] = useState('');
    const [selectedMedication, setSelectedMedication] = useState('');

    const [emailSent, setEmailSent] = useState(false);
      
    const [checkedActivities, setCheckedActivities] = useState<Array<number>>([0,1,3]);
    const [checkedAvailability, setCheckedAvailability] = useState<Array<number>>([1,2,3,4,5]);
    const [checkedLevel, setCheckedLevel] = useState<number>(1);
    const [checkedUserType, setCheckedUserType] = useState<number>(0);
    const [gpReferralCodeInput, setGPReferralCodeInput] = useState(currentUser.gpReferralCode ? currentUser.gpReferralCode : '');
    const [generatingPlan, setGeneratingPlan]= useState(false);
    const [generatedPlan, setGeneratedPlan]= useState(false);

    const [plans, setPlans] = useState<Array<VitovaPlan>>([]);

    // ERRORS
    const [error, setError] = useState(false);

    const handleNextStage = () => {
        if (!surgeryDate || surgeryDate.length !== 10) {
            setError(true); // Show error message
        } else if (!expectedSurgery){
            setExpectedSurgeryError(true);
        }
        else {
            setError(false); // Clear error if valid
            setExpectedSurgeryError(false)
            console.log('Proceeding to next stage with date:', surgeryDate);
            nextStage(); // Call your nextStage function
        }
    };


    const handleActivityClick = (id: number) => {
        setCheckedActivities((prevCheckedActivities) => {
            if (prevCheckedActivities.includes(id)) {
                // Remove id if it exists
                return prevCheckedActivities.filter(activityId => activityId !== id);
            } else {
                // Add id if it doesn't exist
                return [...prevCheckedActivities, id];
            }
        });
    };
    
    useEffect(() => {
        getPlans();
        getConditions();
        getMedications();
        // Calculate 3 months from the current date
        const today = new Date();
        today.setMonth(today.getMonth() + 3);
        
        // Format the date to YYYY-MM-DD
        const formattedDate = today.toISOString().split("T")[0];
        
        // Set the default surgery date to 3 months from today
        setSurgeryDate(formattedDate);
        setDOB(formattedDate)
    }, []);

    const handleCheckboxChange = (name: string) => {
        setCheckedConditions((prevCheckedConditions) =>
          prevCheckedConditions.map((item) =>
            item.condition.name === name ? { ...item, checked: !item.checked } : item
          )
        );
      };

      const handleMedicationCheckboxChange = (name: string) => {
        setCheckedMedications((prevCheckedMedications) =>
            prevCheckedMedications.map((item) =>
            item.medication.name === name ? { ...item, checked: !item.checked } : item
          )
        );
      };
    // State variables to store the answers and form stage
    const [formData, setFormData] = useState({
        heartCondition: '',
        chestPainActivity: '',
        chestPainRest: '',
        dizziness: '',
        boneJointProblem: '',
        bloodPressureMedication: '',
        dob: '',
        address: '',
        signature: '',
    });

    const disclaimerText = "I hereby declare that by signing this form I accept sole responsibility for my health throughout any training plan or coaching undertaken. Training undertaken from this plan is done so solely at my own risk. I declare that I will not train beyond my ability and I understand that any information or advice given does not substitute, replace, or change any professional medical advice, diagnosis, or treatment. If you believe you are injured or require health-related advice, you should contact a health-care professional.I hereby fully and forever release and discharge the online personal trainer (seller), its assigns and agents from all claims, demands, damages, rights of action, present and future therein. I understand and warrant, release and agree that I am in good physical condition and that I have no disability, impairment or ailment preventing me from engaging in active or passive exercise that will be detrimental to overall health, safety, or comfort, or physical condition if I engage or participate (other than those items fully discussed on the health history form). All client information received will be destroyed upon finishing or termination of a training plan or package. Any information stored will be done so securely and safely in line with GDPR guidelines.  Any information given shall not be shared with any 3rd party companies or otherwise. All information given is tailored and private to the purchaser of this plan and by signing this you declare that any information shall not be distributed outside of it’s intended users. I understand that within 14 days of the first month purchase, the client is entitled to a full refund. After this point fees are non-refundable for the month purchased."

    const nextStage = () => setQuestionStage(prevStage => prevStage + 1);
    const prevStage = () => setQuestionStage(prevStage => Math.max(prevStage - 1, 0));

    async function logout() {
        try {
            await Api.logout();
            onLogoutSuccessful();
        } catch (error) {
            console.error(error);
            alert(error);
        }
    }

    // Function to handle going back to main view
    const handleBackButtonClick = () => {
        setQuestionStage(0);
    };
    async function getPlans(){
        const response = await Api.getVitovaPlans().then(res=>{
            setPlans(res);
        });
    }

    async function getConditions() {
        try {
            const response = await Api.getConditions();
            setCheckedConditions(response.map((condition: DBCondition) => ({ condition:condition, checked: false })));
        } catch (error) {
            console.error("Failed to fetch medications:", error);
        }
    }

    async function getMedications() {
        try {
            const response = await Api.getMedications();
            setCheckedMedications(response.map((medication: DBMedication) => ({ medication:medication, checked: false })));
        } catch (error) {
            console.error("Failed to fetch medications:", error);
        }
    }

    const handleInfoStageNext = () =>{
        const today = new Date();
        const userDOB = new Date(dob);
        const eighteenYearsAgo = new Date();
        eighteenYearsAgo.setFullYear(today.getFullYear() - 18);
        
        if (userDOB > eighteenYearsAgo){
            alert('You must be at Least 18 years old')
        }else{
            nextStage();
        }
    }
    

    const finishReview = () => {
        setQuestionStage(0);
    }

    const getNextMonday = () => {
        const today = new Date();
        const dayOfWeek = today.getDay();
        const daysUntilNextMonday = (8 - dayOfWeek) % 7;
        const nextMonday = new Date(today);
        nextMonday.setDate(today.getDate() + daysUntilNextMonday);
        nextMonday.setHours(0, 0, 0, 0);
        return nextMonday;
    };

    const getActivityTypeFromIndex = (i:number)=>{
        switch (i){
            case 0:
                return 'Run';
            case 1:
                return 'Cycle';
            case 2:
                return 'Swim';
            case 3:
                return 'Strength';
            default:
                return 'Run'
        }
    }

    async function handleSubmitClick() {  
        setGeneratingPlan(true)
        if (plans){
            try {
                let newUser = { ...user };
                const today = new Date();
        
                // INFO OBJECT
                newUser.info = {
                    height: height,
                    weight: weight,
                    waistCircumference: waist,
                    conditions: checkedConditions,
                    medications: checkedMedications,
                    parq: formData,
                    dob:new Date(dob),
                    gender:gender,
                    complete: true,
                    averageWeeklyUnits:averageWeeklyUnits
                };

                newUser.tests = [
                    {
                        test:phq9,
                        result:phq9Result,
                        dateTaken:today
                    },
                    {
                        test:gad7,
                        result:gad7Result,
                        dateTaken:today
                    },
                ]

                // BASIC INFORMATION
                newUser.surgeryDate = new Date(surgeryDate);
                newUser.smoker = smoking === 0 ? false : true;
                newUser.drinker = drinking === 0 ? false : true;
                newUser.expectedSurgery = expectedSurgery;
                newUser.smokingHabit = smoking;
                newUser.drinkingHabit = drinking;
                newUser.exerciseHistory = exerciseHistory;
                newUser.activityPreferences = checkedActivities;
                newUser.availability = checkedAvailability;
                newUser.weightData = [...(newUser.weightData || []), { value: weight, date: today }];
                newUser.waistData = [...(newUser.waistData || []), {value: waist, date:today}];
                
                newUser.accountCreatedDate = today;

                newUser.bpData = [...(newUser.bpData || []), { value: bp, date: today }];
                newUser.hba1cData = [...(newUser.hba1cData || []), { value: hba1c, date: today }];
                newUser.hdlData = [...(newUser.hdlData || []), { value: hdl, date: today }];
                newUser.ldlData = [...(newUser.ldlData || []), { value: ldl, date: today }];
                newUser.cholesterolData = [...(newUser.cholesterolData || []), { value: cholesterol, date: today }];


                // ACCOUNT TYPE
                newUser.accountType = UserTypeMap[checkedUserType]
                if (checkedUserType === 0){
                    newUser.gpReferralCode = gpReferralCodeInput;
                }

                // PLAN START DATE
                if (newUser.accountType === 'ffs'){
                    newUser.userPlanStartDate = getNextMonday();
                }else{
                    newUser.userPlanStartDate = today;
                }
            
                // Find first condition
                // const firstCondition = checkedConditions.filter(c=>c.checked)[0].condition.name.toLowerCase()
                const foundPlan = plans.find(p=>p.title.toLowerCase().includes('surgery'));
                if (foundPlan){
                    try {
                        // Use await to handle asynchronous plan generation
                        let newPlan: VitovaPlan = foundPlan;
                        newUser.currentVitovaPlan = newPlan;
                    
                        // First, update the user
                        await Api.updateUser(newUser._id, newUser);
                    
                        // Then, adapt the plan length
                        const updatedUser = await Api.adaptPlanLength(newUser._id, {
                            currentVitovaPlan: newUser.currentVitovaPlan,
                            surgeryDate: newUser.surgeryDate
                        });

                        await Api.updateUser(newUser._id, updatedUser);

                        onInfoSet(updatedUser); // Call onInfoSet with currentUser
                        setCurrentUser(updatedUser);
                        setGeneratingPlan(false);
                        setGeneratedPlan(true);
                    } catch (error) {
                        console.error("Error updating user or adapting plan:", error);
                        setGeneratingPlan(false); // Ensure state is reset even on failure
                    }
                    

                }else{
                    onInfoSet(newUser); // Call onInfoSet with currentUser
                    setCurrentUser(newUser);
                    setGeneratingPlan(false);
                    setGeneratingPlan(true)
                    await Api.updateUser(newUser._id, newUser);
                }

                const body:Api.WelcomeEmailBody = {
                    email:newUser.email
                }
                await Api.sendWelcomeEmail(body);

            } catch (error) {
                console.error('Error updating user:', error);
                alert('Failed to save changes');
            }
        }

    }
    
    const handleChange = (e:any) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const generateMarks = () => {
        const marks = [];
        for (let seconds = 60; seconds <= 540; seconds += 5) {
            marks.push({ value: seconds });
        }
        return marks;
    };

    const handleSubmit = (e:any) => {
        e.preventDefault();
        // Handle form submission, e.g., send data to an API or display it
        console.log(formData);
    };

    const checkGpReferralCode = async () => {
        const body:Api.GetPatientsBody = {gpReferralCode:gpReferralCodeInput}
        try{
            const response = await Api.getPatients(body);

            if (response.length > 0){
                const GP = response.filter((u:User) => u.GPAccount === true)[0];
                if (GP) {
                    const userConfirmed = window.confirm(`We've found your Health Care Professional: ${GP.fullname}`);
                    if (userConfirmed) {
                        setSelectedGP(GP);
                        nextStage();
                    } else {
                        // Handle the "No" response, if necessary
                        console.log("User indicated the HCP is not correct.");
                    }
                } else {
                    console.log("No HCP found.");
                }
                
            }else{
                alert("Couldn't find that HCP in our records")
            }

        }catch{
            alert("Couldn't find that HCP in our records")
        }
    }


    const handleAddCustomCondition = async () => {
        const findCondition = checkedConditions.find(c=>c.condition.name.toLowerCase()===customCondition.toLowerCase());

        if (findCondition){
            alert("We've Already Got That One!")
            return;
        }
        if (customCondition.trim() !== "") {
            const newCondition:DBCondition = {
                _id:'',
                id:'',
                name:customCondition,
                description:'',
                symptoms:[],
                commonMedications:[],
                cardioConstraints:{
                    rpeMin:1,
                    rpeMax:5,
                    durationMin:30,
                    durationMax:60,
                    weeklyFreqMax:3,
                    weeklyFreqMin:2
                },
                resistanceConstraints:{
                    rpeMin:1,
                    rpeMax:5,
                    percentOf10RepMaxMin:40,
                    percentOf10RepMaxMax:70,
                    durationMin:30,
                    durationMax:60,
                    weeklyFreqMax:3,
                    weeklyFreqMin:2,
                    setsMin:1,
                    setsMax:3,
                    repsMin:10,
                    repsMax:15,
                    weeklyExercisesMin:5,
                    weeklyExercisesMax:12,
                },
                flexibilityConstraints:{
                    rpeMin:1,
                    rpeMax:5,
                    durationMin:30,
                    durationMax:60,
                    weeklyFreqMax:3,
                    weeklyFreqMin:2
                },
                verified:false,
            }
            setCheckedConditions((prev) => [...prev, {condition:newCondition, checked:true}]);
            setCustomCondition("");
            await Api.createCondition(newCondition);
        }
    };

    const handleAddCustomMedication = async () => {
        const findMedication= checkedMedications.find(m=>m.medication.name.toLowerCase()===customMedication.toLowerCase());

        if (findMedication){
            alert("We've Already Got That One!")
            return;
        }
        if (customMedication.trim() !== "") {
            const newMedication:DBMedication = {
                _id:'',
                name:customMedication,
                medId:'',
                verified:false,
            }
            setCheckedMedications((prev) => [...prev, {medication:newMedication, checked:true}]);
            setCustomMedication("");
            await Api.createMedication(newMedication);
        }
    };

    async function sendVerificationEmail () {
        try{
        // Send email verification
        await Api.sendVerificationEmail({
            userId:user._id,
            email:user.email
        });

        }catch(error){
            console.log(error)
        }finally{
            setEmailSent(true);

        }

    }

    const marks = generateMarks();


    const renderPathwayOptions = () => {
        return (
            <div className="form-stage" style={{display:'flex', flexDirection:'column', overflowY:'scroll'}}>
            <div style={{width:'auto', display:'flex', justifyContent:'center', flexDirection:'column', height:'70vh'}}>
                <h2 style={{fontSize:'2em', alignSelf:'center', marginBottom:'1vh', marginTop:'2vh', color:'#454545'}} className="main-title">Which platform are you looking to access?</h2>
                <label style={{textAlign:'center', color:'#454545'}}>Let us know so we can best help you!</label>
                <div style={{display:'flex', flexDirection:'column', overflow:'scroll', maxHeight:'60vh', padding:'25px'}}>
            <ul className="option-grid-container" style={{width:'auto'}}>
                {[
                    { id: 0, title: "FITNESS FOR SURGERY", icon: faHeartPulse, description: "A program designed to prepare you physically and mentally for surgery.", disabled: false },
                    { id: 1, title: "HEALTH AND WELLBEING", icon: faHeart, description: "A holistic approach to improving overall health and wellness.", disabled: false },
                    { id: 2, title: "HCP EXERCISE REFERRAL", icon: faRunning, description: "A guided exercise plan prescribed by healthcare professionals.", disabled: false },
                ].map((option, index) => (
                    <li key={option.id} style={{pointerEvents:option.disabled?'none':'all'}} className="activity-grid-item" onClick={()=>setCheckedUserType(index)}>
                        
                        <div className="activity-card" style={{height:'100%', justifyContent:'flex-start',padding:'0 20px', borderRadius:'5px', boxShadow:'none', maxWidth:'24em', paddingBottom:'10px'}}>
                            {checkedUserType === option.id ?
                                <FontAwesomeIcon icon={faCircleCheck} className="activity-card-checkbox" style={{color:colorPalette.steelBlue}}/>
                                :
                                <FontAwesomeIcon icon={faCircle} className="activity-card-checkbox" style={{color:'#676767'}}/>
                            }
                            <FontAwesomeIcon className="activity-card-img" icon={option.icon} style={{color:checkedUserType===option.id?colorPalette.steelBlue:'#676767'}}/>
                            <h2 className="activity-card-title" style={{color:checkedUserType===(option.id) ? colorPalette.steelBlue:'#676767', transition:'0.3s',fontStyle:checkedUserType===option.id?'italic':'normal', fontWeight:checkedUserType===option.id?'bold':'normal'}}>{option.title}</h2>
                            <label style={{color:checkedUserType===option.id?colorPalette.steelBlue:'#676767', fontSize:'1em'}}>{option.description}</label>

                            {(index === 0) &&
                            <input value={ gpReferralCodeInput} onChange={(e)=>setGPReferralCodeInput(e.currentTarget.value)} placeholder="Enter Your HCP Referral Code" className="activity-card-title" style={{color:checkedUserType===option.id?colorPalette.steelBlue:'#676767', fontSize:'1em', borderRadius:'5px', outline:'none', boxShadow:'none', border:'1px solid #dddddd', marginTop:'15px',marginBottom:'15px'}}></input>
                            }
                        </div>
                    </li>
                ))}
            </ul>
            </div>
            </div>
            <div style={{width:'100%', display:'flex', justifyContent:'space-between', borderRadius:'5px',position:'absolute', bottom:0}}>
                <Button className="form-back-button" onClick={prevStage}>Previous</Button>
                <Button className="form-next-button" onClick={()=>{((checkedUserType === 0) ? gpReferralCodeInput.length <= 0 ? alert('Please enter a HCP referral code') : checkGpReferralCode() : nextStage())}}>Next Step</Button>
            </div>
        </div>
        )
    }

    const renderGpReferralPathway = () => {
        switch (questionStage){
            case 1:
                return (
                    <div className="form-stage" style={{display:'flex', flexDirection:'column', alignItems:'center', maxWidth:'70em'}}>
                    <h2 style={{ fontSize: "2em", alignSelf: "center", marginBottom: "2vh", color: "#454545" }} className="main-title">
                        Terms and Conditions
                    </h2>
        
                    <label style={{ fontSize: "1em", textAlign: "center", padding: "10px 5px", backgroundColor: "white", borderRadius: "5px", color: "#454545"}}>
                        {disclaimerText}
                    </label>
        
                    <div style={{ width: "100%", display: "flex", justifyContent: "center", marginTop: "20px" }}>
                        <Button variant="outlined" onClick={()=>setOpen('t&c')}>View Full Terms and Conditions</Button>
                        <Button variant="outlined" onClick={()=>setOpen('pp')}>View Privacy Policy</Button>
                    </div>
                    <div style={{ width: "100%", display: "flex", color:colorPalette.steelBlue,justifyContent: "center", marginTop: "10px", flexDirection: "row", alignItems: "center" }}>
                        <label style={{}}>
                            <Checkbox checked={tcChecked} onChange={()=>setTcChecked(!tcChecked)} style={{marginBottom:'3px'}} /> I Have Read The Terms And Conditions
                        </label>
                        <label style={{marginLeft:'30px'}}>
                            <Checkbox checked={ppChecked} onChange={()=>setPpChecked(!ppChecked)} /> I Have Read The Privacy Policy
                        </label>
                    </div>

                    <Dialog open={open!==undefined} onClose={handleClose} maxWidth="md" fullWidth>
                        <DialogTitle>{open === 't&c' ? 'Terms And Conditions':'Privacy Policy'}</DialogTitle>
                        <DialogContent dividers>
                                <iframe
                                src={open === 't&c' ? pdfFile:privacyPolicyPdf}
                                width="100%"
                                height="600px"
                                title={open === 't&c' ? 'T&Cs':'PrivacyPolicy'}
                                style={{ border: "none" }}
                                ></iframe>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose}>Close</Button>
                        </DialogActions>
                    </Dialog>
        
                    <div style={{ width: "100%", display: "flex", justifyContent: "space-between", borderRadius: "5px", position: "absolute", bottom: 0 }}>
                        <Button className="form-back-button" onClick={logout}>Logout</Button>
                        <Button className="form-next-button" onClick={()=>tcChecked && ppChecked ? nextStage():alert('Agreement with our terms and conditons and privacy policy is mandatory')}>Accept Conditions</Button>
                    </div>
                </div>
                )
            case 2:
                return renderPathwayOptions()
            case 3:
                return(
                <div className="form-stage" style={{ display: 'flex', flexDirection: 'column', overflowY: 'scroll' }}>
                    {selectedGP ?
                        <div style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', height: '70vh', width: '90%', color:'black'}}>
                            <h2 style={{ fontSize: '2em', alignSelf: 'center', marginBottom: '1vh', marginTop: '2vh', color: '#454545' }} className="main-title">
                            A Little Bit About Your Health Care Professional
                            </h2>
                            <label style={{ textAlign: 'center', color: '#454545' }}>
                            {`Here's Some Information About ${selectedGP.fullname}`}
                            </label>
                            
                            <div className="dob-container" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            {/* Display GP's Full Name */}
                            <label style={{ textAlign: 'center', color: '#454545' }}>
                                <strong>Name:</strong> {selectedGP.fullname}
                            </label>
                            
                            {/* Display GP's Email */}
                            <label style={{ textAlign: 'center', color: '#454545' }}>
                                <strong>Email:</strong> {selectedGP.email}
                            </label>
                            
                            {/* Display GP's Image */}
                            {selectedGP.image && (
                                <img 
                                src={selectedGP.image} 
                                alt={`${selectedGP.fullname}`} 
                                style={{ width: '150px', height: '150px', borderRadius: '50%', marginTop: '20px' }} 
                                />
                            )}
                            </div>
                        </div>
                        :
                        <>No HCP Selected</>                
                    }

                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', borderRadius: '5px', position: 'absolute', bottom: 0 }}>
                    <Button className="form-back-button" onClick={prevStage}>Previous</Button>
                    <Button className="form-next-button" onClick={nextStage}>Next Step</Button>
                </div>
                </div>

                    )
            case 4:
                // Please can you confirm your healthcare details for us:​
                // Date of surgery.​
                // Expected surgery.​
                // Health conditions / past medical history (drop down bar?).​
                // Medications (drop down bar).​
                // Have you been signed off to complete this exercise referral pathway by your doctor?
                return(
                <div className="form-stage" style={{display:'flex', flexDirection:'column', overflowY:'scroll'}}>
                    <div style={{display:'flex', justifyContent:'flex-start', flexDirection:'column', height:'70vh', width:'90%'}}>
                        <h2 style={{fontSize:'2em', alignSelf:'center', marginBottom:'1vh', marginTop:'2vh', color:'#454545'}} className="main-title">Basic Information</h2>
                        <label style={{textAlign:'center', color:'#454545'}}>Please can you confirm your healthcare details for us</label>
                        <div className="dob-container" style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <label className="question-label">
                                When is your surgery?
                            </label>
                            <TextField
                                label="Surgery Date"
                                type="date"
                                fullWidth
                                variant="outlined"
                                value={surgeryDate ? surgeryDate : ""}  // Default to empty string if surgeryDate is not set
                                onChange={(e) => {
                                    setSurgeryDate(e.currentTarget.value);
                                    setError(false);
                                }}
                                InputLabelProps={{ shrink: true }}
                                style={{ fontSize: "1.2em", backgroundColor: "transparent" }}
                            />

                            {!surgeryDate ? (
                                <p style={{ color: 'steelblue', fontWeight:'bold', fontStyle:'italic',marginTop: '0.5em' }}>
                                    Please select a date for your surgery.
                                </p>
                            ) : isNaN(new Date(surgeryDate).getTime()) ? (
                                <p style={{ color: 'steelblue', fontWeight:'bold', fontStyle:'italic',marginTop: '0.5em' }}>
                                    Invalid date selected. Please choose a valid date.
                                </p>
                            ) : 
                            isNaN(new Date(surgeryDate).getTime()) || new Date(surgeryDate) <= new Date() ? (
                                <p style={{ color: 'steelblue', fontWeight: 'bold', fontStyle: 'italic', marginTop: '0.5em' }}>
                                    Invalid date selected. Please choose a valid future date.
                                </p>
                            ) : (
                                <p style={{ color: 'steelblue', fontWeight:'bold', fontStyle:'italic',marginTop: '0.5em' }}>
                                    {`YOUR SURGERY IS IN ${Math.ceil(
                                        (new Date(surgeryDate).getTime() - Date.now()) / (1000 * 60 * 60 * 24)
                                    )} DAYS!`}
                                </p>
                            )}
 
                            {error && <p style={{ color: 'red', marginTop: '0.5em' }}>A valid date is required</p>}

                        </div>
                        <div className="dob-container" style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <label style={{marginTop:'20px'}} className="question-label">
                                Which surgery will you be undergoing?
                            </label>
                            <FormControl 
                                key="Surgery" 
                                fullWidth 
                                variant="outlined" 
                                style={{ marginBottom: "20px" }}
                                >
                                <InputLabel shrink={!!expectedSurgery}>Surgery</InputLabel>
                                <Select
                                    value={expectedSurgery}
                                    onChange={(e) => {
                                    const target = e.target as HTMLInputElement; // Explicitly cast to HTMLInputElement
                                    if (target && target.value) {
                                        setExpectedSurgery(target.value);
                                    }
                                    }}
                                    label="Surgery"
                                >
                                    <MenuItem disabled value="">
                                    Select A Surgery
                                    </MenuItem>
                                    <MenuItem value="Complex Abdominal Wall Surgery">
                                    Complex Abdominal Wall Surgery
                                    </MenuItem>
                                </Select>
                                </FormControl>

                                {expectedSurgeryError && <p style={{ color: 'red', marginTop: '0.5em' }}>Please select a surgery</p>}

                        </div>
                        <div className="dob-container" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <label style={{ marginTop: '10px' }} className="question-label">
                Which conditions do you have?
            </label>
            <FormControl fullWidth variant="outlined" style={{ marginBottom: "10px"}}>
                <InputLabel 
                // shrink={!!selectedCondition}
                >Conditions</InputLabel>
                <Select
                    value={selectedCondition}
                    onChange={(e) => {
                        const selectedName = e.target.value;
                        handleCheckboxChange(selectedName);
                        // setSelectedCondition(selectedName);
                    }}
                    label="Conditions"
                >
                    <MenuItem disabled value="">Select A Condition</MenuItem>
                    {checkedConditions
                    .sort((a, b) => a.condition.name.localeCompare(b.condition.name)) // Sort alphabetically by name
                    .map((condition, index) => (
                        <MenuItem key={index} value={condition.condition.name}>
                        {condition.condition.name}
                        </MenuItem>
                    ))}

                </Select>
            </FormControl>
            <div style={{display:'flex', flexDirection:'row', width:'100%', justifyContent:'center', alignItems:'center'}}>
                <TextField
                    variant="outlined"
                    value={customCondition}
                    onChange={(e) => setCustomCondition(e.target.value)}
                    style={{ marginBottom: "10px", width: '100%'}}
                    placeholder="Have a condition that is not listed? Enter it here"
                />
                <Button 
                    variant="contained" 
                    onClick={handleAddCustomCondition}
                    style={{ marginLeft: '10px', padding: '10px' }}
                >
                    <FontAwesomeIcon icon={faPlus} style={{color:colorPalette.steelBlue, fontSize:'2em'}}/>
                </Button>
            </div>

            
            <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '10px' }}>
                {checkedConditions.filter(c => c.checked).map((condition, index) => (
                    <div key={index} style={{ display: 'flex', alignItems: 'center', margin: '5px' }}>
                        <Checkbox
                            checked={condition.checked}
                            onChange={() => handleCheckboxChange(condition.condition.name)}
                            style={{ color: '#454545' }}
                        />
                        <label style={{ color: 'steelblue' }}>{condition.condition.name}</label>
                    </div>
                ))}
            </div>
        </div>

                        <div className="dob-container" style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <label style={{marginTop:'10px'}} className="question-label">
                                Which medications do you take?
                            </label>
                            <FormControl 
                            key="Medications" 
                            fullWidth 
                            variant="outlined" 
                            style={{ marginBottom: "10px" }}
                            >
                            <InputLabel 
                            // shrink={!!selectedMedication}
                            >Medications</InputLabel>
                            <Select
                                value={selectedMedication} // Make sure there's an initial value for the select
                                onChange={(e) => {
                                const selectedName = e.target.value as string;
                                handleMedicationCheckboxChange(selectedName);
                                // setSelectedMedication(selectedName)
                                }}
                                label="Medications" // Link the label to the Select component
                            >
                                <MenuItem disabled value="">
                                Select A Medicaiton
                                </MenuItem>
                                {checkedMedications
                                    .sort((a, b) => a.medication.name.localeCompare(b.medication.name)) // Sort alphabetically by name
                                    .map((medication, index) => (
                                <MenuItem 
                                    style={{ width: '100%' }}
                                    key={medication.medication.name} 
                                    value={medication.medication.name}
                                >
                                    {medication.medication.name}
                                </MenuItem>
                                ))}
                            </Select>
                            </FormControl>
                            <div style={{display:'flex', flexDirection:'row', width:'100%', justifyContent:'center', alignItems:'center'}}>
                                <TextField
                                    variant="outlined"
                                    value={customMedication}
                                    onChange={(e) => setCustomMedication(e.target.value)}
                                    style={{ marginBottom: "10px", width: '100%'}}
                                    placeholder="Have a medication that is not listed? Enter it here"
                                />
                                <Button 
                                    variant="contained" 
                                    onClick={handleAddCustomMedication}
                                    style={{ marginLeft: '10px', padding: '10px' }}
                                >
                                    <FontAwesomeIcon icon={faPlus} style={{color:colorPalette.steelBlue, fontSize:'2em'}}/>
                                </Button>
                            </div>
                            <div style={{ display: 'flex',}}>
                            {checkedMedications.filter(m=>m.checked).map((medication:CheckedMedication, index) => (
                                    <div key={index} style={{ width: '25%', marginLeft:'2em', marginRight:'2em',display: 'flex', alignItems: 'center'}}>
                                    <Checkbox 
                                        checked={medication.checked}
                                        onChange={() => handleMedicationCheckboxChange(medication.medication.name)}
                                        style={{color:'#454545'}}/>
                                    <label style={{color:colorPalette.steelBlue}}>{medication.medication.name}</label>
                                    </div>
                            ))}
                            </div>
                        </div>
                        <label style={{marginTop:'20px', color:'red'}} className="question-label">
                            By continuing you agree that you have been signed off to complete this exercise referral pathway by your doctor
                        </label>
                    </div>
                    <div style={{width:'100%', display:'flex', justifyContent:'space-between', borderRadius:'5px',position:'absolute', bottom:0}}>
                        <Button className="form-back-button" onClick={prevStage}>Previous</Button>
                        <Button className="form-next-button" onClick={handleNextStage}>Next Step</Button>
                    </div>
                </div>
                )
            case 5:
                return (
                <div className="form-stage" style={{display:'flex', flexDirection:'column', overflowY:'scroll', color:'#454545', maxHeight:'70vh'}}>
                <h2 style={{fontSize:'2em', alignSelf:'center', marginBottom:'1vh', marginTop:'2vh', color:'#454545'}} className="parq-title">Basic Information</h2>
                <label >Let's learn about you</label>
                <div className="dob-container" style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width:'75%'}}>
                            <label className="question-label">
                                What is your date of birth?
                            </label>
                            <TextField
                                label="Date Of Birth"
                                type="date"
                                fullWidth
                                variant="outlined"
                                value={dob} 
                                onChange={(e)=>setDOB(e.currentTarget.value)} 
                                InputLabelProps={{ shrink: true }}
                                style={{ fontSize: "1.2em", backgroundColor: "transparent" }}
                                />
                </div>
                <div className="dob-container" style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width:'75%'}}>
                    <label style={{marginTop:'20px'}} className="question-label">
                        What is your gender?
                    </label>
                    <FormControl 
                            key="Gender" 
                            fullWidth 
                            variant="outlined" 
                            >
                            <InputLabel shrink={!!gender}>Gender</InputLabel>
                            <Select
                                value={gender} 
                                onChange={(e)=>{
                                    const selectedValue = e.target.value as string;
                                    setGender(selectedValue)
                                }}
                                label="Gender" // Link the label to the Select component
                            >
                                <MenuItem disabled selected value="">
                                Select A Gender
                                </MenuItem>
                                <MenuItem value="m">Male</MenuItem>
                                <MenuItem value="f">Female</MenuItem>
                                <MenuItem value="o">Other</MenuItem>
                                <MenuItem value="p">Prefer Not To Say</MenuItem>


                            </Select>
                    </FormControl>
                </div>
                <label className="question-label" style={{color:'#454545'}}>What Is Your Height? (cm)</label>
                    <TextField
                        label="Height"
                        type="number"
                        variant="outlined"
                        value={height || ""}  // Default to empty string if surgeryDate is not set
                        onChange={(e) => setHeight(Number(e.target.value))}
                        InputLabelProps={{ shrink: true }}
                        style={{ fontSize: "1.2em", backgroundColor: "transparent", width:'75%'}}
                        />
                <label className="question-label"style={{color:'#454545'}}>What Is Your Weight? (kg)</label>
                <TextField
                    label="Weight"
                    type="number"
                    fullWidth
                    variant="outlined"
                    value={weight || ""}  // Default to empty string if surgeryDate is not set
                    onChange={(e) => setWeight(Number(e.target.value))}
                    InputLabelProps={{ shrink: true }}
                    style={{ fontSize: "1.2em", backgroundColor: "transparent", width:'75%'}}
                    />
                <label className="question-label"style={{color:'#454545'}}>What Is Your Waist Circumferences? (cm)</label>
                <TextField
                    label="Waist"
                    type="number"
                    fullWidth
                    variant="outlined"
                    value={waist || ""}  // Default to empty string if surgeryDate is not set
                    onChange={(e) => setWaist(Number(e.target.value))}
                    InputLabelProps={{ shrink: true }}
                    style={{ fontSize: "1.2em", backgroundColor: "transparent", width:'75%'}}
                    />
            {/* Blood Pressure */}
            <label className="question-label" style={{ color: "#454545" }}>
                What Is Your Blood Pressure? (mmHg)
            </label>
            <TextField
                label="BP"
                type="text"
                fullWidth
                variant="outlined"
                value={bp || ""}
                onChange={(e) => setBp(e.target.value)}
                InputLabelProps={{ shrink: true }}
                style={{ fontSize: "1.2em", backgroundColor: "transparent", width: "75%" }}
            />

            {/* HbA1c */}
            <label className="question-label" style={{ color: "#454545" }}>
                What Is Your HbA1c Level? (mmols/mol)
            </label>
            <TextField
                label="HbA1c"
                type="number"
                fullWidth
                variant="outlined"
                value={hba1c || ""}
                onChange={(e) => setHba1c(Number(e.target.value))}
                InputLabelProps={{ shrink: true }}
                style={{ fontSize: "1.2em", backgroundColor: "transparent", width: "75%" }}
            />

            {/* HDL */}
            <label className="question-label" style={{ color: "#454545" }}>
                What Is Your HDL Level? (mmol/L)
            </label>
            <TextField
                label="HDL"
                type="number"
                fullWidth
                variant="outlined"
                value={hdl || ""}
                onChange={(e) => setHdl(Number(e.target.value))}
                InputLabelProps={{ shrink: true }}
                style={{ fontSize: "1.2em", backgroundColor: "transparent", width: "75%" }}
            />

            {/* LDL */}
            <label className="question-label" style={{ color: "#454545" }}>
                What Is Your LDL Level? (mmol/L)
            </label>
            <TextField
                label="LDL"
                type="number"
                fullWidth
                variant="outlined"
                value={ldl || ""}
                onChange={(e) => setLdl(Number(e.target.value))}
                InputLabelProps={{ shrink: true }}
                style={{ fontSize: "1.2em", backgroundColor: "transparent", width: "75%" }}
            />

            {/* Cholesterol */}
            <label className="question-label" style={{ color: "#454545" }}>
                What Is Your Total Cholesterol Level? (mmol/L)
            </label>
            <TextField
                label="Cholesterol"
                type="number"
                fullWidth
                variant="outlined"
                value={cholesterol || ""}
                onChange={(e) => setCholesterol(Number(e.target.value))}
                InputLabelProps={{ shrink: true }}
                style={{ fontSize: "1.2em", backgroundColor: "transparent", width: "75%" }}
            />
                <div style={{width:'100%', display:'flex', justifyContent:'space-between', borderRadius:'5px',position:'absolute', bottom:0}}>
                    <Button className="form-back-button" onClick={prevStage}>Previous</Button>
                    <Button className="form-next-button" type="submit" onClick={()=>handleInfoStageNext()}>Next Step</Button>
                </div>
                </div>
                )
            case 6:
                return (
                <div className="form-stage" style={{display:'flex', flexDirection:'column', overflowY:'scroll', color:'#454545',  minHeight:'70vh',maxHeight:'70vh'}}>
                <h2 style={{fontSize:'2em', alignSelf:'center', marginBottom:'1vh', marginTop:'2vh', color:'#454545'}} className="parq-title">Health Questions</h2>
                <label >Let's learn about your health</label>
                
                <div style={{paddingLeft:'30px',paddingRight:'30px', display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>
                    <label className="question-label" style={{color: '#454545'}}>
                        How Often Do You Smoke?
                    </label>
                    <Slider
                        defaultValue={0}
                        value={smoking}
                        onChange={(e: Event, value: number | number[]) => setSmoking(value as number)}
                        step={1}
                        min={0}
                        max={5}
                        marks={[
                            { value: 0, label: "I don't smoke" },
                            { value: 1, label: "Rarely" },
                            { value: 2, label: "Occasionally" },
                            { value: 3, label: "Sometimes" },
                            { value: 4, label: "Often" },
                            { value: 5, label: "I smoke every day" },
                        ]}
                        valueLabelDisplay="off" // Hides numeric labels
                        sx={{
                            ...sliderStyling,
                            '& .MuiSlider-markLabel': {
                                marginTop: '40px', // Adds spacing below the slider for labels
                                fontSize: '0.85em', // Optional: Adjusts font size of labels
                                color: '#454545', // Optional: Matches label text color
                            },
                        }}
                    />
                </div>
                <div style={{paddingLeft:'30px',paddingRight:'30px', marginTop:'20px',display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>
                    <label className="question-label" style={{color: '#454545'}}>
                        How Often Do You Drink?
                    </label>
                    <Slider
                        defaultValue={0}
                        value={drinking}
                        onChange={(e: Event, value: number | number[]) => setDrinking(value as number)}
                        step={1}
                        min={0}
                        max={5}
                        marks={[
                            { value: 0, label: "I don't drink" },
                            { value: 1, label: "Rarely" },
                            { value: 2, label: "Occasionally" },
                            { value: 3, label: "Sometimes" },
                            { value: 4, label: "Often" },
                            { value: 5, label: "I drink every day" },
                        ]}
                        valueLabelDisplay="off" // Hides numeric labels
                        sx={{
                            ...sliderStyling,
                            '& .MuiSlider-markLabel': {
                                marginTop: '40px', // Adds spacing below the slider for labels
                                fontSize: '0.85em', // Optional: Adjusts font size of labels
                                color: '#454545', // Optional: Matches label text color
                            },
                        }}
                    />
                <label className="question-label"style={{color:'#454545', marginTop:'60px'}}>On Average, How Many Units Do You Drink Per Week?</label>
                <TextField
                    label="Units"
                    type="number"
                    fullWidth
                    variant="outlined"
                    value={averageWeeklyUnits || 0}  // Default to empty string if surgeryDate is not set
                    onChange={(e) => setAverageWeeklyUnits(Number(e.target.value))}
                    InputLabelProps={{ shrink: true }}
                    style={{ fontSize: "1.2em", backgroundColor: "transparent", width:'100%', marginTop:'20px'}}
                    />
                </div>
                <div style={{paddingLeft:'30px',paddingRight:'30px', marginTop:'20px',display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>
                    <label className="question-label" style={{color: '#454545'}}>
                        How would you describe your exercise history?
                    </label>
                    <Slider
                        defaultValue={0}
                        value={exerciseHistory}
                        onChange={(e: Event, value: number | number[]) => setExerciseHistory(value as number)}
                        step={1}
                        min={0}
                        max={5}
                        marks={[
                            { value: 0, label: "Very rarely exercise" },
                            { value: 1, label: "Rarely exercise" },
                            { value: 2, label: "Occasionally exercise" },
                            { value: 3, label: "Sometimes exercise" },
                            { value: 4, label: "Exercise often" },
                            { value: 5, label: "Exercise every day" },
                        ]}
                        valueLabelDisplay="off" // Hides numeric labels
                        sx={{
                            ...sliderStyling,
                            '& .MuiSlider-markLabel': {
                                marginTop: '40px', // Adds spacing below the slider for labels
                                fontSize: '0.85em', // Optional: Adjusts font size of labels
                                color: '#454545', // Optional: Matches label text color
                            },
                        }}
                    />
                </div>
                <div style={{width:'100%', display:'flex', justifyContent:'space-between', borderRadius:'5px',position:'absolute', bottom:0}}>
                    <Button className="form-back-button" onClick={prevStage}>Previous</Button>
                    <Button className="form-next-button" onClick={nextStage}>Next Step</Button>
                </div>
                </div>
                )
            case 7:
                return (
                    <div className="form-stage" style={{display:'flex', flexDirection:'column', overflowY:'scroll', color:'#454545',  height:'70vh',maxHeight:'75vh', overflowX:'hidden'}}>
                        <h2 style={{ fontSize: '2em', alignSelf: 'center', marginBottom: '1vh', marginTop: '2vh', color: '#454545' }} className="parq-title">
                            {phq9.testName}
                        </h2>
                        <label>{phq9.description}</label>
                        
                        <div style={{ paddingLeft: '30px', paddingRight: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                            {phq9.questions.map((question, index) => (
                                <div key={index} style={{ marginBottom: '60px', width: '100%' }}>
                                    <label className="question-label" style={{ color: '#454545' }}>{`Q${index+1}. ${question.question}`}</label>
                                    <Slider
                                        defaultValue={0}
                                        value={phq9Result[index]}
                                        onChange={(e, value) => handleSliderChange(index, value as number)}
                                        step={1}
                                        min={0}
                                        max={3}
                                        marks={[
                                            { value: 0, label: "Not at all" },
                                            { value: 1, label: "Several days" },
                                            { value: 2, label: "More than half the days" },
                                            { value: 3, label: "Nearly every day" }
                                        ]}
                                        valueLabelDisplay="off"
                                        sx={{
                                            '& .MuiSlider-markLabel': {
                                                marginTop: '20px',
                                                fontSize: '0.85em',
                                                color: '#454545'
                                            },
                                        }}
                                    />
                                </div>
                            ))}
                        </div>

                    <div style={{width:'100%', display:'flex', justifyContent:'space-between', borderRadius:'5px',position:'absolute', bottom:0}}>
                        <Button className="form-back-button" onClick={prevStage}>Previous</Button>
                        <Button className="form-next-button" onClick={nextStage}>Next Step</Button>
                    </div>
                    </div>
                    )
            case 8:
                return (
                    <div className="form-stage" style={{display:'flex', flexDirection:'column', overflowY:'scroll', color:'#454545', width:'70em', height:'70vh',maxHeight:'75vh', overflowX:'hidden'}}>
                        <h2 style={{ fontSize: '2em', alignSelf: 'center', marginBottom: '1vh', marginTop: '2vh', color: '#454545' }} className="parq-title">
                            {gad7.testName}
                        </h2>
                        <label>{gad7.description}</label>
                        
                        <div style={{ paddingLeft: '30px', paddingRight: '30px', display: 'flex', justifyContent: 'center', width:'100%',alignItems: 'center', flexDirection: 'column' }}>
                            {gad7.questions.map((question, index) => (
                                <div key={index} style={{ marginBottom: '60px', width: '100%' }}>
                                    <label className="question-label" style={{ color: '#454545' }}>{`Q${index+1}. ${question.question}`}</label>
                                    <Slider
                                        defaultValue={0}
                                        value={gad7Result[index]}
                                        onChange={(e, value) => handleCad9SliderChange(index, value as number)}
                                        step={1}
                                        min={0}
                                        max={3}
                                        marks={[
                                            { value: 0, label: "Not at all" },
                                            { value: 1, label: "Several days" },
                                            { value: 2, label: "More than half the days" },
                                            { value: 3, label: "Nearly every day" }
                                        ]}
                                        valueLabelDisplay="off"
                                        sx={{
                                            '& .MuiSlider-markLabel': {
                                                marginTop: '20px',
                                                fontSize: '0.85em',
                                                color: '#454545'
                                            },
                                        }}
                                    />
                                </div>
                            ))}
                        </div>

                    <div style={{width:'100%', display:'flex', justifyContent:'space-between', borderRadius:'5px',position:'absolute', bottom:0}}>
                        <Button className="form-back-button" onClick={prevStage}>Previous</Button>
                        <Button className="form-next-button" onClick={nextStage}>Next Step</Button>
                    </div>
                    </div>
                    )
            case 9:
                    if (generatingPlan){
                        return (
                            <div className="form-stage" style={{maxHeight:'70vh', overflowY:'scroll', color:'#454545', display:'flex', justifyContent:'center', alignItems:'center', height:'100vh'}}>
                            <Spinner text="Generating Your Custom Plan!"/>
                        </div>
                        )
                    }else{
                        if (generatedPlan){
                            return (
                                <div style={{width:'86em',height:'74vh',backgroundColor:'#fefefe'}}>        
                                <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', backgroundColor:`${colorPalette.default}88`, width:'100%', height:'8vh', borderRadius:'0'}}>
                                    <div style={{backgroundColor:'transparent',  display:'flex', alignItems:'center', width:'100%'}}>
                                        {/* <FontAwesomeIcon className="back-button" style={{width:'2em', height:'2em', marginLeft:'0.75em', padding:'5px'}} onClick={()=>regeneratePlan()} icon={faRotateRight}></FontAwesomeIcon> */}
                                        {/* <label style={{fontWeight:'bold', fontSize:'1.3em', marginLeft:'8px'}}>Generate A New Plan</label> */}
                                    </div>
                                </div>
                                <div style={{display:'flex', width:'100%', height:'90%', flexDirection:'row', alignItems:'flex-start'}}>
                
                                        <div style={{display:'flex', width:'100%', height:'100%', flex:1, backgroundColor:'#f1f1f1', flexDirection:'column'}}>
                                            <label style={{color:'#565656', marginTop: '10px', padding:'5px 20px', fontSize:'1.5em', fontWeight:'bold', fontStyle:'italic'}}>{currentUser.currentVitovaPlan.title.toUpperCase()}</label>
                                            <label style={{color:'#565656', fontSize:'1.125em', textAlign:'justify', padding:'0px 20px',}}>{currentUser.currentVitovaPlan.description.slice(0, 400)}</label>
                
                                            <div style={{display: 'flex', marginTop:'1vh',margin:'10px 20px',alignItems: 'flex-start', flexDirection:'column', justifyContent:'flex-start'}}>
                                        <div style={{backgroundColor: `${colorPalette.steelBlue}dd`, padding: '6px 10px', width:'100%',borderRadius: '3px', marginTop: '5px', color: '#fff', fontWeight: 'bold'}}>
                                            {`${currentUser.currentVitovaPlan.content.length} WEEKS`}
                                        </div>
                                    </div>
                                    <button className="set-plan-button" 
                                                style={{
                                                    fontWeight: 'bold', 
                                                    color: '#fff', 
                                                    fontStyle:'italic',
                                                    padding: '10px 15px', 
                                                    borderRadius: '3px', 
                                                    border: 'none', 
                                                    cursor: 'pointer', 
                                                    width: 'auto',
                                                    transition: 'background-color 0.3s',
                                                    margin:'0px 20px',
                                                    fontSize:'1.25em'
                                                }}
                                                onClick={() => {
                                                    onInfoSet(currentUser); // Call onInfoSet with currentUser
                                                }}
                                                onMouseOver={(e) => e.currentTarget.style.backgroundColor = `steelblue`}
                                                onMouseOut={(e) => e.currentTarget.style.backgroundColor = colorPalette.steelBlue}
                                        >
                                            {`GO TO DASHBOARD`}
                                        </button>
                                        </div>
                                        <div style={{display:'flex', width:'100%', height:'100%', flex:2, flexDirection:'column', alignItems:'flex-start', justifyContent:'center'}}>
                                            <label style={{color:'#565656', marginTop: '10px', marginLeft:'1em', fontSize:'1.5em', fontWeight:'bold'}}>WEEK 1 TRAINING LOAD OVERVIEW</label>
                                            <GeneralChartComponent mh={80} mw={100} 
                                            chartData={currentUser.currentVitovaPlan.content[0].days.map(day=>getDayStressScore(day, user, false))} 
                                            labelData={currentUser.currentVitovaPlan.content[0].days.map((day:VitovaDay, index:number)=>`Day ${index+1}`)}

                                            user={currentUser}></GeneralChartComponent>
                                        </div>
                                    </div>
                            </div>
                            )
                        }else{
                            if (checkedConditions.filter(c=>c.checked === true).length <= 0){
                                return (
                                <div className="form-stage" style={{maxHeight:'70vh', overflowY:'scroll', color:'#454545', display:'flex', justifyContent:'flex-start', alignItems:'center', height:'100vh'}}>
                                <h2 style={{fontSize:'2em', alignSelf:'center', marginBottom:'5vh', marginTop:'2vh', color:'#454545'}} className="main-title">Review Your Answers Before Submitting</h2>
                                <table style={{width: '60em', borderCollapse: 'collapse'}}>
                                    <thead>
                                        <tr>
                                            <th style={{fontSize:'1.25em', padding:'0.75em', borderBottom: '2px solid #000'}}>Height</th>
                                            <th style={{fontSize:'1.25em', padding:'0.75em', borderBottom: '2px solid #000'}}>Weight</th>
                                            <th style={{fontSize:'1.25em', padding:'0.75em', borderBottom: '2px solid #000'}}>Waist</th>
                                            <th style={{fontSize:'1.25em', padding:'0.75em', borderBottom: '2px solid #000'}}>Conditions</th>
                                            <th style={{fontSize:'1.25em', padding:'0.75em', borderBottom: '2px solid #000'}}>Medications</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr  key={1}>
                                            <td style={{fontSize:'18px', padding:'0.75em', borderBottom: '1px solid #ddd', verticalAlign:'top'}}>{height}</td>
                                            <td style={{fontSize:'18px', padding:'0.75em', borderBottom: '1px solid #ddd', verticalAlign:'top'}}>{weight}</td>
                                            <td style={{fontSize:'18px', padding:'0.75em', borderBottom: '1px solid #ddd', verticalAlign:'top'}}>{waist}</td>
                                            {checkedConditions.filter(c=>c.checked).length > 0 && 
                                                <td style={{fontSize:'18px', padding:'0.75em', borderBottom: '1px solid #ddd', verticalAlign:'top'}}>
                                                    <ul>
                                                        {checkedConditions.map((c, index) => (
                                                            c.checked &&
                                                            <li key={index}>{c.condition.name}</li>
                                                        ))}
                                                    </ul>
                                                </td>
                                            }
                                            {checkedConditions.filter(c=>c.checked).length <= 0 && 
                                                <td style={{fontSize:'18px', padding:'0.75em', borderBottom: '1px solid #ddd', verticalAlign:'top'}}>None</td>
                                            }
                                            {checkedMedications.filter(c=>c.checked).length > 0 && 
                                                <td style={{fontSize:'18px', padding:'0.75em', borderBottom: '1px solid #ddd'}}>
                                                    <ul>
                                                        {checkedMedications.map((m, index) => (
                                                            m.checked &&
                                                            <li key={index}>{m.medication.name}</li>
                                                        ))}
                                                    </ul>
                                                </td>
                                            }
                                            {checkedMedications.filter(c=>c.checked).length <= 0 && 
                                                <td style={{fontSize:'18px', padding:'0.75em', borderBottom: '1px solid #ddd', verticalAlign:'top'}}>None</td>
                                            }
                                        </tr>
                                    </tbody>
                                </table>
                                
                                <div style={{width:'100%', display:'flex', justifyContent:'space-between', borderRadius:'5px',position:'absolute', bottom:0}}>
                                    <Button className="form-back-button" onClick={prevStage}>Previous</Button>
                                    <Button className="form-next-button" onClick={handleSubmitClick}>Finish And Generate My Plan!</Button>
                                </div>
                                </div>
                                )
                            }else{
                                return (
                                    <div className="form-stage" style={{maxHeight:'70vh', overflowY:'scroll', color:'#454545', display:'flex', justifyContent:'flex-start', alignItems:'center', height:'100vh'}}>
                                    <h2 style={{fontSize:'2em', alignSelf:'center', marginBottom:'5vh', marginTop:'2vh', color:'#454545'}} className="main-title">Review Your Answers Before Submitting</h2>
                                    <table style={{width: '60em', borderCollapse: 'collapse'}}>
                                        <thead>
                                            <tr>
                                                <th style={{fontSize:'1.25em', padding:'0.75em', borderBottom: '2px solid #000'}}>Height</th>
                                                <th style={{fontSize:'1.25em', padding:'0.75em', borderBottom: '2px solid #000'}}>Weight</th>
                                                <th style={{fontSize:'1.25em', padding:'0.75em', borderBottom: '2px solid #000'}}>Waist</th>
                                                <th style={{fontSize:'1.25em', padding:'0.75em', borderBottom: '2px solid #000'}}>Conditions</th>
                                                <th style={{fontSize:'1.25em', padding:'0.75em', borderBottom: '2px solid #000'}}>Medications</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr  key={1}>
                                                <td style={{fontSize:'18px', padding:'0.75em', borderBottom: '1px solid #ddd', verticalAlign:'top'}}>{height}</td>
                                                <td style={{fontSize:'18px', padding:'0.75em', borderBottom: '1px solid #ddd', verticalAlign:'top'}}>{weight}</td>
                                                <td style={{fontSize:'18px', padding:'0.75em', borderBottom: '1px solid #ddd', verticalAlign:'top'}}>{waist}</td>
                                                {checkedConditions.filter(c=>c.checked).length > 0 && 
                                                    <td style={{fontSize:'18px', padding:'0.75em', borderBottom: '1px solid #ddd', verticalAlign:'top'}}>
                                                        <ul>
                                                            {checkedConditions.map((c, index) => (
                                                                c.checked &&
                                                                <li key={index}>{c.condition.name}</li>
                                                            ))}
                                                        </ul>
                                                    </td>
                                                }
                                                {checkedConditions.filter(c=>c.checked).length <= 0 && 
                                                    <td style={{fontSize:'18px', padding:'0.75em', borderBottom: '1px solid #ddd', verticalAlign:'top'}}>None</td>
                                                }
                                                {checkedMedications.filter(c=>c.checked).length > 0 && 
                                                    <td style={{fontSize:'18px', padding:'0.75em', borderBottom: '1px solid #ddd'}}>
                                                        <ul>
                                                            {checkedMedications.map((m, index) => (
                                                                m.checked &&
                                                                <li key={index}>{m.medication.name}</li>
                                                            ))}
                                                        </ul>
                                                    </td>
                                                }
                                                {checkedMedications.filter(c=>c.checked).length <= 0 && 
                                                    <td style={{fontSize:'18px', padding:'0.75em', borderBottom: '1px solid #ddd', verticalAlign:'top'}}>None</td>
                                                }
                                            </tr>
                                        </tbody>
                                    </table>
                                    
                                    <div style={{width:'100%', display:'flex', justifyContent:'space-between', borderRadius:'5px',position:'absolute', bottom:0}}>
                                        <Button className="form-back-button" onClick={prevStage}>Previous</Button>
                                        <Button className="form-next-button" onClick={handleSubmitClick}>Find Me My Plan!</Button>
                                    </div>
                                    </div>
                                )
                            }
                        }
                    }
            default:
                return <></>
        }   
    }

    const renderFitnessForSurgeryPathway = () => {
        switch (questionStage){
            case 1:
                return (
                    <div className="form-stage" style={{display:'flex', flexDirection:'column', alignItems:'center', maxWidth:'70em'}}>
                    <h2 style={{ fontSize: "2em", alignSelf: "center", marginBottom: "2vh", color: "#454545" }} className="main-title">
                        Terms and Conditions
                    </h2>
        
                    <label style={{ fontSize: "1em", textAlign: "center", padding: "10px 5px", backgroundColor: "white", borderRadius: "5px", color: "#454545"}}>
                        {disclaimerText}
                    </label>
        
                    <div style={{ width: "100%", display: "flex", justifyContent: "center", marginTop: "20px" }}>
                        <Button variant="outlined" onClick={()=>setOpen('t&c')}>View Full Terms and Conditions</Button>
                        <Button variant="outlined" onClick={()=>setOpen('pp')}>View Privacy Policy</Button>
                    </div>
                    <div style={{ width: "100%", display: "flex", color:colorPalette.steelBlue,justifyContent: "center", marginTop: "10px", flexDirection: "row", alignItems: "center" }}>
                        <label style={{}}>
                            <Checkbox checked={tcChecked} onChange={()=>setTcChecked(!tcChecked)} style={{marginBottom:'3px'}} /> I Have Read The Terms And Conditions
                        </label>
                        <label style={{marginLeft:'30px'}}>
                            <Checkbox checked={ppChecked} onChange={()=>setPpChecked(!ppChecked)} /> I Have Read The Privacy Policy
                        </label>
                    </div>

                    <Dialog open={open!==undefined} onClose={handleClose} maxWidth="md" fullWidth>
                        <DialogTitle>{open === 't&c' ? 'Terms And Conditions':'Privacy Policy'}</DialogTitle>
                        <DialogContent dividers>
                                <iframe
                                src={open === 't&c' ? pdfFile:privacyPolicyPdf}
                                width="100%"
                                height="600px"
                                title={open === 't&c' ? 'T&Cs':'PrivacyPolicy'}
                                style={{ border: "none" }}
                                ></iframe>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose}>Close</Button>
                        </DialogActions>
                    </Dialog>
        
                    <div style={{ width: "100%", display: "flex", justifyContent: "space-between", borderRadius: "5px", position: "absolute", bottom: 0 }}>
                        <Button className="form-back-button" onClick={logout}>Logout</Button>
                        <Button className="form-next-button" onClick={()=>tcChecked && ppChecked ? nextStage():alert('Agreement with our terms and conditons and privacy policy is mandatory')}>Accept Conditions</Button>
                    </div>
                </div>
                )
            case 2:
                return renderPathwayOptions()
            case 3:
                return(
                <div className="form-stage" style={{ display: 'flex', flexDirection: 'column', overflowY: 'scroll' }}>
                    {selectedGP ?
                        <div style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', height: '70vh', width: '90%', color:'black'}}>
                            <h2 style={{ fontSize: '2em', alignSelf: 'center', marginBottom: '1vh', marginTop: '2vh', color: '#454545' }} className="main-title">
                            A Little Bit About Your Health Care Professional
                            </h2>
                            <label style={{ textAlign: 'center', color: '#454545' }}>
                            {`Here's Some Information About ${selectedGP.fullname}`}
                            </label>
                            
                            <div className="dob-container" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            {/* Display GP's Full Name */}
                            <label style={{ textAlign: 'center', color: '#454545' }}>
                                <strong>Name:</strong> {selectedGP.fullname}
                            </label>
                            
                            {/* Display GP's Email */}
                            <label style={{ textAlign: 'center', color: '#454545' }}>
                                <strong>Email:</strong> {selectedGP.email}
                            </label>
                            
                            {/* Display GP's Image */}
                            {selectedGP.image && (
                                <img 
                                src={selectedGP.image} 
                                alt={`${selectedGP.fullname}`} 
                                style={{ width: '150px', height: '150px', borderRadius: '50%', marginTop: '20px' }} 
                                />
                            )}
                            </div>
                        </div>
                        :
                        <>No HCP Selected</>                
                    }

                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', borderRadius: '5px', position: 'absolute', bottom: 0 }}>
                    <Button className="form-back-button" onClick={prevStage}>Previous</Button>
                    <Button className="form-next-button" onClick={nextStage}>Next Step</Button>
                </div>
                </div>

                    )
            case 4:
                // Please can you confirm your healthcare details for us:​
                // Date of surgery.​
                // Expected surgery.​
                // Health conditions / past medical history (drop down bar?).​
                // Medications (drop down bar).​
                // Have you been signed off to complete this exercise referral pathway by your doctor?
                return(
                <div className="form-stage" style={{display:'flex', flexDirection:'column', overflowY:'scroll'}}>
                    <div style={{display:'flex', justifyContent:'flex-start', flexDirection:'column', height:'70vh', width:'90%'}}>
                        <h2 style={{fontSize:'2em', alignSelf:'center', marginBottom:'1vh', marginTop:'2vh', color:'#454545'}} className="main-title">Basic Information</h2>
                        <label style={{textAlign:'center', color:'#454545'}}>Please can you confirm your healthcare details for us</label>
                        <div className="dob-container" style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <label className="question-label">
                                When is your surgery?
                            </label>
                            <TextField
                                label="Surgery Date"
                                type="date"
                                fullWidth
                                variant="outlined"
                                value={surgeryDate ? surgeryDate : ""}  // Default to empty string if surgeryDate is not set
                                onChange={(e) => {
                                    setSurgeryDate(e.currentTarget.value);
                                    setError(false);
                                }}
                                InputLabelProps={{ shrink: true }}
                                style={{ fontSize: "1.2em", backgroundColor: "transparent" }}
                            />

                            {!surgeryDate ? (
                                <p style={{ color: 'steelblue', fontWeight:'bold', fontStyle:'italic',marginTop: '0.5em' }}>
                                    Please select a date for your surgery.
                                </p>
                            ) : isNaN(new Date(surgeryDate).getTime()) ? (
                                <p style={{ color: 'steelblue', fontWeight:'bold', fontStyle:'italic',marginTop: '0.5em' }}>
                                    Invalid date selected. Please choose a valid date.
                                </p>
                            ) : 
                            isNaN(new Date(surgeryDate).getTime()) || new Date(surgeryDate) <= new Date() ? (
                                <p style={{ color: 'steelblue', fontWeight: 'bold', fontStyle: 'italic', marginTop: '0.5em' }}>
                                    Invalid date selected. Please choose a valid future date.
                                </p>
                            ) : (
                                <p style={{ color: 'steelblue', fontWeight:'bold', fontStyle:'italic',marginTop: '0.5em' }}>
                                    {`YOUR SURGERY IS IN ${Math.ceil(
                                        (new Date(surgeryDate).getTime() - Date.now()) / (1000 * 60 * 60 * 24)
                                    )} DAYS!`}
                                </p>
                            )}
 
                            {error && <p style={{ color: 'red', marginTop: '0.5em' }}>A valid date is required</p>}

                        </div>
                        <div className="dob-container" style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <label style={{marginTop:'20px'}} className="question-label">
                                Which surgery will you be undergoing?
                            </label>
                            <FormControl 
                                key="Surgery" 
                                fullWidth 
                                variant="outlined" 
                                style={{ marginBottom: "20px" }}
                                >
                                <InputLabel shrink={!!expectedSurgery}>Surgery</InputLabel>
                                <Select
                                    value={expectedSurgery}
                                    onChange={(e) => {
                                    const target = e.target as HTMLInputElement; // Explicitly cast to HTMLInputElement
                                    if (target && target.value) {
                                        setExpectedSurgery(target.value);
                                    }
                                    }}
                                    label="Surgery"
                                >
                                    <MenuItem disabled value="">
                                    Select A Surgery
                                    </MenuItem>
                                    <MenuItem value="Complex Abdominal Wall Surgery">
                                    Complex Abdominal Wall Surgery
                                    </MenuItem>
                                </Select>
                                </FormControl>

                                {expectedSurgeryError && <p style={{ color: 'red', marginTop: '0.5em' }}>Please select a surgery</p>}

                        </div>
                        <div className="dob-container" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <label style={{ marginTop: '10px' }} className="question-label">
                Which conditions do you have?
            </label>
            <FormControl fullWidth variant="outlined" style={{ marginBottom: "10px"}}>
                <InputLabel 
                // shrink={!!selectedCondition}
                >Conditions</InputLabel>
                <Select
                    value={selectedCondition}
                    onChange={(e) => {
                        const selectedName = e.target.value;
                        handleCheckboxChange(selectedName);
                        // setSelectedCondition(selectedName);
                    }}
                    label="Conditions"
                >
                    <MenuItem disabled value="">Select A Condition</MenuItem>
                    {checkedConditions
                    .sort((a, b) => a.condition.name.localeCompare(b.condition.name)) // Sort alphabetically by name
                    .map((condition, index) => (
                        <MenuItem key={index} value={condition.condition.name}>
                        {condition.condition.name}
                        </MenuItem>
                    ))}

                </Select>
            </FormControl>
            <div style={{display:'flex', flexDirection:'row', width:'100%', justifyContent:'center', alignItems:'center'}}>
                <TextField
                    variant="outlined"
                    value={customCondition}
                    onChange={(e) => setCustomCondition(e.target.value)}
                    style={{ marginBottom: "10px", width: '100%'}}
                    placeholder="Have a condition that is not listed? Enter it here"
                />
                <Button 
                    variant="contained" 
                    onClick={handleAddCustomCondition}
                    style={{ marginLeft: '10px', padding: '10px' }}
                >
                    <FontAwesomeIcon icon={faPlus} style={{color:colorPalette.steelBlue, fontSize:'2em'}}/>
                </Button>
            </div>

            
            <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '10px' }}>
                {checkedConditions.filter(c => c.checked).map((condition, index) => (
                    <div key={index} style={{ display: 'flex', alignItems: 'center', margin: '5px' }}>
                        <Checkbox
                            checked={condition.checked}
                            onChange={() => handleCheckboxChange(condition.condition.name)}
                            style={{ color: '#454545' }}
                        />
                        <label style={{ color: 'steelblue' }}>{condition.condition.name}</label>
                    </div>
                ))}
            </div>
        </div>

                        <div className="dob-container" style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <label style={{marginTop:'10px'}} className="question-label">
                                Which medications do you take?
                            </label>
                            <FormControl 
                            key="Medications" 
                            fullWidth 
                            variant="outlined" 
                            style={{ marginBottom: "10px" }}
                            >
                            <InputLabel 
                            // shrink={!!selectedMedication}
                            >Medications</InputLabel>
                            <Select
                                value={selectedMedication} // Make sure there's an initial value for the select
                                onChange={(e) => {
                                const selectedName = e.target.value as string;
                                handleMedicationCheckboxChange(selectedName);
                                // setSelectedMedication(selectedName)
                                }}
                                label="Medications" // Link the label to the Select component
                            >
                                <MenuItem disabled value="">
                                Select A Medicaiton
                                </MenuItem>
                                {checkedMedications
                                    .sort((a, b) => a.medication.name.localeCompare(b.medication.name)) // Sort alphabetically by name
                                    .map((medication, index) => (
                                <MenuItem 
                                    style={{ width: '100%' }}
                                    key={medication.medication.name} 
                                    value={medication.medication.name}
                                >
                                    {medication.medication.name}
                                </MenuItem>
                                ))}
                            </Select>
                            </FormControl>
                            <div style={{display:'flex', flexDirection:'row', width:'100%', justifyContent:'center', alignItems:'center'}}>
                                <TextField
                                    variant="outlined"
                                    value={customMedication}
                                    onChange={(e) => setCustomMedication(e.target.value)}
                                    style={{ marginBottom: "10px", width: '100%'}}
                                    placeholder="Have a medication that is not listed? Enter it here"
                                />
                                <Button 
                                    variant="contained" 
                                    onClick={handleAddCustomMedication}
                                    style={{ marginLeft: '10px', padding: '10px' }}
                                >
                                    <FontAwesomeIcon icon={faPlus} style={{color:colorPalette.steelBlue, fontSize:'2em'}}/>
                                </Button>
                            </div>
                            <div style={{ display: 'flex',}}>
                            {checkedMedications.filter(m=>m.checked).map((medication:CheckedMedication, index) => (
                                    <div key={index} style={{ width: '25%', marginLeft:'2em', marginRight:'2em',display: 'flex', alignItems: 'center'}}>
                                    <Checkbox 
                                        checked={medication.checked}
                                        onChange={() => handleMedicationCheckboxChange(medication.medication.name)}
                                        style={{color:'#454545'}}/>
                                    <label style={{color:colorPalette.steelBlue}}>{medication.medication.name}</label>
                                    </div>
                            ))}
                            </div>
                        </div>
                        <label style={{marginTop:'20px', color:'red'}} className="question-label">
                            By continuing you agree that you have been signed off to complete this exercise referral pathway by your doctor
                        </label>
                    </div>
                    <div style={{width:'100%', display:'flex', justifyContent:'space-between', borderRadius:'5px',position:'absolute', bottom:0}}>
                        <Button className="form-back-button" onClick={prevStage}>Previous</Button>
                        <Button className="form-next-button" onClick={handleNextStage}>Next Step</Button>
                    </div>
                </div>
                )
            case 5:
                return (
                <div className="form-stage" style={{display:'flex', flexDirection:'column', overflowY:'scroll', color:'#454545', maxHeight:'70vh'}}>
                <h2 style={{fontSize:'2em', alignSelf:'center', marginBottom:'1vh', marginTop:'2vh', color:'#454545'}} className="parq-title">Basic Information</h2>
                <label >Let's learn about you</label>
                <div className="dob-container" style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width:'75%'}}>
                            <label className="question-label">
                                What is your date of birth?
                            </label>
                            <TextField
                                label="Date Of Birth"
                                type="date"
                                fullWidth
                                variant="outlined"
                                value={dob} 
                                onChange={(e)=>setDOB(e.currentTarget.value)} 
                                InputLabelProps={{ shrink: true }}
                                style={{ fontSize: "1.2em", backgroundColor: "transparent" }}
                                />
                </div>
                <div className="dob-container" style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width:'75%'}}>
                    <label style={{marginTop:'20px'}} className="question-label">
                        What is your gender?
                    </label>
                    <FormControl 
                            key="Gender" 
                            fullWidth 
                            variant="outlined" 
                            >
                            <InputLabel shrink={!!gender}>Gender</InputLabel>
                            <Select
                                value={gender} 
                                onChange={(e)=>{
                                    const selectedValue = e.target.value as string;
                                    setGender(selectedValue)
                                }}
                                label="Gender" // Link the label to the Select component
                            >
                                <MenuItem disabled selected value="">
                                Select A Gender
                                </MenuItem>
                                <MenuItem value="m">Male</MenuItem>
                                <MenuItem value="f">Female</MenuItem>
                                <MenuItem value="o">Other</MenuItem>
                                <MenuItem value="p">Prefer Not To Say</MenuItem>


                            </Select>
                    </FormControl>
                </div>
                <label className="question-label" style={{color:'#454545'}}>What Is Your Height? (cm)</label>
                    <TextField
                        label="Height"
                        type="number"
                        variant="outlined"
                        value={height || ""}  // Default to empty string if surgeryDate is not set
                        onChange={(e) => setHeight(Number(e.target.value))}
                        InputLabelProps={{ shrink: true }}
                        style={{ fontSize: "1.2em", backgroundColor: "transparent", width:'75%'}}
                        />
                <label className="question-label"style={{color:'#454545'}}>What Is Your Weight? (kg)</label>
                <TextField
                    label="Weight"
                    type="number"
                    fullWidth
                    variant="outlined"
                    value={weight || ""}  // Default to empty string if surgeryDate is not set
                    onChange={(e) => setWeight(Number(e.target.value))}
                    InputLabelProps={{ shrink: true }}
                    style={{ fontSize: "1.2em", backgroundColor: "transparent", width:'75%'}}
                    />
                <label className="question-label"style={{color:'#454545'}}>What Is Your Waist Circumferences? (cm)</label>
                <TextField
                    label="Waist"
                    type="number"
                    fullWidth
                    variant="outlined"
                    value={waist || ""}  // Default to empty string if surgeryDate is not set
                    onChange={(e) => setWaist(Number(e.target.value))}
                    InputLabelProps={{ shrink: true }}
                    style={{ fontSize: "1.2em", backgroundColor: "transparent", width:'75%'}}
                    />
            {/* Blood Pressure */}
            <label className="question-label" style={{ color: "#454545" }}>
                What Is Your Blood Pressure? (mmHg)
            </label>
            <TextField
                label="BP"
                type="text"
                fullWidth
                variant="outlined"
                value={bp || ""}
                onChange={(e) => setBp(e.target.value)}
                InputLabelProps={{ shrink: true }}
                style={{ fontSize: "1.2em", backgroundColor: "transparent", width: "75%" }}
            />

            {/* HbA1c */}
            <label className="question-label" style={{ color: "#454545" }}>
                What Is Your HbA1c Level? (mmols/mol)
            </label>
            <TextField
                label="HbA1c"
                type="number"
                fullWidth
                variant="outlined"
                value={hba1c || ""}
                onChange={(e) => setHba1c(Number(e.target.value))}
                InputLabelProps={{ shrink: true }}
                style={{ fontSize: "1.2em", backgroundColor: "transparent", width: "75%" }}
            />

            {/* HDL */}
            <label className="question-label" style={{ color: "#454545" }}>
                What Is Your HDL Level? (mmol/L)
            </label>
            <TextField
                label="HDL"
                type="number"
                fullWidth
                variant="outlined"
                value={hdl || ""}
                onChange={(e) => setHdl(Number(e.target.value))}
                InputLabelProps={{ shrink: true }}
                style={{ fontSize: "1.2em", backgroundColor: "transparent", width: "75%" }}
            />

            {/* LDL */}
            <label className="question-label" style={{ color: "#454545" }}>
                What Is Your LDL Level? (mmol/L)
            </label>
            <TextField
                label="LDL"
                type="number"
                fullWidth
                variant="outlined"
                value={ldl || ""}
                onChange={(e) => setLdl(Number(e.target.value))}
                InputLabelProps={{ shrink: true }}
                style={{ fontSize: "1.2em", backgroundColor: "transparent", width: "75%" }}
            />

            {/* Cholesterol */}
            <label className="question-label" style={{ color: "#454545" }}>
                What Is Your Total Cholesterol Level? (mmol/L)
            </label>
            <TextField
                label="Cholesterol"
                type="number"
                fullWidth
                variant="outlined"
                value={cholesterol || ""}
                onChange={(e) => setCholesterol(Number(e.target.value))}
                InputLabelProps={{ shrink: true }}
                style={{ fontSize: "1.2em", backgroundColor: "transparent", width: "75%" }}
            />
                <div style={{width:'100%', display:'flex', justifyContent:'space-between', borderRadius:'5px',position:'absolute', bottom:0}}>
                    <Button className="form-back-button" onClick={prevStage}>Previous</Button>
                    <Button className="form-next-button" type="submit" onClick={()=>handleInfoStageNext()}>Next Step</Button>
                </div>
                </div>
                )
            case 6:
                return (
                <div className="form-stage" style={{display:'flex', flexDirection:'column', overflowY:'scroll', color:'#454545',  minHeight:'70vh',maxHeight:'70vh'}}>
                <h2 style={{fontSize:'2em', alignSelf:'center', marginBottom:'1vh', marginTop:'2vh', color:'#454545'}} className="parq-title">Health Questions</h2>
                <label >Let's learn about your health</label>
                
                <div style={{paddingLeft:'30px',paddingRight:'30px', display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>
                    <label className="question-label" style={{color: '#454545'}}>
                        How Often Do You Smoke?
                    </label>
                    <Slider
                        defaultValue={0}
                        value={smoking}
                        onChange={(e: Event, value: number | number[]) => setSmoking(value as number)}
                        step={1}
                        min={0}
                        max={5}
                        marks={[
                            { value: 0, label: "I don't smoke" },
                            { value: 1, label: "Rarely" },
                            { value: 2, label: "Occasionally" },
                            { value: 3, label: "Sometimes" },
                            { value: 4, label: "Often" },
                            { value: 5, label: "I smoke every day" },
                        ]}
                        valueLabelDisplay="off" // Hides numeric labels
                        sx={{
                            ...sliderStyling,
                            '& .MuiSlider-markLabel': {
                                marginTop: '40px', // Adds spacing below the slider for labels
                                fontSize: '0.85em', // Optional: Adjusts font size of labels
                                color: '#454545', // Optional: Matches label text color
                            },
                        }}
                    />
                </div>
                <div style={{paddingLeft:'30px',paddingRight:'30px', marginTop:'20px',display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>
                    <label className="question-label" style={{color: '#454545'}}>
                        How Often Do You Drink?
                    </label>
                    <Slider
                        defaultValue={0}
                        value={drinking}
                        onChange={(e: Event, value: number | number[]) => setDrinking(value as number)}
                        step={1}
                        min={0}
                        max={5}
                        marks={[
                            { value: 0, label: "I don't drink" },
                            { value: 1, label: "Rarely" },
                            { value: 2, label: "Occasionally" },
                            { value: 3, label: "Sometimes" },
                            { value: 4, label: "Often" },
                            { value: 5, label: "I drink every day" },
                        ]}
                        valueLabelDisplay="off" // Hides numeric labels
                        sx={{
                            ...sliderStyling,
                            '& .MuiSlider-markLabel': {
                                marginTop: '40px', // Adds spacing below the slider for labels
                                fontSize: '0.85em', // Optional: Adjusts font size of labels
                                color: '#454545', // Optional: Matches label text color
                            },
                        }}
                    />
                <label className="question-label"style={{color:'#454545', marginTop:'60px'}}>On Average, How Many Units Do You Drink Per Week?</label>
                <TextField
                    label="Units"
                    type="number"
                    fullWidth
                    variant="outlined"
                    value={averageWeeklyUnits || 0}  // Default to empty string if surgeryDate is not set
                    onChange={(e) => setAverageWeeklyUnits(Number(e.target.value))}
                    InputLabelProps={{ shrink: true }}
                    style={{ fontSize: "1.2em", backgroundColor: "transparent", width:'100%', marginTop:'20px'}}
                    />
                </div>
                <div style={{paddingLeft:'30px',paddingRight:'30px', marginTop:'20px',display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>
                    <label className="question-label" style={{color: '#454545'}}>
                        How would you describe your exercise history?
                    </label>
                    <Slider
                        defaultValue={0}
                        value={exerciseHistory}
                        onChange={(e: Event, value: number | number[]) => setExerciseHistory(value as number)}
                        step={1}
                        min={0}
                        max={5}
                        marks={[
                            { value: 0, label: "Very rarely exercise" },
                            { value: 1, label: "Rarely exercise" },
                            { value: 2, label: "Occasionally exercise" },
                            { value: 3, label: "Sometimes exercise" },
                            { value: 4, label: "Exercise often" },
                            { value: 5, label: "Exercise every day" },
                        ]}
                        valueLabelDisplay="off" // Hides numeric labels
                        sx={{
                            ...sliderStyling,
                            '& .MuiSlider-markLabel': {
                                marginTop: '40px', // Adds spacing below the slider for labels
                                fontSize: '0.85em', // Optional: Adjusts font size of labels
                                color: '#454545', // Optional: Matches label text color
                            },
                        }}
                    />
                </div>
                <div style={{width:'100%', display:'flex', justifyContent:'space-between', borderRadius:'5px',position:'absolute', bottom:0}}>
                    <Button className="form-back-button" onClick={prevStage}>Previous</Button>
                    <Button className="form-next-button" onClick={nextStage}>Next Step</Button>
                </div>
                </div>
                )
            case 7:
                return (
                    <div className="form-stage" style={{display:'flex', flexDirection:'column', overflowY:'scroll', color:'#454545',  height:'70vh',maxHeight:'75vh', overflowX:'hidden'}}>
                        <h2 style={{ fontSize: '2em', alignSelf: 'center', marginBottom: '1vh', marginTop: '2vh', color: '#454545' }} className="parq-title">
                            {phq9.testName}
                        </h2>
                        <label>{phq9.description}</label>
                        
                        <div style={{ paddingLeft: '30px', paddingRight: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                            {phq9.questions.map((question, index) => (
                                <div key={index} style={{ marginBottom: '60px', width: '100%' }}>
                                    <label className="question-label" style={{ color: '#454545' }}>{`Q${index+1}. ${question.question}`}</label>
                                    <Slider
                                        defaultValue={0}
                                        value={phq9Result[index]}
                                        onChange={(e, value) => handleSliderChange(index, value as number)}
                                        step={1}
                                        min={0}
                                        max={3}
                                        marks={[
                                            { value: 0, label: "Not at all" },
                                            { value: 1, label: "Several days" },
                                            { value: 2, label: "More than half the days" },
                                            { value: 3, label: "Nearly every day" }
                                        ]}
                                        valueLabelDisplay="off"
                                        sx={{
                                            '& .MuiSlider-markLabel': {
                                                marginTop: '20px',
                                                fontSize: '0.85em',
                                                color: '#454545'
                                            },
                                        }}
                                    />
                                </div>
                            ))}
                        </div>

                    <div style={{width:'100%', display:'flex', justifyContent:'space-between', borderRadius:'5px',position:'absolute', bottom:0}}>
                        <Button className="form-back-button" onClick={prevStage}>Previous</Button>
                        <Button className="form-next-button" onClick={nextStage}>Next Step</Button>
                    </div>
                    </div>
                    )
            case 8:
                return (
                    <div className="form-stage" style={{display:'flex', flexDirection:'column', overflowY:'scroll', color:'#454545', width:'70em', height:'70vh',maxHeight:'75vh', overflowX:'hidden'}}>
                        <h2 style={{ fontSize: '2em', alignSelf: 'center', marginBottom: '1vh', marginTop: '2vh', color: '#454545' }} className="parq-title">
                            {gad7.testName}
                        </h2>
                        <label>{gad7.description}</label>
                        
                        <div style={{ paddingLeft: '30px', paddingRight: '30px', display: 'flex', justifyContent: 'center', width:'100%',alignItems: 'center', flexDirection: 'column' }}>
                            {gad7.questions.map((question, index) => (
                                <div key={index} style={{ marginBottom: '60px', width: '100%' }}>
                                    <label className="question-label" style={{ color: '#454545' }}>{`Q${index+1}. ${question.question}`}</label>
                                    <Slider
                                        defaultValue={0}
                                        value={gad7Result[index]}
                                        onChange={(e, value) => handleCad9SliderChange(index, value as number)}
                                        step={1}
                                        min={0}
                                        max={3}
                                        marks={[
                                            { value: 0, label: "Not at all" },
                                            { value: 1, label: "Several days" },
                                            { value: 2, label: "More than half the days" },
                                            { value: 3, label: "Nearly every day" }
                                        ]}
                                        valueLabelDisplay="off"
                                        sx={{
                                            '& .MuiSlider-markLabel': {
                                                marginTop: '20px',
                                                fontSize: '0.85em',
                                                color: '#454545'
                                            },
                                        }}
                                    />
                                </div>
                            ))}
                        </div>

                    <div style={{width:'100%', display:'flex', justifyContent:'space-between', borderRadius:'5px',position:'absolute', bottom:0}}>
                        <Button className="form-back-button" onClick={prevStage}>Previous</Button>
                        <Button className="form-next-button" onClick={nextStage}>Next Step</Button>
                    </div>
                    </div>
                    )
            case 9:
                    if (generatingPlan){
                        return (
                            <div className="form-stage" style={{maxHeight:'70vh', overflowY:'scroll', color:'#454545', display:'flex', justifyContent:'center', alignItems:'center', height:'100vh'}}>
                            <Spinner text="Generating Your Custom Plan!"/>
                        </div>
                        )
                    }else{
                        if (generatedPlan){
                            return (
                                <div style={{width:'86em',height:'74vh',backgroundColor:'#fefefe'}}>        
                                <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', backgroundColor:`${colorPalette.default}88`, width:'100%', height:'8vh', borderRadius:'0'}}>
                                    <div style={{backgroundColor:'transparent',  display:'flex', alignItems:'center', width:'100%'}}>
                                        {/* <FontAwesomeIcon className="back-button" style={{width:'2em', height:'2em', marginLeft:'0.75em', padding:'5px'}} onClick={()=>regeneratePlan()} icon={faRotateRight}></FontAwesomeIcon> */}
                                        {/* <label style={{fontWeight:'bold', fontSize:'1.3em', marginLeft:'8px'}}>Generate A New Plan</label> */}
                                    </div>
                                </div>
                                <div style={{display:'flex', width:'100%', height:'90%', flexDirection:'row', alignItems:'flex-start'}}>
                
                                        <div style={{display:'flex', width:'100%', height:'100%', flex:1, backgroundColor:'#f1f1f1', flexDirection:'column'}}>
                                            <label style={{color:'#565656', marginTop: '10px', padding:'5px 20px', fontSize:'1.5em', fontWeight:'bold', fontStyle:'italic'}}>{currentUser.currentVitovaPlan.title.toUpperCase()}</label>
                                            <label style={{color:'#565656', fontSize:'1.125em', textAlign:'justify', padding:'0px 20px',}}>{currentUser.currentVitovaPlan.description.slice(0, 400)}</label>
                
                                            <div style={{display: 'flex', marginTop:'1vh',margin:'10px 20px',alignItems: 'flex-start', flexDirection:'column', justifyContent:'flex-start'}}>
                                        <div style={{backgroundColor: `${colorPalette.steelBlue}dd`, padding: '6px 10px', width:'100%',borderRadius: '3px', marginTop: '5px', color: '#fff', fontWeight: 'bold'}}>
                                            {`${currentUser.currentVitovaPlan.content.length} WEEKS`}
                                        </div>
                                    </div>
                                    <button className="set-plan-button" 
                                                style={{
                                                    fontWeight: 'bold', 
                                                    color: '#fff', 
                                                    fontStyle:'italic',
                                                    padding: '10px 15px', 
                                                    borderRadius: '3px', 
                                                    border: 'none', 
                                                    cursor: 'pointer', 
                                                    width: 'auto',
                                                    transition: 'background-color 0.3s',
                                                    margin:'0px 20px',
                                                    fontSize:'1.25em'
                                                }}
                                                onClick={() => {
                                                    onInfoSet(currentUser); // Call onInfoSet with currentUser
                                                }}
                                                onMouseOver={(e) => e.currentTarget.style.backgroundColor = `steelblue`}
                                                onMouseOut={(e) => e.currentTarget.style.backgroundColor = colorPalette.steelBlue}
                                        >
                                            {`GO TO DASHBOARD`}
                                        </button>
                                        </div>
                                        <div style={{display:'flex', width:'100%', height:'100%', flex:2, flexDirection:'column', alignItems:'flex-start', justifyContent:'center'}}>
                                            <label style={{color:'#565656', marginTop: '10px', marginLeft:'1em', fontSize:'1.5em', fontWeight:'bold'}}>WEEK 1 TRAINING LOAD OVERVIEW</label>
                                            <GeneralChartComponent mh={80} mw={100} 
                                            chartData={currentUser.currentVitovaPlan.content[0].days.map(day=>getDayStressScore(day, user, false))} 
                                            labelData={currentUser.currentVitovaPlan.content[0].days.map((day:VitovaDay, index:number)=>`Day ${index+1}`)}

                                            user={currentUser}></GeneralChartComponent>
                                        </div>
                                    </div>
                            </div>
                            )
                        }else{
                            if (checkedConditions.filter(c=>c.checked === true).length <= 0){
                                return (
                                <div className="form-stage" style={{maxHeight:'70vh', overflowY:'scroll', color:'#454545', display:'flex', justifyContent:'flex-start', alignItems:'center', height:'100vh'}}>
                                <h2 style={{fontSize:'2em', alignSelf:'center', marginBottom:'5vh', marginTop:'2vh', color:'#454545'}} className="main-title">Review Your Answers Before Submitting</h2>
                                <table style={{width: '60em', borderCollapse: 'collapse'}}>
                                    <thead>
                                        <tr>
                                            <th style={{fontSize:'1.25em', padding:'0.75em', borderBottom: '2px solid #000'}}>Height</th>
                                            <th style={{fontSize:'1.25em', padding:'0.75em', borderBottom: '2px solid #000'}}>Weight</th>
                                            <th style={{fontSize:'1.25em', padding:'0.75em', borderBottom: '2px solid #000'}}>Waist</th>
                                            <th style={{fontSize:'1.25em', padding:'0.75em', borderBottom: '2px solid #000'}}>Conditions</th>
                                            <th style={{fontSize:'1.25em', padding:'0.75em', borderBottom: '2px solid #000'}}>Medications</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr  key={1}>
                                            <td style={{fontSize:'18px', padding:'0.75em', borderBottom: '1px solid #ddd', verticalAlign:'top'}}>{height}</td>
                                            <td style={{fontSize:'18px', padding:'0.75em', borderBottom: '1px solid #ddd', verticalAlign:'top'}}>{weight}</td>
                                            <td style={{fontSize:'18px', padding:'0.75em', borderBottom: '1px solid #ddd', verticalAlign:'top'}}>{waist}</td>
                                            {checkedConditions.filter(c=>c.checked).length > 0 && 
                                                <td style={{fontSize:'18px', padding:'0.75em', borderBottom: '1px solid #ddd', verticalAlign:'top'}}>
                                                    <ul>
                                                        {checkedConditions.map((c, index) => (
                                                            c.checked &&
                                                            <li key={index}>{c.condition.name}</li>
                                                        ))}
                                                    </ul>
                                                </td>
                                            }
                                            {checkedConditions.filter(c=>c.checked).length <= 0 && 
                                                <td style={{fontSize:'18px', padding:'0.75em', borderBottom: '1px solid #ddd', verticalAlign:'top'}}>None</td>
                                            }
                                            {checkedMedications.filter(c=>c.checked).length > 0 && 
                                                <td style={{fontSize:'18px', padding:'0.75em', borderBottom: '1px solid #ddd'}}>
                                                    <ul>
                                                        {checkedMedications.map((m, index) => (
                                                            m.checked &&
                                                            <li key={index}>{m.medication.name}</li>
                                                        ))}
                                                    </ul>
                                                </td>
                                            }
                                            {checkedMedications.filter(c=>c.checked).length <= 0 && 
                                                <td style={{fontSize:'18px', padding:'0.75em', borderBottom: '1px solid #ddd', verticalAlign:'top'}}>None</td>
                                            }
                                        </tr>
                                    </tbody>
                                </table>
                                
                                <div style={{width:'100%', display:'flex', justifyContent:'space-between', borderRadius:'5px',position:'absolute', bottom:0}}>
                                    <Button className="form-back-button" onClick={prevStage}>Previous</Button>
                                    <Button className="form-next-button" onClick={handleSubmitClick}>Finish And Generate My Plan!</Button>
                                </div>
                                </div>
                                )
                            }else{
                                return (
                                    <div className="form-stage" style={{maxHeight:'70vh', overflowY:'scroll', color:'#454545', display:'flex', justifyContent:'flex-start', alignItems:'center', height:'100vh'}}>
                                    <h2 style={{fontSize:'2em', alignSelf:'center', marginBottom:'5vh', marginTop:'2vh', color:'#454545'}} className="main-title">Review Your Answers Before Submitting</h2>
                                    <table style={{width: '60em', borderCollapse: 'collapse'}}>
                                        <thead>
                                            <tr>
                                                <th style={{fontSize:'1.25em', padding:'0.75em', borderBottom: '2px solid #000'}}>Height</th>
                                                <th style={{fontSize:'1.25em', padding:'0.75em', borderBottom: '2px solid #000'}}>Weight</th>
                                                <th style={{fontSize:'1.25em', padding:'0.75em', borderBottom: '2px solid #000'}}>Waist</th>
                                                <th style={{fontSize:'1.25em', padding:'0.75em', borderBottom: '2px solid #000'}}>Conditions</th>
                                                <th style={{fontSize:'1.25em', padding:'0.75em', borderBottom: '2px solid #000'}}>Medications</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr  key={1}>
                                                <td style={{fontSize:'18px', padding:'0.75em', borderBottom: '1px solid #ddd', verticalAlign:'top'}}>{height}</td>
                                                <td style={{fontSize:'18px', padding:'0.75em', borderBottom: '1px solid #ddd', verticalAlign:'top'}}>{weight}</td>
                                                <td style={{fontSize:'18px', padding:'0.75em', borderBottom: '1px solid #ddd', verticalAlign:'top'}}>{waist}</td>
                                                {checkedConditions.filter(c=>c.checked).length > 0 && 
                                                    <td style={{fontSize:'18px', padding:'0.75em', borderBottom: '1px solid #ddd', verticalAlign:'top'}}>
                                                        <ul>
                                                            {checkedConditions.map((c, index) => (
                                                                c.checked &&
                                                                <li key={index}>{c.condition.name}</li>
                                                            ))}
                                                        </ul>
                                                    </td>
                                                }
                                                {checkedConditions.filter(c=>c.checked).length <= 0 && 
                                                    <td style={{fontSize:'18px', padding:'0.75em', borderBottom: '1px solid #ddd', verticalAlign:'top'}}>None</td>
                                                }
                                                {checkedMedications.filter(c=>c.checked).length > 0 && 
                                                    <td style={{fontSize:'18px', padding:'0.75em', borderBottom: '1px solid #ddd'}}>
                                                        <ul>
                                                            {checkedMedications.map((m, index) => (
                                                                m.checked &&
                                                                <li key={index}>{m.medication.name}</li>
                                                            ))}
                                                        </ul>
                                                    </td>
                                                }
                                                {checkedMedications.filter(c=>c.checked).length <= 0 && 
                                                    <td style={{fontSize:'18px', padding:'0.75em', borderBottom: '1px solid #ddd', verticalAlign:'top'}}>None</td>
                                                }
                                            </tr>
                                        </tbody>
                                    </table>
                                    
                                    <div style={{width:'100%', display:'flex', justifyContent:'space-between', borderRadius:'5px',position:'absolute', bottom:0}}>
                                        <Button className="form-back-button" onClick={prevStage}>Previous</Button>
                                        <Button className="form-next-button" onClick={handleSubmitClick}>Find Me My Plan!</Button>
                                    </div>
                                    </div>
                                )
                            }
                        }
                    }
            default:
                return <></>
        }   

    }

    const renderHealthAndWellbeingPathway = () => {
        switch (questionStage){
            case 1:
                return (
                    <div className="form-stage" style={{display:'flex', flexDirection:'column', alignItems:'center', maxWidth:'70em'}}>
                    <h2 style={{ fontSize: "2em", alignSelf: "center", marginBottom: "2vh", color: "#454545" }} className="main-title">
                        Terms and Conditions
                    </h2>
        
                    <label style={{ fontSize: "1em", textAlign: "center", padding: "10px 5px", backgroundColor: "white", borderRadius: "5px", color: "#454545"}}>
                        {disclaimerText}
                    </label>
        
                    <div style={{ width: "100%", display: "flex", justifyContent: "center", marginTop: "20px" }}>
                        <Button variant="outlined" onClick={()=>setOpen('t&c')}>View Full Terms and Conditions</Button>
                        <Button variant="outlined" onClick={()=>setOpen('pp')}>View Privacy Policy</Button>
                    </div>
                    <div style={{ width: "100%", display: "flex", color:colorPalette.steelBlue,justifyContent: "center", marginTop: "10px", flexDirection: "row", alignItems: "center" }}>
                        <label style={{}}>
                            <Checkbox checked={tcChecked} onChange={()=>setTcChecked(!tcChecked)} style={{marginBottom:'3px'}} /> I Have Read The Terms And Conditions
                        </label>
                        <label style={{marginLeft:'30px'}}>
                            <Checkbox checked={ppChecked} onChange={()=>setPpChecked(!ppChecked)} /> I Have Read The Privacy Policy
                        </label>
                    </div>

                    <Dialog open={open!==undefined} onClose={handleClose} maxWidth="md" fullWidth>
                        <DialogTitle>{open === 't&c' ? 'Terms And Conditions':'Privacy Policy'}</DialogTitle>
                        <DialogContent dividers>
                                <iframe
                                src={open === 't&c' ? pdfFile:privacyPolicyPdf}
                                width="100%"
                                height="600px"
                                title={open === 't&c' ? 'T&Cs':'PrivacyPolicy'}
                                style={{ border: "none" }}
                                ></iframe>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose}>Close</Button>
                        </DialogActions>
                    </Dialog>
        
                    <div style={{ width: "100%", display: "flex", justifyContent: "space-between", borderRadius: "5px", position: "absolute", bottom: 0 }}>
                        <Button className="form-back-button" onClick={logout}>Logout</Button>
                        <Button className="form-next-button" onClick={()=>tcChecked && ppChecked ? nextStage():alert('Agreement with our terms and conditons and privacy policy is mandatory')}>Accept Conditions</Button>
                    </div>
                </div>
                )
            case 2:
                return renderPathwayOptions()
            case 3:
                // Please can you confirm your healthcare details for us:​
                // Date of surgery.​
                // Expected surgery.​
                // Health conditions / past medical history (drop down bar?).​
                // Medications (drop down bar).​
                // Have you been signed off to complete this exercise referral pathway by your doctor?
                return(
                <div className="form-stage" style={{display:'flex', flexDirection:'column', overflowY:'scroll', maxWidth:'70em'}}>
                    <div style={{display:'flex', justifyContent:'flex-start', flexDirection:'column', height:'70vh', width:'90%'}}>
                        <h2 style={{fontSize:'2em', alignSelf:'center', marginBottom:'1vh', marginTop:'2vh', color:'#454545'}} className="main-title">Basic Information</h2>
                        <label style={{textAlign:'center', color:'#454545'}}>Please can you confirm your healthcare details for us</label>
                        <div className="dob-container" style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <label className="question-label">
                                When is your target?
                            </label>
                            <TextField
                                label="Target Date"
                                type="date"
                                fullWidth
                                variant="outlined"
                                value={surgeryDate ? surgeryDate : ""}  // Default to empty string if surgeryDate is not set
                                onChange={(e) => {
                                    setSurgeryDate(e.currentTarget.value);
                                    setError(false);
                                }}
                                InputLabelProps={{ shrink: true }}
                                style={{ fontSize: "1.2em", backgroundColor: "transparent",}}
                            />

                            {!surgeryDate ? (
                                <p style={{ color: 'steelblue', fontWeight:'bold', fontStyle:'italic',marginTop: '0.5em' }}>
                                    Please select a date for your target.
                                </p>
                            ) : isNaN(new Date(surgeryDate).getTime()) ? (
                                <p style={{ color: 'steelblue', fontWeight:'bold', fontStyle:'italic',marginTop: '0.5em' }}>
                                    Invalid date selected. Please choose a valid date.
                                </p>
                            ) : 
                            isNaN(new Date(surgeryDate).getTime()) || new Date(surgeryDate) <= new Date() ? (
                                <p style={{ color: 'steelblue', fontWeight: 'bold', fontStyle: 'italic', marginTop: '0.5em' }}>
                                    Invalid date selected. Please choose a valid future date.
                                </p>
                            ) : (
                                <p style={{ color: 'steelblue', fontWeight:'bold', fontStyle:'italic',marginTop: '0.5em' }}>
                                    {`YOUR TARGET IS IN ${Math.ceil(
                                        (new Date(surgeryDate).getTime() - Date.now()) / (1000 * 60 * 60 * 24)
                                    )} DAYS!`}
                                </p>
                            )}
 
                            {error && <p style={{ color: 'red', marginTop: '0.5em' }}>A valid date is required</p>}

                        </div>
                        <div className="dob-container" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <label style={{ marginTop: '10px' }} className="question-label">
                Which conditions do you have?
            </label>
            <FormControl fullWidth variant="outlined" style={{ marginBottom: "10px"}}>
                <InputLabel 
                // shrink={!!selectedCondition}
                >Conditions</InputLabel>
                <Select
                    value={selectedCondition}
                    onChange={(e) => {
                        const selectedName = e.target.value;
                        handleCheckboxChange(selectedName);
                        // setSelectedCondition(selectedName);
                    }}
                    label="Conditions"
                >
                    <MenuItem disabled value="">Select A Condition</MenuItem>
                    {checkedConditions
                    .sort((a, b) => a.condition.name.localeCompare(b.condition.name)) // Sort alphabetically by name
                    .map((condition, index) => (
                        <MenuItem key={index} value={condition.condition.name}>
                        {condition.condition.name}
                        </MenuItem>
                    ))}

                </Select>
            </FormControl>
            <div style={{display:'flex', flexDirection:'row', width:'100%', justifyContent:'center', alignItems:'center'}}>
                <TextField
                    variant="outlined"
                    value={customCondition}
                    onChange={(e) => setCustomCondition(e.target.value)}
                    style={{ marginBottom: "10px", width: '100%'}}
                    placeholder="Have a condition that is not listed? Enter it here"
                />
                <Button 
                    variant="contained" 
                    onClick={handleAddCustomCondition}
                    style={{ marginLeft: '10px', padding: '10px' }}
                >
                    <FontAwesomeIcon icon={faPlus} style={{color:colorPalette.steelBlue, fontSize:'2em'}}/>
                </Button>
            </div>

            
            <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '10px' }}>
                {checkedConditions.filter(c => c.checked).map((condition, index) => (
                    <div key={index} style={{ display: 'flex', alignItems: 'center', margin: '5px' }}>
                        <Checkbox
                            checked={condition.checked}
                            onChange={() => handleCheckboxChange(condition.condition.name)}
                            style={{ color: '#454545' }}
                        />
                        <label style={{ color: 'steelblue' }}>{condition.condition.name}</label>
                    </div>
                ))}
            </div>
        </div>

                        <div className="dob-container" style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <label style={{marginTop:'10px'}} className="question-label">
                                Which medications do you take?
                            </label>
                            <FormControl 
                            key="Medications" 
                            fullWidth 
                            variant="outlined" 
                            style={{ marginBottom: "10px" }}
                            >
                            <InputLabel 
                            // shrink={!!selectedMedication}
                            >Medications</InputLabel>
                            <Select
                                value={selectedMedication} // Make sure there's an initial value for the select
                                onChange={(e) => {
                                const selectedName = e.target.value as string;
                                handleMedicationCheckboxChange(selectedName);
                                // setSelectedMedication(selectedName)
                                }}
                                label="Medications" // Link the label to the Select component
                            >
                                <MenuItem disabled value="">
                                Select A Medicaiton
                                </MenuItem>
                                {checkedMedications
                                    .sort((a, b) => a.medication.name.localeCompare(b.medication.name)) // Sort alphabetically by name
                                    .map((medication, index) => (
                                <MenuItem 
                                    style={{ width: '100%' }}
                                    key={medication.medication.name} 
                                    value={medication.medication.name}
                                >
                                    {medication.medication.name}
                                </MenuItem>
                                ))}
                            </Select>
                            </FormControl>
                            <div style={{display:'flex', flexDirection:'row', width:'100%', justifyContent:'center', alignItems:'center'}}>
                                <TextField
                                    variant="outlined"
                                    value={customMedication}
                                    onChange={(e) => setCustomMedication(e.target.value)}
                                    style={{ marginBottom: "10px", width: '100%'}}
                                    placeholder="Have a medication that is not listed? Enter it here"
                                />
                                <Button 
                                    variant="contained" 
                                    onClick={handleAddCustomMedication}
                                    style={{ marginLeft: '10px', padding: '10px' }}
                                >
                                    <FontAwesomeIcon icon={faPlus} style={{color:colorPalette.steelBlue, fontSize:'2em'}}/>
                                </Button>
                            </div>
                            <div style={{ display: 'flex',}}>
                            {checkedMedications.filter(m=>m.checked).map((medication:CheckedMedication, index) => (
                                    <div key={index} style={{ width: '25%', marginLeft:'2em', marginRight:'2em',display: 'flex', alignItems: 'center'}}>
                                    <Checkbox 
                                        checked={medication.checked}
                                        onChange={() => handleMedicationCheckboxChange(medication.medication.name)}
                                        style={{color:'#454545'}}/>
                                    <label style={{color:colorPalette.steelBlue}}>{medication.medication.name}</label>
                                    </div>
                            ))}
                            </div>
                        </div>
                        {/* <label style={{marginTop:'20px', color:'red'}} className="question-label">
                            By continuing you agree that you have been signed off to complete this exercise referral pathway by your doctor
                        </label> */}
                    </div>
                    <div style={{width:'100%', display:'flex', justifyContent:'space-between', borderRadius:'5px',position:'absolute', bottom:0}}>
                        <Button className="form-back-button" onClick={prevStage}>Previous</Button>
                        <Button className="form-next-button" onClick={nextStage}>Next Step</Button>
                    </div>
                </div>
                )
            case 4:
                return (
                <div className="form-stage" style={{display:'flex', flexDirection:'column', overflowY:'scroll', color:'#454545', maxHeight:'70vh', maxWidth:'85em'}}>
                <h2 style={{fontSize:'2em', alignSelf:'center', marginBottom:'1vh', marginTop:'2vh', color:'#454545'}} className="parq-title">Basic Information</h2>
                <label >Let's learn about you</label>
                <div className="dob-container" style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width:'75%'}}>
                            <label className="question-label">
                                What is your date of birth?
                            </label>
                            <TextField
                                label="Date Of Birth"
                                type="date"
                                fullWidth
                                variant="outlined"
                                value={dob} 
                                onChange={(e)=>setDOB(e.currentTarget.value)} 
                                InputLabelProps={{ shrink: true }}
                                style={{ fontSize: "1.2em", backgroundColor: "transparent" }}
                                />
                </div>
                <div className="dob-container" style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width:'75%'}}>
                    <label style={{marginTop:'20px'}} className="question-label">
                        What is your gender?
                    </label>
                    <FormControl 
                            key="Gender" 
                            fullWidth 
                            variant="outlined" 
                            >
                            <InputLabel shrink={!!gender}>Gender</InputLabel>
                            <Select
                                value={gender} 
                                onChange={(e)=>{
                                    const selectedValue = e.target.value as string;
                                    setGender(selectedValue)
                                }}
                                label="Gender" // Link the label to the Select component
                            >
                                <MenuItem disabled selected value="">
                                Select A Gender
                                </MenuItem>
                                <MenuItem value="m">Male</MenuItem>
                                <MenuItem value="f">Female</MenuItem>
                                <MenuItem value="o">Other</MenuItem>
                                <MenuItem value="p">Prefer Not To Say</MenuItem>


                            </Select>
                    </FormControl>
                </div>
                <label className="question-label" style={{color:'#454545'}}>What Is Your Height? (cm)</label>
                    <TextField
                        label="Height"
                        type="number"
                        variant="outlined"
                        value={height || ""}  // Default to empty string if surgeryDate is not set
                        onChange={(e) => setHeight(Number(e.target.value))}
                        InputLabelProps={{ shrink: true }}
                        style={{ fontSize: "1.2em", backgroundColor: "transparent", width:'75%'}}
                        />
                <label className="question-label"style={{color:'#454545'}}>What Is Your Weight? (kg)</label>
                <TextField
                    label="Weight"
                    type="number"
                    fullWidth
                    variant="outlined"
                    value={weight || ""}  // Default to empty string if surgeryDate is not set
                    onChange={(e) => setWeight(Number(e.target.value))}
                    InputLabelProps={{ shrink: true }}
                    style={{ fontSize: "1.2em", backgroundColor: "transparent", width:'75%'}}
                    />
                <label className="question-label"style={{color:'#454545'}}>What Is Your Waist Circumferences? (cm)</label>
                <TextField
                    label="Waist"
                    type="number"
                    fullWidth
                    variant="outlined"
                    value={waist || ""}  // Default to empty string if surgeryDate is not set
                    onChange={(e) => setWaist(Number(e.target.value))}
                    InputLabelProps={{ shrink: true }}
                    style={{ fontSize: "1.2em", backgroundColor: "transparent", width:'75%'}}
                    />
            {/* Blood Pressure */}
            <label className="question-label" style={{ color: "#454545" }}>
                What Is Your Blood Pressure? (mmHg)
            </label>
            <TextField
                label="BP"
                type="text"
                fullWidth
                variant="outlined"
                value={bp || ""}
                onChange={(e) => setBp(e.target.value)}
                InputLabelProps={{ shrink: true }}
                style={{ fontSize: "1.2em", backgroundColor: "transparent", width: "75%" }}
            />

            {/* HbA1c */}
            <label className="question-label" style={{ color: "#454545" }}>
                What Is Your HbA1c Level? (mmols/mol)
            </label>
            <TextField
                label="HbA1c"
                type="number"
                fullWidth
                variant="outlined"
                value={hba1c || ""}
                onChange={(e) => setHba1c(Number(e.target.value))}
                InputLabelProps={{ shrink: true }}
                style={{ fontSize: "1.2em", backgroundColor: "transparent", width: "75%" }}
            />

            {/* HDL */}
            <label className="question-label" style={{ color: "#454545" }}>
                What Is Your HDL Level? (mmol/L)
            </label>
            <TextField
                label="HDL"
                type="number"
                fullWidth
                variant="outlined"
                value={hdl || ""}
                onChange={(e) => setHdl(Number(e.target.value))}
                InputLabelProps={{ shrink: true }}
                style={{ fontSize: "1.2em", backgroundColor: "transparent", width: "75%" }}
            />

            {/* LDL */}
            <label className="question-label" style={{ color: "#454545" }}>
                What Is Your LDL Level? (mmol/L)
            </label>
            <TextField
                label="LDL"
                type="number"
                fullWidth
                variant="outlined"
                value={ldl || ""}
                onChange={(e) => setLdl(Number(e.target.value))}
                InputLabelProps={{ shrink: true }}
                style={{ fontSize: "1.2em", backgroundColor: "transparent", width: "75%" }}
            />

            {/* Cholesterol */}
            <label className="question-label" style={{ color: "#454545" }}>
                What Is Your Total Cholesterol Level? (mmol/L)
            </label>
            <TextField
                label="Cholesterol"
                type="number"
                fullWidth
                variant="outlined"
                value={cholesterol || ""}
                onChange={(e) => setCholesterol(Number(e.target.value))}
                InputLabelProps={{ shrink: true }}
                style={{ fontSize: "1.2em", backgroundColor: "transparent", width: "75%" }}
            />
                <div style={{width:'100%', display:'flex', justifyContent:'space-between', borderRadius:'5px',position:'absolute', bottom:0}}>
                    <Button className="form-back-button" onClick={prevStage}>Previous</Button>
                    <Button className="form-next-button" type="submit" onClick={()=>handleInfoStageNext()}>Next Step</Button>
                </div>
                </div>
                )
            case 5:
                return (
                <div className="form-stage" style={{display:'flex', flexDirection:'column', overflowY:'scroll', color:'#454545',  minHeight:'70vh',maxHeight:'70vh', maxWidth:'85em'}}>
                <h2 style={{fontSize:'2em', alignSelf:'center', marginBottom:'1vh', marginTop:'2vh', color:'#454545'}} className="parq-title">Health Questions</h2>
                <label >Let's learn about your health</label>
                
                <div style={{paddingLeft:'30px',paddingRight:'30px', display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>
                    <label className="question-label" style={{color: '#454545'}}>
                        How Often Do You Smoke?
                    </label>
                    <Slider
                        defaultValue={0}
                        value={smoking}
                        onChange={(e: Event, value: number | number[]) => setSmoking(value as number)}
                        step={1}
                        min={0}
                        max={5}
                        marks={[
                            { value: 0, label: "I don't smoke" },
                            { value: 1, label: "Rarely" },
                            { value: 2, label: "Occasionally" },
                            { value: 3, label: "Sometimes" },
                            { value: 4, label: "Often" },
                            { value: 5, label: "I smoke every day" },
                        ]}
                        valueLabelDisplay="off" // Hides numeric labels
                        sx={{
                            ...sliderStyling,
                            '& .MuiSlider-markLabel': {
                                marginTop: '40px', // Adds spacing below the slider for labels
                                fontSize: '0.85em', // Optional: Adjusts font size of labels
                                color: '#454545', // Optional: Matches label text color
                            },
                        }}
                    />
                </div>
                <div style={{paddingLeft:'30px',paddingRight:'30px', marginTop:'20px',display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>
                    <label className="question-label" style={{color: '#454545'}}>
                        How Often Do You Drink?
                    </label>
                    <Slider
                        defaultValue={0}
                        value={drinking}
                        onChange={(e: Event, value: number | number[]) => setDrinking(value as number)}
                        step={1}
                        min={0}
                        max={5}
                        marks={[
                            { value: 0, label: "I don't drink" },
                            { value: 1, label: "Rarely" },
                            { value: 2, label: "Occasionally" },
                            { value: 3, label: "Sometimes" },
                            { value: 4, label: "Often" },
                            { value: 5, label: "I drink every day" },
                        ]}
                        valueLabelDisplay="off" // Hides numeric labels
                        sx={{
                            ...sliderStyling,
                            '& .MuiSlider-markLabel': {
                                marginTop: '40px', // Adds spacing below the slider for labels
                                fontSize: '0.85em', // Optional: Adjusts font size of labels
                                color: '#454545', // Optional: Matches label text color
                            },
                        }}
                    />
                <label className="question-label"style={{color:'#454545', marginTop:'60px'}}>On Average, How Many Units Do You Drink Per Week?</label>
                <TextField
                    label="Units"
                    type="number"
                    fullWidth
                    variant="outlined"
                    value={averageWeeklyUnits || 0}  // Default to empty string if surgeryDate is not set
                    onChange={(e) => setAverageWeeklyUnits(Number(e.target.value))}
                    InputLabelProps={{ shrink: true }}
                    style={{ fontSize: "1.2em", backgroundColor: "transparent", width:'100%', marginTop:'20px'}}
                    />
                </div>
                <div style={{paddingLeft:'30px',paddingRight:'30px', marginTop:'20px',display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>
                    <label className="question-label" style={{color: '#454545'}}>
                        How would you describe your exercise history?
                    </label>
                    <Slider
                        defaultValue={0}
                        value={exerciseHistory}
                        onChange={(e: Event, value: number | number[]) => setExerciseHistory(value as number)}
                        step={1}
                        min={0}
                        max={5}
                        marks={[
                            { value: 0, label: "Very rarely exercise" },
                            { value: 1, label: "Rarely exercise" },
                            { value: 2, label: "Occasionally exercise" },
                            { value: 3, label: "Sometimes exercise" },
                            { value: 4, label: "Exercise often" },
                            { value: 5, label: "Exercise every day" },
                        ]}
                        valueLabelDisplay="off" // Hides numeric labels
                        sx={{
                            ...sliderStyling,
                            '& .MuiSlider-markLabel': {
                                marginTop: '40px', // Adds spacing below the slider for labels
                                fontSize: '0.85em', // Optional: Adjusts font size of labels
                                color: '#454545', // Optional: Matches label text color
                            },
                        }}
                    />
                </div>
                <div style={{width:'100%', display:'flex', justifyContent:'space-between', borderRadius:'5px',position:'absolute', bottom:0}}>
                    <Button className="form-back-button" onClick={prevStage}>Previous</Button>
                    <Button className="form-next-button" onClick={nextStage}>Next Step</Button>
                </div>
                </div>
                )
            case 6:
                return (
                    <div className="form-stage" style={{display:'flex', flexDirection:'column', overflowY:'scroll', color:'#454545',  height:'70vh',maxHeight:'75vh', overflowX:'hidden', maxWidth:'60em'}}>
                        <h2 style={{ fontSize: '2em', alignSelf: 'center', marginBottom: '1vh', marginTop: '2vh', color: '#454545' }} className="parq-title">
                            {phq9.testName}
                        </h2>
                        <label>{phq9.description}</label>
                        
                        <div style={{ paddingLeft: '30px', paddingRight: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                            {phq9.questions.map((question, index) => (
                                <div key={index} style={{ marginBottom: '60px', width: '100%' }}>
                                    <label className="question-label" style={{ color: '#454545' }}>{`Q${index+1}. ${question.question}`}</label>
                                    <Slider
                                        defaultValue={0}
                                        value={phq9Result[index]}
                                        onChange={(e, value) => handleSliderChange(index, value as number)}
                                        step={1}
                                        min={0}
                                        max={3}
                                        marks={[
                                            { value: 0, label: "Not at all" },
                                            { value: 1, label: "Several days" },
                                            { value: 2, label: "More than half the days" },
                                            { value: 3, label: "Nearly every day" }
                                        ]}
                                        valueLabelDisplay="off"
                                        sx={{
                                            '& .MuiSlider-markLabel': {
                                                marginTop: '20px',
                                                fontSize: '0.85em',
                                                color: '#454545'
                                            },
                                        }}
                                    />
                                </div>
                            ))}
                        </div>

                    <div style={{width:'100%', display:'flex', justifyContent:'space-between', borderRadius:'5px',position:'absolute', bottom:0}}>
                        <Button className="form-back-button" onClick={prevStage}>Previous</Button>
                        <Button className="form-next-button" onClick={nextStage}>Next Step</Button>
                    </div>
                    </div>
                    )
            case 7:
                return (
                    <div className="form-stage" style={{display:'flex', flexDirection:'column', overflowY:'scroll', color:'#454545', width:'70em', height:'70vh',maxHeight:'75vh', overflowX:'hidden'}}>
                        <h2 style={{ fontSize: '2em', alignSelf: 'center', marginBottom: '1vh', marginTop: '2vh', color: '#454545' }} className="parq-title">
                            {gad7.testName}
                        </h2>
                        <label>{gad7.description}</label>
                        
                        <div style={{ paddingLeft: '30px', paddingRight: '30px', display: 'flex', justifyContent: 'center', width:'100%',alignItems: 'center', flexDirection: 'column' }}>
                            {gad7.questions.map((question, index) => (
                                <div key={index} style={{ marginBottom: '60px', width: '100%' }}>
                                    <label className="question-label" style={{ color: '#454545' }}>{`Q${index+1}. ${question.question}`}</label>
                                    <Slider
                                        defaultValue={0}
                                        value={gad7Result[index]}
                                        onChange={(e, value) => handleCad9SliderChange(index, value as number)}
                                        step={1}
                                        min={0}
                                        max={3}
                                        marks={[
                                            { value: 0, label: "Not at all" },
                                            { value: 1, label: "Several days" },
                                            { value: 2, label: "More than half the days" },
                                            { value: 3, label: "Nearly every day" }
                                        ]}
                                        valueLabelDisplay="off"
                                        sx={{
                                            '& .MuiSlider-markLabel': {
                                                marginTop: '20px',
                                                fontSize: '0.85em',
                                                color: '#454545'
                                            },
                                        }}
                                    />
                                </div>
                            ))}
                        </div>

                    <div style={{width:'100%', display:'flex', justifyContent:'space-between', borderRadius:'5px',position:'absolute', bottom:0}}>
                        <Button className="form-back-button" onClick={prevStage}>Previous</Button>
                        <Button className="form-next-button" onClick={nextStage}>Next Step</Button>
                    </div>
                    </div>
                    )
            case 8:
                    if (generatingPlan){
                        return (
                            <div className="form-stage" style={{maxHeight:'70vh', overflowY:'scroll', color:'#454545', display:'flex', justifyContent:'center', alignItems:'center', height:'100vh'}}>
                            <Spinner text="Generating Your Custom Plan!"/>
                        </div>
                        )
                    }else{
                        if (generatedPlan){
                            return (
                                <div style={{width:'86em',height:'74vh',backgroundColor:'#fefefe'}}>        
                                <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', backgroundColor:`${colorPalette.default}88`, width:'100%', height:'8vh', borderRadius:'0'}}>
                                    <div style={{backgroundColor:'transparent',  display:'flex', alignItems:'center', width:'100%'}}>
                                        {/* <FontAwesomeIcon className="back-button" style={{width:'2em', height:'2em', marginLeft:'0.75em', padding:'5px'}} onClick={()=>regeneratePlan()} icon={faRotateRight}></FontAwesomeIcon> */}
                                        {/* <label style={{fontWeight:'bold', fontSize:'1.3em', marginLeft:'8px'}}>Generate A New Plan</label> */}
                                    </div>
                                </div>
                                <div style={{display:'flex', width:'100%', height:'90%', flexDirection:'row', alignItems:'flex-start'}}>
                
                                        <div style={{display:'flex', width:'100%', height:'100%', flex:1, backgroundColor:'#f1f1f1', flexDirection:'column'}}>
                                            <label style={{color:'#565656', marginTop: '10px', padding:'5px 20px', fontSize:'1.5em', fontWeight:'bold', fontStyle:'italic'}}>{currentUser.currentVitovaPlan.title.toUpperCase()}</label>
                                            <label style={{color:'#565656', fontSize:'1.125em', textAlign:'justify', padding:'0px 20px',}}>{currentUser.currentVitovaPlan.description.slice(0, 400)}</label>
                
                                            <div style={{display: 'flex', marginTop:'1vh',margin:'10px 20px',alignItems: 'flex-start', flexDirection:'column', justifyContent:'flex-start'}}>
                                        <div style={{backgroundColor: `${colorPalette.steelBlue}dd`, padding: '6px 10px', width:'100%',borderRadius: '3px', marginTop: '5px', color: '#fff', fontWeight: 'bold'}}>
                                            {`${currentUser.currentVitovaPlan.content.length} WEEKS`}
                                        </div>
                                    </div>
                                    <button className="set-plan-button" 
                                                style={{
                                                    fontWeight: 'bold', 
                                                    color: '#fff', 
                                                    fontStyle:'italic',
                                                    padding: '10px 15px', 
                                                    borderRadius: '3px', 
                                                    border: 'none', 
                                                    cursor: 'pointer', 
                                                    width: 'auto',
                                                    transition: 'background-color 0.3s',
                                                    margin:'0px 20px',
                                                    fontSize:'1.25em'
                                                }}
                                                onClick={() => {
                                                    onInfoSet(currentUser); // Call onInfoSet with currentUser
                                                }}
                                                onMouseOver={(e) => e.currentTarget.style.backgroundColor = `steelblue`}
                                                onMouseOut={(e) => e.currentTarget.style.backgroundColor = colorPalette.steelBlue}
                                        >
                                            {`GO TO DASHBOARD`}
                                        </button>
                                        </div>
                                        <div style={{display:'flex', width:'100%', height:'100%', flex:2, flexDirection:'column', alignItems:'flex-start', justifyContent:'center'}}>
                                            <label style={{color:'#565656', marginTop: '10px', marginLeft:'1em', fontSize:'1.5em', fontWeight:'bold'}}>WEEK 1 TRAINING LOAD OVERVIEW</label>
                                            <GeneralChartComponent mh={80} mw={100} 
                                            chartData={currentUser.currentVitovaPlan.content[0].days.map(day=>getDayStressScore(day, user, false))} 
                                            labelData={currentUser.currentVitovaPlan.content[0].days.map((day:VitovaDay, index:number)=>`Day ${index+1}`)}

                                            user={currentUser}></GeneralChartComponent>
                                        </div>
                                    </div>
                            </div>
                            )
                        }else{
                            if (checkedConditions.filter(c=>c.checked === true).length <= 0){
                                return (
                                <div className="form-stage" style={{maxHeight:'70vh', overflowY:'scroll', color:'#454545', display:'flex', justifyContent:'flex-start', alignItems:'center', height:'100vh'}}>
                                <h2 style={{fontSize:'2em', alignSelf:'center', marginBottom:'5vh', marginTop:'2vh', color:'#454545'}} className="main-title">Review Your Answers Before Submitting</h2>
                                <table style={{width: '60em', borderCollapse: 'collapse'}}>
                                    <thead>
                                        <tr>
                                            <th style={{fontSize:'1.25em', padding:'0.75em', borderBottom: '2px solid #000'}}>Height</th>
                                            <th style={{fontSize:'1.25em', padding:'0.75em', borderBottom: '2px solid #000'}}>Weight</th>
                                            <th style={{fontSize:'1.25em', padding:'0.75em', borderBottom: '2px solid #000'}}>Waist</th>
                                            <th style={{fontSize:'1.25em', padding:'0.75em', borderBottom: '2px solid #000'}}>Conditions</th>
                                            <th style={{fontSize:'1.25em', padding:'0.75em', borderBottom: '2px solid #000'}}>Medications</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr  key={1}>
                                            <td style={{fontSize:'18px', padding:'0.75em', borderBottom: '1px solid #ddd', verticalAlign:'top'}}>{height}</td>
                                            <td style={{fontSize:'18px', padding:'0.75em', borderBottom: '1px solid #ddd', verticalAlign:'top'}}>{weight}</td>
                                            <td style={{fontSize:'18px', padding:'0.75em', borderBottom: '1px solid #ddd', verticalAlign:'top'}}>{waist}</td>
                                            {checkedConditions.filter(c=>c.checked).length > 0 && 
                                                <td style={{fontSize:'18px', padding:'0.75em', borderBottom: '1px solid #ddd', verticalAlign:'top'}}>
                                                    <ul>
                                                        {checkedConditions.map((c, index) => (
                                                            c.checked &&
                                                            <li key={index}>{c.condition.name}</li>
                                                        ))}
                                                    </ul>
                                                </td>
                                            }
                                            {checkedConditions.filter(c=>c.checked).length <= 0 && 
                                                <td style={{fontSize:'18px', padding:'0.75em', borderBottom: '1px solid #ddd', verticalAlign:'top'}}>None</td>
                                            }
                                            {checkedMedications.filter(c=>c.checked).length > 0 && 
                                                <td style={{fontSize:'18px', padding:'0.75em', borderBottom: '1px solid #ddd'}}>
                                                    <ul>
                                                        {checkedMedications.map((m, index) => (
                                                            m.checked &&
                                                            <li key={index}>{m.medication.name}</li>
                                                        ))}
                                                    </ul>
                                                </td>
                                            }
                                            {checkedMedications.filter(c=>c.checked).length <= 0 && 
                                                <td style={{fontSize:'18px', padding:'0.75em', borderBottom: '1px solid #ddd', verticalAlign:'top'}}>None</td>
                                            }
                                        </tr>
                                    </tbody>
                                </table>
                                
                                <div style={{width:'100%', display:'flex', justifyContent:'space-between', borderRadius:'5px',position:'absolute', bottom:0}}>
                                    <Button className="form-back-button" onClick={prevStage}>Previous</Button>
                                    <Button className="form-next-button" onClick={handleSubmitClick}>Finish And Generate My Plan!</Button>
                                </div>
                                </div>
                                )
                            }else{
                                return (
                                    <div className="form-stage" style={{maxHeight:'70vh', overflowY:'scroll', color:'#454545', display:'flex', justifyContent:'flex-start', alignItems:'center', height:'100vh'}}>
                                    <h2 style={{fontSize:'2em', alignSelf:'center', marginBottom:'5vh', marginTop:'2vh', color:'#454545'}} className="main-title">Review Your Answers Before Submitting</h2>
                                    <table style={{width: '60em', borderCollapse: 'collapse'}}>
                                        <thead>
                                            <tr>
                                                <th style={{fontSize:'1.25em', padding:'0.75em', borderBottom: '2px solid #000'}}>Height</th>
                                                <th style={{fontSize:'1.25em', padding:'0.75em', borderBottom: '2px solid #000'}}>Weight</th>
                                                <th style={{fontSize:'1.25em', padding:'0.75em', borderBottom: '2px solid #000'}}>Waist</th>
                                                <th style={{fontSize:'1.25em', padding:'0.75em', borderBottom: '2px solid #000'}}>Conditions</th>
                                                <th style={{fontSize:'1.25em', padding:'0.75em', borderBottom: '2px solid #000'}}>Medications</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr  key={1}>
                                                <td style={{fontSize:'18px', padding:'0.75em', borderBottom: '1px solid #ddd', verticalAlign:'top'}}>{height}</td>
                                                <td style={{fontSize:'18px', padding:'0.75em', borderBottom: '1px solid #ddd', verticalAlign:'top'}}>{weight}</td>
                                                <td style={{fontSize:'18px', padding:'0.75em', borderBottom: '1px solid #ddd', verticalAlign:'top'}}>{waist}</td>
                                                {checkedConditions.filter(c=>c.checked).length > 0 && 
                                                    <td style={{fontSize:'18px', padding:'0.75em', borderBottom: '1px solid #ddd', verticalAlign:'top'}}>
                                                        <ul>
                                                            {checkedConditions.map((c, index) => (
                                                                c.checked &&
                                                                <li key={index}>{c.condition.name}</li>
                                                            ))}
                                                        </ul>
                                                    </td>
                                                }
                                                {checkedConditions.filter(c=>c.checked).length <= 0 && 
                                                    <td style={{fontSize:'18px', padding:'0.75em', borderBottom: '1px solid #ddd', verticalAlign:'top'}}>None</td>
                                                }
                                                {checkedMedications.filter(c=>c.checked).length > 0 && 
                                                    <td style={{fontSize:'18px', padding:'0.75em', borderBottom: '1px solid #ddd'}}>
                                                        <ul>
                                                            {checkedMedications.map((m, index) => (
                                                                m.checked &&
                                                                <li key={index}>{m.medication.name}</li>
                                                            ))}
                                                        </ul>
                                                    </td>
                                                }
                                                {checkedMedications.filter(c=>c.checked).length <= 0 && 
                                                    <td style={{fontSize:'18px', padding:'0.75em', borderBottom: '1px solid #ddd', verticalAlign:'top'}}>None</td>
                                                }
                                            </tr>
                                        </tbody>
                                    </table>
                                    
                                    <div style={{width:'100%', display:'flex', justifyContent:'space-between', borderRadius:'5px',position:'absolute', bottom:0}}>
                                        <Button className="form-back-button" onClick={prevStage}>Previous</Button>
                                        <Button className="form-next-button" onClick={handleSubmitClick}>Find Me My Plan!</Button>
                                    </div>
                                    </div>
                                )
                            }
                        }
                    }
            default:
                return <></>
        }   

    }

    const sliderStyling = {
        color: 'gray', // or use a hex code like '#1976d2'
        height: 48, // height of the slider track
        width: '50em',
        borderRadius:'1px',
        margin:'5px 20px',
        '& .MuiSlider-thumb': {
            height: 48, // size of the thumb
            width: 6,
            backgroundColor: '#fff',
            borderRadius:'0px',
            // border: '2px solid currentColor',
            '&:hover': {
                boxShadow: 0,
                backgroundColor:'steelblue'

            },
            '&.Mui-active': {
                boxShadow: 0,
                backgroundColor:'steelblue'
            },
        },
        '& .MuiSlider-rail': {
            height: 48,
        },
        '& .MuiSlider-track': {
            height: 48,
        },
    }
    return (
        user.info ? 
        user.GPAccount ?
            <div className="home-page" style={{display:'flex', height:'70vh', justifyContent:'center'}}>
            <h2 style={{fontSize:'3.5em', color:'#454545'}} className="main-title">{`Hi ${user.fullname.split(' ')[0]}`}</h2>
            <p style={{color:'#454545'}}>{`Taking on another patient? Your code is ${user.gpReferralCode}`}</p>
            <div className="review-button" style={{display:'flex', width:'auto', marginTop:'0', height:'auto',flexDirection:'column', justifyContent:'center', alignContent:'center', alignItems:'center', justifyItems:'center'}}>
                <Link to={'/dashboard'} >Go To My Patients</Link>
            </div>
        </div>
        :
        <div className="home-page" style={{display:'flex', height:'70vh', justifyContent:'center'}}>
            <h2 style={{fontSize:'3.5em', color:'#454545'}} className="main-title">{`Welcome back ${user.fullname.split(' ')[0]}!`}</h2>
            <p style={{color:'#454545'}}>Ready to log another activity?</p>
            <div className="review-button" style={{display:'flex', width:'auto', marginTop:'0', height:'auto',flexDirection:'column', justifyContent:'center', alignContent:'center', alignItems:'center', justifyItems:'center'}}>
                <Link to={'/dashboard'} >Go To Dashboard</Link>
            </div>
        </div>
            :
            (
            user.GPAccount ?
                !user.isEmailVerified && enableEmailVerification ?
                <div className="home-page" style={{display:'flex', height:'70vh', justifyContent:'center'}}>
                <h2 style={{fontSize:'2.5em', color:'#454545'}} className="main-title">{`EMAIL VERIFICATION`}</h2>
                {emailSent ?
                    <p style={{color:'#454545'}}>{`We've sent an email to ${user.email}. Check your email and follow the link.`}</p>
                    :
                    <p style={{ color: '#454545', maxWidth:'60em'}}>
                    At <b>VITOVA</b>, we prioritize data protection and security. One essential step in this process is email verification. Please follow the instructions to verify your email address.
                    </p>

                }
                <div style={{outline:'none', border:'none', display:'flex', paddingTop:0,width:'auto', marginTop:0,flexDirection:'column', justifyContent:'center', alignContent:'center', alignItems:'center', justifyItems:'center'}}>
                    <button className="email-verfification-button"  style={{borderRadius:'5px', padding:'8px 12px', fontWeight:'bold'}} onClick={()=>sendVerificationEmail()}>{emailSent? 'SEND ANOTHER EMAIL' : 'SEND EMAIL VERIFICATION'}</button>
                </div>
                <label style={{fontSize:'1.25em', color:'#454545',}} className="main-title">{`OR`}</label>

                <div style={{outline:'none', border:'none', display:'flex', width:'auto', marginTop:'0', height:'auto',flexDirection:'column', justifyContent:'center', alignContent:'center', alignItems:'center', justifyItems:'center'}}>
                    <button className="email-verfification-button" style={{borderRadius:'5px', padding:'8px 12px', fontWeight:'bold'}} onClick={()=>logout()}>{'LOGOUT'}</button>
                </div>
            </div>
                :
            <div className="home-page" style={{display:'flex', height:'70vh', justifyContent:'center'}}>
                <h2 style={{fontSize:'3.5em', color:'#454545'}} className="main-title">{`Hi ${user.fullname.split(' ')[0]}`}</h2>
                <p style={{color:'#454545'}}>{`Taking on another patient? Your code is ${user.gpReferralCode}`}</p>
                <div className="review-button" style={{display:'flex', width:'auto', marginTop:'0', height:'auto',flexDirection:'column', justifyContent:'center', alignContent:'center', alignItems:'center', justifyItems:'center'}}>
                    <Link to={'/gpdashboard'} >Go To My Patients</Link>
                </div>
            </div>
            :
            questionStage === 0 ?
            !user.isEmailVerified && enableEmailVerification  ?
            <div className="home-page" style={{display:'flex', height:'70vh', justifyContent:'center'}}>
                <h2 style={{fontSize:'2.5em', color:'#454545'}} className="main-title">{`EMAIL VERIFICATION`}</h2>
                {emailSent ?
                    <p style={{color:'#454545'}}>{`We've sent an email to ${user.email}. Check your email and follow the link.`}</p>
                    :
                    <p style={{ color: '#454545', maxWidth:'60em'}}>
                    At <b>VITOVA</b>, we prioritize data protection and security. One essential step in this process is email verification. Please follow the instructions to verify your email address.
                    </p>

                }
                <div style={{outline:'none', border:'none', display:'flex', paddingTop:0,width:'auto', marginTop:0,flexDirection:'column', justifyContent:'center', alignContent:'center', alignItems:'center', justifyItems:'center'}}>
                    <button className="email-verfification-button"  style={{borderRadius:'5px', padding:'8px 12px', fontWeight:'bold'}} onClick={()=>sendVerificationEmail()}>{emailSent? 'SEND ANOTHER EMAIL' : 'SEND EMAIL VERIFICATION'}</button>
                </div>
                <label style={{fontSize:'1.25em', color:'#454545',}} className="main-title">{`OR`}</label>

                <div style={{outline:'none', border:'none', display:'flex', width:'auto', marginTop:'0', height:'auto',flexDirection:'column', justifyContent:'center', alignContent:'center', alignItems:'center', justifyItems:'center'}}>
                    <button className="email-verfification-button" style={{borderRadius:'5px', padding:'8px 12px', fontWeight:'bold'}} onClick={()=>logout()}>{'LOGOUT'}</button>
                </div>
            </div>
            :   
            <div className="home-page" style={{display:'flex', height:'70vh', flex:1,justifyContent:'center', alignItems:'center'}}>
                    <h2 style={{fontSize:'3.5em', color:'#454545', fontWeight:'bold', fontStyle:'italic'}} className="main-title">Hi There!</h2>
                    <p style={{color:'#454545'}}>Fill out some basic information to get started</p>
                    <label className="review-button" style={{marginTop:'0'}} onClick={()=>setQuestionStage(1)}>Start Your Journey With Vitova!</label>
                </div>
            :
            (
            <body style={{display:'flex', flex:1, justifyContent:'center', alignItems:'center', height:'80vh'}}>
                <div 
                style={{
                    backgroundColor: '#ffffff55', 
                    height: '80vh', 
                    width: '90em', 
                    display: 'flex', 
                    flexDirection: 'column', 
                    justifyContent: 'flex-start', 
                    borderRadius:'5px',
                    alignItems: 'center',
                    color:'white',
                    paddingTop:'20px'
                }}>

                    {questionStage === 1 && 
                    <div style={{width:'100%'}}>
                        <FontAwesomeIcon className="back-button" style={{marginLeft:'1.5em'}} onClick={()=>setQuestionStage(0)} icon={faArrowLeft}></FontAwesomeIcon>

                    </div>
                    }
                    <div>
                    {checkedUserType === 0 
                    ? renderFitnessForSurgeryPathway() : checkedUserType === 1 ? renderHealthAndWellbeingPathway() : renderGpReferralPathway()
                    }
                    </div>
                </div>
            </body>
            )
        )
        );

}

export default HomePageLoggedInView;