import { EMPTY_EXERCISE } from "../components/templateObjects/ObjectTemplates";
import { Exercise } from "../models/exercise";

export function getExerciseById(exercises:Array<Exercise>, exerciseId:string | undefined){
        if (!exerciseId){
            const emptyExercise: Exercise = { ...EMPTY_EXERCISE };
            return emptyExercise;
        }
        const findExercise = exercises.find(e=>e.id===exerciseId);

        if (findExercise){
            return findExercise
        }else{
            console.log('exercise not found')
            
            // Usage (if modification is needed)
            const emptyExercise: Exercise = { ...EMPTY_EXERCISE };

            return emptyExercise;
            
        }
    }