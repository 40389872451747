import { Exercise, VitovaExerciseCategory, VitovaExerciseEquipmentNeeded, VitovaExerciseForceType, VitovaExerciseLevel, VitovaExerciseMechanic } from "../../models/exercise";
import { VitovaActivity, VitovaActivityType, VitovaStepType } from "../../models/vitovaPlan";

export const EMPTY_EXERCISE: Readonly<Exercise> = Object.freeze({
    id: '',
    name: 'New Exercise',
    stepType: VitovaStepType.STRENGTH,
    force: VitovaExerciseForceType.PULL,
    level: VitovaExerciseLevel.BEGINNER,
    mechanic: VitovaExerciseMechanic.COMPOUND,
    equipment: VitovaExerciseEquipmentNeeded.HOME,
    primaryMuscles: [],
    secondaryMuscles: [],
    instructions: [],
    category: VitovaExerciseCategory.STRENGTH,
    images: [],
    video: '',
    joints: [],
    homeSuitable: false,
    barbell: false,
    dumbbell: false,
    bands: false,
    kettlebell: false,
    machine: false,
    progression: [],
    regression: [],
    isolation: false,
    compound: false,
    homeGymSwap: [],
    overhead: false,
    floor: false,
    isometric: false,
    axialLoad: false,
    abdominalStrain: false,
    highImpact: false
});

export const EMPTY_ACTIVITY: Readonly<VitovaActivity> = Object.freeze({
        id: `user_created_activity_${Date.now()}`,
        type: VitovaActivityType.STANDARD,
        title: `Untitled Activity`,
        description: "",
        plannedDuration: '60',
        plannedRPE: '5',
        steps: [],
        compulsory: false,
});